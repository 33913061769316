import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import Stats from 'repository/Stats';

export const getStats = createAsyncThunk('stats/getStats', async (params, token) => {
  const response = await Stats.get('', token);
  return response.data;
});

const stats = createSlice({
  name: 'stats',
  initialState: {
    loading: false,
    data: {},
    error: null
  },
  reducers: {
    get: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    }
  },
  extraReducers: {
    [getStats.fulfilled]: (state, action) => {
      state.data = action.payload?.data;
    },
    [getStats.rejected]: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const selectors = {
  getStats: createSelector(
    (state) => state.stats,
    (data) => data
  )
};

export function getFilteredStats(params, token) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Stats.get(params, token);
      if (response.status === 200) {
        dispatch(actions.success(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Stats');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export const { actions } = stats;

export default stats.reducer;
