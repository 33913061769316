import styled from 'styled-components';
import Rgba from 'utils/Rgba';

export const PickerWrapper = styled.div`
  .MuiOutlinedInput-notchedOutline {
    border-color: #dddbe1;
  }
  .MuiInputLabel-outlined {
    top: -8px;
    color: #808080;
  }
  .MuiInputLabel-shrink.MuiInputLabel-outlined {
    top: 0;
  }
  .MuiOutlinedInput-input {
    color: #4a4a4a;
    padding: 0 14px;
    min-height: 38px;
  }
`;

export const PickerLabel = styled.label`
  color: ${Rgba('#4A4A4A', 0.6)};
  display: block;
  font-size: 14px;
  line-height: 12px;
  margin: 0 0 6px 8px;
`;
