import SuperFetch from 'api/SuperFetch';

class Auth {
  login(data) {
    return SuperFetch(`auth/login`, {
      method: 'POST',
      data
    });
  }
}

export default new Auth();
