import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getStores } from 'redux/stores';
import MaterialTable from 'components/MaterialTable';
import { MTableToolbar } from 'material-table';
import Toolbar from './ToolBar';

const Index = ({ setShowForm, getStoreId}) => {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const getData = async (query) => {
    let body = {
      page: query.page + 1,
      limit: query.pageSize
    };
    const paramObj = history?.location?.state;

    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.zone) {
      body.zone = paramObj?.zone;
    }

    try {
      const res = await dispatch(getStores(body));
      const copy = JSON.parse(JSON.stringify(res));

      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  const columns = [
    {
      title: 'ID',
      field: 'id',
      sorting: false,
      editable: 'never',
      render: ({ id }) => <p>{id}</p>
    },
    {
      title: 'Name',
      field: 'name',
      sorting: false,
      // editable: 'never',
      render: ({ name }) => <p>{name}</p>
    },
    {
      title: 'Contact Name',
      field: 'primary_contact_name',
      sorting: false,
      // editable: 'never',
      render: ({ primary_contact_name }) => <p>{primary_contact_name}</p>
    },
    {
      title: 'Contact Number',
      field: 'primary_contact_phone',
      sorting: false,
      // editable: 'never',
      render: ({ primary_contact_phone }) => <p>{primary_contact_phone}</p>
    }
  ];

  return (
    <div>
      <MaterialTable
        liveSearch
        tableRef={tableRef}
        title={'Store'}
        onRowClick={(event, rowData) => {
          setShowForm(true)
          getStoreId(rowData)
        }}
        components={{
          // EditField: (props) => {
          //   if (props.columnDef.required && props.value.length === 0) {
          //     return <MTableEditField {...props} error label="Required" />;
          //   }
          //   return <MTableEditField {...props} />;
          // },
          Toolbar: (props) => {
            return (
              <>
                <MTableToolbar {...props} search={false} columnsButton={false} />
                <Toolbar
                  // open={open}
                  // handleClose={handleClose}
                  // handleClickOpen={handleClickOpen}
                  // handleAddStore={handleAddStore}
                  history={history}
                  tableRef={tableRef}
                />
              </>
            );
          }
        }}
        columns={columns}
        data={getData}
        options={{
          columnsButton: false,
          draggable: false,
          sorting: false,
          search: false,
          pageSize: 120,
          pageSizeOptions: [120, 150, 180, 200]
        }}
      />
    </div>
  );
};

export default Index;
