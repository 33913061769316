import { combineReducers } from 'redux';
import orders from 'redux/orders';
import orderDetails from 'redux/orderDetails';
import category from 'redux/category';
import stats from 'redux/stats';
import auth from 'redux/auth';
import notistack from 'redux/notifications';
import products from 'redux/products';
import dms from 'redux/dms';
import categories from 'redux/categories';
import productStatus from 'redux/productStatus'
import productPerStore from 'redux/productperstore';
import couponSlice from 'redux/couponSlice';
import customOrderSlice from 'redux/customOrderInfoSlice';
import voiceOrdersSlice from 'redux/voiceOrders';
import voiceOrderDetails from 'redux/voiceOrderDetails';

export default combineReducers({
  orders,
  products,
  orderDetails,
  category,
  stats,
  auth,
  notistack,
  dms,
  categories,
  productStatus,
  productPerStore,
  coupon: couponSlice,
  customOrder: customOrderSlice,
  voiceOrders: voiceOrdersSlice,
  voiceOrderDetails: voiceOrderDetails,
});
