import React from 'react';
import Select from 'react-select';
import chroma from 'chroma-js';

const ReactSelect = (props) => {
  const colourStyles = {
    container: (provided, state) => ({
      ...provided,
      minWidth: 160
    }),
    control: (styles, state) => ({
      ...styles,
      border: state.isFocused ? '1px solid rgba(0,0,0,0.23)' : '1px solid #e7e7e7',
      boxShadow: '0 !important',
      '&:hover': {
        borderColor: state.isFocused && 'rgba(0,0,0,0.23)'
      }
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma('rgb(255, 227, 63)');
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? 'rgb(255, 227, 63)'
          : isFocused
          ? 'rgba(108, 193, 255, 0.2)'
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : '#4a4a4a'
          : '#4a4a4a',
        fontWeight: isSelected ? 700 : null,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && isSelected && 'rgb(255, 227, 63)'
        }
      };
    },
    menu: (styles) => ({
      ...styles,
      zIndex: 9950
    }),
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles, color: '#4A4A4A', fontSize: 14 }),
    singleValue: (styles) => ({ ...styles })
  };
  return (
    <Select
      styles={colourStyles}
      classNamePrefix="sh"
      components={{
        IndicatorSeparator: () => null
      }}
      {...props}
    />
  );
};

export default ReactSelect;
