import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

export default {
  add: (data, token) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('brandImage', data.logo);
    return SuperFetch(`v2/brands/create`, {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    });
  },

  config: (data, token) => {
    const formData = new FormData();
    formData.append('brand', `${data.brand}`);
    formData.append('primary_color', data.primary_color);
    formData.append('secondary_color', data.secondary_color);
    formData.append('short_description', data.short_description);
    formData.append('bannerImage', data.banner);
    if (data?.footerBannerImage) {
      formData.append('footerBannerImage', data.footerBannerImage);
    }

    return SuperFetch(`v2/brand-configs/create`, {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    });
  },

  addMultipleProducts: (data, token) => {
    return SuperFetch(`v2/brand-products/add-multiple`, {
      method: 'POST',
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },

  removeMultipleProducts: (data, token) => {
    return SuperFetch(`v2/brand-products/remove-multiple`, {
      method: 'POST',
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },

  edit: (id, data, token) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('is_active', data.is_active ? true : false);
    if (data?.brandImage) {
      formData.append('brandImage', data.brandImage);
    }

    return SuperFetch(`v2/brands/${id}`, {
      method: 'PUT',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    });
  },
  configEdit: (id, data, token) => {
    const formData = new FormData();
    formData.append('primary_color', data.primary_color);
    formData.append('secondary_color', data.secondary_color);
    formData.append('short_description', data.short_description);
    if (data?.banner) {
      formData.append('bannerImage', data.banner);
    }

    if (data?.footerBannerImage) {
      formData.append('footerBannerImage', data.footerBannerImage);
    }

    return SuperFetch(`v2/brand-configs/${id}`, {
      method: 'PUT',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    });
  },

  delete: (id) => {
    return SuperFetch(`category/id/${id}`, {
      method: 'DELETE'
    });
  },

  getDetails(id) {
    return SuperFetch(`v2/brands/${id}`);
  },

  getBrands: async (params) => {
    return SuperFetch(`v2/brands?${querystring.stringify(params, { arrayFormat: 'comma' })}`);
    // return SuperFetch(`category?limit=120&page=1`);
  },

  getCategory: async (id) => {
    return SuperFetch(`category/id/${id}`);
  },

  addThumb: (id, thumb) => {
    var bodyFormData = new FormData();

    // console.log(thumb, "api");

    // Display the values
    bodyFormData.append('image', thumb[0]);

    return SuperFetch(`category/id/${id}/image`, {
      method: 'PUT',
      data: bodyFormData,
      headers: {
        'Content-Type':
          'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
        // 'X-Requested-With': 'XMLHttpRequest'
      }
    });
  }
};
