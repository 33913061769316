import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import reducers from './reducers';

export default function configureAppStore(preloadedState = {}) {
  const persistConfig = {
    key: 'dh-ops-panel',
    storage,
    version: 1,
    whitelist: ['auth'],
    stateReconciler: autoMergeLevel2
  };

  const persistedReducer = persistReducer(persistConfig, reducers);
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [...getDefaultMiddleware({ serializableCheck: false })],
    preloadedState
  });

  let persistor = persistStore(store);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(reducers));
  }

  return { store, persistor };
}
