import React from 'react';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import StoreForm from '../StoreForm/StoreForm';
import { Wrapper, Header } from './addStore.style';
import 'antd/dist/antd.css';


const AddStore = ({ open, handleClose, handleAddStore }) => {

  return (
    <Dialog open={open} onClose={handleClose} fullScreen fullWidth>
      <Wrapper>
        <Header>
          <Typography component="h3" className="title">
            Add a Store
          </Typography>
         <div>
          <Button variant="contained" color="secondary" onClick={handleClose} style={{margin: "0 4px"}}>
           Close
          </Button>
         </div>
        </Header>
        <StoreForm handleAddStore={handleAddStore} />
      </Wrapper>
    </Dialog>
  );
};

export default AddStore;
