import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

export default {

  add: (data) => {
    return SuperFetch(`category`, {
      method: 'POST',
      data
    });
  },

  edit: (id, data) => {
    return SuperFetch(`category/id/${id}`, {
      method: 'PUT',
      data
    });
  },

  delete: (id) => {
    return SuperFetch(`category/id/${id}`, {
      method: 'DELETE'
    });
  },

  getDetails(id) {
    return SuperFetch(`category/id/${id}`);
  },

  getCategories: async (params) => {
    return SuperFetch(`category?${querystring.stringify(params, { arrayFormat: 'comma' })}`);
    // return SuperFetch(`category?limit=120&page=1`);
  },

  getCategory: async (id) => {
    return SuperFetch(`category/id/${id}`);
  },

  addThumb: (id, thumb) => {

    var bodyFormData = new FormData();

    // console.log(thumb, "api");

    // Display the values
    bodyFormData.append('image', thumb[0]);

    return SuperFetch(`category/id/${id}/image`, {
      method: 'PUT',
      data: bodyFormData,
      headers: {
        'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        // 'X-Requested-With': 'XMLHttpRequest'
      }
    });
  },

};
