import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const StatsWrapper = styled(Paper)`
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  .title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  .closeState {
    position: absolute;
    right: 0;
    top: 12px;
  }
`;
export const StatGroup = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  ul {
    list-style: none;
    padding: 0;
    li {
      font-size: 16px;
      display: flex;
      font-weight: 500;
      justify-content: space-between;
      padding: 5px;
      border-bottom: 1px solid #e7e7e7;
      > span {
        &:first-of-type {
          min-width: 100px;
          text-align: left;
        }
        &:last-of-type {
          text-align: right;
          max-width: 240px;
        }
      }
    }
  }
  .input-field {
    display: flex;
    input {
      padding: 0 0 0 14px;
      min-height: 40px;
    }
  }
`;
export const Span = styled.span`
  font-weight: 400;
  min-width: 240px;
  text-align: left;
`;
