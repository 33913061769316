import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import { SearchWrapper, FormControl } from './storeProduct.style';
import useProductsSearch from './useProducts';
import Products from 'repository/Products';
import { useSelector } from 'react-redux';
import { ENV } from 'ENV';

const StoreProduct = ({ handleAddProduct, order }) => {
  const { inputText, setInputText, search } = useProductsSearch();
  const [product, setProduct] = useState(null);
  const [productList, setProductList] = useState([]);
  const ordZone = useSelector((state) => state?.orderDetails?.data?.data?.area?.zone);
  const handleSearch = async (e) => {
    e.persist();
    // setProduct(null);
    setInputText(e.target.value);
    const val = e.target.value;
    const response = await Products.get({ zone: ordZone, search: val });
    if (response.status === 200) {
      setProductList([...response?.data?.data]);
    }
  };

  const handleProductSelect = (item) => {
    // console.log(item);
    // console.log(item.images.thumb);
    const {
      id,
      name,
      selling_price,
      discounted_price,
      productPerStores,
      long_description,
      images,
      categories
    } = item;
    const address = `${productPerStores[0]?.store?.name}-${productPerStores[0]?.store?.address}-${productPerStores[0]?.store?.primary_contact_phone}`;

    const obj = {
      item_name: name,
      price: discounted_price ? discounted_price : selling_price,
      source_price: productPerStores[0].sourcing_price,
      description: long_description,
      assigned_pickup_source: address,
      product_id: id,
      image: `${ENV.REACT_APP_API_URL}${item?.images?.thumb}`,
      images: images,
      category_id: categories[0].id,
      category_name: categories[0].name,
      category_slug: categories[0].slug
    };
    setProduct({ ...obj });

    // console.log(obj)
  };


  const handleQuantityChange = (e) => {
    if (product) {
      setProduct({
        ...product,
        quantity: parseInt(e.target.value)
      });
    }
  };

  const addProduct = () => {
    if (product && product?.quantity) {
      handleAddProduct(product);
    }
  };

  return (
    <SearchWrapper>
      <FormControl>
        <label htmlFor="store_product">Search Products From Store </label>
        <TextField
          fullWidth
          id="store_product"
          name="store_product"
          variant="outlined"
          value={inputText}
          onChange={handleSearch}
        />
      </FormControl>
      {!product && search.loading && <p>Loading...</p>}
      {productList && (
        <List>
          {productList?.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem alignItems="flex-start" button onClick={() => handleProductSelect(item)}>
                <ListItemAvatar>
                  <Avatar
                    alt="Remy Sharp"
                    src={`${ENV.REACT_APP_API_URL}${item?.images?.thumb}`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={item.name}
                  secondary={`Price: ${
                    item?.discounted_price ? item?.discounted_price : item?.selling_price
                  }`}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      )}
      {product && (
        <>
          <p style={{ fontWeight: 'bold', fontSize: 18 }}>Product: {product?.item_name}</p>
          <p style={{ fontWeight: 'bold', fontSize: 18 }}>Price: {product?.price}</p>
          <FormControl>
            <label htmlFor="quantity">Quantity</label>
            <TextField
              fullWidth
              type="number"
              id="quantity"
              name="quantity"
              variant="outlined"
              onChange={handleQuantityChange}
            />
          </FormControl>
          <Button
            disabled={!product || !product?.quantity}
            variant="contained"
            color="primary"
            onClick={addProduct}
          >
            Add Product
          </Button>
        </>
      )}
    </SearchWrapper>
  );
};

export default StoreProduct;
