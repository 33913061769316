import styled from 'styled-components';

export const Page = styled.div``;

const EditFormWrapper = styled.div`
  max-width: 550px;
  margin: 0 auto;
  padding: 1.5rem 1rem 1rem;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: #fff;
  .MuiTextField-root {
    display: block;
  }

  .MuiButton-root {
    width: 100%;
  }
`;

export default EditFormWrapper;
