import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Container from 'components/Container';
import Header from 'components/Header';
import { bulkAddProducts } from 'redux/stores';
import Divider from '@material-ui/core/Divider';

import Wrapper, { Page, PageHeader, Main, MainContent, ImageSlider } from './StoreBulk.style';

const ProductsStoreBulkUpdate = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [file, setFile] = useState('');

  const onSubmit = async () => {
    if (file) {
      const res = await dispatch(bulkAddProducts(file));
      setProducts(res);
    }
  };

  const onChangeFile = (event) => {
    setFile(event.target.files[0]);
  };

  // console.log(products, thumbs, file, 'p');

  return (
    <Page>
      <Header />
      <Container>
        <Wrapper>
          <PageHeader>
            <h1 className="product-heading">Bulk Products to Stores Update</h1>
            <div className="product-upload">
              <label htmlFor="contained-button-file">
                <Button onClick={onSubmit} variant="contained" color="primary">
                  Update Products to Stores
                </Button>
              </label>
            </div>
          </PageHeader>
          <Main>
            <MainContent>
              {!file ? (
                <h4>
                  No file selected yet. Please select a file and click on upload products to Stores.
                </h4>
              ) : (
                <h4>Filename: {file?.name}</h4>
              )}
              <br />
              <Input type="file" onChange={onChangeFile} />
              <div style={{ margin: '20px 0px' }}>
                <div className="">Download Bulk Upload Product Template</div>
                <div style={{ margin: '10px 0px', display: 'flex' }}>
                  <div>Download link : </div>
                  <a
                    href="https://dh-public-assets.s3-ap-southeast-1.amazonaws.com/bulk_upload_template/Product_per_store_template.csv"
                    download
                  >
                    Click here
                  </a>
                </div>
              </div>
            </MainContent>
            <ImageSlider>
              {!!products.length && (
                <>
                  <h2>Products to Stores</h2>

                  {products?.map((item, index) => {
                    return (
                      <div key={index} style={{ padding: '12px 12px' }}>
                        <h4>Product ID: {item?.input?.product_id}</h4>
                        <h4>Store ID: {item?.input?.store_id}</h4>
                        <h4>Success: {item?.success ? 'True' : 'False'}</h4>
                        <Divider />
                      </div>
                    );
                  })}
                </>
              )}
            </ImageSlider>
          </Main>
        </Wrapper>
      </Container>
    </Page>
  );
};

export default ProductsStoreBulkUpdate;
