import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from 'redux/auth';

import { enqueueSnackbar } from './notifications';
import Orders from 'repository/Orders';

export const bulkUpdate = createAsyncThunk('orders/bulkUpdate', async (body, thunkApi) => {
  const response = await Orders.bulkUpdate(body);
  thunkApi.dispatch(
    enqueueSnackbar({
      message: 'Order updated successfully',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success'
      }
    })
  );
  return response.data;
});

const orders = createSlice({
  name: 'orders',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {
    get: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    }
  }
});

export const selectors = {
  getOrders: createSelector(
    (state) => state.orders,
    (data) => data
  )
};

export function getOrders(params, token) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Orders.get(params, token);
      if (response.status === 200) {
        dispatch(actions.success(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else if (response.status === 401) {
        dispatch(
          enqueueSnackbar({
            message: 'Your login session has expired, please login again',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        dispatch(logout());
      } else {
        throw new Error('Cannot get orders');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function editOrder(id, body) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      // console.log(body);
      const response = await Orders.edit(id, body);
      // console.log('response', response);
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Order updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
      } else {
        throw new Error('Cannot edit order');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Order updated failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}

export function updateCheckout(id, token, body) {
  return async (dispatch) => {
    try {
      const response = await Orders.updateCheckout(id, token, body);
      dispatch(
        enqueueSnackbar({
          message: 'Order updated successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      );
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Order update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export const { actions } = orders;

export default orders.reducer;
