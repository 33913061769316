import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { updateCheckout } from 'redux/orders';
import { useDispatch } from 'react-redux';

import { dateFormat, dateArr, timeArr, convertToIso } from '../../../utils/timeSlot';

const DateTime = (props) => {
  const [selectDate, setSelectDate] = useState({
    day: 'Today',
    date: moment().format('DD MMM YY hh:mm a')
  });

  const [selectedTime, setSelectedTime] = useState('');

  const dispatch = useDispatch();

  const handleTimeChange = (e) => {
    const value = JSON.parse(e.target.value);
    if (value.start) {
      let payload = {
        delivery_slot: {
          slot_start: convertToIso(selectDate, value),
          slot_end: convertToIso(selectDate, value)
        }
      };
      dispatch(updateCheckout(props?.id, { ...payload }));
    }
  };

  useEffect(() => {
    if (props?.slot) {
      setSelectedTime(moment(props?.slot?.slot_start).format('hh:mm a'));
      let farr = dateArr.filter(
        (f) =>
          dateFormat({ date: f?.date }) ===
          dateFormat({ date: moment(props?.slot?.slot_start).format('DD MMM YY hh:mm a') })
      );
      setSelectDate({ day: farr[0]?.day, date: moment(farr[0]?.date).format('DD MMM YY hh:mm a') });
    }
  }, [props?.slot]);
  return (
    <div>
      <select
        className="date-picker picker"
        onChange={(e) => {
          const value = JSON.parse(e.target.value);
          setSelectDate(value);
        }}
      >
        {dateArr.map((dates, idx) => (
          <option
            key={idx}
            value={JSON.stringify({
              day: dates?.day,
              date: moment(dates?.date).format('DD MMM YY hh:mm a')
            })}
            selected={
              dateFormat({ date: dates?.date }) ===
              dateFormat({ date: moment(props?.slot?.slot_start).format('DD MMM YY hh:mm a') })
            }
          >
            {dateFormat(dates)}
          </option>
        ))}
      </select>

      <div style={{ padding: '5px' }}></div>

      <select className="time-picker picker" onChange={handleTimeChange}>
        {selectDate?.day === 'Today'
          ? timeArr
              .filter((time, idx) => {
                let a = moment().format('DD MMM YY');
                let parseTime = Date.parse(`${a} ${time?.start}`);
                let selectTime = Date.parse(selectDate?.date);
                return selectTime < parseTime;
              })
              .map((e, indx) => (
                <option key={indx} value={JSON.stringify(e)} selected={selectedTime === e.start}>
                  {e.start} - {e.end}
                </option>
              ))
          : timeArr.map((time, idx) => (
              <option key={idx} value={JSON.stringify(time)} selected={selectedTime === time.start}>
                {time.start} - {time.end}
              </option>
            ))}
      </select>
    </div>
  );
};

export default DateTime;
