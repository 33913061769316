import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useForm, ErrorMessage } from 'react-hook-form';
import * as yup from 'yup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import ImageUploader from 'react-images-upload';
import AreaSelect from './CustomAreaSelect';
import SuperFetch from '../../../api/SuperFetch';
import { enqueueSnackbar } from '../../../redux/notifications';
import { changeZone, clearCart } from '../../../redux/customOrderInfoSlice';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

import Form, { FormControl } from './storeForm.style';
import { useHistory } from "react-router-dom";
import { logout } from 'redux/auth';

const storeSchema = yup.object().shape({
  // name: yup.string().required('Required'),
  // address: yup.string().required('Required'),
  // phone: yup.string().required('Required'),
  // primary_contact_name: yup.string().required('Required'),
  // primary_contact_phone: yup.string().required('Required'),
  // area_id: yup.string().required('Required'),
  // delivery_fee: yup.number().required('Required'),
  // delivery_start_date: yup.string().required('Required'),
  // delivery_end_date: yup.string().required('Required'),
  // delivery_type: yup.string().required('Required')
});

function createData(filed_name, price) {
  return { filed_name, price };
}

const StoreForm = ({ handleAddStore, storeID, single, setStore, StoreData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [thumb, setThumb] = useState([]);
  const [areaID, setAreaID] = useState([]);
  const [coupon, setCoupon] = useState('');
  const [couponData, setCouponData] = useState({});
  const [deliFee, setDeliFee] = useState(20);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('Cash on Delivery');
  const [deliType, setDeliType] = useState('1Hr');
  const [deliMethod, setDeliMethod] = useState('OFFLINE');
  const [deliStartDate, setDeliStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [orderChannel, setOrderChannel] = useState('');
  const [deliEndDate, setDeliEndDate] = useState('');
  const [phoneErr, setPhoneErr] = useState(false);

  const cartState = useSelector((state) => state.customOrder.cart);

  let totalCartPrice = 0;
  for (let i = 0; i < cartState.length; i++) {
    totalCartPrice += cartState[i].selling_price * cartState[i].quantity;
  }
  let serviceCharge = totalCartPrice > 300 ? Math.ceil(totalCartPrice * 0.095) : 20;
  if (totalCartPrice === 0) {
    serviceCharge = 0;
  } else if (totalCartPrice > 300 && totalCartPrice <= 500 && serviceCharge > 40) {
    serviceCharge = 40;
  } else if (totalCartPrice > 500 && totalCartPrice <= 1000 && serviceCharge > 100) {
    serviceCharge = 100;
  } else if (totalCartPrice > 1000 && totalCartPrice <= 2000 && serviceCharge > 100) {
    serviceCharge = 100;
  } else if (totalCartPrice > 2000 && serviceCharge > 150) {
    serviceCharge = 150;
  } else if (couponData?.extra_config?.sc_discount === 1) {
    serviceCharge = 0;
  }
  console.log(deliStartDate);

  let orderProcFee = 0;
  let vatFee = 0;
  let couponAmount = couponData?.discount_amount ? couponData?.discount_amount : 0;
  let deliAmount = couponData?.extra_config?.delivery_discount === 1 ? 0 : deliFee;

  // console.log(deliAmount)

  let totalPrice =
    totalCartPrice + serviceCharge + orderProcFee + vatFee + parseInt(deliAmount) - couponAmount;

  if (totalPrice < 0) {
    totalPrice = 0;
  }

  // console.log(totalCartPrice);

  const rows = [
    createData('Cart Price:', totalCartPrice),
    createData('Service Charge (up to 9.5%):', serviceCharge),
    createData('Order Processing Fee:', orderProcFee),
    createData('VAT:', vatFee),
    createData('Delivery Fee:', deliAmount),
    createData('Coupon Discount', couponAmount),
    createData('Total Fee:', totalPrice)
  ];

  const { register, control, handleSubmit, setValue, errors } = useForm({
    validationSchema: storeSchema,
    defaultValues: {
      name: '',
      address: '',
      primary_contact_name: '',
      primary_contact_phone: '',
      area_id: ''
    }
  });

  const zoneSlice = useSelector((state) => state.customOrder.zone);
  // console.log(zoneSlice)

  // console.log(cartState)
  // console.log(couponData)
  // console.log(couponData);

  const handleOrderPlacement = async () => {
    if (phone.length !== 1) {
      let regex = new RegExp(/^(?<phone>0?1[3-9]\d{8})$/); //accepts +880, 880, and 0
      if (phone.match(regex)) {
        console.log(phone.match(regex));
        setPhoneErr(false);
      } else {
        return setPhoneErr(true);
      }
    }
    const body = {
      fromOps: true,
      checkout_type: deliMethod,
      checkout_channel: orderChannel,
      additional_req: '',
      area: {
        id: zoneSlice?.value,
        area: zoneSlice?.area,
        zone: zoneSlice?.zone,
        station: zoneSlice?.station,
        is_active: zoneSlice?.is_active,
        is_available: zoneSlice?.is_available
      },
      area_id: zoneSlice?.value,
      area_name: zoneSlice?.area,
      coupon_code:
        couponData?.min_purchase < totalCartPrice && couponData?.code ? couponData?.code : '', //couponData?.min_purchase < totalCartPrice && couponData?.code ? couponData?.code : '',
      discount:
        couponData?.min_purchase < totalCartPrice && couponData?.code
          ? couponData?.discount_amount
          : 0,
      customer_name: name,
      customer_phone: phone,
      del_fee: parseInt(deliAmount),
      delivery_slot: {
        slot_start: deliStartDate,
        slot_end: deliEndDate
      },
      delivery_type: deliType,
      full_address: address,
      items: cartState,
      payment_method: paymentMethod,
      processing_fee: orderProcFee,
      service_charge: serviceCharge,
      vat: vatFee
    };

    // console.log(body)
    // console.log('body', body);
    try {
      const res = await SuperFetch(`/checkout`, {
        method: 'POST',
        data: body,
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${authToken}`
        }
      }).then((res) => res);
      console.log(res);
      if (res.status === 400 || res.status === 500) {
        dispatch(
          enqueueSnackbar({
            message: 'Error occurred while making order placement',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      } 
      if (res.status === 401) {
        dispatch(
          enqueueSnackbar({
            message: 'Your login session has expired, please login again',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        dispatch(logout());
        history.push("/");
      }
      
      else {
        dispatch(
          enqueueSnackbar({
            message: 'Order placement was successful',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(clearCart()); //clearing cart
        setAddress('');
        setName('');
        setDeliFee(0);
        setDeliType('');
        setDeliMethod('');
        setOrderChannel('');
        setPhone('');
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: 'Error occurred while making order placement API call',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      console.log(err);
    }
    // dispatch(changeZone('DH-Zone7'));
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isCouponBtnDisabled, setIsCouponBtnDisabled] = useState(true);

  // console.log(useSelector((state) => state.customOrder.zone));

  const authToken = useSelector((state) => state.auth.token);

  const handleCouponSubmit = async () => {
    // console.log(coupon);

    let res = await SuperFetch(`/discounts/coupons/${coupon}`).then((res) => res);

    // console.log(res);
    if (res?.status === 404 || res?.data.message === 'Invalid promo code!') {
      // window.alert('Invalid Promo Code');
      dispatch(
        enqueueSnackbar({
          message: 'Invalid Promo Code',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      setDialogOpen(true);
    } else {
      // console.log('Valid Promo code');
      dispatch(
        enqueueSnackbar({
          message: 'Promo code applied successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      );
      setCouponData(res?.data);
      setIsCouponBtnDisabled(true);
      setDialogOpen(false);
    }
  };

  const handleCouponFiledChange = (e) => {
    let text = e.target.value;
    if (text.length >= 2) {
      setIsCouponBtnDisabled(false);
      setCoupon(text);
    } else {
      setIsCouponBtnDisabled(true);
    }
  };

  // console.log('area id: ', areaID)
  // console.log(useSelector((state) => state.customOrder.zone));

  // useEffect(() => {
  //   if (deliMethod === 'OFFLINE') {
  //     setOrderChannel('');
  //   }
  // }, [orderChannel, deliMethod]);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="apply coupon"
        aria-describedby="apply coupon"
      >
        <DialogTitle id="alert-dialog-title">{'Add Coupon'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter your coupon code
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="e.g. KOLAMAN20"
            variant="outlined"
            onChange={handleCouponFiledChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCouponSubmit} disabled={isCouponBtnDisabled}>
            Apply Coupon
          </Button>
          <Button
            onClick={() => {
              setIsCouponBtnDisabled(true);
              setDialogOpen(false);
            }}
            autoFocus
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
      <Form onSubmit={handleSubmit(handleCouponSubmit)}>
        <div>
          <FormControl>
            <label htmlFor="area_id">Area</label>
            <AreaSelect
              onChange={(value) => {
                setAreaID(value);
                dispatch(changeZone(value));
              }}
              value={areaID}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="name">Customer Name</label>
            <TextField
              variant="outlined"
              fullWidth
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="name" />
            </span>
          </FormControl>
          <FormControl>
            <label htmlFor="phone">Customer Phone</label>
            <TextField
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              style={phoneErr ? { border: '1px solid red', borderRadius: '5px' } : {}}
              value={phone}
            />
            {phoneErr ? <b style={{ color: 'red' }}>Invalid Phone No.</b> : null}
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="name" />
            </span>
          </FormControl>
          <FormControl>
            <label htmlFor="address">Full Address</label>
            <TextField
              variant="outlined"
              fullWidth
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="address" />
            </span>
          </FormControl>
          <FormControl>
            <label htmlFor="payment_method">Payment Method</label>
            <TextField
              variant="outlined"
              fullWidth
              onChange={(e) => setPaymentMethod(e.target.value)}
              value={paymentMethod}
            />
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="payment_method" />
            </span>
          </FormControl>
          <br />
        </div>
        <div>
          <FormControl>
            <label htmlFor="delivery_fee">Delivery Fee</label>
            <TextField
              variant="outlined"
              fullWidth
              onChange={(e) => setDeliFee(e.target.value)}
              value={deliFee}
              type="number"
            />
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="delivery_fee" />
            </span>
          </FormControl>
          <FormControl>
            <label htmlFor="delivery_type">Delivery Type</label>
            <Select
              labelId="delivery_type"
              id="delivery_type"
              label="delivery_type"
              variant="outlined"
              fullWidth
              value={deliType}
              onChange={(e) => setDeliType(e.target.value)}
            >
              <MenuItem value={'1hr'}>1Hr</MenuItem>
              <MenuItem value={'24hr'}>24Hr</MenuItem>
            </Select>
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="delivery_type" />
            </span>
          </FormControl>
          <FormControl>
            <label htmlFor="delivery_method">Delivery Method</label>
            <Select
              labelId="delivery_type"
              id="delivery_method"
              label="delivery_method"
              variant="outlined"
              fullWidth
              value={deliMethod}
              onChange={(e) => setDeliMethod(e.target.value)}
            >
              <MenuItem value={'OFFLINE'}>Offline</MenuItem>
              <MenuItem value={'ONLINE'}>Online</MenuItem>
            </Select>
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="delivery_method" />
            </span>
          </FormControl>
          <FormControl>
            <label htmlFor="order_channel">Order Channel</label>
            <Select
              labelId="order_channel"
              id="order_channel"
              label="order_channel"
              variant="outlined"
              fullWidth
              // disabled={deliMethod !== 'ONLINE' ? true : false}
              value={orderChannel}
              onChange={(e) => setOrderChannel(e.target.value)}
            >
              <MenuItem value={'MESSENGER'} disabled={deliMethod !== 'ONLINE' ? true : false}>
                Messenger
              </MenuItem>
              <MenuItem value={'WHATSAPP'} disabled={deliMethod !== 'ONLINE' ? true : false}>
                Whatsapp
              </MenuItem>
              <MenuItem value={'GOOGLE_FORM'} disabled={deliMethod !== 'OFFLINE' ? true : false}>
                Google Form
              </MenuItem>
              <MenuItem value={'OTHERS'}>Others</MenuItem>
            </Select>
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="delivery_method" />
            </span>
          </FormControl>
          <label htmlFor="delivery_date" style={{ fontWeight: 'bold' }}>
            Delivery Date
          </label>
          <FormControl>
            <label htmlFor="delivery_start_date" style={{ marginTop: '1rem' }}>
              Start Date
            </label>
            <TextField
              variant="outlined"
              fullWidth
              data={deliStartDate}
              onChange={(e) => setDeliStartDate(e.target.value)}
              value={deliStartDate}
              type="date"
            />
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="delivery_start_date" />
            </span>
          </FormControl>
          <FormControl>
            <label htmlFor="delivery_end_date" style={{ marginTop: '1rem' }}>
              End Date
            </label>
            <TextField
              variant="outlined"
              fullWidth
              data={deliEndDate}
              onChange={(e) => setDeliEndDate(e.target.value)}
              value={deliEndDate}
              type="date"
            />
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="delivery_end_date" />
            </span>
          </FormControl>
          <br />
        </div>
        <div>
          <div style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOrderPlacement}
              disabled={
                name === '' ||
                phone === '' ||
                address === '' ||
                deliStartDate === '' ||
                deliEndDate === '' ||
                cartState.length < 1
                  ? true
                  : false
              }
            >
              Place Order
            </Button>
          </div>
          <br />
          <label>Checkout Details</label>
          <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Criteria</TableCell>
                  <TableCell align="right">BDT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.filed_name}
                    </TableCell>
                    <TableCell align="right">{row.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {couponData?.code ? (
            <div style={{ marginTop: '1rem' }}>
              <b style={{ color: 'purple' }}>Applied Coupon: {couponData?.code}</b>
            </div>
          ) : null}
          {couponData?.extra_config?.discount_text ? (
            <div style={{ marginTop: '1rem' }}>
              <b style={{ color: 'green' }}>{couponData?.extra_config?.discount_text}</b>
            </div>
          ) : null}
          <div style={{ textAlign: 'left', marginTop: '2rem' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDialogOpen(true)}
              disabled={cartState.length < 1 ? true : false}
            >
              Add Coupon
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default StoreForm;
