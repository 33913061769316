import styled from 'styled-components';

export const ColumnWrapper = styled.div`
  top: calc(100% + 16px);
  right: 0;
  padding: 8px 16px;
  z-index: 100;
  position: absolute;
  min-width: 300px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: #fff;
  max-height: 600px;
  overflow-y: auto;
  .MuiFormControlLabel-root {
    display: flex;
  }
`;
