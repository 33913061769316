import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'antd';
import { useDispatch } from 'react-redux';
import Products from 'repository/Products';

const { TreeNode } = TreeSelect;

const CategorySelect = ({ cats, setCatValue }) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [value, setValue] = useState(undefined);

  const onChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    !categories?.length &&
      (async () => {
        //this prevents api call
        // const res = await Products.getCategories({resolve_levels: 2});
        const res = await Products.getCategories({ cat_tree: true });
        const data = await res.data;
        const cat = await JSON.parse(JSON.stringify(data));
        setCategories(cat);
        console.log(cat);
      })();
  }, [dispatch, categories]);

  return (
    <>
      {categories?.length > 0 ? (
        <TreeSelect
          showSearch
          style={{ width: '100%' }}
          value={cats}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999999' }}
          placeholder="⚠️ Select Category (if blank, then this category will be parent)"
          allowClear
          multiple
          treeDefaultExpandAll
          size="middle"
          onChange={(value) => setCatValue(value)}
          filterTreeNode={(search, item) => {
            return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
          }}
        >
          {categories?.map(
            (
              item //parent_name
            ) => (
              <TreeNode key={item?.id} value={item?.id} title={item?.name}>
                {item?.second_layer_cats?.map(
                  (
                    child //second_layer_cats
                  ) => (
                    <TreeNode key={child?.id} value={child?.id} title={child?.name}>
                      <>
                        {child?.children?.map(
                          (
                            child //second_layer_cats.children
                          ) => (
                            <TreeNode key={child?.id} value={child?.id} title={child?.name} />
                          )
                        )}
                      </>
                    </TreeNode>
                  )
                )}
              </TreeNode>
            )
          )}
        </TreeSelect>
      ) : (
        <p style={{ marginTop: 2 }}> ⌛ Loading Please Wait ....</p>
      )}
    </>
  );
};

export default CategorySelect;
