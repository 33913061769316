import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Select from 'components/select/AsyncSelect';
import Areas from 'repository/CustomAreas';
import Rgba from 'utils/Rgba';

const useStyles = makeStyles({
  reactSelect: ({ minWidth, fullWidth }) => ({
    minWidth: minWidth ?? 200,
    maxWidth: fullWidth ? '100%' : 230
  }),
  selectLabel: {
    color: Rgba('#4A4A4A', 0.6),
    fontSize: 14,
    lineHeight: '12px',
    margin: '0 0 6px 8px'
  },
  selectBox: {
    '& .sh__control': {
      border: '1px solid #DDDBE1'
    }
  }
});

const ZoneSelect = ({ label, onChange, value }) => {
  const promiseOptions = async (inputValue) => {
    const res = await Areas.getAllZone(Object.assign(inputValue ? { zone: inputValue } : {}));
    const resObj = res.data.data.map((item) => ({
      label: item.areas_zone,
      zone: item.areas_zone,
      areas: item.areas
    }));
    // console.log(resObj);

    return resObj;
  };

  const classes = useStyles();

  return (
    <div className={classes.reactSelect}>
      {label && <p className={classes.selectLabel}>{label}</p>}
      <Select
        onChange={onChange}
        defaultValue={value}
        loadOptions={promiseOptions}
        className={classes.selectBox}
        placeholder={value ? value : 'Search Zone here'}
      />
    </div>
  );
};

export default ZoneSelect;
