import React from 'react';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Redirect } from 'react-router-dom';

import Header from 'components/Header';
import { loginRequest, selectors, logout } from 'redux/auth';

import { LoginPage, LoginWrapper } from './login.style';

const loginSchema = yup.object().shape({
  phone: yup.string().required(),
  password: yup.string().required()
});

const Login = () => {
  const isLoggedIn = useSelector(selectors.getAuth);
  const user = useSelector(selectors.getUser);
  const { control, handleSubmit, errors } = useForm({
    validationSchema: loginSchema
  });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    try{
      dispatch(loginRequest(data));
    }catch(err){
      window.alert("Login Failed")
    }
  }
  if (isLoggedIn && user) {
    if (user?.role === 'customer') {
      window.alert("You are not authorized for this action ☹️")
      dispatch(logout());
    }
    else{
      return (
        <Redirect
          to={{
            pathname: '/'
          }}
        />
      );
    }
  }
  return (
    <LoginPage>
      <Header />
      <LoginWrapper>
        <Typography component="h2" className="formTitle">
          Login
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={TextField}
            fullWidth
            label="Phone"
            name="phone"
            control={control}
            defaultValue=""
            variant="outlined"
            className="textField"
          />
          <ErrorMessage errors={errors} name="phone" />
          <Controller
            as={TextField}
            fullWidth
            label="Password"
            name="password"
            type="password"
            control={control}
            defaultValue=""
            variant="outlined"
            className="textField"
          />
          <ErrorMessage errors={errors} name="password" />
          <Button type="submit" variant="contained" color="primary">
            Login
          </Button>
        </form>
      </LoginWrapper>
    </LoginPage>
  );
};

export default Login;
