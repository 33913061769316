import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import Button from "@material-ui/core/Button";
import Container from 'components/Container';
import Header from 'components/Header';
import { updateStore, addStoreProduct } from 'redux/stores';
import StoreForm from '../StoreForm/StoreForm';
import ProductsTable from "../ProductsTable";
import AddProduct from '../AddProduct';


import Wrapper, {
  Page,
} from "./storeDetails.style";



const StoreDetails = () => {
  // const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [store, setStore] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => window.scroll({ top: 0, behavior: 'smooth' }), 500);
  }, [])

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onSubmit = (sid, body) => {
    console.log("update store");
    dispatch(updateStore({id: sid, data: body}));
  };

  const onProductSubmit = (body) => {
    console.log("add store products", body);
    dispatch(addStoreProduct(body));
    // toggleOpen();
  }
  
  return (
    <Page>
      <Header/>
    <Container>
    <Wrapper>
      <StoreForm storeID={id} single={true} handleAddStore={onSubmit} setStore={setStore} StoreData={store}/>
    </Wrapper>
    </Container>
    <ProductsTable storeID={id} toggleOpen={toggleOpen}/>
    <AddProduct handleClose={toggleOpen} open={open} handleAddProduct={onProductSubmit} store={store} />
    </Page>
  );
};

export default StoreDetails;
