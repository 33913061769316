import styled from 'styled-components';

export const Page = styled.div``;

export const OrdersTable = styled.div`
  .MuiTableCell-root:first-child {
    /* width: 30px !important; */
  }
  .MuiTableRow-head {
    .MuiTableCell-root {
      white-space: nowrap;
    }
  }
  &.isOpen {
    padding-bottom: 140px;
  }
  .text__typography {
    font-size: 12px;
    color: #545252;
    line-height: 15px;
  }
  .date_time {
    min-width: 100px;
  }
  .hold_reason {
    min-width: 130px;
    padding: 10px 0;
  }
  .customer__name{
    display: block;
    text-transform: capitalize;
    font-size: 12px;
  }
  .city_area {
    min-width: 160px;
    span {
      opacity: 0.8;
    }
  }
  .cart__collection {
    display: block;
  }
  .assigned_agent {
    min-width: 130px;
  }
`;
