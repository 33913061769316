import React, { useEffect } from 'react';
import { TreeSelect } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getProductStatusAct } from 'redux/productStatus';

const { TreeNode } = TreeSelect;

const StatusSelect = ({ status, setStatusValue }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  useEffect(() => {
    dispatch(getProductStatusAct());
  }, [dispatch]);

  return (
    <TreeSelect
      showSearch
      style={{ width: '100%' }}
      value={status}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999999' }}
      placeholder="Select Status"
      allowClear
      treeDefaultExpandAll
      size="middle"
      onChange={(value) => setStatusValue(value)}
    >
      {state?.productStatus?.status?.map((item) => (
        <TreeNode key={item?.status} value={item?.status} title={item?.label} />
      ))}
    </TreeSelect>
  );
};

export default StatusSelect;
