import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { transform, isEqual, isObject } from 'lodash';
import { useHistory } from 'react-router-dom';

import { getStores, updateStore, addStore} from 'redux/stores';
import MaterialTable from 'components/MaterialTable';
import Container from 'components/Container';
import Header from 'components/Header';
import { MTableToolbar, MTableEditField } from 'material-table';
import Toolbar from './Toolbar/toolbar';
import { Page, StoresTable } from './StoresPage.style';
// import isPositiveInt from 'utils/isPositiveInt';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const StoresPage = () => {
  const classes = useStyles();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddStore = async (data) => {
    const body = {
      ...data
    };
    // console.log(body);
    const res = await dispatch(addStore(body));
    console.log(res, "catress");
    if(res?.success) {
      handleClose();
      tableRef.current.onQueryChange();
    }
  };

  const getData = async (query) => {
    let body = {
      page: query.page + 1,
      limit: query.pageSize
    };
    const paramObj = history?.location?.state;

    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.zone) {
      body.zone = paramObj?.zone;
    }
    
    try {
      const res = await dispatch(getStores(body));
      const copy = JSON.parse(JSON.stringify(res));

      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  const diffObject = (object, base) => {
    function changes(object, base) {
      return transform(object, function(result, value, key) {
        if (!isEqual(value, base[key])) {
          result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
        }
      });
    }
    return changes(object, base);
  }

  const handleRowEdit = async (newData, oldData) => {
    const data = JSON.parse(JSON.stringify(oldData)); // copy oldData object
    delete data.tableData; // tableData was added by material-table

    const parsedData = {
      ...newData,
    };
    const diff = diffObject(parsedData, data);
    // console.log(diff);
    if (Boolean(Object.keys(diff).length)) {
      await dispatch(updateStore({ id: newData.id, data: diff }));
    }
  };

  // const handleRowDelete = async (oldData) => {
  //   await dispatch(deleteStore(oldData?.id))
  // }

  const columns = [
    {
      title: 'ID',
      field: 'id',
      sorting: false,
      editable: 'never',
      render: ({ id }) => <p>{id}</p>
    },
    {
      title: 'Name',
      field: 'name',
      sorting: false,
      // editable: 'never',
      render: ({ name }) => <p>{name}</p>
    },
    {
      title: 'Address',
      field: 'address',
      sorting: false,
      // editable: 'never',
      render: ({ address }) => <p>{address}</p>
    },
    {
      title: 'Contact Name',
      field: 'primary_contact_name',
      sorting: false,
      // editable: 'never',
      render: ({ primary_contact_name }) => <p>{primary_contact_name}</p>
    },
    {
      title: 'Contact Number',
      field: 'primary_contact_phone',
      sorting: false,
      // editable: 'never',
      render: ({ primary_contact_phone }) => <p>{primary_contact_phone}</p>
    },
    {
      title: 'Tags',
      field: 'tags',
      sorting: false,
      editable: 'never',
      render: ({ tags }) => <div className={classes.root}>{tags?.map((item, id) => <Chip size="small" label={item} key={id} />)}</div>
    },
  ];

  return (
    <Page>
      <Header />
      <Container>
        <StoresTable>
          <MaterialTable
            liveSearch
            tableRef={tableRef}
            title={'Stores'}
            onRowClick={(event, rowData) => history.push(`store/${rowData.id}`)}
            components={{
              EditField: (props) => {
                if (props.columnDef.required && props.value.length === 0) {
                  return <MTableEditField {...props} error label="Required" />;
                }
                return <MTableEditField {...props} />;
              },
              Toolbar: (props) => {
                return (
                  <>
                    <MTableToolbar {...props} search={false} columnsButton={false} />
                    <Toolbar
                      open={open}
                      handleClose={handleClose}
                      handleClickOpen={handleClickOpen}
                      handleAddStore={handleAddStore}
                      history={history}
                      tableRef={tableRef}
                    />
                  </>
                );
              }
            }}
            columns={columns}
            data={getData}
            options={{
              columnsButton: true,
              draggable: false,
              sorting: false,
              pageSize: 120,
              pageSizeOptions: [120, 150, 180, 200]
            }}
            editable={{
              onRowUpdate: (newData, oldData) => handleRowEdit(newData, oldData),
              // onRowDelete: (oldData) => handleRowDelete(oldData)
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange()
              }
            ]}
          />
        </StoresTable>
      </Container>
    </Page>
  );
};

export default StoresPage;
