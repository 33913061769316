import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from './notifications';
import Orders from 'repository/VoiceOrders';
import { logout } from 'redux/auth';

export const bulkUpdate = createAsyncThunk('orders/bulkUpdate', async (body, thunkApi) => {
  const response = await Orders.bulkUpdate(body);
  thunkApi.dispatch(
    enqueueSnackbar({
      message: 'Order updated successfully',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success'
      }
    })
  );
  return response.data;
});

const initialState = {
  zone: 'DH-Z8',
  cart: [],
  tempUnlistedProds: [],
  tempUnlistedProdsNames: [],
  newlyAddedUnlistedProdName: [],
  tempUnlistedProdIds: [],
  loading: false
};

const voiceOrdersSlice = createSlice({
  name: 'customOrderSlice',
  initialState,
  reducers: {
    changeZone: (state, action) => {
      state.zone = action.payload;
      console.log(action.payload);
    },
    addToCart: (state, action) => {
      state.cart.push(action.payload);
    },
    clearCart: (state) => {
      state.cart = [];
    },
    addToTempUnlistedProds: (state, action) => {
      state.tempUnlistedProds.push(action.payload);
    },
    addToTempUnlistedProdIds: (state, action) => {
      state.tempUnlistedProdIds.push(action.payload);
    },
    removeUnlistedProdsByIndex: (state, action) => {
      // console.log(action.payload);
      state.tempUnlistedProds.splice(action.payload, 1);
      state.tempUnlistedProdIds.splice(action.payload, 1);
      state.newlyAddedUnlistedProdName.splice(
        state.newlyAddedUnlistedProdName.indexOf(state.tempUnlistedProdsNames[action.payload]),
        1
      );
      state.tempUnlistedProdsNames.splice(action.payload, 1);
    },
    addToTempUnlistedProdNames: (state, action) => {
      state.tempUnlistedProdsNames.push(action.payload);
    },
    addToNewlyAddedUnlistedProdName: (state, action) => {
      state.newlyAddedUnlistedProdName.push(action.payload);
    },
    clearTempUnlistedProds: (state) => {
      state.tempUnlistedProds = [];
      state.tempUnlistedProdsNames = [];
      state.newlyAddedUnlistedProdName = [];
    },
    get: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    }
  }
});

export function getOrders(params, token) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Orders.get(params, token);
      if (response.status === 200) {
        dispatch(actions.success(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else if (response.status === 401) {
        dispatch(
          enqueueSnackbar({
            message: 'Your login session has expired, please login again',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        dispatch(logout());
      } else {
        throw new Error('Cannot get orders');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export const selectors = {
  getOrders: createSelector(
    (state) => state.orders,
    (data) => data
  )
};

export function editOrder(id, body) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      // console.log(body);
      const response = await Orders.edit(id, body);
      // console.log('response', response);
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Order updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
      } else {
        throw new Error('Cannot edit order');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Order updated failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}

export function updateCheckout(id, body, token) {
  return async (dispatch) => {
    try {
      const response = await Orders.updateCheckout(id, body, token);
      if (response?.status === 200 || response?.status === 204) {
        console.log(response);
        dispatch(
          enqueueSnackbar({
            message: 'Order updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: `Order update failed`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Order update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export function updateConfirmLabeling(id, body, token) {
  return async (dispatch) => {
    try {
      const response = await Orders.confirmLabeling(id, body, token);
      if (response?.status === 200 || response?.status === 204 || response?.status === 201) {
        console.log(response);
        dispatch(
          enqueueSnackbar({
            message: 'Order updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: `Order update failed`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Order update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export function updateConfirmOrder(id, body, token) {
  return async (dispatch) => {
    try {
      const response = await Orders.confirmOrder(id, body, token);
      if (response?.status === 200 || response?.status === 204 || response?.status === 201) {
        console.log(response);
        dispatch(
          enqueueSnackbar({
            message: 'Order updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: `Order update failed`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Order update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export function updateSingleVpcItemQty(id, body, token) {
  return async (dispatch) => {
    try {
      const response = await Orders.updateSingleVpcQty(id, body, token);
      if (response?.status === 200 || response?.status === 204) {
        console.log(response);
        // dispatch(
        //   enqueueSnackbar({
        //     message: 'Order updated successfully',
        //     options: {
        //       key: new Date().getTime() + Math.random(),
        //       variant: 'success'
        //     }
        //   })
        // );
      } else {
        dispatch(
          enqueueSnackbar({
            message: `Order update failed`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Order update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export function updateCustomProducts(id, body, token) {
  return async (dispatch) => {
    try {
      const response = await Orders.addCustomProductVpc(id, body, token);
      if (response?.status === 200 || response?.status === 201) {
        console.log(response);
        dispatch(
          enqueueSnackbar({
            message: 'Order updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: `Order update failed`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Order update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export function deleteCheckOutItem(id, body, token) {
  return async (dispatch) => {
    try {
      const response = await Orders.deleteOrderItem(id, body, token);
      if (response?.status === 200 || response?.status === 204) {
        console.log(response);
        dispatch(
          enqueueSnackbar({
            message: 'Order updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: `Order update failed`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Order update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export function deleteReqItem(id, body, token) {
  return async (dispatch) => {
    try {
      const response = await Orders.deleteReqItem(id, body, token);
      if (response?.status === 200 || response?.status === 204) {
        console.log(response);
        dispatch(
          enqueueSnackbar({
            message: 'Order updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: `Order update failed`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Order update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export const { actions } = voiceOrdersSlice;
export const {
  changeZone,
  addToCart,
  clearCart,
  addToTempUnlistedProds,
  clearTempUnlistedProds,
  addToTempUnlistedProdNames,
  addToNewlyAddedUnlistedProdName,
  removeUnlistedProdsByIndex
} = voiceOrdersSlice.actions;
export default voiceOrdersSlice.reducer;
