import { useState } from 'react';
import useConstant from 'use-constant';
import { useAsync } from 'react-async-hook';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import Products from 'repository/Products';

const searchAPI = (text) =>
  Products.fetchProducts({
    search: text,
    per_page: 10
  });

const useProductsSearch = () => {
  // Handle the input text state
  const [inputText, setInputText] = useState('');

  // Debounce the original search async function
  const debouncedSearchProducts = useConstant(() => AwesomeDebouncePromise(searchAPI, 300));

  const search = useAsync(debouncedSearchProducts, [inputText]);

  // Return everything needed for the hook consumer
  return {
    inputText,
    setInputText,
    search
  };
};

export default useProductsSearch;
