/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useRef, useEffect } from 'react';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { getOrders, editOrder, updateCheckout, bulkUpdate } from 'redux/voiceOrders';
import { getOrderStatus, selectors } from 'redux/voiceOrderDetails';
import { compare } from 'fast-json-patch';
import moment from 'moment';
import { MTableToolbar, MTableEditField } from 'material-table';
import MaterialTable from 'components/MaterialTable';
// import PageHeader from 'components/PageHeader';
// import Select from 'components/select/Select';
import Container from 'components/Container';
import Header from 'components/Header';
import { orders } from 'data/orders';
import formatDate from 'utils/formatDate';
import OrderDetailPane from './OrderDetailPane';
import PdfDocument from './pdfDocument';
import BatchEdit from './BatchEdit';
import OrderTableToolbar from './OrderTableToolbar';
// import { zones } from 'data/zones';
import { orderHoldReasons } from 'data/orderHoldReasons';
import { Page, OrdersTable } from './home.style';
import Button from '@material-ui/core/Button';
import Print from 'components/icons/Print';
import { OpenInNew } from '@material-ui/icons';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { getStats, selectors as statSelectors } from 'redux/stats';

const holdReasonLabels = orderHoldReasons.reduce(
  (reasons, { value, label }) => ({ ...reasons, [value]: label }),
  {}
);

const VoiceOrders = () => {
  const tableRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const voiceOrders = useSelector((state) => state.voiceOrders?.data);
  const [data, setData] = useState();
  const [button, setButton] = useState(1);
  const [voiceOrderCount, setVoiceOrderCount] = useState(0);
  const [selectedFilter, setSelcetedFilter] = useState('NONE');
  const [chkOutTypeFilter, setChkOutTypeFilter] = useState('');
  const [deliMethod, setDeliMethod] = useState('OFFLINE');
  const [cuurentStatus, setCurrentStatus] = useState('IN_REVIEW');
  // const tableRef = React.createRef();
  // const [openBatchEdit, setOpenBatchEdit] = useState(false);
  const orderStatus = useSelector(selectors.getStatus);
  const [tableData, setTableData] = useState([]);
  const authToken = useSelector((state) => state.auth.token);

  const getData = async (query) => {
    let body = {
      page: !isNaN(query.page) ? query.page + 1 : 1,
      limit: query.pageSize,
      current_status: 'PENDING_LABELLING'
    };
    const paramObj = history?.location?.state;
    if (paramObj?.date) {
      body.date = paramObj?.date;
    }
    if (paramObj?.delivery_date) {
      body.delivery_date = paramObj?.delivery_date;
    }
    if (paramObj?.status) {
      const status = paramObj?.status?.map((st) => st.value);
      body.status = status;
    }
    if (paramObj?.zones) {
      const zones = paramObj?.zones?.map((zone) => zone.value);
      body.zones = zones;
    }
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.delivery_type) {
      console.log(paramObj?.delivery_type);
      body.delivery_type = paramObj?.delivery_type.value;
    }
    try {
      const res = await dispatch(getOrders(body, authToken));
      const copy = JSON.parse(JSON.stringify(res));
      setVoiceOrderCount(copy?.count);
      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  const getPendingLabelingData = async (query) => {
    let body = {
      page: !isNaN(query.page) ? query.page + 1 : 1,
      limit: query.pageSize,
      current_status: 'PENDING_LABELLING'
    };
    const paramObj = history?.location?.state;
    if (paramObj?.date) {
      body.date = paramObj?.date;
    }
    if (paramObj?.delivery_date) {
      body.delivery_date = paramObj?.delivery_date;
    }
    if (paramObj?.status) {
      const status = paramObj?.status?.map((st) => st.value);
      body.status = status;
    }
    if (paramObj?.zones) {
      const zones = paramObj?.zones?.map((zone) => zone.value);
      body.zones = zones;
    }
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.delivery_type) {
      console.log(paramObj?.delivery_type);
      body.delivery_type = paramObj?.delivery_type.value;
    }
    try {
      const res = await dispatch(getOrders(body, authToken));
      const copy = JSON.parse(JSON.stringify(res));
      setVoiceOrderCount(copy?.count);
      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  const getConfirmedData = async (query) => {
    let body = {
      page: !isNaN(query.page) ? query.page + 1 : 1,
      limit: query.pageSize,
      current_status: 'CONFIRMED'
    };
    const paramObj = history?.location?.state;
    if (paramObj?.date) {
      body.date = paramObj?.date;
    }
    if (paramObj?.delivery_date) {
      body.delivery_date = paramObj?.delivery_date;
    }
    if (paramObj?.status) {
      const status = paramObj?.status?.map((st) => st.value);
      body.status = status;
    }
    if (paramObj?.zones) {
      const zones = paramObj?.zones?.map((zone) => zone.value);
      body.zones = zones;
    }
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.delivery_type) {
      console.log(paramObj?.delivery_type);
      body.delivery_type = paramObj?.delivery_type.value;
    }
    try {
      const res = await dispatch(getOrders(body, authToken));
      const copy = JSON.parse(JSON.stringify(res));
      setVoiceOrderCount(copy?.count);
      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };
  const getCheckoutTypeData = async (query) => {
    let body = {
      page: !isNaN(query.page) ? query.page + 1 : 1,
      limit: query.pageSize,
      pre_checkout_type: chkOutTypeFilter
    };
    const paramObj = history?.location?.state;
    if (paramObj?.date) {
      body.date = paramObj?.date;
    }
    if (paramObj?.delivery_date) {
      body.delivery_date = paramObj?.delivery_date;
    }
    if (paramObj?.status) {
      const status = paramObj?.status?.map((st) => st.value);
      body.status = status;
    }
    if (paramObj?.zones) {
      const zones = paramObj?.zones?.map((zone) => zone.value);
      body.zones = zones;
    }
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.delivery_type) {
      console.log(paramObj?.delivery_type);
      body.delivery_type = paramObj?.delivery_type.value;
    }
    try {
      const res = await dispatch(getOrders(body, authToken));
      const copy = JSON.parse(JSON.stringify(res));
      setVoiceOrderCount(copy?.count);
      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };
  const getPendingRecoData = async (query) => {
    let body = {
      page: !isNaN(query.page) ? query.page + 1 : 1,
      limit: query.pageSize,
      current_status: 'PENDING_RECOMMENDATION'
    };
    const paramObj = history?.location?.state;
    if (paramObj?.date) {
      body.date = paramObj?.date;
    }
    if (paramObj?.delivery_date) {
      body.delivery_date = paramObj?.delivery_date;
    }
    if (paramObj?.status) {
      const status = paramObj?.status?.map((st) => st.value);
      body.status = status;
    }
    if (paramObj?.zones) {
      const zones = paramObj?.zones?.map((zone) => zone.value);
      body.zones = zones;
    }
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.delivery_type) {
      console.log(paramObj?.delivery_type);
      body.delivery_type = paramObj?.delivery_type.value;
    }
    try {
      const res = await dispatch(getOrders(body, authToken));
      const copy = JSON.parse(JSON.stringify(res));
      setVoiceOrderCount(copy?.count);
      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };
  const getPendingRecoAndConifrmData = async (query) => {
    let body = {
      page: !isNaN(query.page) ? query.page + 1 : 1,
      limit: query.pageSize,
      current_status: 'PENDING_RECOMMENDATION_AND_PENDING_CONFIRMATION'
    };
    const paramObj = history?.location?.state;
    if (paramObj?.date) {
      body.date = paramObj?.date;
    }
    if (paramObj?.delivery_date) {
      body.delivery_date = paramObj?.delivery_date;
    }
    if (paramObj?.status) {
      const status = paramObj?.status?.map((st) => st.value);
      body.status = status;
    }
    if (paramObj?.zones) {
      const zones = paramObj?.zones?.map((zone) => zone.value);
      body.zones = zones;
    }
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.delivery_type) {
      console.log(paramObj?.delivery_type);
      body.delivery_type = paramObj?.delivery_type.value;
    }
    try {
      const res = await dispatch(getOrders(body, authToken));
      const copy = JSON.parse(JSON.stringify(res));
      setVoiceOrderCount(copy?.count);
      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };
  const getPendinConfirmData = async (query) => {
    let body = {
      page: !isNaN(query.page) ? query.page + 1 : 1,
      limit: query.pageSize,
      current_status: 'PENDING_CONFIRMATION'
    };
    const paramObj = history?.location?.state;
    if (paramObj?.date) {
      body.date = paramObj?.date;
    }
    if (paramObj?.delivery_date) {
      body.delivery_date = paramObj?.delivery_date;
    }
    if (paramObj?.status) {
      const status = paramObj?.status?.map((st) => st.value);
      body.status = status;
    }
    if (paramObj?.zones) {
      const zones = paramObj?.zones?.map((zone) => zone.value);
      body.zones = zones;
    }
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.delivery_type) {
      console.log(paramObj?.delivery_type);
      body.delivery_type = paramObj?.delivery_type.value;
    }
    try {
      const res = await dispatch(getOrders(body, authToken));
      const copy = JSON.parse(JSON.stringify(res));
      setVoiceOrderCount(copy?.count);
      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  const getCancelledOrderData = async (query) => {
    let body = {
      page: !isNaN(query.page) ? query.page + 1 : 1,
      limit: query.pageSize,
      current_status: 'CANCELLED'
    };
    const paramObj = history?.location?.state;
    if (paramObj?.date) {
      body.date = paramObj?.date;
    }
    if (paramObj?.delivery_date) {
      body.delivery_date = paramObj?.delivery_date;
    }
    if (paramObj?.status) {
      const status = paramObj?.status?.map((st) => st.value);
      body.status = status;
    }
    if (paramObj?.zones) {
      const zones = paramObj?.zones?.map((zone) => zone.value);
      body.zones = zones;
    }
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.delivery_type) {
      console.log(paramObj?.delivery_type);
      body.delivery_type = paramObj?.delivery_type.value;
    }
    try {
      const res = await dispatch(getOrders(body, authToken));
      const copy = JSON.parse(JSON.stringify(res));
      setVoiceOrderCount(copy?.count);
      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };
  // console.log(tableData);

  const [selectedRows, setSelectedRows] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [closeState, setCloseState] = useState(true);

  const closeBatchEdit = () => {
    setIsEditOpen(false);
  };

  const handleRowSelect = (rows) => {
    const selectEnabled = !!rows.length;
    const ids = rows.map((r) => r.id);
    setSelectedRows(ids);
    if (selectEnabled !== isEditOpen) {
      setIsEditOpen(selectEnabled);
    }
  };

  const handleBatchEdit = async (values) => {
    const body = {};
    Object.keys(values).forEach((item) => {
      console.log(values);
      if (values[item] !== undefined) {
        body[item] = values[item];
      }
    });

    if (values?.current_status) {
      const status = values?.current_status?.value;
      body.current_status = status;
    }

    if (Object.keys(body).length) {
      body.checkout_ids = selectedRows;
      await dispatch(bulkUpdate(body));
      tableRef.current.onQueryChange();
      closeBatchEdit();
    } else {
      console.log('no changes');
    }
  };

  useEffect(() => {
    dispatch(getOrderStatus());
    console.log(getData({ page: 0 }));
  }, [dispatch]);

  const makePdfData = async (data) => {
    setData(data);
  };

  const handleRowEdit = async (newData, oldData) => {
    if (!newData.customer_name || !newData.customer_phone || !newData.full_address) {
      throw new Error('Required field');
    }
    const data = JSON.parse(JSON.stringify(oldData)); // copy oldData object
    delete data.tableData; // tableData was added by material-table
    const diff = compare(data, newData);
    // console.log(diff);
    if (Boolean(diff.length)) {
      await dispatch(editOrder(newData.id, diff));
    }
  };

  const handleOrderUpdate = async (id, body) => {
    await dispatch(updateCheckout(id, body, authToken));
    tableRef.current.onQueryChange();
    // tableRef?.current?.onQueryChange() || console.log('Table ref not found');
  };

  const columns = [
    {
      title: 'Order ID',
      field: 'id',
      sorting: false,
      editable: 'never',
      // cellStyle: {
      //   width: 'calc(100% - 0px) / 9) !important',
      //   maxWidth: 'calc(100% - 0px) / 9) !important',
      // },
      // headerStyle: {
      //   width: 'calc(100% - 0px) / 9) !important',
      //   maxWidth: 'calc(100% - 0px) / 9) !important',
      // },
      render: ({ id }) => <p className="text__typography">DH-{id}</p>
    },
    // {
    //   title: 'Unique ID',
    //   field: 'id',
    //   sorting: false,
    //   editable: 'never',
    //   // cellStyle: {
    //   //   width: 'calc(100% - 0px) / 9) !important',
    //   //   maxWidth: 'calc(100% - 0px) / 9) !important',
    //   // },
    //   // headerStyle: {
    //   //   width: 'calc(100% - 0px) / 9) !important',
    //   //   maxWidth: 'calc(100% - 0px) / 9) !important',
    //   // },
    //   render: ({ unique_code }) => <p className="text__typography">{unique_code}</p>
    // },
    {
      title: 'Store Coupon',
      field: 'id',
      sorting: false,
      editable: 'never',
      // cellStyle: {
      //   width: 'calc(100% - 0px) / 9) !important',
      //   maxWidth: 'calc(100% - 0px) / 9) !important',
      // },
      // headerStyle: {
      //   width: 'calc(100% - 0px) / 9) !important',
      //   maxWidth: 'calc(100% - 0px) / 9) !important',
      // },
      render: ({ store_coupon }) => <p className="text__typography">{store_coupon}</p>
    },

    // {
    //   title: 'Hold Reason',
    //   field: 'hold_reason',
    //   sorting: false,
    //   editable: 'never',
    //   render: ({ hold_reason }) => {
    //     return <div className="hold_reason">{holdReasonLabels?.[hold_reason]}</div>;
    //   }
    // },
    {
      title: 'Order Time',
      field: 'order_time',
      sorting: false,
      editable: 'never',
      render: ({ created_at }) => (
        <div className="date_time text__typography">
          {moment(created_at).format('hh:mm:ss A')} <br />
          {formatDate(created_at)}
        </div>
      )
    },
    {
      title: 'Name',
      field: 'customer_name',
      sorting: false,
      required: true,
      render: ({ user }) => (
        <>
          <span className="customer__name text__typography">{user?.name}</span>
        </>
      )
    },
    {
      title: 'Phone No.',
      field: 'phone_no.',
      sorting: false,
      required: true,
      render: ({ user }) => (
        <>
          <span className="customer__name text__typography">{user?.phone}</span>
        </>
      )
    },
    {
      title: 'Cart Price',
      field: 'cart_price',
      sorting: false,
      required: true,
      render: ({ cart_price }) => (
        <>
          <span className="customer__name text__typography">Tk {cart_price}</span>
        </>
      )
    },
    {
      title: 'Checkout Type',
      field: 'checkout_type',
      sorting: false,
      required: true,
      render: ({ pre_checkout_type, id }) => (
        <>
          <span className="customer__name text__typography">{pre_checkout_type}</span>
        </>
      )
    },
    // {
    //   title: 'Customer Phone',
    //   field: 'customer_phone',
    //   required: true,
    //   sorting: false,
    //   render: ({ customer_phone }) => <p>{customer_phone}</p>
    // },
    {
      title: 'Full Address & Area',
      field: 'area_id',
      sorting: false,
      editable: 'never',
      render: ({ address }) => (
        <>
          <span className="text__typography">
            {address?.full_address ? address?.full_address : ''}
          </span>
          <div className="city_area text__typography">
            <b>
              {address?.area ? address?.area : ''} <br />
            </b>
            {/* <span>Zone</span>: {area.zone} <br /> */}
            {/* <span>Station</span>: {area.station} */}
          </div>
        </>
      )
    },
    {
      title: 'Current Status',
      field: 'current_status',
      sorting: false,
      required: true,
      render: ({ current_status, id }) => (
        <>
          <Select
            labelId="delivery_type"
            id="delivery_method"
            label="delivery_method"
            variant="outlined"
            width={'200px'}
            value={current_status}
            onChange={(e) => {
              setCurrentStatus(e.target.value);
              handleOrderUpdate(id, { current_status: e.target.value });
              tableRef.current.onQueryChange();
            }}
          >
            <MenuItem value={'PENDING_LABELLING'} disabled={true}>
              Pending Labeling
            </MenuItem>
            <MenuItem value={'PENDING_RECOMMENDATION_AND_PENDING_CONFIRMATION'} disabled={true}>
              Pending Reco. & Confirmation
            </MenuItem>
            <MenuItem value={'CONFIRMED'} disabled={true}>
              Confirmed
            </MenuItem>
            <MenuItem value={'CANCELLED'} disabled={true}>
              Canceled
            </MenuItem>
            <MenuItem value={'TEST_ORDER'}>
              Test Order
            </MenuItem>
          </Select>
        </>
      )
    }
    // {
    //   title: 'Full Address',
    //   field: 'full_address',
    //   sorting: false,
    //   required: true,
    //   render: ({ full_address }) => <p>{full_address}</p>
    // },
    // {
    //   title: 'Delivery Type',
    //   field: 'delivery_type',
    //   sorting: false,
    //   editable: 'never',
    //   render: ({ delivery_type }) => <p>{delivery_type}</p>
    // },
    // {
    //   title: 'Additional Req',
    //   field: 'additional_req',
    //   sorting: false,
    //   editable: 'never',
    //   editComponent: (props) => <Select options={orderHoldReasons} />,
    //   render: ({ additional_req }) => <p style={{ minWidth: 160 }}>{additional_req}</p>
    // },
    // {
    //   title: (
    //     <span>
    //       Total Collectable <br />& Cart Items
    //     </span>
    //   ),
    //   field: 'total_collectable',
    //   sorting: false,
    //   type: 'currency',
    //   editable: 'never',
    //   cellStyle: {
    //     textAlign: 'left'
    //   },
    //   render: ({ order_price, items_count }) => (
    //     <>
    //       <span className="cart__collection text__typography">Tk {order_price}</span>
    //       <span className="cart__collection text__typography">{items_count} items</span>
    //     </>
    //   )
    // },
    // {
    //   title: 'Cart Items',
    //   field: 'cart_items',
    //   type: 'numeric',
    //   editable: 'never',
    //   sorting: false,
    //   render: ({ items_count }) => <p>{items_count}</p>
    // },
    // {
    //   title: 'Status & Hold Reason',
    //   field: 'order_status',
    //   sorting: false,
    //   editable: 'never',
    //   render: ({ status, hold_reason, admin_comment }) => (
    //     <>
    //       <span className="text__typography">{status?.label}</span>
    //       <div className="hold_reason text__typography">{holdReasonLabels?.[hold_reason]}</div>
    //       <div className="text__typography">{admin_comment}</div>
    //     </>
    //   )
    // },
    // {
    //   title: 'Delivery Time',
    //   field: 'delivery_date',
    //   editable: 'never',
    //   sorting: false,
    //   render: ({ delivery_date }) => <p className="text__typography">{formatDate(delivery_date)}</p>
    // },
    // {
    //   title: 'Assigned Agent',
    //   field: 'assigned_agent',
    //   sorting: false,
    //   editable: 'never',
    //   render: ({ delivery_man }) => (
    //     <div className="assigned_agent text__typography">
    //       {delivery_man?.name}
    //       <br />
    //       {delivery_man?.phone}
    //     </div>
    //   )
    // },
    // {
    //   title: 'Unclear?',
    //   field: 'unclear',
    //   editable: 'yes',
    //   sorting: false,
    //   render: ({ voice_status, id }) => (
    //     <>
    //       <FormControlLabel
    //         label="Unclear"
    //         control={
    //           <Switch
    //             checked={voice_status}
    //             onChange={() => {
    //               handleOrderUpdate(id, { voice_status: !voice_status })
    //               tableRef.current.onQueryChange();
    //             }}
    //           />
    //         }
    //         className="closeState"
    //       />
    //     </>
    //   )
    // }

    // {
    //   title: 'Cart Price',
    //   field: 'cart_price',
    //   type: 'numeric',
    //   editable: 'never',
    //   sorting: false,
    //   render: ({ cart_price }) => <p>Tk {cart_price}</p>
    // }
  ];

  const name = data?.map((ck) => `DH-${ck.id}-${ck?.area?.area?.slice(8)}`)?.join('+') ?? 'pdf';

  return (
    <Page>
      <Header />
      <Container>
        <OrdersTable className={isEditOpen && 'isOpen'}>
          {/* <PageHeader title="DeliveryHobe Orders Management Dashboard" data={stats.data} /> */}
          <div style={{ textAlign: 'end' }}>
            <span onClick={() => setData(undefined)}>
              {data && (
                <PDFDownloadLink
                  document={<PdfDocument data={data} />}
                  fileName={`${name}.pdf`}
                  style={{
                    textDecoration: 'none',
                    color: '#4a4a4a',
                    backgroundColor: '#f2f2f2'
                  }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      ''
                    ) : (
                      <Button className="print-button" variant="contained" color="primary">
                        <Print stroke="#4A4A4A" width={19} height={19} /> Print {name}
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
            </span>
          </div>
          <h2>Total Order(s): {voiceOrderCount}</h2>
          <MaterialTable
            liveSearch
            tableRef={tableRef}
            title={voiceOrderCount > 1 ? 'Voice Orders Table' : 'Voice Order Table'}
            columns={columns}
            data={
              selectedFilter === 'NONE'
                ? getPendingLabelingData
                : selectedFilter === 'PENDING_LABELLING'
                ? getPendingLabelingData
                : selectedFilter === 'PENDING_RECOMMENDATION'
                ? getPendingRecoData
                : selectedFilter === 'PENDING_RECOMMENDATION_AND_PENDING_CONFIRMATION'
                ? getPendingRecoAndConifrmData
                : selectedFilter === 'PENDING_CONFIRMATION'
                ? getPendinConfirmData
                : selectedFilter === 'CHECKOUT'
                ? getCheckoutTypeData
                : selectedFilter === 'CONFIRMED'
                ? getConfirmedData
                : selectedFilter === 'CANCELLED'
                ? getCancelledOrderData
                : []
            }
            onSelectionChange={handleRowSelect}
            // onRowClick={(event, rowData) => history.push(`voice-order/${rowData.id}`)}
            onSearchChange={(v) => console.log(v)}
            components={{
              EditField: (props) => {
                if (props.columnDef.required && props.value?.length === 0) {
                  return <MTableEditField {...props} error label="Required" />;
                }
                return <MTableEditField {...props} />;
              },
              Toolbar: (props, rowData) => {
                console.log(props);
                // const { actions } = props;
                return (
                  <>
                    <MTableToolbar {...props} search={false} columnsButton={false} />
                    {/* <OrderTableToolbar
                      columns={columns}
                      tableRef={tableRef}
                      hideColumn={props.onColumnsChanged}
                      orderStatus={orderStatus}
                      button={button}
                      setButton={setButton}
                      {...props}
                    /> */}
                    <div style={{ padding: '1rem' }}>
                      {/* <Button
                        type="button"
                        variant="contained"
                        color={button === 4 ? 'primary' : 'default'}
                        style={{ margin: '0 4px' }}
                        onClick={() => {
                          // onSubmit({ current_status: [{ value: 'PENDING', label: 'Pending' }] });
                          setSelcetedFilter('NONE');
                          tableRef.current.onQueryChange();
                          setButton(4);
                        }}
                      >
                        All Orders
                      </Button> */}
                      <Button
                        type="button"
                        variant="contained"
                        color={button === 1 ? 'primary' : 'default'}
                        style={{ margin: '0 4px' }}
                        onClick={() => {
                          // onSubmit({ current_status: [{ value: 'PENDING', label: 'Pending' }] });
                          setSelcetedFilter('PENDING_LABELLING');
                          tableRef.current.onQueryChange();
                          setButton(1);
                        }}
                      >
                        {'Pending Labeling'}
                      </Button>
                      {/* <Button
                        type="button"
                        variant="contained"
                        color={button === 2 ? 'primary' : 'default'}
                        style={{ margin: '0 4px' }}
                        onClick={() => {
                          // onSubmit({ voice_status: true });
                          setSelcetedFilter('UNCLEAR');
                          tableRef.current.onQueryChange();
                          setButton(2);
                        }}
                      >
                        {'Unclear'}
                      </Button> */}
                      {/* <Button
                        type="button"
                        variant="contained"
                        color={button === 3 ? 'primary' : 'default'}
                        style={{ margin: '0 4px' }}
                        onClick={() => {
                          // onSubmit({
                          //   current_status: [
                          //     { value: 'COMPLETED', label: 'Completed' },
                          //     { value: 'COMPLETED', label: 'Completed' }
                          //   ]
                          // });
                          setSelcetedFilter('PENDING_RECOMMENDATION');
                          tableRef.current.onQueryChange();
                          setButton(3);
                        }}
                      >
                        {'Pending Recommendation'}
                      </Button> */}
                      <Button
                        type="button"
                        variant="contained"
                        color={button === 5 ? 'primary' : 'default'}
                        style={{ margin: '0 4px' }}
                        onClick={() => {
                          // onSubmit({
                          //   current_status: [
                          //     { value: 'COMPLETED', label: 'Completed' },
                          //     { value: 'COMPLETED', label: 'Completed' }
                          //   ]
                          // });
                          setSelcetedFilter('PENDING_RECOMMENDATION_AND_PENDING_CONFIRMATION');
                          tableRef.current.onQueryChange();
                          setButton(5);
                        }}
                      >
                        {'Pending Reco. & Confirmation'}
                      </Button>
                      {/* <Button
                        type="button"
                        variant="contained"
                        color={button === 7 ? 'primary' : 'default'}
                        style={{ margin: '0 4px' }}
                        onClick={() => {
                          // onSubmit({
                          //   current_status: [
                          //     { value: 'COMPLETED', label: 'Completed' },
                          //     { value: 'COMPLETED', label: 'Completed' }
                          //   ]
                          // });
                          setSelcetedFilter('PENDING_CONFIRMATION');
                          tableRef.current.onQueryChange();
                          setButton(7);
                        }}
                      >
                        {'Pending Confirmation'}
                      </Button> */}
                      <Button
                        type="button"
                        variant="contained"
                        color={button === 8 ? 'primary' : 'default'}
                        style={{ margin: '0 4px' }}
                        onClick={() => {
                          // onSubmit({
                          //   current_status: [
                          //     { value: 'COMPLETED', label: 'Completed' },
                          //     { value: 'COMPLETED', label: 'Completed' }
                          //   ]
                          // });
                          setSelcetedFilter('CONFIRMED');
                          tableRef.current.onQueryChange();
                          setButton(8);
                        }}
                      >
                        {'Confirmed'}
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color={button === 9 ? 'primary' : 'default'}
                        style={{ margin: '0 4px' }}
                        onClick={() => {
                          // onSubmit({
                          //   current_status: [
                          //     { value: 'COMPLETED', label: 'Completed' },
                          //     { value: 'COMPLETED', label: 'Completed' }
                          //   ]
                          // });
                          setSelcetedFilter('CANCELLED');
                          tableRef.current.onQueryChange();
                          setButton(9);
                        }}
                      >
                        {'Cancelled'}
                      </Button>
                      <Select
                        labelId="delivery_type"
                        id="delivery_method"
                        label="delivery_method"
                        variant="outlined"
                        width={'200px'}
                        value={chkOutTypeFilter}
                        onChange={(e) => {
                          setChkOutTypeFilter(e.target.value);
                          setSelcetedFilter('CHECKOUT');
                          tableRef.current.onQueryChange();
                        }}
                      >
                        <MenuItem value={'OFFLINE'}>Offline</MenuItem>
                        <MenuItem value={'ONLINE'}>Online</MenuItem>
                      </Select>
                    </div>
                  </>
                );
              }
            }}
            options={{
              columnsButton: true,
              draggable: false,
              selection: true,
              pageSize: 120,
              pageSizeOptions: [120, 150, 180, 200],
              detailPanelType: 'single'
              // actionsColumnIndex: -1
              // sorting: false
            }}
            // editable={{
            //   onRowUpdate: (newData, oldData) => handleRowEdit(newData, oldData)
            // }}
            actions={[
              // {
              //   tooltip: 'click for pdf',
              //   icon: (props) => <PictureAsPdfIcon />,
              //   onClick: (evt, data) => {
              //     // console.log(evt);
              //     makePdfData(data);
              //   }
              //   // isFreeAction: true
              // },
              {
                icon: 'refresh',
                tooltip: 'Reset Data',
                isFreeAction: true,
                onClick: () => {
                  setSelcetedFilter('NONE');
                  setButton(1);
                  tableRef.current.onQueryChange();
                }
              },
              {
                icon: () => <OpenInNew />,
                tooltip: 'Open in New Tab',
                position: 'row',
                onClick(evt, rowData) {
                  window.open(`voice-order/${rowData.id}`, '_blank');
                }
              }
            ]}
            detailPanel={(rowData) => {
              // console.log(rowData);
              return (
                <OrderDetailPane
                  order={rowData}
                  onOrderUpdate={handleOrderUpdate}
                  order_id={rowData.id}
                  refreshTable={() => {
                    if (selectedFilter === 'NONE' || selectedFilter === 'PENDING_LABELLING') {
                      getPendingLabelingData({ page: 0 });
                      tableRef.current.onQueryChange();
                    } else if (selectedFilter === 'PENDING_RECOMMENDATION') {
                      getPendingRecoData({ page: 0 });
                      tableRef.current.onQueryChange();
                    } else if (
                      selectedFilter === 'PENDING_RECOMMENDATION_AND_PENDING_CONFIRMATION'
                    ) {
                      getPendingRecoAndConifrmData({ page: 0 });
                      tableRef.current.onQueryChange();
                    } else if (selectedFilter === 'PENDING_CONFIRMATION') {
                      getPendinConfirmData({ page: 0 });
                      tableRef.current.onQueryChange();
                    }
                  }}
                />
              );
            }}
          />
        </OrdersTable>
        {/* <BatchEdit onSubmit={handleBatchEdit} open={isEditOpen} onClose={closeBatchEdit} /> */}
      </Container>
    </Page>
  );
};

export default VoiceOrders;
