import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const Wrapper = styled(Paper)`
  text-align: left;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1840px;
`;

export const Group = styled.div`
  text-align: left;
  padding: 16px;
  width: calc(25% - 20px);
  margin-right: 20px;
  .groupTitle {
    margin-top: 0;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 12px;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    span {
      display: block;
      color: #707070;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dhSelect {
    min-width: 240px;
  }
  .MuiOutlinedInput-input {
    padding: 0 14px;
    min-height: 40px;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`;
