import styled from 'styled-components';

export const Page = styled.div``;

export const OrdersTable = styled.div`
  .MuiTableCell-root:first-child {
    /* width: 30px !important; */
  }
  .MuiTableRow-head {
    .MuiTableCell-root {
      white-space: nowrap;
    }
  }
`;
