import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

import CustomProductSelect from './CustomProductSelcet';
import Products from 'repository/CustomProducts';
import { ProductPriceWrapper, ProductTopWrapper, ProductItem } from './addProduct.style';
import { addToCart } from '../../../redux/customOrderInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ENV } from 'ENV';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1.5, 0),
      width: '45%'
    }
  }
}));

const AddForm = ({ handleAddProduct, store, zone }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [state, setState] = useState({
    product_id: '',
    quantity: '',
    sourcing_price: ''
  });

  const [selectedItem, setSelectedItem] = useState({});

  const handleChange = (name) => (event) => {
    event.persist();
    setState((prev) => ({ ...prev, [name]: event.target.value }));
  };

  let cartArr = [];

  const handleProductChange = async (value) => {
    const pd = await Products.getDetails(parseInt(value.value));
    setSelectedItem(pd?.data);
    setState((prev) => ({ ...prev, product_id: value }));
    // console.log(selectedItem);
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const body = {
      sourcing_price: Number(state?.sourcing_price),
      quantity: Number(state?.quantity),
      product_id: state?.product_id?.value,
      store_id: store?.id,
      area_id: store?.area?.id,
      zone: store?.area?.zone
    };
    await handleAddProduct(body);
    setState((prev) => ({ ...prev, quantity: '', sourcing_price: '' }));
  };

  // console.log(selectedItem?.productPerStores)
  //logic to get the highest quantity store
  let pps_array = selectedItem?.productPerStores || [];

  let max = -Infinity;
  let key;

  pps_array.forEach(function (store, k) {
    if (max < +store.quantity) {
      max = +store.quantity;
      key = k;
    }
  });

  // console.log(`Max quantity: ${pps_array[key]?.quantity} || Index: ${key}`)

  let handleAddToCart = () => {
    if (state.quantity === '' || state.quantity === 0) {
      return window.alert('Please add quantity');
    } else if (pps_array[key]?.quantity < state.quantity) {
      return window.alert(
        `Max quantity exceeded, max quantity for this product is: ${pps_array[key]?.quantity}`
      );
    }
    try {
      // console.log(state?.quantity);
      cartArr.push(Object.assign(selectedItem, { quantity: state?.quantity }));
      // console.log(cartArr);
      // let pps_length = selectedItem?.productPerStores.length || 1
      let bestStoreIdx = key || 0;
      // console.log(selectedItem)
      dispatch(
        addToCart(
          Object.assign(selectedItem, {
            quantity: parseInt(state?.quantity),
            product_id: parseInt(selectedItem?.id),
            pps_id: parseInt(selectedItem?.productPerStores[bestStoreIdx]?.id), //Math.floor(Math.random(pps_length)*pps_length)
            item_name: selectedItem?.name,
            price: selectedItem?.mrp,
            source_price: selectedItem?.productPerStores[bestStoreIdx]?.sourcing_price,
            category_id: selectedItem?.categories[0].id,
            category_name: selectedItem?.categories[0]?.name,
            category_slug: selectedItem?.categories[0]?.slug,
            estimated_delivery_time: 1,
            image: `${ENV.REACT_APP_API_URL}${selectedItem?.images.thumb}`
          })
        )
      );
      
    } catch (err) {
      // console.log(selectedItem)
      // console.log(err)
      return window.alert(`Error Occurred\n Error: ${err}`);
    }
  };

  // console.log(useSelector((state) => state.customOrder.cart));

  const cartState = useSelector((state) => state.customOrder.cart);

  // console.log(cartState.length);

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleUpdate}>
      <CustomProductSelect onChange={handleProductChange} value={state.product_id} zone={zone} />
      <ProductTopWrapper>
        {Object.keys(selectedItem).length !== 0 && (
          <ProductPriceWrapper>
            <ProductItem>
              <h4>Product Image</h4>
              <img
                src={`${ENV.REACT_APP_API_URL}${selectedItem?.images.thumb}`}
                alt={selectedItem?.name}
                width="120px"
              ></img>
            </ProductItem>
            <ProductItem>
              <h4>Product Name</h4>
              <div>{selectedItem?.name}</div>
            </ProductItem>
            <ProductItem>
              <h4>Product Price</h4>
              <div>{selectedItem?.selling_price}</div>
            </ProductItem>
          </ProductPriceWrapper>
        )}
      </ProductTopWrapper>
      {/* <TextField
        name="sourcing_price"
        label="Store Distribution Price"
        variant="outlined"
        type="number"
        fullWidth
        value={state.sourcing_price}
        onChange={handleChange('sourcing_price')}
      /> */}
      <TextField
        required={true}
        variant="outlined"
        fullWidth
        name="quantity"
        label="Quantity"
        type="number"
        value={state.quantity}
        onChange={handleChange('quantity')}
      />
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleAddToCart}
        disabled={state.quantity < 1 ? true : false}
        style={{ marginLeft: 5, height: '56px', cursor: 'pointer' }}
      >
        Add to cart
      </Button>
      <h3>🛒 Your cart - </h3>
      {cartState.length > 0 ? (
        <ul style={{minWidth: '300px'}}>
          {cartState.map((product) => (
            <li key={product.name}>
              {product.name} - BDT {product.selling_price} Tk - {product.quantity} Pc(s)
            </li>
          ))}
        </ul>
      ) : (
        <p>Your cart is empty</p>
      )}
    </form>
  );
};

export default AddForm;
