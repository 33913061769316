import styled from 'styled-components';

export const TableWrapper = styled.div`
  .MuiTableRow-head {
    .MuiTableCell-root {
      background-color: #f7f7fa;
      &:first-child {
        text-align: center;
      }
    }
  }
  .MuiTableBody-root {
    .MuiTableCell-root:first-child {
      text-align: center;
    }
  }
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const ToolbarTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 1.25rem;
    font-weight: 500;
  }
`;
