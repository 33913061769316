import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

class Status {
  getAll(query) {
    return SuperFetch(`status?${querystring.stringify(query)}`);
  }
}

export default new Status();
