import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const Tags = ({Tags, setTags}) => {

    return (
      <Select 
        mode="tags" 
        style={{ width: '100%', marginBottom: '20px' }} 
        placeholder="Tags" 
        onChange={(value) => setTags(value)}
        defaultValue={Tags}
      >
        {
            Tags?.map((item, i) => {
                return <Option key={item+i} value={item}>{item}</Option>;
            })
        }
      </Select>
    );
}

export default Tags;
