import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CategoryIcon from '@material-ui/icons/Category';
import StoreIcon from '@material-ui/icons/Store';
import { useForm, FormContext } from 'react-hook-form';
import { zones } from 'data/zones';
import SelectController from 'components/select/SelectController';
import TableSearch from 'components/TableSearch';
// import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

// import { ToolbarTitle } from 'components/MaterialTable/materialTable.style';
import AddProduct from '../AddProduct/AddProduct';
import {
  ToolbarWrapper,
  ToolbarWrapperButton,
  ToolbarWrapperTop,
  Left,
  Right
} from './toolbar.style';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from 'redux/categories';
import { getProductStatusAct } from 'redux/productStatus';

const Toolbar = ({
  open,
  tableRef,
  product,
  handleClickOpen,
  handleClose,
  clearProductData,
  handleAddProduct,
  history
}) => {
  const [category, setCategory] = useState([]);
  const state = useSelector((state) => state);
  const { data } = state?.categories?.data;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories({ active: true }));
    dispatch(getProductStatusAct());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      let arr = [];
      data.forEach((p, i) => {
        arr.push(p);
        if (p.children) {
          p.children.forEach((c, idx) => {
            arr.push({ ...c });
          });
        }
      });
      setCategory(arr);
    }
  }, [data]);

  // const history = useHistory();
  const paramObj = history?.location?.state || {};
  const defaultValues = {
    zones: paramObj?.zones || null,
    text: paramObj?.search || '',
    category: paramObj?.category || '',
    status: paramObj?.status || ''
  };

  const methods = useForm({
    defaultValues
  });

  const { formState, handleSubmit, reset } = methods;

  // console.log(getValues());
  const isDirty = formState.dirty || Object.keys(paramObj).find((param) => Boolean(param) === true);

  const onSubmit = (data) => {
    const body = {};

    if (data?.zones) {
      body.zones = data?.zones?.value;
    }
    if (data?.text) {
      body.search = data?.text;
    }
    if (data?.category) {
      body.category_id = data?.category?.value;
    }

    if (data?.status) {
      body.product_status = data?.status?.value;
    }

    history.push({
      state: { ...body }
    });

    tableRef.current.onQueryChange();
  };

  const handleClearFilter = () => {
    reset(defaultValues);
    history.push({
      state: {}
    });
    tableRef.current.onQueryChange();
  };

  return (
    <>
      <ToolbarWrapper>
        <Left>
          {/* <ToolbarTitle>
          <Typography className="title" component="h2">
            Products
          </Typography>
        </ToolbarTitle> */}
          <Button
            variant="contained"
            startIcon={<CategoryIcon />}
            onClick={() => history.push(`categories`)}
            style={{ margin: '0 4px' }}
          >
            Categories
          </Button>
          <span />
          <Button
            variant="contained"
            startIcon={<StoreIcon />}
            onClick={() => history.push(`stores`)}
            style={{ margin: '0 4px' }}
          >
            Stores
          </Button>
        </Left>
        <Right>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(`productstoreupdate`)}
            style={{ margin: '0 4px' }}
          >
            Add Product To Store
          </Button>
          <span />
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(`product/bulkadd`)}
            style={{ margin: '0 4px' }}
          >
            Bulk Upload Product
          </Button>
          <span />
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            style={{ margin: '0 4px' }}
          >
            Add Product
          </Button>
        </Right>

        <AddProduct
          open={open}
          clearProductData={clearProductData}
          product={product}
          handleClose={handleClose}
          handleAddProduct={handleAddProduct}
        />
      </ToolbarWrapper>

      <FormContext {...methods}>
        <ToolbarWrapperButton>
          <TableSearch value={paramObj?.search || ''} onSubmit={onSubmit} mw={230} placeHolder={'Search Products'}/>
          <ToolbarWrapperTop onSubmit={handleSubmit(onSubmit)}>
            <SelectController
              isClearable
              label="Categories"
              name="category"
              options={category.map((ctg) => {
                return { value: ctg.id, label: ctg.name };
              })}
              minWidth={150}
              // selectProps={{ isMulti: true }}
            />

            {/* <SelectController
              isClearable
              label="Delivery Zone"
              name="zones"
              options={zones}
              minWidth={150}
            /> */}

            <SelectController
              isClearable
              label="Product Status"
              name="status"
              options={state?.productStatus?.status.map((st) => {
                return { value: st.status, label: st.status };
              })}
              minWidth={150}
              // selectProps={{ isMulti: true }}
            />

            <Button type="submit" variant="contained" color="primary" disabled={!isDirty}>
              Filter
            </Button>
            <Button
              variant="contained"
              type="reset"
              className="reset-toolbar"
              onClick={handleClearFilter}
              disabled={!isDirty}
            >
              Clear
            </Button>
          </ToolbarWrapperTop>
        </ToolbarWrapperButton>
      </FormContext>
    </>
  );
};

export default Toolbar;
