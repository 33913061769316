import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: #000;
`;

export const StatsContainer = styled(Paper)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: transparent;
  margin-top: 50px;
  grid-gap: 30px;
  header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 10px;
    grid-column: 1/3;
    h2 {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
    }
    button {
      background-color: #585858;
      color: #fff;
    }
  }
`;

export const StatsWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-column: 1/3;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  background-color: #282828;
  border-radius: 4px;
`;

export const StatRow = styled.div``;

export const StartFilter = styled.div`
  padding: 20px;
  background-color: #282828;
  border-radius: 4px;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  form {
    display: grid;
    grid-row-gap: 20px;
  }
  .submitButton {
    background-color: #585858;
    color: #fff;
    margin-top: 10px;
  }
`;

export const FormControl = styled.div`
  position: relative;
  .DateInput_input__focused,
  .DateRangePickerInput,
  .DateInput_input,
  .DateInput {
    background: transparent;
  }
  .DateRangePickerInput,
  .DateRangePicker {
    display: block;
  }
  .DateInput__small {
    width: initial;
  }
  .DateRangePicker,
  .DateRangePickerInput {
    display: flex;
    align-items: center;
  }
  .DateInput_input {
    color: #fff;
  }
  .MuiListItem-root {
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .product-search {
    background-color: #585858;
    border-radius: 4px;
    .MuiInputBase-root {
      color: #fff;
      .MuiOutlinedInput-input {
        padding: 0 14px;
        min-height: 40px;
      }
    }
  }
  .products-list {
    background-color: #fff;
    position: absolute;
    max-height: 230px;
    overflow-y: scroll;
    z-index: 100;
    width: 100%;
  }
`;

export const InputLabel = styled.span`
  display: block;
  color: #878787;
  font-size: 16px;
  margin: 0 0 4px 8px;
`;
