/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';
import { DatePicker } from '@material-ui/pickers';

import AreaSelect from 'pages/Home/AreaSelect';
import Select from 'components/select/Select';
import AsyncSelect from 'components/select/AsyncSelect';
import { orderHoldReasons } from 'data/orderHoldReasons';

import { Wrapper, Group, Row } from './detailPane.style';
import NumberInput from './NumberInput';
import TextInput from './TextInput';

import DmRepo from 'repository/Dm';
import formatDate from 'utils/formatDate';
import useStatusSelect from 'hooks/useStatusSelect';

const OrderDetailPane = ({ order, onOrderUpdate }) => {
  const [selectStates, setSelectStates] = useState({
    current_status: order?.status,
    hold_reason: { value: order?.hold_reason, label: order?.hold_reason },
    area_id: { value: order?.area?.id, label: order?.area?.area },
    hub_collection_status: order?.hub_collection,
    acc_collection_status: order?.acc_collection,
    delivery_man_id: {
      value: order?.delivery_man?.id,
      label: order?.delivery_man?.name
    },
    collection_method_value: order?.collection_method,
    payment_status_value: order?.payment_status
  });
  const updateOrder = (body) => {
    onOrderUpdate(order.id, body);
  };
  const handleStatusSelect = (newValue) => {
    setSelectStates({
      ...selectStates,
      current_status: newValue
    });
    updateOrder({ current_status: newValue?.value });
  };
  const handleHoldSelect = (newValue) => {
    setSelectStates({
      ...selectStates,
      hold_reason: newValue
    });
    updateOrder({ hold_reason: newValue?.value ?? null });
  };
  const handleCityArea = (newValue) => {
    setSelectStates({
      ...selectStates,
      area_id: newValue
    });
    updateOrder({ area_id: newValue?.value });
  };
  const handleHmCollection = (newValue) => {
    setSelectStates({
      ...selectStates,
      hub_collection_status: newValue
    });
    updateOrder({ hub_collection_status: newValue?.value });
  };
  const handleAccCollection = (newValue) => {
    setSelectStates({
      ...selectStates,
      acc_collection_status: newValue
    });
    updateOrder({ acc_collection_status: newValue?.value });
  };
  const handleDmSelect = (newValue) => {
    setSelectStates({
      ...selectStates,
      delivery_man_id: newValue
    });
    updateOrder({ delivery_man_id: newValue?.value ?? null });
  };
  const handleDeliveryDate = (date) => {
    updateOrder({ delivery_date: formatDate(date, 'YYYY-MM-DD') });
  };
  const handlePaymentMethod = (newValue) => {
    setSelectStates({
      ...selectStates,
      collection_method_value: newValue
    });
    updateOrder({ collection_method_value: newValue?.value });
  };
  const handlePaymentStatus = (newValue) => {
    setSelectStates({
      ...selectStates,
      payment_status_value: newValue
    });
    updateOrder({ payment_status_value: newValue?.value });
  };
  const statusOptionsAsync = useStatusSelect();
  const promiseOptions = async (input) => {
    const res = await DmRepo.getAll({ search: input });
    const data = res?.data?.data?.map((item) => ({ value: item.id, label: item.name }));
    return data;
  };

  return (
    <Wrapper>
      {/* Created at <span>{formatDate(order.created_at)}</span> */}
      <Group>
        <h3 className="groupTitle">General Section</h3>
        <Row>
          <p>🔍 Order Status</p>
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={statusOptionsAsync}
            value={selectStates.current_status}
            onChange={handleStatusSelect}
            className="dhSelect"
            classNamePrefix="dh"
            style={{ minWidth: 240 }}
          />
        </Row>
        <Row>
          <p>Update City Area</p>
          <AreaSelect onChange={handleCityArea} value={selectStates.area_id} />
        </Row>
        <Row>
          <p>Hold Reason</p>
          <Select
            className="dhSelect"
            options={orderHoldReasons}
            classNamePrefix="dh"
            value={selectStates.hold_reason}
            style={{ minWidth: 240 }}
            onChange={handleHoldSelect}
            isClearable
          />
        </Row>
        <Row>
          <p>Admin Comment</p>
          <TextInput
            name="admin_comment"
            defaultValue={order?.admin_comment}
            onSave={updateOrder}
          />
        </Row>
      </Group>
      <Group>
        <h3 className="groupTitle">Station Manager Section</h3>
        <Row>
          <p>Total Collected</p>
          <NumberInput
            name="total_collected"
            defaultValue={order?.total_collected}
            onSave={updateOrder}
          />
        </Row>
        <Row>
          <p>Collection Status</p>
          <Select
            className="dhSelect"
            classNamePrefix="dh"
            style={{ minWidth: 240 }}
            onChange={handleHmCollection}
            value={selectStates.hub_collection_status}
            options={[
              { value: 'PENDING', label: 'Pending' },
              { value: 'HM_COLLECTED', label: 'Collected By HM' }
            ]}
          />
        </Row>
        <Row>
          <p>Assigned Agent</p>
          <AsyncSelect
            loadOptions={promiseOptions}
            cacheOptions
            defaultOptions
            value={selectStates.delivery_man_id}
            onChange={handleDmSelect}
            className="dhSelect"
            classNamePrefix="dh"
            style={{ minWidth: 240 }}
            isClearable
          />
        </Row>
        <Row>
          <p>Delivery Date</p>
          <DatePicker
            autoOk
            clearable
            value={order?.delivery_date}
            id="delivery_date"
            format="YYYY-MM-DD"
            name="delivery_date"
            animateYearScrolling
            inputVariant="outlined"
            onChange={handleDeliveryDate}
          />
        </Row>
      </Group>
      <Group>
        <h3 className="groupTitle"> Accounts Section</h3>
        <Row>
          <p>Account Collection</p>
          <NumberInput
            name="accounts_collected"
            defaultValue={order?.accounts_collected}
            onSave={updateOrder}
          />
        </Row>

        <Row>
          <p>Account Collection Status</p>
          <Select
            className="dhSelect"
            classNamePrefix="dh"
            style={{ minWidth: 240 }}
            onChange={handleAccCollection}
            value={selectStates.acc_collection_status}
            options={[
              { value: 'PENDING', label: 'Pending Collection' },
              { value: 'COLLECTED', label: 'Collected' }
            ]}
          />
        </Row>
        <Row>
          <p>Order Profit </p>
          <NumberInput
            name="order_profit"
            defaultValue={order?.order_profit}
            onSave={updateOrder}
          />
        </Row>
        <Row>
          <p>Collection Method</p>
          <Select
            className="dhSelect"
            classNamePrefix="dh"
            style={{ minWidth: 240 }}
            onChange={handlePaymentMethod}
            defaultValue={selectStates.collection_method_value}
            options={[
              {
                value: 'BANK',
                label: 'Bank'
              },
              {
                value: 'BKASH',
                label: 'Bkash'
              },
              {
                value: 'CASH',
                label: 'Cash'
              }
            ]}
          />
        </Row>
        <Row>
          <p>Paid Amount</p>
          <NumberInput name="paid_amount" defaultValue={order?.paid_amount} onSave={updateOrder} />
        </Row>
      </Group>
      <Group>
        <h3 className="groupTitle">Accounts Collection</h3>
        <Row>
          <p>Payment Status</p>
          <Select
            className="dhSelect"
            classNamePrefix="dh"
            style={{ minWidth: 240 }}
            onChange={handlePaymentStatus}
            value={selectStates.payment_status_value}
            options={[
              {
                value: 'PAID',
                label: 'Paid'
              },
              {
                value: 'PENDING_PAYMENT',
                label: 'Pending Payment'
              }
            ]}
          />
        </Row>
        <Row>
          <p>Delivery Hobe Sourcing Price</p>
          <NumberInput
            name="dh_sourcing_price"
            defaultValue={order?.dh_sourcing_price}
            onSave={updateOrder}
          />
        </Row>
        <Row>
          <p>Outsourcing Price</p>
          <NumberInput
            name="outsourcing_price"
            defaultValue={order?.outsourcing_price}
            onSave={updateOrder}
          />
        </Row>
        <Row>
          <p>Freelance DM Fee</p>
          <NumberInput
            name="freelance_dm_fee"
            defaultValue={order?.freelance_dm_fee}
            onSave={updateOrder}
          />
        </Row>
      </Group>
    </Wrapper>
  );
};

export default OrderDetailPane;
