import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm, Controller, ErrorMessage, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
// import ImageUploader from 'react-images-upload';
import Tags from './tags';
import AreaSelect from 'pages/Home/AreaSelect';
import { getDetails } from 'redux/stores';

import Form, { FormControl } from './storeForm.style';

const storeSchema = yup.object().shape({
  name: yup.string().required('Required'),
  address: yup.string().required('Required'),
  primary_contact_name: yup.string().required('Required'),
  primary_contact_phone: yup.string().required('Required'),
  // secondery_contact_name: yup.string(),
  // secondery_contact_phone: yup.string(),
  tags: yup.array().of(yup.string()),
  meta: yup.array().of(yup.object()),
  mfs_accounts: yup.array().of(yup.object()),
  bank_accounts: yup.array().of(yup.object()),
  commission_plan: yup.array().of(yup.object()),
});

const StoreForm = ({ handleAddStore, storeID, single, setStore, StoreData}) => {
 
  const dispatch = useDispatch();
  // const [thumb, setThumb] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [areaID, setAreaID] = useState([]);

  const { register, control, handleSubmit, setValue, errors } = useForm({
    validationSchema: storeSchema,
    defaultValues: {
      name: '',
      address: '',
      primary_contact_name: '',
      primary_contact_phone: '',
      // secondery_contact_name: '',
      // secondery_contact_phone: '',
      area_id: '',
      tags: [],
      meta: single ? [] : [{key: "color", value: "#000000"}],
      mfs_accounts: single ? [] : [{key: "bkash", value: ""}, {key: "nagad", value: ""}],
      bank_accounts: single ? [] : [{
        account_name: "Account Name",
        account_number: "Account No",
        bank_name: "Bank Name",
        bank_branch: "Branch Name",
        bank_routing_number: "Routing No (optional)"
      }],
      commission_plan: single ? [] : [{key: "sale", value: "%"}]
    }
  });


  const { fields: metaFields, append: metaAppend, remove: metaRemove } = useFieldArray({
    control,
    name: "meta"
  });

  const { fields: mfsFields, append: mfsAppend, remove: mfsRemove } = useFieldArray({
    control,
    name: "mfs_accounts"
  });

  const { fields: bankFields, append: bankAppend, remove: bankRemove } = useFieldArray({
    control,
    name: "bank_accounts"
  });

  const { fields: commFields, append: commAppend, remove: commRemove } = useFieldArray({
    control,
    name: "commission_plan"
  });


  useEffect(() => {
      single && !StoreData && (async () => {
        console.log("running...")
        const res = await dispatch(getDetails(storeID));;
        const data = JSON.parse(JSON.stringify(res));
        setTagsList(res?.tags?.map((item) => item));
        setAreaID({label: res?.area?.area, value: res?.area?.id});
        const metas = data?.meta && Object.keys(data?.meta)?.map((key) => {
          metaAppend({key: key, value: data?.meta[key]});
          return {key: key, value: data?.meta[key]};
        });
        const mfsAccounts = data?.mfs_accounts && Object.keys(data?.mfs_accounts)?.map((key) => {
          mfsAppend({key: key, value: data?.mfs_accounts[key]});
          return {key: key, value: data?.mfs_accounts[key]};
        });
        const CommPlans = data?.commission_plan && Object.keys(data?.commission_plan)?.map((key) => {
          commAppend({key: key, value: data?.commission_plan[key]});
          return {key: key, value: data?.commission_plan[key]};
        });
        const banksAcc = data?.bank_accounts && data?.bank_accounts?.map((item) => {
          bankAppend(item);
          return item;
        });
      
        // console.log(metas);
        setStore(data);
        setValue('name', res?.name || '');
        setValue('address', res?.address || '');
        setValue('primary_contact_name', res?.primary_contact_name || '');
        setValue('primary_contact_phone', res?.primary_contact_phone || '');
        // setValue('secondery_contact_name', res?.secondery_contact_name || '');
        // setValue('secondery_contact_phone', res?.secondery_contact_phone || '');
        setValue('area_id', res?.area?.id || '');
        setValue('tags', res?.tags?.map((item) => item) || []);
        setValue('meta', !!metas?.length ? [...metas] : []);
        setValue('mfs_accounts', !!mfsAccounts?.length ? [...mfsAccounts] : []);
        setValue('commission_plan', !!CommPlans?.length ? [...CommPlans] : []);
        setValue('bank_accounts', !!banksAcc?.length ? [...banksAcc] : []);
        
    })();
  }, [dispatch, storeID, single, setValue, metaAppend, mfsAppend, bankAppend, commAppend, setStore, StoreData]);

  const onSubmit = (data) => {
    const body = {
      ...data,
      tags: tagsList || null,
      area_id: Number(areaID?.value),
      meta: !!data?.meta?.length ? data?.meta?.reduce((a, item) => {
        const { key } = item;
        const camel = key.replace(/ ./g, chars => chars[1].toUpperCase());
        a[camel] = item.value;
        return a; 
      }, {}) : null,
      mfs_accounts: !!data?.mfs_accounts?.length ? data?.mfs_accounts?.reduce((a, item) => {
        const { key } = item;
        const camel = key.replace(/ ./g, chars => chars[1].toUpperCase());
        a[camel] = item.value;
        return a; 
      }, {}) : null,
      commission_plan: !!data?.commission_plan?.length ? data?.commission_plan?.reduce((a, item) => {
        const { key } = item;
        const camel = key.replace(/ ./g, chars => chars[1].toUpperCase());
        a[camel] = item.value;
        return a; 
      }, {}) : null,
    };
    console.log(body);
    !single ? handleAddStore(body) : handleAddStore(storeID, body)
  };  


  if(single && !StoreData?.id) return (
    <div>
      ⏳ Loading data...
    </div>
  );



  console.log(tagsList, areaID, StoreData);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormControl>
          <label htmlFor="name">Store Name</label>
          <Controller
            as={TextField}
            fullWidth
            id="name"
            name="name"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="name" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="address">Address</label>
          <Controller
            as={TextField}
            fullWidth
            id="address"
            name="address"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="address" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="primary_contact_name">Contact Name</label>
          <Controller
            as={TextField}
            fullWidth
            id="primary_contact_name"
            name="primary_contact_name"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="primary_contact_name" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="primary_contact_phone">Contact Phone</label>
          <Controller
            as={TextField}
            fullWidth
            id="primary_contact_phone"
            name="primary_contact_phone"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="primary_contact_phone" />
          </span>
        </FormControl>
        {/* <FormControl>
          <label htmlFor="secondery_contact_name">Secondary Contact Name</label>
          <Controller
            as={TextField}
            fullWidth
            id="secondery_contact_name"
            name="secondery_contact_name"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="secondery_contact_name" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="secondery_contact_phone">Secondery Contact Phone</label>
          <Controller
            as={TextField}
            fullWidth
            id="secondery_contact_phone"
            name="secondery_contact_phone"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="secondery_contact_phone" />
          </span>
        </FormControl> */}
        <br/>
          <label>Meta</label>
          <ul>
            {metaFields.map((item, index) => (
              <li key={item.id}>
                <input
                  name={`meta[${index}].key`}
                  ref={register()}
                  defaultValue={item.key} // make sure to set up defaultValue
                />
                <span />
                <Controller
                  as={<input />}
                  name={`meta[${index}].value`}
                  control={control}
                  defaultValue={item.value} // make sure to set up defaultValue
                />
                <button type="button" onClick={() => metaRemove(index)}>Delete</button>
              </li>
            ))}
          </ul>
          <button
            type="button"
            onClick={() => metaAppend({ key: "Key", value: "Value" })}
          >
            append
          </button>
      </div>
      <div>
        <FormControl>
          <label htmlFor="area_id">Area ID</label>
          <AreaSelect onChange={(value) => setAreaID(value)} value={areaID} />
        </FormControl>
          <label>Tags</label>
          <Tags Tags={tagsList} setTags={setTagsList}/>
          <br/>
          <label>Mobile Banking Accounts</label>
          <ul>
            {mfsFields.map((item, index) => (
              <li key={item.id}>
                <input
                  name={`mfs_accounts[${index}].key`}
                  ref={register()}
                  defaultValue={item.key} // make sure to set up defaultValue
                />
                <span />
                <Controller
                  as={<input />}
                  name={`mfs_accounts[${index}].value`}
                  control={control}
                  defaultValue={item.value} // make sure to set up defaultValue
                />
                <button type="button" onClick={() => mfsRemove(index)}>Delete</button>
              </li>
            ))}
          </ul>
          <button
            type="button"
            onClick={() => mfsAppend({ key: "Name", value: "Number" })}
          >
            append
          </button>
          <br/>
          <br/>
          <label>Comission Plans</label>
          <ul>
            {commFields.map((item, index) => (
              <li key={item.id}>
                <input
                  name={`commission_plan[${index}].key`}
                  ref={register()}
                  defaultValue={item.key} // make sure to set up defaultValue
                />
                <span />
                <Controller
                  as={<input />}
                  name={`commission_plan[${index}].value`}
                  control={control}
                  defaultValue={item.value} // make sure to set up defaultValue
                />
                <button type="button" onClick={() => commRemove(index)}>Delete</button>
              </li>
            ))}
          </ul>
          <button
            type="button"
            onClick={() => commAppend({ key: "Name", value: "%" })}
          >
            append
          </button>
          <br/>
      </div>
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button type="submit" variant="contained" color="primary">
            {single ? "Update Store" : "Add Store"}
          </Button>
        </div>
        <br/>
          <label>Bank Accounts</label>
          <ul>
            {bankFields.map((item, index) => (
              <li key={item.id}>
                <input
                  name={`bank_accounts[${index}].account_name`}
                  ref={register()}
                  defaultValue={item.account_name} // make sure to set up defaultValue
                />
                <span />
                <Controller
                  as={<input />}
                  name={`bank_accounts[${index}].account_number`}
                  control={control}
                  defaultValue={item.account_number} // make sure to set up defaultValue
                />
                <br/>
                <input
                  name={`bank_accounts[${index}].bank_name`}
                  ref={register()}
                  defaultValue={item.bank_name} // make sure to set up defaultValue
                />
                <span />
                <Controller
                  as={<input />}
                  name={`bank_accounts[${index}].bank_branch`}
                  control={control}
                  defaultValue={item.bank_branch} // make sure to set up defaultValue
                />
                <span />
                <Controller
                  as={<input />}
                  name={`bank_accounts[${index}].bank_routing_number`}
                  control={control}
                  defaultValue={item.bank_routing_number} // make sure to set up defaultValue
                />
                <span />
                <button type="button" onClick={() => bankRemove(index)}>Delete</button>
              </li>
            ))}
          </ul>
          <Button
            onClick={() => bankAppend({
              account_name: "",
              account_number: "",
              bank_name: "",
              bank_branch: "",
              bank_routing_number: "optional"
            })}
          >
            append
          </Button>
          <br/>
        {/* <FormControl>
          <label>Products</label>
          <ImageUploader
            singleImage
            withIcon={true}
            buttonText='Choose a single image'
            onChange={(pictureFiles) => setThumb(pictureFiles)}
            imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
            maxFileSize={5242880}
            withPreview
          />
        </FormControl> */}
      </div>
    </Form>
  );
};

export default StoreForm;
