import { createSlice, createSelector } from '@reduxjs/toolkit';
import Products from 'repository/Products';

const category = createSlice({
  name: 'category',
  initialState: {
    data: [],
    loading: false,
    error: null
  },

  reducers: {
    get: (state, actions) => {
      state.loading = true;
    },
    getSuccess: (state, actions) => {
      state.loading = false;
      // console.log(actions.payload);
      const data = actions.payload.map((item) => ({
        id: item?.id,
        name: item?.name,
        slug: item?.slug,
        image: item?.image?.src
      }));
      state.data = data;
    },
    getFailed: (state, actions) => {
      state.loading = false;
      state.error = actions.payload.error;
    }
  }
});

export const selectors = {
  getCategory: createSelector(
    (state) => state.category,
    (data) => data
  ),
  getSingleCategory: (id) =>
    createSelector(
      (state) => state.category.data,
      (data) => data.filter((item) => Number(id) === Number(item.id))[0]
    )
};

export function getCategory(query) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Products.fetchCategory(query);
      // console.log(response);
      if (response.status === 200) {
        dispatch(actions.getSuccess(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.getFailed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get category');
      }
    } catch (error) {
      dispatch(actions.getFailed(error));
    }
  };
}

export const { actions } = category;

export default category.reducer;
