import { createSelector, createSlice } from '@reduxjs/toolkit';

import Stores from 'repository/Stores';
import { enqueueSnackbar } from './notifications';

const stores = createSlice({
  name: 'stores',
  initialState: {
    loading: false,
    data: {
      data: [],
      count: 0,
      page: 1,
      limit: 100
    },
    error: null
  },
  reducers: {
    get: (state) => {
      state.loading = true;
      state.error = null;
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    done: (state) => {
      state.loading = false;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    }
  }
});

export const selectors = {
  getStores: createSelector(
    (state) => state.stores,
    (data) => data
  ),
};

export function getStores(params) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Stores.getAll(params);
      if (response.status === 200) {
        console.log(response.data);
        dispatch(actions.success(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get stores list');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function updateStore(body) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Stores.edit(body.id, body.data);
      // console.log('response', response);
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Store updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(actions.done());
        return { success: true };
      } else {
        throw new Error('Cannot edit Store');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Store update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}

export function getDetails(id) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Stores.getDetails(id);
      if (response.status === 200) {
        dispatch(actions.done(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Store');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function addStore(body) {
  return async (dispatch) => {
    try {
      const response = await Stores.add(body);
      if (response.status === 201) {
        dispatch(
          enqueueSnackbar({
            message: 'Store added successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        console.log(response);
        return { success: true };
      }
      else {
        throw new Error('Cannot add store');
      }
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Store addition failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return { error: true };
    }
  };
}

export function deleteStore(id) {
    return async (dispatch) => {
      try {
        const response = await Stores.delete(id);
        dispatch(
          enqueueSnackbar({
            message: 'Store deleted successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        console.log(response);
        return { success: true };
      } catch (error) {
        console.log(error);
        dispatch(
          enqueueSnackbar({
            message: 'Store remove failed',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        return { error: true };
      }
    };
  }

  export function bulkAddProducts(file) {
    return async (dispatch) => {
      dispatch(actions.get());
      try {
        const response = await Stores.bulkAddProducts(file);
        if (response.status === 201) {
          dispatch(
            enqueueSnackbar({
              message: 'Products updated to stores successfully',
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success'
              }
            })
          );
          dispatch(actions.done());
          return response.data;
        } else if (response.status === 204) {
          dispatch(actions.failed(response));
          return {
            error: true
          };
        } else {
          throw new Error('Cannot update Products store');
        }
      } catch (error) {
        console.log(error);
        dispatch(actions.failed(error));
        return {
          error: true
        };
      }
    };
  }

  export function bulkAddStores(file) {
    return async (dispatch) => {
      dispatch(actions.get());
      try {
        const response = await Stores.bulkAddStores(file);
        if (response.status === 201) {
          dispatch(
            enqueueSnackbar({
              message: 'Stores updated successfully',
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success'
              }
            })
          );
          dispatch(actions.done());
          return response.data;
        } else if (response.status === 204) {
          dispatch(actions.failed(response));
          return {
            error: true
          };
        } else {
          throw new Error('Cannot upload stores');
        }
      } catch (error) {
        console.log(error);
        dispatch(actions.failed(error));
        return {
          error: true
        };
      }
    };
  }

  export function addStoreProduct(body) {
    return async (dispatch) => {
      try {
        const response = await Stores.addProduct(body);
        if (response.status === 201) {
          dispatch(
            enqueueSnackbar({
              message: 'Product added successfully',
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success'
              }
            })
          );
          console.log(response);
          return { success: true };
        }
        else {
          throw new Error('Cannot add store product');
        }
      } catch (error) {
        console.log(error);
        dispatch(
          enqueueSnackbar({
            message: 'Store product addition failed',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        return { error: true };
      }
    };
  }

export function editProducts(body) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Stores.editProduct(body.id, body.data);
      // console.log('response', response);
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Store Products updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(actions.done());
        return { success: true };
      } else {
        throw new Error('Cannot edit Store Products');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Store Products update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}


export const { actions } = stores;

export default stores.reducer;
