import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

import ProductSelect from 'components/select/ProductSelect';
import Products from 'repository/Products';
import { ProductPriceWrapper, ProductTopWrapper, ProductItem } from './addProduct.style';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1.5, 0),
      width: '45%'
    }
  }
}));

const AddForm = ({ handleAddProduct, store }) => {
  const classes = useStyles();

  const [state, setState] = useState({
    product_id: '',
    quantity: '',
    sourcing_price: ''
  });

  const [selectedItem, setSelectedItem] = useState({});

  const handleChange = (name) => (event) => {
    event.persist();
    setState((prev) => ({ ...prev, [name]: event.target.value }));
  };

  const handleProductChange = async (value) => {
    const pd = await Products.getDetails(parseInt(value.value));
    setSelectedItem(pd?.data);
    setState((prev) => ({ ...prev, product_id: value }));
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const body = {
      sourcing_price: Number(state?.sourcing_price),
      quantity: Number(state?.quantity),
      product_id: state?.product_id?.value,
      store_id: store?.id,
      area_id: store?.area?.id,
      zone: store?.area?.zone
    };
    await handleAddProduct(body);
    setState((prev) => ({ ...prev, quantity: '', sourcing_price: '' }));
  };

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleUpdate}>
      <ProductTopWrapper>
        <ProductSelect onChange={handleProductChange} value={state.product_id} />
        {Object.keys(selectedItem).length !== 0 && (
          <ProductPriceWrapper>
            <ProductItem>
              <h4>Discount Percentage</h4>
              <div>{selectedItem?.discount_percentage}</div>
            </ProductItem>
            <ProductItem>
              <h4>Discounted Price</h4>
              <div>{selectedItem?.discounted_price}</div>
            </ProductItem>
            <ProductItem>
              <h4>Selling Price</h4>
              <div>{selectedItem?.selling_price}</div>
            </ProductItem>
            <ProductItem>
              <h4>Mrp</h4>
              <div>{selectedItem?.mrp}</div>
            </ProductItem>
          </ProductPriceWrapper>
        )}
      </ProductTopWrapper>
      <TextField
        name="sourcing_price"
        label="Store Distribution Price"
        variant="outlined"
        type="number"
        fullWidth
        value={state.sourcing_price}
        onChange={handleChange('sourcing_price')}
      />
      <TextField
        variant="outlined"
        fullWidth
        name="quantity"
        label="Quantity"
        type="number"
        value={state.quantity}
        onChange={handleChange('quantity')}
      />
      <Button variant="contained" color="primary" size="large" type="submit">
        Add to store
      </Button>
    </form>
  );
};

export default AddForm;
