import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller, ErrorMessage, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import ImageUploader from 'react-images-upload';
import { useParams } from 'react-router-dom';

import Form, { FormControl } from './brandDetails.style';
import SelectBrandProducts from '../SelectBrandProducts/SelectBrandProducts';
import { Page } from '../brandPage.style';
import Header from 'components/Header';
import Container from 'components/Container';
import { getBrandDetails, updateBrand } from 'redux/brands';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const brandSchema = yup.object().shape({
  name: yup.string().required('Required'),
  primary_color: yup
    .string()
    .required('Primary color required')
    .matches(/^#([0-9A-Fa-f]{3}){1,2}$/, 'Invalid color code!'),
  secondary_color: yup
    .string()
    .required('Secondary color required')
    .matches(/^#([0-9A-Fa-f]{3}){1,2}$/, 'Invalid color code!'),
  short_description: yup.string()
});

const BrandDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [banner, setBanner] = useState(null);
  const [logo, setLogo] = useState(null);
  const [footerBannerImage, setFooterBannerImage] = useState(null);
  const [defaultLogo, setDefaultLogo] = useState('');
  const [defaultBanner, setDefaultBanner] = useState('');
  const [defaultFooterBanner, setDefaultFooterBanner] = useState('');

  const [productValues, setProductValues] = useState(undefined);
  const [brandDetails, setBrandDetails] = useState({});
  const [removeItemIds, setRemoveItemId] = useState([]);

  const authToken = useSelector((state) => state.auth.token);

  const { register, control, handleSubmit, setValue, errors } = useForm({
    validationSchema: brandSchema,
    defaultValues: {
      name: '',
      primary_color: '',
      secondary_color: '',
      short_description: ''
    }
  });

  useEffect(() => {
    init();
  }, [setBrandDetails]);

  const init = async () => {
    const response = await dispatch(getBrandDetails(id));
    if (response?.success) {
      setBrandDetails(response?.payload);
      setValue('name', response?.payload?.name || '');
      setValue('primary_color', response?.payload?.config?.primary_color || '');
      setValue('secondary_color', response?.payload?.config?.secondary_color || '');
      setValue('short_description', response?.payload?.config?.short_description || '');
      setDefaultLogo(response?.payload?.logo ?? '');
      setDefaultBanner(response?.payload?.config?.banner ?? '');
      setDefaultFooterBanner(response?.payload?.config?.footer_banner ?? '');
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'meta'
  });

  const handleRemoveItem = (e, product) => {
    const index = removeItemIds.indexOf(product?.id);

    if (!e?.target?.checked) {
      if (index === -1) {
        setRemoveItemId((pre) => [...pre, product?.id]);
      }
    } else {
      if (index !== -1) {
        const newArray = removeItemIds.filter((item) => item !== product?.id);
        setRemoveItemId(newArray);
      }
    }
  };

  const handleLogo = (files, picture) => {
    setLogo(files);
    setDefaultLogo(picture);
  };

  const handleBanner = (files, picture) => {
    setBanner(files);
    setDefaultBanner(picture);
  };

  const handleFooterBanner = (files, picture) => {
    setFooterBannerImage(files);
    setDefaultFooterBanner(picture);
  };

  const onSubmit = async (data) => {
    const body = {
      ...data,
      is_active: true,
      products: productValues,
      removeProducts: removeItemIds
    };

    if (banner?.length) {
      body.banner = banner[0];
    }
    if (logo?.length) {
      body.logo = logo[0];
    }
    if (footerBannerImage?.length) {
      body.footerBannerImage = footerBannerImage[0];
    }

    const response = await dispatch(updateBrand(id, body, authToken));
    if (response?.success) {
      history.push('/brands');
    }
  };

  return (
    <Page>
      <Header />
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} style={{ display: 'block' }}>
          <div style={{ textAlign: 'right' }}>
            <Button type="submit" variant="contained" color="primary">
              {/* {single ? 'Update Brand' : 'Add Brand'} */}
              Update Brand
            </Button>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gridGap: '1rem' }}>
            <div>
              <FormControl>
                <label htmlFor="name">Brand Name</label>
                <Controller
                  as={TextField}
                  fullWidth
                  id="name"
                  name="name"
                  control={control}
                  variant="outlined"
                />
                <span style={{ color: 'red' }}>
                  <ErrorMessage style={{ color: 'red' }} errors={errors} name="name" />
                </span>
              </FormControl>
              <FormControl>
                <label htmlFor="primary_color">Primary Color</label>
                <Controller
                  as={TextField}
                  fullWidth
                  id="primary_color"
                  name="primary_color"
                  control={control}
                  variant="outlined"
                  defaultValue={brandDetails?.config?.primary_color}
                />
                <span style={{ color: 'red' }}>
                  <ErrorMessage style={{ color: 'red' }} errors={errors} name="primary_color" />
                </span>
              </FormControl>
              <FormControl>
                <label htmlFor="secondary_color">Secondary Color</label>
                <Controller
                  as={TextField}
                  fullWidth
                  id="secondary_color"
                  name="secondary_color"
                  control={control}
                  variant="outlined"
                />
                <span style={{ color: 'red' }}>
                  <ErrorMessage style={{ color: 'red' }} errors={errors} name="secondary_color" />
                </span>
              </FormControl>
              <FormControl>
                <label htmlFor="short_description">Short Description</label>
                <Controller
                  as={TextField}
                  fullWidth
                  id="short_description"
                  name="short_description"
                  control={control}
                  variant="outlined"
                />
              </FormControl>

              <FormControl>
                <label htmlFor="secondary_color">Add new products</label>
                <SelectBrandProducts
                  productValues={productValues}
                  setProductValues={setProductValues}
                />
              </FormControl>

              <FormControl>
                <label htmlFor="secondary_color">Added products</label>
                <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                  <FormGroup>
                    {brandDetails?.brandProducts?.map((product, index) => (
                      <FormControlLabel
                        key={index}
                        control={<Checkbox defaultChecked />}
                        label={product?.product?.name}
                        defaultChecked={true}
                        onClick={(e) => handleRemoveItem(e, product?.product)}
                      />
                    ))}
                  </FormGroup>
                </div>
              </FormControl>
            </div>

            <div>
              <FormControl id="logo">
                <label>Logo</label>
                <ImageUploader
                  singleImage={true}
                  withIcon={true}
                  buttonText="Choose a single image"
                  onChange={(pictureFiles, picture) => handleLogo(pictureFiles, picture)}
                  imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
                  maxFileSize={500000}
                  label="Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted"
                  withPreview={defaultLogo?.length ? true : false}
                  defaultImages={[defaultLogo]}
                />
              </FormControl>
            </div>

            <div>
              <FormControl id="banner">
                <label>Banner</label>
                <ImageUploader
                  singleImage
                  withIcon={true}
                  buttonText="Choose a single image"
                  onChange={(pictureFiles, picture) => handleBanner(pictureFiles, picture)}
                  imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
                  maxFileSize={500000}
                  label="Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted"
                  withPreview={defaultBanner?.length ? true : false}
                  defaultImages={[defaultBanner]}
                />
              </FormControl>
            </div>

            <div>
              <FormControl id="footerbanner">
                <label>Footer Banner</label>
                <ImageUploader
                  singleImage
                  withIcon={true}
                  buttonText="Choose a single image"
                  onChange={(pictureFiles, picture) => handleFooterBanner(pictureFiles, picture)}
                  imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
                  maxFileSize={500000}
                  label="Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted"
                  withPreview={defaultFooterBanner?.length ? true : false}
                  defaultImages={[defaultFooterBanner]}
                />
              </FormControl>
            </div>
          </div>
        </Form>
      </Container>
    </Page>
  );
};

export default BrandDetails;
