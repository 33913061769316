import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-conter: center;
  align-items: center;
  padding: 20px 0px;
`;

export const FormControll = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: 100%
`;

export const AddToStoreInput = styled.input`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  padding: 7px 5px;
  margin-top: 3px;
//   width: 100%;
  &:focus {
    border: 1px solid #ffe33f;
    outline: none;
  }
`;

export const AddToStoreBtn = styled.input`
  background-color: #ffe33f;
  outline: none;
  border: none;
  padding: 10px;
  width: 50%;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
`;
