import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

class Orders {
  get(params, token) {
    return SuperFetch(
      `/voice-pre-checkout?${querystring.stringify(params, { arrayFormat: 'comma' })}`,
      {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
  }

  getDetails(id, token) {
    return SuperFetch(`/voice-pre-checkout/${id}`, {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  getCustomerHistory(number) {
    return SuperFetch(`voice-pre-checkout/history/${number}`);
  }

  getStatusInfo(params) {
    return SuperFetch(
      `/voice-pre-checkout?${querystring.stringify(params, { arrayFormat: 'comma' })}`
    );
  }

  async edit(id, data = {}) {
    return SuperFetch(`voice-pre-checkout/${id}`, {
      method: 'PATCH',
      data
    });
  }

  async updateCheckout(id, data = {}, token) {
    let key = Object.keys(data)[0];
    if (key === 'discount') {
      let payload = {
        op: 'replace',
        path: `/discount`,
        value: data.discount
      };
      return SuperFetch(`voice-pre-checkout/update/${id}`, {
        method: 'PUT',
        data: [{ ...payload }]
      });
    } else {
      return SuperFetch(`voice-pre-checkout/update/${id}`, {
        method: 'PUT',
        data,
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        }
      });
    }
  }

  async addCustomProductVpc(id, data = {}, token) {
    let key = Object.keys(data)[0];
    if (key === 'discount') {
      let payload = {
        op: 'replace',
        path: `/discount`,
        value: data.discount
      };
      return SuperFetch(`voice-pre-checkout/add-custom-product/${id}`, {
        method: 'POST',
        data: [{ ...payload }]
      });
    } else {
      return SuperFetch(`voice-pre-checkout/add-custom-product/${id}`, {
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        }
      });
    }
  }

  async confirmLabeling(id, data = {}, token) {
      return SuperFetch(`voice-pre-checkout/confirm-labelling/${id}`, {
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        }
      });
  }

  async confirmOrder(id, data = {}, token) {
    return SuperFetch(`voice-pre-checkout/create-checkout/${id}`, {
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }
    });
}

  async updateSingleVpcQty(id, data = {}, token) {
    let key = Object.keys(data)[0];
    if (key === 'discount') {
      let payload = {
        op: 'replace',
        path: `/discount`,
        value: data.discount
      };
      return SuperFetch(`voice-pre-checkout-order-items/${id}`, {
        method: 'PUT',
        data: [{ ...payload }]
      });
    } else {
      return SuperFetch(`voice-pre-checkout-order-items/${id}`, {
        method: 'PUT',
        data,
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        }
      });
    }
  }

  async deleteOrderItem(id, data = {}, token) {
    let key = Object.keys(data)[0];
    if (key === 'discount') {
      let payload = {
        op: 'replace',
        path: `/discount`,
        value: data.discount
      };
      return SuperFetch(`voice-pre-checkout-order-items/${id}`, {
        method: 'DELETE',
        data: [{ ...payload }]
      });
    } else {
      return SuperFetch(`voice-pre-checkout-order-items/${id}`, {
        method: 'DELETE',
        data,
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        }
      });
    }
  }

  async deleteReqItem(id, data = {}, token) {
    let key = Object.keys(data)[0];
    if (key === 'discount') {
      let payload = {
        op: 'replace',
        path: `/discount`,
        value: data.discount
      };
      return SuperFetch(`requested-product/${id}`, {
        method: 'DELETE',
        data: [{ ...payload }]
      });
    } else {
      return SuperFetch(`requested-product/${id}`, {
        method: 'DELETE',
        data,
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        }
      });
    }
  }

  async bulkUpdate(data) {
    return SuperFetch(`voice-pre-checkout/bulk`, {
      method: 'DELETE',
      data
    });
  }
}

export default new Orders();
