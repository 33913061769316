import { createSelector, createSlice } from '@reduxjs/toolkit';

import Stores from 'repository/Stores';

const productPerStores = createSlice({
  name: 'productPerStore',
  initialState: {
    loading: false,
    data: {
      data: [],
      count: 0,
      page: 1,
      limit: 100
    },
    error: null
  },
  reducers: {
    get: (state) => {
      state.loading = true;
      state.error = null;
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    done: (state) => {
      state.loading = false;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    }
  }
});

export const selectors = {
  getStores: createSelector(
    (state) => state.productPerStore,
    (data) => data
  )
};

export const { actions } = productPerStores;
export default productPerStores.reducer;

export function getMappings(id, body) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Stores.getProducts(id, body);
      if (response.status === 200) {
        dispatch(actions.success(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get PPSs');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}
