import React, { useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

import Container from 'components/Container';
import Header from 'components/Header';
// import ZoneSelect from 'components/select/ZoneSelect';
import { zones } from 'data/zones';
import SelectController from 'components/select/SelectController';
import DateRangePicker from 'components/RangePicker/RangePicker';

import { useDispatch, useSelector } from 'react-redux';
import { getFilteredStats, selectors as statSelectors } from 'redux/stats';
import useProductsSearch from 'pages/OrderDetails/StoreProduct/useProducts';
import * as keyboard from 'keyboard-handler';

import { selectors as catSelector, getCategory } from 'redux/category';
// import { getChildCategory, selectors as childCatSelector } from 'redux/childCategory';

import Stat from './StatBar';

import {
  PageWrapper,
  StatsContainer,
  StatsWrapper,
  StartFilter,
  FormControl,
  InputLabel
} from './stats.style';

const Stats = () => {
  const history = useHistory();
  const paramObj = history?.location?.state || {};
  const dispatch = useDispatch();
  const { inputText, setInputText, search } = useProductsSearch();
  const [product, setProduct] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const authToken = useSelector((state) => state.auth.token);
  // const [parentCat, setParentCat] = useState(null);

  const stats = useSelector(statSelectors.getStats);
  const categories = useSelector(catSelector.getCategory);

  const defaultValues = {
    product_id: paramObj?.product_id || null,
    product_category_id: paramObj?.product_category_id || null,
    zones: paramObj?.zones || null,
    date_from: paramObj?.date_from || null,
    date_to: paramObj?.date_to || null
  };

  const methods = useForm({
    defaultValues
  });

  const { reset } = methods;

  const handleSearch = (e) => {
    e.persist();
    setProduct(null);
    setInputText(e.target.value);
    setShowResult(inputText.length > 1 && true);
  };

  keyboard.keyPressed((e) => {
    setShowResult(false);
  });

  const handleProductSelect = (item) => {
    setProduct({
      id: item?.id,
      name: item?.name,
      image: item?.images?.[0]?.src
    });
  };

  useEffect(() => {
    dispatch(getFilteredStats('',authToken));
    dispatch(getCategory());
    // dispatch(getChildCategory({ hide_empty: true, parent: catId }));
  }, [dispatch]);

  const catsArr = categories?.data?.map((item) => {
    return { label: parse(item.name), value: item.id };
  });

  const onSubmit = (data) => {
    console.log(data);
    const body = {
      product_id: product?.id,
      product_category_id: data?.categories?.value,
      date_from: data?.start_date,
      date_to: data?.end_date
    };

    if (data?.zones?.length) {
      body.zones = Array.isArray(data?.zones)
        ? data?.zones?.map((zone) => zone.value).join(',')
        : zones;
    }

    history.push({
      state: { ...body }
    });

    dispatch(getFilteredStats(body));
  };

  console.log(paramObj);

  const handleClearFilter = () => {
    reset(defaultValues);
    history.push({
      state: {}
    });
    setProduct(null);
    setInputText('');
    setShowResult(false);
    dispatch(getFilteredStats());
  };

  return (
    <PageWrapper>
      <Header />
      <Container width="1280px">
        <StatsContainer elevation={3}>
          <StatsWrapper>
            <header>
              <Typography component="h2">Statistics</Typography>
            </header>
            <Stat label="🕐 Total Number of Order" value={stats?.data?.total_orders} />
            <Stat label="🕐 Cancellation Rate %" value={stats?.data?.cancel_rate} />
            <Stat label="🕐 Delivery Success %" value={stats?.data?.success_rate} />
            <Stat
              label="🕐 Pending Stock Confirmation (Total)"
              value={stats?.data?.pending_stock_confirmation}
            />
            <Stat
              label="🕐 Pending DM Station Dispatch (Total)"
              value={stats?.data?.pending_dm_station_dispatch}
            />
            <Stat
              label="🕐 Ongoing DM Product Sourcing (Total)"
              value={stats?.data?.ongoing_dm_product_sourcing}
            />
            <Stat label="🚀 In Delivery (Total)" value={stats?.data?.in_delivery} />
            <Stat label="🎉 Delivered (Total)" value={stats?.data?.delivered} />
            <Stat label="🔙 In Return (Total)" value={stats?.data?.in_return} />
            <Stat
              label="🕐 Pending Return in Station (Total)"
              value={stats?.data?.pending_return_in_station}
            />
            <Stat label="😤 Returned (Total)" value={stats?.data?.returned} />
            <Stat label="🕐 Return Rejected (Total)" value={stats?.data?.return_rejected} />
            <Stat label="🕐 Test Order (Total)" value={stats?.data?.test_order} />
            <Stat label="🕐 Duplicate Entry (Total)" value={stats?.data?.duplicate_entry} />
            <Stat
              label="🕐 Avg. Stock Confirmation Time "
              value={`${Number.parseFloat(stats?.data?.avg_pending_stock_confirmation_time).toFixed(
                2
              )} hr`}
            />
            <Stat
              label="🕐 Avg. DM Dispatch Time "
              value={`${Number.parseFloat(
                stats?.data?.avg_pending_dm_station_dispatch_time
              ).toFixed(2)} hr`}
            />
            <Stat
              label="🕐 Avg. Delivery Time ( Delivered and Returned ) "
              value={Number.parseFloat(stats?.data?.avg_delivered_time).toFixed(2)}
            />
          </StatsWrapper>
          <StartFilter>
            <header>
              <Typography component="h2">Filter By</Typography>
              <Button type="reset" onClick={handleClearFilter}>
                Reset
              </Button>
            </header>
            <FormContext {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormControl>
                  <InputLabel>Date</InputLabel>
                  <DateRangePicker />
                </FormControl>
                <FormControl>
                  <InputLabel>Delivery Zone</InputLabel>
                  <SelectController
                    fullWidth
                    name="zones"
                    isClearable
                    options={zones}
                    selectProps={{ isMulti: true }}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel>Product Category</InputLabel>
                  <SelectController
                    clearForm
                    isClearable
                    options={catsArr}
                    fullWidth
                    name="categories"
                  />
                </FormControl>
                {/* <FormControl>
                  <InputLabel>Product Tag</InputLabel>
                  <SelectController isClearable fullWidth name="tags" />
                </FormControl> */}
                <FormControl>
                  <InputLabel>Product</InputLabel>

                  {product && (
                    <ListItem alignItems="flex-start" component="div">
                      <ListItemAvatar>
                        <Avatar alt={product?.name} src={product?.image} />
                      </ListItemAvatar>
                      <ListItemText primary={product.name} />
                    </ListItem>
                  )}
                  <TextField
                    fullWidth
                    id="store_product"
                    name="store_product"
                    className="product-search"
                    variant="outlined"
                    value={inputText}
                    autoComplete="off"
                    onChange={handleSearch}
                  />
                  {!product && search?.result && showResult && (
                    <List className="products-list">
                      {!!search?.result?.data?.length &&
                        search?.result?.data?.map((item) => (
                          <React.Fragment key={item.id}>
                            <ListItem
                              alignItems="flex-start"
                              button
                              onClick={() => handleProductSelect(item)}
                            >
                              <ListItemAvatar>
                                <Avatar alt="Remy Sharp" src={item?.images?.[0]?.src} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={parse(item.name)}
                                secondary={`Price: ${item.price}`}
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </React.Fragment>
                        ))}
                    </List>
                  )}
                </FormControl>
                {/* <Select /> */}

                <div style={{ display: 'flex' }}>
                  <Button type="submit" className="submitButton" fullWidth>
                    Filter
                  </Button>
                </div>
              </form>
            </FormContext>
          </StartFilter>
        </StatsContainer>
      </Container>
    </PageWrapper>
  );
};

export default Stats;
