import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Rgba from 'utils/Rgba';

export const Wrapper = styled(Paper)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 32px 16px;
  z-index: 100;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  .title {
    margin-top: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AvailableOptions = styled.div`
  display: flex;
  align-items: flex-end;
  > * {
    margin-right: 15px;
  }
  .current_status {
    p {
      color: ${Rgba('#4A4A4A', 0.6)};
      font-size: 14px;
      line-height: 12px;
      margin: 0 0 6px 8px;
    }
  }
`;

export const FormAction = styled.div``;
