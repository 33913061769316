import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddForm from './AddForm';
import { Wrapper, Header } from './addProduct.style';

const AddProduct = ({ open, store, handleClose, handleAddProduct, zone }) => {
  // console.log('store details', store);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Wrapper style={{width: '600px', minHeight: '500px'}}>
        <Header>
          <Typography component="h3" className="title">
            Add a Product to cart: 
          </Typography>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              style={{ margin: '0 4px' }}
            >
              Close
            </Button>
          </div>
        </Header>
        <AddForm handleAddProduct={handleAddProduct} store={store} zone={zone}/>
      </Wrapper>
    </Dialog>
  );
};

export default AddProduct;
