import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

class Dm {
  getAll(query) {
    return SuperFetch(`dms?${querystring.stringify(query)}`);
  }

  async updateDm(id, data = {}) {
    return SuperFetch(`dms/${id}`, {
      method: 'PUT',
      data
    });
  }

  async newUpdateDm(id, token, data = {}) {
    return SuperFetch(`v2/dm/${id}`, {
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  async addDm(data = {}) {
    return SuperFetch(`dms`, {
      method: 'POST',
      data
    });
  }
}

export default new Dm();
