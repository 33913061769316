import styled from "styled-components";

export const Page = styled.div``;

const Wrapper = styled.div`
  margin: 0;
  padding: 30px 20px 100px;
  border-radius: 4px;
`;

export const PageHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;

  .product-heading {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    text-transform: capitalize;
    color: #1f1f1f;
  }

  .edit-logo {
    margin-right: 18px;
  }
`;

export const Main = styled.main`
  display: flex;
  justify-content: space-between;
`;

export const MainContent = styled.div``;


export default Wrapper;
