import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  figure {
    margin: 0
  }
`;
export default GlobalStyle;
