import styled from 'styled-components';

export const StatItem = styled.div`
  align-items: center;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  padding: 0 20px;
  color: #fff;
`;
