import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import Button from "@material-ui/core/Button";
import Input from '@material-ui/core/Input';
import Container from 'components/Container';
import Header from 'components/Header';
import { bulkAddStores } from 'redux/stores';
import Divider from '@material-ui/core/Divider';


import Wrapper, {
  Page,
  PageHeader,
  Main,
  MainContent,
  ImageSlider,
} from "./StoreBulk.style";



const StoreBulkUpdate = () => {
  const dispatch = useDispatch();
  const [stores, setStores] = useState([]);
  const [file, setFile] = useState('');
 
  const onSubmit = async () => {
    if(file) {
      const res = await dispatch(bulkAddStores(file));
      setStores(res);
    }
  };

  const onChangeFile = (event) => {
    setFile(event.target.files[0]);
  }

  // console.log(products, thumbs, file, 'p');
  
  return (
    <Page>
      <Header/>
    <Container>
    <Wrapper>
      <PageHeader>
        <h1 className="product-heading">Bulk Stores Upload</h1>
        <div className="product-upload">
          <label htmlFor="contained-button-file">
            <Button onClick={onSubmit} variant="contained" color="primary">
              Update Stores
            </Button>
          </label>
        </div>
      </PageHeader>
      <Main>
        <MainContent>
          {!file ? 
            <h4>No file selected yet. Please select a file and click on upload Stores.</h4>
          :
              <h4>Filename: {file?.name}</h4>
          }
          <br/>
          <Input type="file" onChange={onChangeFile} />
        </MainContent>
        <ImageSlider>

          {!!stores.length &&

          <>
          <h2>Stores List:</h2>
        
            { stores?.map((item, index) => {
              return (
              <div key={index} style={{padding: "12px 12px"}}>
                <h4>Store ID: {item?.id}</h4>
                <h4>Store Name: {item?.input?.name}</h4>
                <h4>Success: {item?.success ? "True" : "False"}</h4>
                {item?.error?.detail && <h4>Errors: {item?.error?.detail}</h4>}
                <Divider/>
              </div>
              )
            })}
          </>           
          }
        </ImageSlider>
      </Main>
    </Wrapper>
    </Container>
    </Page>
  );
};

export default StoreBulkUpdate;
