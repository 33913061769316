import React from 'react';
// import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import AddCategory from '../AddCategory/AddCategory';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
import { ToolbarWrapper, Left, Right } from './toolbar.style';

const Toolbar = ({ open, handleClickOpen, handleClose, handleAddCategory, history }) => {
  // const history = useHistory();
  return (
    <ToolbarWrapper>
      <Left>
        {/* <ToolbarTitle>
          <Typography className="title" component="h2">
            Products
          </Typography>
        </ToolbarTitle> */}
        <Button variant="contained" startIcon={<ShoppingCartIcon />}  onClick={() => history.push(`products`)} style={{margin: "0 4px"}}>
          Products
        </Button>
        <span/>
        <Button variant="contained" startIcon={<StoreIcon />} onClick={() => history.push(`stores`)} style={{margin: "0 4px"}}>
          Stores
        </Button>
      </Left>
      <Right>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add Category
        </Button>
      </Right>

      <AddCategory
        open={open}
        handleClose={handleClose}
        handleAddCategory={handleAddCategory}
      />
    </ToolbarWrapper>
  );
};

export default Toolbar;
