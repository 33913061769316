import styled from 'styled-components';

export const Page = styled.div``;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0 24px;
  min-width: 600px;
  .submit_button {
    grid-column-start: 3;
    grid-column-end: 4;
  }
`;
export default Form;

export const FormControl = styled.div`
  margin-bottom: 16px;
  label {
    display: block;
  }
  .MuiInputBase-input {
    padding: 8.5px 10px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 11px) scale(1);
    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
`;

export const metaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;
