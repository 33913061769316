import React, { useState, useEffect } from 'react';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import { useFormContext } from 'react-hook-form';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

const RangePicker = () => {
  const { register, setValue } = useFormContext();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setValue([
      { start_date: moment(startDate).format('YYYY-MM-DD') },
      { end_date: moment(endDate).format('YYYY-MM-DD') }
    ]);
  };

  useEffect(() => {
    register({ name: 'start_date' });
    register({ name: 'end_date' });
  }, [register]);

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  return (
    <DateRangePicker
      small
      showClearDates
      startDateId="startDate"
      endDateId="endDate"
      startDate={startDate}
      hideKeyboardShortcutsPanel
      numberOfMonths={1}
      isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
      endDate={endDate}
      minimumNights={0}
      onDatesChange={onDatesChange}
      focusedInput={focusedInput}
      onFocusChange={onFocusChange}
    />
  );
};

export default RangePicker;
