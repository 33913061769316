import { createSlice, createSelector } from '@reduxjs/toolkit';

import { enqueueSnackbar } from './notifications';
import Products from 'repository/Products';


const products = createSlice({
  name: 'products',
  initialState: {
    loading: false,
    data: [],
    product: {},
    error: null
  },
  reducers: {
    get: (state) => {
      state.loading = true;
      state.error = null;
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    successDetails: (state, action) => {
      state.loading = false;
      state.product = action.payload;
    },
    done: (state) => {
      state.loading = false;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    }
  }
});

export const selectors = {
  getProducts: createSelector(
    (state) => state.orders,
    (data) => data
  )
};

export function getProducts(params) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Products.get(params);
      if (response.status === 200) {
        dispatch(actions.success(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Products');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function getDetails(id) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Products.getDetails(id);
      if (response.status === 200) {
        dispatch(actions.successDetails(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Product');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function editProduct(body) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      console.log(body, "edit");
      const response = await Products.edit(body.id, body.data);
      // console.log('response', response);
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Product updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(actions.done());
      } else {
        throw new Error('Cannot edit product');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Product updated failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      console.log(body)
      return {
        error: true
      };
    }
  };
}

export function addProduct(body, thumb, images) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      // console.log(body);
      const response = await Products.add(body);
      // console.log('response', response);
      if (response.status === 201) {
        if(thumb?.length) {
          // console.log(thumb,"thumb");
          const thumbRes = await Products.addThumb(response?.data?.id, thumb);

          if(images?.length) {
            const imagesRes = await Products.addGallery(response?.data?.id, images);
            console.log(imagesRes);
          }

          console.log(thumbRes);

          if(thumbRes.status === 200) {
            dispatch(
              enqueueSnackbar({
                message: 'Product added successfully',
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: 'success'
                }
              })
            );
            dispatch(actions.done());
            return { success: true };
          }
          else {
            throw new Error('Cannot add images');
          }
        }
      } else {
        throw new Error('Cannot add product');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Product add failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}

export function editProductThumb(id, thumb) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      
      let thumbs = [];
      thumbs.push(thumb[0]?.file || thumb[0]);
      const response = await Products.addThumb(id, thumbs);
      console.log('response', thumb);
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Product thumbline updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(actions.done());
        return { success: true };
      } else {
        throw new Error('Cannot edit product thumb');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Product thumbline updated failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}

export function editProductGallery(id, list) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      
      let images = [];
      list.map((item) => images.push(item.file ?? item));
      const response = await Products.addGallery(id, images);
      // console.log('response', response);
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Product gallery updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(actions.done());
        return { success: true };
      } else {
        throw new Error('Cannot edit product gallery');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Product gallery updated failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}


export function bulkAdd(file) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Products.bulkAdd(file);
      if (response.status === 201) {
        dispatch(
          enqueueSnackbar({
            message: 'Products updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(actions.done());
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot upload Products');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}




export const { actions } = products;

export default products.reducer;
