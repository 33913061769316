import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
// import { compare } from 'fast-json-patch';
import { transform, isEqual, isObject } from 'lodash';
import { useHistory } from 'react-router-dom';

import { getProducts, editProduct, addProduct } from 'redux/products';
import MaterialTable from 'components/MaterialTable';
import Container from 'components/Container';
import Header from 'components/Header';
import { MTableToolbar, MTableEditField } from 'material-table';
import Toolbar from './Toolbar/toolbar';
import { Page, ProductsTable } from './productsPage.style';
// import Products from 'repository/Products';
import isPositiveInt from 'utils/isPositiveInt';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { ENV } from 'ENV';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const ProductsPage = () => {
  const classes = useStyles();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [productData, setproductData] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddProduct = async (data, thumb, images) => {
    const body = {
      ...data
    };
    // console.log(body);
    const res = await dispatch(addProduct(body, thumb, images));
    console.log(res, 'reess');
    if (res?.success) {
      handleClose();
      tableRef.current.onQueryChange();
    } else setproductData(data);
    if (res?.error) setproductData(data);
  };

  const clearProductData = () => {
    setproductData(null);
  };

  const getData = async (query) => {
    let body = {
      page: query.page + 1,
      limit: query.pageSize,//maybe change should be added here limit=120&order_by=id&order=DESC
      order_by: 'id',
      order: 'DESC'
    };
    const paramObj = history?.location?.state;

    if (paramObj?.zones) {
      body.zones = paramObj?.zones;
    }
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }

    if (paramObj?.category_id) {
      body.category_id = paramObj?.category_id;
    }

    if (paramObj?.product_status) {
      body.product_status = paramObj?.product_status;
    }

    try {
      const res = await dispatch(getProducts(body));
      const copy = JSON.parse(JSON.stringify(res));
      console.log('copy product: ', copy);
      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  const diffObject = (object, base) => {
    function changes(object, base) {
      return transform(object, function (result, value, key) {
        if (!isEqual(value, base[key])) {
          result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
        }
      });
    }
    return changes(object, base);
  };

  const handleRowEdit = async (newData, oldData) => {
    const data = JSON.parse(JSON.stringify(oldData)); // copy oldData object
    delete data.tableData; // tableData was added by material-table
    if (
      !isPositiveInt(newData.mrp) ||
      !isPositiveInt(newData.selling_price) ||
      !isPositiveInt(newData.discounted_price)
    ) {
      alert('invalid amount');
      return;
    }
    const parsedData = {
      ...newData,
      mrp: Number(newData.mrp),
      selling_price: Number(newData.selling_price),
      discounted_price: Number(newData.discounted_price)
    };
    const diff = diffObject(parsedData, data);
    // console.log(diff);
    if (Boolean(Object.keys(diff).length)) {
      await dispatch(editProduct({ id: newData.id, data: diff }));
    }
  };

  const columns = [
    {
      title: 'Product ID',
      field: 'id',
      sorting: false,
      editable: 'never',
      render: ({ id }) => <p>{id}</p>
    },
    {
      title: 'Images',
      field: 'images',
      sorting: false,
      editable: 'never',
      render: ({ images }) => (
        <div>
          {images.thumb && (
            <img
              src={`${ENV.REACT_APP_API_URL}${images?.thumb}`}
              alt=""
              style={{ width: '40px', height: '50px' }}
            />
          )}
        </div>
      )
    },
    {
      title: 'Product Name',
      field: 'name',
      sorting: false,
      // editable: 'never',
      render: ({ name }) => <p>{name}</p>
    },
    {
      title: 'Category',
      field: 'categories',
      sorting: false,
      // editable: 'never',
      render: ({ categories }) => <p>{categories[0]?.name}</p>
    },
    {
      title: 'Short Description',
      field: 'short_description',
      sorting: false,
      // editable: 'never',
      render: ({ short_description }) => (
        <p>{`${
          short_description.length > 30
            ? short_description.substring(0, 30) + '...'
            : short_description
        }`}</p>
      )
    },
    {
      title: 'Selling Price',
      field: 'selling_price',
      sorting: false,
      type: 'numeric',
      // editable: 'never',
      render: ({ selling_price }) => <p>Tk {selling_price}</p>
    },
    {
      title: 'Discounted price',
      field: 'discounted_price',
      sorting: false,
      type: 'numeric',
      // editable: 'never',
      render: ({ discounted_price }) => <p>{discounted_price}</p>
    },
    {
      title: 'Discount Percentage',
      field: 'discount_percentage',
      sorting: false,
      type: 'numeric',
      // editable: 'never',
      render: ({ discount_percentage }) => <p>{discount_percentage}%</p>
    },
    {
      title: 'MRP',
      field: 'mrp',
      sorting: false,
      type: 'numeric',
      // editable: 'never',
      render: ({ mrp }) => <p>Tk {mrp}</p>
    },
    {
      title: 'Weight',
      field: 'weight',
      type: 'numeric',
      // editable: 'never',
      sorting: false,
      render: ({ weight }) => <p>{weight} gm</p>
    },
    {
      title: 'Tags',
      field: 'tags',
      sorting: false,
      editable: 'never',
      render: ({ tags }) => (
        <div className={classes.root}>
          {tags?.map((item, id) => (
            <Chip size="small" label={item} key={id} />
          ))}
        </div>
      )
    }
  ];

  return (
    <Page>
      <Header />
      <Container>
        {/* <ProductDetails data={product} getHistory={getHistory} /> */}
        <ProductsTable>
          <MaterialTable
            liveSearch
            tableRef={tableRef}
            title={'Products'}
            onRowClick={(event, rowData) => history.push(`product/${rowData.id}`)}
            components={{
              EditField: (props) => {
                if (props.columnDef.required && props.value.length === 0) {
                  return <MTableEditField {...props} error label="Required" />;
                }
                return <MTableEditField {...props} />;
              },
              Toolbar: (props) => {
                return (
                  <>
                    <MTableToolbar {...props} search={false} columnsButton={false} />
                    <Toolbar
                      open={open}
                      tableRef={tableRef}
                      product={productData}
                      handleClose={handleClose}
                      clearProductData={clearProductData}
                      handleClickOpen={handleClickOpen}
                      handleAddProduct={handleAddProduct}
                      history={history}
                      {...props}
                    />
                  </>
                );
              }
            }}
            columns={columns}
            data={getData}
            options={{
              columnsButton: true,
              draggable: false,
              sorting: false,
              pageSize: 120,
              pageSizeOptions: [120, 150, 180, 200]
            }}
            editable={{
              onRowUpdate: (newData, oldData) => handleRowEdit(newData, oldData)
              // onRowDelete: (oldData) => Products.delete(oldData.id)
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange()
              }
            ]}
          />
        </ProductsTable>
      </Container>
    </Page>
  );
};

export default ProductsPage;
