import styled from "styled-components";

export const Page = styled.div``;

const Wrapper = styled.form`
  margin: 0;
  padding: 30px 20px 100px;
  border-radius: 4px;
`;

export const PageHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;

  .product-heading {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    text-transform: capitalize;
    color: #1f1f1f;
  }

  .edit-logo {
    margin-right: 18px;
  }
`;

export const Main = styled.main`
  display: flex;
  justify-content: space-between;
`;

export const MainContent = styled.div``;

export const ImageContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
`;

export const ImageSlider = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;
    margin-left: 50px;

    .gallery-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .image-item {
      width: 100%;
    }

    .image-item-g {
      padding: 4px 4px;
    }
  `;

export default Wrapper;
