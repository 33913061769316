import { ENV } from 'ENV';
import WooCommerceRestApi from './wooRestApi';

export const baseUrl = ENV.REACT_APP_API_URL;

export const wooApi = new WooCommerceRestApi({
  url: ENV.REACT_APP_WOO_URL,
  consumerKey: ENV.REACT_APP_WOO_CK,
  consumerSecret: ENV.REACT_APP_WOO_CS,
  version: 'wc/v3'
});
