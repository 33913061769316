import { baseUrl } from 'api/config';
import { useEffect, useState } from 'react';

const requestPermission = () => {
  if ('safari' in window)
    return new Promise((resolve) => Notification.requestPermission((perm) => resolve(perm)));
  return Notification.requestPermission();
};

function useFirebaseNotifications(isLoggedIn) {
  const [isDoneSetup, setIsDoneSetup] = useState(false);
  useEffect(() => {
    if (isLoggedIn && !localStorage.getItem('dhops_notif_regd') && !('safari' in window)) {
      requestPermission()
        .then((perm) => {
          if (perm === 'granted') {
            localStorage.setItem('dhops_notif_regd', 'me me big boi');
            setIsDoneSetup((s) => !s);
            return console.log('Notification permission granted!');
          }
          throw new Error('Notifications permission denied');
        })
        .catch((err) => console.error(err?.message ?? err));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (localStorage.getItem('dhops_notif_regd') && !('safari' in window)) {
      const firebase = require('firebase/app');
      require('firebase/messaging');
      // Initialize Firebase
      // firebase.initializeApp({
      //   apiKey: 'AIzaSyBjHzh9i1x3Ex7bo5roJVWAvioyeOhSCmM',
      //   authDomain: 'deliveryhobe-4cf33.firebaseapp.com',
      //   databaseURL: 'https://deliveryhobe-4cf33.firebaseio.com',
      //   projectId: 'deliveryhobe-4cf33',
      //   storageBucket: 'deliveryhobe-4cf33.appspot.com',
      //   messagingSenderId: '276545122305',
      //   appId: '1:276545122305:web:5197e5c22aeea438e79000',
      //   measurementId: 'G-Z2R8FXKC8L'
      // });

      firebase.initializeApp({
        apiKey: 'AIzaSyCdm81hH1Az2O6xE5BRxYQ-t0zzppMbx1I',
        authDomain: 'deliveryhobe-365002.firebaseapp.com',
        databaseURL:
          'https://deliveryhobe-365002-default-rtdb.asia-southeast1.firebasedatabase.app',
        projectId: 'deliveryhobe-365002',
        storageBucket: 'deliveryhobe-365002.appspot.com',
        messagingSenderId: '909497631105',
        appId: '1:909497631105:web:db3002b849f8cdd4202880',
        measurementId: 'G-CLDSHJXE78'
      });
      const messaging = firebase.messaging();
      messaging
        .getToken({
          vapidKey:
            // 'BJKeQRkDxxp28qZl9J3DmmFu2hBO4UhlafhH3ApQTlWhV5lKZkAd6_hj7UG3Os8Qglopzruz8MGMHZqCzR3Deow',
            'BBPN9f7qd7UkHUu_XhRZrAXPcAT6BGQD0M9TsjnFveiRxdAxYIBjGwF3zPTihrsqaY9FdVTznzBwEP48sWGCtWs'
        })
        .then((token) =>
          fetch(`${baseUrl.replace(/\/?$/, '')}/notifications/ops`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ tokens: [].concat(token) })
          })
        )
        .then((res) => res.text())
        .then((resp) => console.log(`FG Notification Registration: ${resp}`));
      messaging.onMessage((payload) => {
        const title = payload.notification.title;
        const options = {
          body: payload.notification.body,
          icon: payload.notification.icon
        };
        const n = new Notification(title, options);
        n.onclick = () => {
          window.open(payload.notification.click_action);
        };
      });
    }
  }, [isDoneSetup]);
}

export default useFirebaseNotifications;
