import {
  assoc,
  compose,
  filter,
  groupBy,
  isNil,
  map,
  path,
  prop,
  propSatisfies,
  values
} from 'ramda';
import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { transform, isEqual, isObject } from 'lodash';
import { MTableToolbar, MTableEditField } from 'material-table';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { getCategories, updateCategories, addCategory } from 'redux/categories';
import MaterialTable from 'components/MaterialTable';
import Container from 'components/Container';
import Header from 'components/Header';
import Toolbar from './Toolbar/toolbar';
import { Page, CategoriesTable } from './categoriesPage.style';
import { ENV } from 'ENV';
// import CategoryTableToolbar from './CategoriesToolbar/index';
// import isPositiveInt from 'utils/isPositiveInt';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const generateIdMap = compose(map(prop(0)), groupBy(prop('id')));
const relateCategories = (catMap) =>
  compose(
    filter(propSatisfies(isNil, '__relate')),
    map((cat) =>
      assoc(
        'parent',
        cat?.parent ? path([cat.parent.id ?? -1, 'name'], catMap) || cat.parent.id : undefined,
        cat
      )
    ),
    values
  )(catMap);

const CategoriesPage = () => {
  const tableRef = useRef();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddCategory = async (data, thumb) => {
    const body = {
      ...data
    };
    // console.log(body);
    const res = await dispatch(addCategory(body, thumb));
    if (res?.success) {
      handleClose();
      tableRef.current.onQueryChange();
    }
  };

  const getData = async (query) => {
    let body = {
      page: query.page + 1,
      limit: query.pageSize,
      search: query.search,
      resolve_levels: -1
    };
    const paramObj = history?.location?.state;
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }

    try {
      const { data, ...rest } = await dispatch(getCategories(body));
      const dataMap = generateIdMap(data ?? []);
      const relatedData = relateCategories(dataMap);
      const finalData = { data: relatedData, ...rest };

      return {
        data: finalData.data ?? [],
        totalCount: finalData.count ?? 0,
        page: finalData.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  const diffObject = (object, base) => {
    function changes(object, base) {
      return transform(object, function (result, value, key) {
        if (!isEqual(value, base[key])) {
          result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
        }
      });
    }
    return changes(object, base);
  };

  const handleRowEdit = async (newData, oldData) => {
    const data = JSON.parse(JSON.stringify(oldData)); // copy oldData object
    delete data.tableData; // tableData was added by material-table

    const parsedData = {
      ...newData,
      parent: Number(newData.parent_id) || null
    };
    const diff = diffObject(parsedData, data);
    console.log(diff);
    if (Boolean(Object.keys(diff).length)) {
      if(diff?.sequence && diff?.name && diff?.description){
        await dispatch(updateCategories({ id: newData.id, data: {
          sequence: parseInt(diff?.sequence),
          name: diff?.name,
          description: diff?.description
        }}));
        return
      }
      if(diff?.sequence && diff?.name){
        await dispatch(updateCategories({ id: newData.id, data: {
          sequence: parseInt(diff?.sequence),
          name: diff?.name,
        }}));
        return
      }
      if(diff?.sequence){
        await dispatch(updateCategories({ id: newData.id, data: {
          sequence: parseInt(diff?.sequence),
        }}));
        return
      }
      else{
        await dispatch(updateCategories({ id: newData.id, data: diff}));
      }
    }
  };

  // const handleRowDelete = async (oldData) => {
  //   await dispatch(deleteCategory(oldData?.id))
  // }

  const columns = [
    {
      title: 'Category ID',
      field: 'id',
      sorting: true,
      editable: 'never',
      render: ({ id }) => <p>{id}</p>
    },
    {
      title: 'Images',
      field: 'meta',
      sorting: false,
      editable: 'never',
      render: ({ meta, images }) => {
        const img = { ...meta?.images, ...images };
        return (
          <div>
            {img?.thumb && (
              <img
                src={`${ENV.REACT_APP_API_URL}${img?.thumb}`}
                alt=""
                style={{ width: '40px', height: '50px' }}
              />
            )}
          </div>
        );
      }
    },
    {
      title: 'Name',
      field: 'name',
      sorting: true,
      // editable: 'never',
      render: ({ name }) => <p>{name}</p>
    },
    {
      title: 'Category URL',
      field: 'slug',
      sorting: false,
      editable: 'never',
      render: ({ slug }) => <p>{slug}</p>
    },
    {
      title: 'Description',
      field: 'description',
      sorting: true,
      // editable: 'never',
      render: ({ description }) => <p>{description}</p>
    },
    {
      title: 'Active',
      field: 'active',
      sorting: true,
      type: 'boolean',
      // editable: 'never',
      render: ({ active }) => <p>{active ? 'true' : 'false'}</p>
    },
    {
      title: 'Parent',
      field: 'parent',
      sorting: true,
      // type: 'numeric',
      editable: 'never',
      render: ({ parent }) => <p>{parent || 'Self'}</p>
    },

    {
      title: '⚡ Sequence',
      field: 'sequence',
      sorting: true,
      // type: 'numeric',
      // editable: 'never',
      render: ({ sequence }) => <p>{sequence}</p>
    },

    {
      title: 'Category Layer',
      field: 'category_layer',
      sorting: true,
      // type: 'numeric',
      editable: 'never',
      render: ({ category_layer }) => <p>{category_layer || 'null'}</p>
    },
    {
      title: 'Tags',
      field: 'tags',
      sorting: true,
      editable: 'never',
      render: ({ tags }) => (
        <div className={classes.root}>
          {tags?.map((item, id) => (
            <Chip size="small" label={item} key={id} />
          ))}
        </div>
      )
    }
  ];

  return (
    <Page>
      <Header />
      <Container>
        {/* <CategoryTableToolbar columns={columns} tableRef={tableRef} /> */}
        <CategoriesTable>
          <MaterialTable
            tableRef={tableRef}
            title={'Categories'}
            onRowClick={(event, rowData) => history.push(`category/${rowData.id}`)}
            components={{
              EditField: (props) => {
                if (props.columnDef.required && props.value.length === 0) {
                  return <MTableEditField {...props} error label="Required" />;
                }
                return <MTableEditField {...props} />;
              },
              Toolbar: (props) => {
                return (
                  <>
                    <MTableToolbar {...props} columnsButton />
                    <Toolbar
                      open={open}
                      handleClose={handleClose}
                      handleClickOpen={handleClickOpen}
                      handleAddCategory={handleAddCategory}
                      history={history}
                    />
                  </>
                );
              }
            }}
            columns={columns}
            data={getData}
            options={{
              columnsButton: true,
              draggable: false,
              sorting: true,
              pageSize: 1000,
              // pageSizeOptions: [120, 150, 180, 200]
            }}
            editable={{
              onRowUpdate: (newData, oldData) => handleRowEdit(newData, oldData)
              // onRowDelete: (oldData) => handleRowDelete(oldData)
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange()
              }
            ]}
          />
        </CategoriesTable>
      </Container>
    </Page>
  );
};

export default CategoriesPage;
