import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
  zone: 'DH-Z8', 
  cart: []
};

const customOrderSlice = createSlice({
  name: 'customOrderSlice',
  initialState,
  reducers: {
    changeZone: (state, action) => {
      state.zone = action.payload;
      console.log(action.payload)
    },
    addToCart: (state, action) => {
      state.cart.push(action.payload);
    },
    clearCart: (state) => {
      state.cart = []
    }
  }
});

export const { changeZone, addToCart, clearCart } = customOrderSlice.actions;
export default customOrderSlice.reducer;
