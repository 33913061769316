import React from 'react';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import CategoryForm from '../CategoryForm/categoryForm';
import { Wrapper, Header } from './addCategory.style';
import 'antd/dist/antd.css';


const AddCategory = ({ open, handleClose, handleAddCategory }) => {

  return (
    <Dialog open={open} onClose={handleClose} fullScreen fullWidth>
      <Wrapper>
        <Header>
          <Typography component="h3" className="title">
            Add a Category
          </Typography>
         <div>
          <Button variant="contained" onClick={handleClose} style={{margin: "0 4px"}}>
           Close
          </Button>
         </div>
        </Header>
        <CategoryForm handleAddCategory={handleAddCategory} />
      </Wrapper>
    </Dialog>
  );
};

export default AddCategory;
