import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

class CustomAreas {
  getAll(query) {
    return SuperFetch(`areas?${querystring.stringify(query)}&is_active=true`);
  }

  getAllZone(query) {
    return SuperFetch(`/v2/areas/by-zone?${querystring.stringify(query)}`);
  }
}

export default new CustomAreas();
