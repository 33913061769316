import { createSelector, createSlice } from '@reduxjs/toolkit';

import Brands from 'repository/Brands';
import { enqueueSnackbar } from './notifications';

const brands = createSlice({
  name: 'brands',
  initialState: {
    loading: false,
    data: {
      data: [],
      count: 0,
      page: 1,
      limit: 100
    },
    error: null
  },
  reducers: {
    get: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    success: (state, action) => {
      console.log('🚀 ~ file: brands.js:35 ~ action:', action);

      state.loading = false;
      state.data = action.payload;
    },
    done: (state) => {
      state.loading = false;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    }
  }
});

export const selectors = {
  getBrands: createSelector(
    (state) => state.brands,
    (data) => data.data
  )
};

export const { actions } = brands;

export function addBrand(body, token) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Brands.add({ name: body?.name, logo: body?.logo }, token);
      console.log('🚀 ~ file: brands.js:53 ~ return ~ response:', response?.data?.payload.id);

      if (response?.data?.success) {
        const configRes = await Brands.config(
          { brand: `${response?.data?.payload.id}`, ...body },
          token
        );
        if (!configRes?.data?.success) {
          throw new Error('Brand config error');
        }

        if (body?.products?.length) {
          const addProducts = await Brands.addMultipleProducts(
            {
              brand_id: response?.data?.payload?.id,
              product_ids: body?.products
            },
            token
          );

          if (!addProducts?.data?.success) {
            throw new Error('Cannot add products');
          }
        }

        return { success: true };
      } else {
        throw new Error('Cannot add brands');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Brand addition failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return { error: true };
    } finally {
      dispatch(actions.done());
    }
  };
}

export function getBrands(params) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Brands.getBrands(params);
      if (response.status === 200) {
        dispatch(actions.success(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get brand list');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function getBrandDetails(id) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Brands.getDetails(id);
      if (response.status === 200) {
        dispatch(actions.done(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Category');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function updateBrand(id, body, token) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Brands.edit(
        id,
        {
          name: body?.name,
          is_active: body?.is_active,
          brandImage: body?.logo
        },
        token
      );
      if (response.status === 200) {
        if (body?.primary_color) {
          const brandConfigRes = await Brands.configEdit(id, body, token);
          if (brandConfigRes?.status === 200) {
            if (body?.products?.length) {
              const addProducts = await Brands.addMultipleProducts(
                {
                  brand_id: +id,
                  product_ids: body?.products
                },
                token
              );

              if (!addProducts?.data?.success) {
                throw new Error('Cannot add new products');
              }
            }
            if (body?.removeProducts?.length) {
              const removeProducts = await Brands.removeMultipleProducts(
                {
                  brand_id: +id,
                  product_ids: body?.removeProducts
                },
                token
              );

              if (!removeProducts?.data?.success) {
                throw new Error('Cannot remove products');
              }
            }
          } else {
            throw new Error('Cannot config brand');
          }

          dispatch(
            enqueueSnackbar({
              message: 'Brand updated successfully',
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success'
              }
            })
          );
        }
        dispatch(actions.done());
        return { success: true };
      } else {
        throw new Error('Cannot edit Brand');
      }
    } catch (error) {
      console.log('🚀 ~ file: brands.js:216 ~ return ~ error:', error);
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Brand update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}

export function editCatThumb(id, thumb) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      let thumbs = [];
      thumbs.push(thumb[0]?.file || thumb[0]);
      const response = await Brands.addThumb(id, thumbs);
      console.log('response', thumb);
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Category thumbline updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(actions.done());
        return { success: true };
      } else {
        throw new Error('Cannot edit category thumb');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Category thumbline updated failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}

export function deleteCategory(id) {
  return async (dispatch) => {
    try {
      const response = await Brands.delete(id);
      dispatch(
        enqueueSnackbar({
          message: 'Category deleted successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      );
      console.log(response);
      return { success: true };
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Category remove failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return { error: true };
    }
  };
}

export default brands.reducer;
