import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Container from 'components/Container';
import Header from 'components/Header';
import { editProductThumb, editProductGallery, bulkAdd } from 'redux/products';

import ImageUploader from 'react-images-upload';

import Wrapper, {
  Page,
  PageHeader,
  Main,
  MainContent,
  ImageSlider,
  ImageContent
} from './ProductDetails.style';

const ProductBulkUpload = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [file, setFile] = useState('');
  const [thumbs, setThumbs] = useState([]);
  const [gallery, setGallery] = useState([]);

  const onSubmit = async () => {
    if (file) {
      const res = await dispatch(bulkAdd(file));
      setProducts(res);
    }
  };

  const onChangeFile = (event) => {
    setFile(event.target.files[0]);
  };

  const updateThumb = (index, id, pictureFiles) => {
    setThumbs([...thumbs, { index: pictureFiles }]);
    dispatch(editProductThumb(id, pictureFiles));
  };

  const updateGallery = (index, id, pictureFiles) => {
    setGallery([...gallery, { index: pictureFiles }]);
    dispatch(editProductGallery(id, pictureFiles));
  };

  console.log(thumbs, 'p');

  return (
    <Page>
      <Header />
      <Container>
        <Wrapper>
          <PageHeader>
            <h1 className="product-heading">Bulk Products Upload</h1>
            <div className="product-upload">
              <label htmlFor="contained-button-file">
                <Button onClick={onSubmit} variant="contained" color="primary">
                  Update Products
                </Button>
              </label>
            </div>
          </PageHeader>
          <Main>
            <MainContent>
              {!file ? (
                <h4>No file selected yet. Please select a file and click on upload products.</h4>
              ) : (
                <h4>Filename: {file?.name}</h4>
              )}
              <br />
              <Input type="file" onChange={onChangeFile} />
              <div style={{ margin: '20px 0px' }}>
                <div className="">Download Bulk Upload Product Template</div>
                <div style={{ margin: '10px 0px', display: 'flex' }}>
                  <div>Download link : </div>
                  <a
                    href="https://dh-public-assets.s3-ap-southeast-1.amazonaws.com/bulk_upload_template/product_bulk_upload_template.csv"
                    download
                  >
                    Click here
                  </a>
                </div>
              </div>
            </MainContent>
            <ImageSlider>
              {!!products.length && (
                <>
                  <h4>Update products images</h4>

                  {products?.map((item, index) => {
                    return (
                      <div key={index} style={{ maxWidth: '600px' }}>
                        <h4>Product Name: {item?.input?.name}</h4>
                        <ImageContent>
                          <ImageUploader
                            withIcon={true}
                            buttonText="Choose gallery images"
                            style={{ padding: '0px 5px' }}
                            onChange={(pictureFiles) => {
                              updateGallery(index, item?.id, pictureFiles);
                              updateThumb(index, item?.id, [pictureFiles[0]]);
                            }}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
                            maxFileSize={500000}
                            label='Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted'
                            withPreview
                          />
                        </ImageContent>
                      </div>
                    );
                  })}
                </>
              )}
            </ImageSlider>
          </Main>
        </Wrapper>
      </Container>
    </Page>
  );
};

export default ProductBulkUpload;
