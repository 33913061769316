import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Container from 'components/Container';
import Header from 'components/Header';
import { getDetails, editProductThumb, editProductGallery, editProduct } from 'redux/products';
import ImageUploader from 'react-images-upload';

import TextField from '@material-ui/core/TextField';
import { useForm, Controller, ErrorMessage, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import CategorySelect from '../ProductForm/CatSelect';
import StatusSelect from '../ProductForm/StatusSelect';
import Tags from '../ProductForm/tags';
import cross from '../../../assets/img/cross-icon.png';
import Dialog from '@material-ui/core/Dialog';

import DataTable from '../AddToStore';
import AddToStoreForm from '../AddToStore/AddToStoreForm';
import crossIcon from 'assets/img/cross-icon.png';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Wrapper, {
  Page,
  PageHeader,
  Main,
  MainContent,
  ImageSlider,
  PriceTag,
  ProductTag,
  ProductButton,
  ExtendedDescriptionForm,
  SalesPrice,
  CategoryWrapper,
  PpsHeadingContainer,
  PpsUpdateBtn,
  PpsTableContainer,
  PpsTableItem,
  PpsInputContainer,
  PpsInput,
  PpsAddBtn,
  DialogHeader,
  PpsWrapper
} from './ProductDetails.style';
import Products from 'repository/Products';
import { editProducts } from 'redux/stores';
import { Typography } from '@material-ui/core';
import { ENV } from 'ENV';

// TODO: Add meaningful error messages, these are meaningless
const productSchema = yup.object().shape({
  name: yup.string().required('Required'),
  short_description: yup.string().required('Required'),
  long_description: yup.string(),
  selling_price: yup.number('Should be number').required('Required'),
  discounted_price: yup.number('Should be number').required('Required'),
  discount_percentage: yup.number(),
  mrp: yup.number('Should be number').required('Required'),
  weight: yup.number(),
  sequence: yup.number(), //setting seq schema as number else it'll be string
  tags: yup.array().of(yup.string()),
  meta: yup.array().of(yup.object()),
  categories: yup.array().of(yup.number()),
  product_status: yup.string()
});

const ProductDetails = () => {
  // const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [product, setProduct] = useState([]);
  const [images, setImages] = useState([]);
  const [defaultImages, setDefaultImages] = useState([]);
  const [thumb, setThumb] = useState([]);
  const [defaultThumb, setDefaultThumb] = useState('');
  const [catValue, setCatValue] = useState(undefined);
  const [statusValue, setStatusValue] = useState(undefined);
  const [tagsList, setTagsList] = useState([]);

  const [sellPrice, setSellPrice] = useState(null);
  const [discountParcentage, setDiscountParcentage] = useState(0);
  const [mrPrice, setMrPrice] = useState(null);
  const [categories, setCategories] = useState([]);
  const [pps, setPps] = useState([]);
  const [ppsUpdate, setPpsUpdate] = useState({});
  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [storeId, setStoreId] = useState(null);

  //quill editor state
  const [editorValue, setEditorValue] = useState('');

  const { register, control, handleSubmit, setValue, errors } = useForm({
    validationSchema: productSchema,
    defaultValues: {
      name: '',
      short_description: '',
      long_description: '',
      selling_price: '',
      sequence: 0,
      discounted_price: '',
      discount_percentage: '',
      mrp: '',
      weight: '1',
      tags: [],
      meta: [],
      categories: [],
      product_status: ''
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'meta'
  });

  //this useEffect grabs all the product values from the backend and initializes it
  useEffect(() => {
    (async () => {
      // console.log("running...")
      const res = await dispatch(getDetails(id));
      const data = JSON.parse(JSON.stringify(res));
      setStatusValue(res?.product_status?.status);
      setTagsList(res?.tags?.map((item) => item));
      setCatValue(res?.categories?.map((item) => item.id));
      const metas =
        data?.meta &&
        Object.keys(data?.meta).map((key) => {
          append({ key: key, value: data?.meta[key] });
          return { key: key, value: data?.meta[key] };
        });
      setProduct(data);
      setDefaultImages(data?.images?.gallery);
      setDefaultThumb(data?.images?.thumb);
      setValue('name', res?.name || '');
      setValue('short_description', res?.short_description || '');
      setValue('long_description', res?.long_description || '');
      setEditorValue(res?.long_description || ''); //for rich text editor
      setValue('selling_price', res?.selling_price || '');
      setValue('sequence', res?.sequence || 0); //setting sequence
      setValue('discounted_price', res?.discounted_price || 0);
      setValue('discount_percentage', res?.discount_percentage || 0);
      setValue('mrp', res?.mrp || '');
      setValue('weight', res?.weight || 0);
      setValue('tags', res?.tags?.map((item) => item) || []);
      setValue('meta', !!metas?.length ? [...metas] : []);
      setValue('categories', res?.categories?.map((item) => item.id) || []);
      setSellPrice(res?.selling_price);
      setDiscountParcentage(res?.discount_percentage || 0);
      setMrPrice(res?.mrp);

      const { productPerStores } = data;
      let finalData = [];
      let temp = [];

      productPerStores.forEach((data, idx) => {
        productPerStores.forEach((d, i) => {
          if (data.zone === d.zone) {
            temp.push(d);
          }
        });
        if (!finalData.some((s) => s[0].zone === temp[0].zone)) {
          finalData = [...finalData, [...temp]];
          temp = [];
        } else {
          temp = [];
        }
      });
      setPps(finalData);
    })();
  }, [dispatch, id, setValue, append]);

  useEffect(() => {
    (async () => {
      const res = await Products.getCategories({ cat_tree: true });
      const data = await res.data;
      // const DataArr = []
      // Object.entries(data).map(item => DataArr.push(item))
      // data.push(await res.data)
      // console.log(DataArr);
      const cat = await JSON.parse(JSON.stringify(data));
      setCategories(cat);
    })();
  }, []);

  // const onChangeThumb = (imageList, addUpdateIndex) => {
  //   // data for submit
  //   // console.log(imageList, addUpdateIndex);
  //   setThumb(imageList);
  // };

  // const onChangeImages = (imageList, addUpdateIndex) => {
  //   // data for submit
  //   // console.log(imageList, addUpdateIndex);
  //   setImages(imageList);
  // };

  const removeDefaultImage = (idx) => () => {
    let copyImages = defaultImages.slice();
    copyImages.splice(idx, 1);
    setDefaultImages(copyImages);
  };

  const onSubmit = (data) => {
    console.log(data);
    const body = {
      ...data,
      tags: tagsList || null,
      categories: catValue || null,
      product_status: statusValue || null,
      meta: !!data?.meta?.length
        ? data?.meta?.reduce((a, item) => {
            const { key } = item;
            const camel = key.replace(/ ./g, (chars) => chars[1].toUpperCase());
            a[camel] = item.value;
            return a;
          }, {})
        : null
    };

    console.log(body);

    dispatch(editProduct({ id: product?.id, data: body }));
    !!thumb.length && dispatch(editProductThumb(product?.id, thumb));
    !!images.length && dispatch(editProductGallery(product?.id, images));
  };

  const parcentageCalc = (sell, par, mr) => {
    const p = (sell * par) / 100;
    const discountedPrice = sell > mr ? Math.floor(sell - p) : Math.ceil(sell - p);
    return discountedPrice;
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'selling_price') {
      setSellPrice(value);
      setValue('selling_price', value);
      setValue('discounted_price', parcentageCalc(value, discountParcentage, mrPrice));
    }
    if (name === 'discount_percentage') {
      if (parseInt(value) <= 0 || !value) {
        setValue('discounted_price', 0);
      } else {
        setValue('discounted_price', parcentageCalc(sellPrice, value, mrPrice));
        setDiscountParcentage(value);
      }
    }
    if (name === 'mrp') {
      setValue('discounted_price', parcentageCalc(sellPrice, discountParcentage, value));
      setValue('mrp', value);
      setMrPrice(value);
    }
  };

  if (!product?.id)
    return (
      <Page>
        <Header />
        <Container>⏳ Loading data...</Container>
      </Page>
    );

  const handleParentChange = (data) => (event) => {
    if (!catValue.some((s) => s === data?.id)) {
      setCatValue([...catValue, data?.id]);
    } else {
      let filterData = catValue.filter((f) => f !== data?.id);
      setCatValue([...filterData]);
    }
  };

  const ppsChange = (id) => (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (ppsUpdate?.id === id) {
      setPpsUpdate({ ...ppsUpdate, [name]: value });
    } else {
      setPpsUpdate({ id: id, [name]: value });
    }
  };

  const updateProductPerStore = () => {
    if (ppsUpdate?.id) {
      dispatch(
        editProducts({
          id: ppsUpdate?.id,
          data: {
            quantity: parseInt(ppsUpdate?.quantity),
            sourcing_price: parseInt(ppsUpdate?.sourcing_price)
          }
        })
      );
    }
  };

  const getStoreId = (id) => {
    setStoreId(id);
  };
  return (
    <Page>
      <Header />
      <Container>
        <Wrapper onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
          <PageHeader>
            <h1 className="product-heading">{product?.name}</h1>
            <div className="product-upload">
              <label htmlFor="contained-button-file">
                <Button type="submit" variant="contained" color="primary">
                  Update Product
                </Button>
              </label>
            </div>
          </PageHeader>
          <Main>
            <MainContent>
              <PriceTag>
                <span>
                  BDT{' '}
                  {product?.discounted_price ? product?.discounted_price : product?.selling_price}
                </span>
                <span>
                  <del>BDT {product?.selling_price}</del>
                </span>
              </PriceTag>
              <ProductTag>
                {product?.tags &&
                  product?.tags.map((item, id) => {
                    return (
                      <ProductButton key={id}>
                        {item} {/* <span onClick={() => console.log("delete tag", id)}>X</span> */}
                      </ProductButton>
                    );
                  })}
              </ProductTag>
              <ExtendedDescriptionForm>
                <div className="form__group">
                  <label htmlFor="name" className="form__label">
                    Product Name
                  </label>
                  <Controller
                    as={TextField}
                    fullWidth
                    id="name"
                    name="name"
                    control={control}
                    variant="outlined"
                  />
                  <span style={{ color: 'red' }}>
                    <ErrorMessage style={{ color: 'red' }} errors={errors} name="name" />
                  </span>
                </div>
                <div className="form__group">
                  <label htmlFor="short_description" className="form__label">
                    Product Short Description
                  </label>
                  <Controller
                    as={TextField}
                    fullWidth
                    id="short_description"
                    name="short_description"
                    control={control}
                    variant="outlined"
                  />
                  <span style={{ color: 'red' }}>
                    <ErrorMessage
                      style={{ color: 'red' }}
                      errors={errors}
                      name="short_description"
                    />
                  </span>
                </div>
                <div className="form__group">
                  <label htmlFor="long_description" className="form__label">
                    Product Long Description
                  </label>
                  <Controller
                    as={
                      <ReactQuill
                        theme="snow"
                        value={editorValue}
                        onChange={setEditorValue}
                        id="long_description"
                        control={control}
                        name="long_description"
                      />
                    }
                    multiline
                    fullWidth
                    id="long_description"
                    name="long_description"
                    control={control}
                    variant="outlined"
                  />
                  <span style={{ color: 'red' }}>
                    <ErrorMessage errors={errors} name="long_description" />
                  </span>
                </div>
              </ExtendedDescriptionForm>
              <SalesPrice>
                <div className="input-container">
                  <div className="form__group">
                    <label htmlFor="selling_price" className="form__label">
                      <span role="img" aria-label="sale price">
                        💵
                      </span>{' '}
                      Sale Price
                    </label>
                    <Controller
                      as={TextField}
                      fullWidth
                      type="number"
                      id="selling_price"
                      name="selling_price"
                      control={control}
                      variant="outlined"
                    />
                    <span style={{ color: 'red' }}>
                      <ErrorMessage errors={errors} name="selling_price" />
                    </span>
                  </div>

                  <div className="form__group">
                    <label htmlFor="discount_percentage" className="form__label">
                      <span role="img" aria-label="discount parcentage">
                        🏷
                      </span>{' '}
                      Discount Percentage
                    </label>
                    <Controller
                      as={TextField}
                      id="discount_percentage"
                      type="number"
                      variant="outlined"
                      name="discount_percentage"
                      control={control}
                      fullWidth
                    />
                    <span style={{ color: 'red' }}>
                      <ErrorMessage errors={errors} name="discount_percentage" />
                    </span>
                  </div>

                  <div className="form__group">
                    <label htmlFor="discounted_price" className="form__label">
                      <span role="img" aria-label="discount price">
                        🏷
                      </span>{' '}
                      Discounted Price
                    </label>
                    <Controller
                      as={TextField}
                      id="discounted_price"
                      type="number"
                      variant="outlined"
                      name="discounted_price"
                      control={control}
                      fullWidth
                      // disabled
                    />
                    <span style={{ color: 'red' }}>
                      <ErrorMessage errors={errors} name="discounted_price" />
                    </span>
                  </div>

                  <div className="form__group">
                    <label htmlFor="mrp" className="form__label">
                      <span role="img" aria-label="mrp">
                        💸
                      </span>{' '}
                      MRP
                    </label>
                    <Controller
                      as={TextField}
                      fullWidth
                      type="number"
                      id="mrp"
                      name="mrp"
                      control={control}
                      variant="outlined"
                    />
                    <span style={{ color: 'red' }}>
                      <ErrorMessage errors={errors} name="mrp" />
                    </span>
                  </div>
                </div>
                {/* <div className="price-condition">
                  <span className="price-condition-item price-condition-item--1">X</span>
                  <span className="price-condition-item price-condition-item--2">
                    Sales Price = MRP?
                  </span>
                </div> */}

                {/* <hr /> */}

                <div className="status-container">
                  <div className="form__group">
                    <label htmlFor="weight" className="form__label form__label--modify">
                      Weight
                    </label>
                    <Controller
                      as={TextField}
                      fullWidth
                      type="number"
                      id="weight"
                      name="weight"
                      control={control}
                      variant="outlined"
                    />
                    <span style={{ color: 'red' }}>
                      <ErrorMessage errors={errors} name="weight" />
                    </span>
                  </div>
                  <div className="form__group" style={{ marginLeft: '10px' }}>
                    <label htmlFor="sequence" className="form__label form__label--modify">
                      Sequence
                    </label>
                    <Controller
                      as={TextField}
                      fullWidth
                      type="number"
                      id="sequence"
                      name="sequence"
                      control={control}
                      variant="outlined"
                    />
                    <span style={{ color: 'red' }}>
                      <ErrorMessage errors={errors} name="weight" />
                    </span>
                  </div>
                </div>
              </SalesPrice>
              <label></label>
              <label htmlFor="tags" className="form__label">
                Tags
              </label>
              <Tags Tags={tagsList} setTags={setTagsList} />
              <br />
              <label htmlFor="category" className="form__label">
                Category
              </label>

              <CategoryWrapper>
                {categories
                  ?.filter((f) => f.active)
                  ?.map((parent, idx) => (
                    <div key={idx}>
                      <input
                        type="checkbox"
                        onChange={handleParentChange(parent)}
                        defaultChecked={catValue.includes(parent.id)}
                      />
                      <span style={{ paddingLeft: '0.8rem', fontSize: '16px' }}>
                        {parent?.name}1️⃣
                      </span>
                      {parent?.second_layer_cats?.map((child, indx) => (
                        <div
                          key={indx}
                          style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}
                        >
                          <span style={{ paddingLeft: '0.8rem', fontSize: '14px' }}>
                          <input
                            type="checkbox"
                            onChange={handleParentChange(child)}
                            defaultChecked={catValue.includes(child.id)}
                            style={{ marginRight: '20px' }}
                          />
                          {child?.name} 2️⃣
                            <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                          {child?.children.map((children, indx) => (
                          <div
                            key={indx}
                            style={{ marginLeft: '30px', display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              type="checkbox"
                              onChange={handleParentChange(children)}
                              defaultChecked={catValue.includes(children.id)}
                            />
                            <span style={{ paddingLeft: '0.8rem', fontSize: '14px' }}>
                              {children?.name}3️⃣
                            </span>
                          </div>
                        ))}
                        </div>
                          </span>
                        </div>
                      ))}
                      {/* {parent?.second_layer_cats?.map((child, indx) =>
                        child?.children.map((children, indx) => (
                          <div
                            key={indx}
                            style={{ marginLeft: '50px', display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              type="checkbox"
                              onChange={handleParentChange(children)}
                              defaultChecked={catValue.includes(children.id)}
                            />
                            <span style={{ paddingLeft: '0.8rem', fontSize: '14px' }}>
                              {children?.name}3️⃣
                            </span>
                          </div>
                        ))
                      )} */}
                    </div>
                  ))}
              </CategoryWrapper>

              {/* <br />
              <CategorySelect cats={catValue} setCatValue={setCatValue} />
              <br /> */}
              <br />
              <label>Status</label>
              <StatusSelect status={statusValue} setStatusValue={setStatusValue} />
              <br />
              <br />
              <label htmlFor="meta" className="form__label">
                Meta
              </label>
              <ul style={{ listStyle: 'none', padding: '0' }}>
                {fields.map((item, index) => (
                  <li style={{ padding: '5px 0' }} key={item.id}>
                    <input
                      name={`meta[${index}].key`}
                      ref={register()}
                      defaultValue={item.key} // make sure to set up defaultValue
                    />
                    <span />
                    <Controller
                      style={{ marginLeft: '5px' }}
                      as={<input />}
                      name={`meta[${index}].value`}
                      control={control}
                      defaultValue={item.value} // make sure to set up defaultValue
                    />
                    <button
                      style={{ marginLeft: '5px' }}
                      type="button"
                      onClick={() => remove(index)}
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
              <button type="button" onClick={() => append({ key: 'Key', value: 'Value' })}>
                append
              </button>
            </MainContent>
            <ImageSlider>
              <h4>Thumbnail</h4>

              {defaultThumb && (
                <div
                  className="image-item"
                  style={{
                    position: 'relative',
                    width: '200px',
                    height: '200px',
                    boxShadow: '0px 0px 3px',
                    marginRight: '20px',
                    marginBottom: '20px',
                    borderRadius: '5px',
                    backgroundImage: `url(${ENV.REACT_APP_API_URL + defaultThumb})`,
                    backgroundSize: '100% 100%'
                  }}
                >
                  {/* <img
                    src={ENV.REACT_APP_API_URL + defaultThumb}
                    alt="thumb"
                    style={{ width: '100%', height: '100%' }}
                  /> */}
                  <img
                    onClick={() => setDefaultThumb('')}
                    src={cross}
                    alt=""
                    style={{
                      width: '18px',
                      position: 'absolute',
                      top: '-6px',
                      right: '-6px',
                      border: '2px solid #ED2122',
                      padding: '2px',
                      borderRadius: '50%',
                      zIndex: '100',
                      cursor: 'pointer'
                    }}
                  />
                </div>
              )}
              <ImageUploader
                singleImage
                withIcon={true}
                buttonText="Choose a single thumb image"
                onChange={(pictureFiles) => setThumb(pictureFiles)}
                imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
                // defaultImages={[ENV.REACT_APP_API_URL + product?.images?.thumb ?? '']}
                maxFileSize={500000}
                label="Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted"
                withPreview
                style={{ maxWidth: '600px' }}
              />

              <h4>Gallery</h4>

              <div className="image-item">
                <div className="gallery-wrapper" style={{ margin: '0px 10px' }}>
                  {defaultImages?.map((image, index) => (
                    <div
                      className="image-item-g"
                      key={index}
                      style={{
                        position: 'relative',
                        width: '200px',
                        height: '200px',
                        boxShadow: '0px 0px 3px',
                        marginRight: '20px',
                        marginBottom: '20px',
                        borderRadius: '5px'
                      }}
                    >
                      <img
                        src={ENV.REACT_APP_API_URL + image}
                        alt={'gallery' + index}
                        style={{ width: '100%', height: '100%' }}
                      />
                      <img
                        onClick={removeDefaultImage(index)}
                        src={cross}
                        alt=""
                        style={{
                          width: '18px',
                          position: 'absolute',
                          top: '-6px',
                          right: '-6px',
                          border: '2px solid #ED2122',
                          padding: '2px',
                          borderRadius: '50%',
                          zIndex: '100',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <ImageUploader
                // singleImage
                withIcon={true}
                buttonText="Choose images for gallery"
                onChange={(pictureFiles) => setImages(pictureFiles)}
                imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
                maxFileSize={500000}
                label="Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted"
                withPreview
                style={{ maxWidth: '600px' }}
              />
            </ImageSlider>
          </Main>

          <div>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth disableBackdropClick>
              <DialogHeader>
                <Typography component="h3" className="title">
                  Add to Store
                </Typography>
                <div
                  onClick={() => {
                    setOpen(false);
                    setShowForm(false);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    style={{
                      width: '25px',
                      boxShadow: '0 0 2px',
                      borderRadius: '50%',
                      padding: '5px'
                    }}
                    src={crossIcon}
                    alt=""
                  />
                </div>
              </DialogHeader>
              {showForm ? (
                <AddToStoreForm
                  prodId={product?.id}
                  storeId={storeId}
                  setShowForm={setShowForm}
                  setOpen={setOpen}
                />
              ) : (
                <DataTable setShowForm={setShowForm} getStoreId={getStoreId} />
              )}
            </Dialog>
          </div>

          {pps.length !== 0 && (
            <PpsWrapper>
              <PpsHeadingContainer>
                <h2 style={{ margin: '0', padding: '0' }}>Product Per Store </h2>
                <PpsAddBtn onClick={() => setOpen(!open)}>Add</PpsAddBtn>
                <PpsUpdateBtn onClick={updateProductPerStore}>Update</PpsUpdateBtn>
              </PpsHeadingContainer>
              <PpsTableContainer>
                {pps.map((e, i) => (
                  <div>
                    <h3>{e[0]?.zone}</h3>
                    <div style={{ marginBottom: '30px' }}>
                      {e.map((el, ind) => (
                        <PpsTableItem>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <div>
                              <span>Id: </span>
                              <span>{el?.store?.id}</span>
                            </div>
                            <div>
                              <span>Name: </span>
                              <span>{el?.store?.name}</span>
                            </div>
                            <div>
                              <span>Phone: </span>
                              <span>{el?.store?.primary_contact_phone}</span>
                            </div>
                          </div>
                          <PpsInputContainer>
                            <div style={{ marginRight: '20px' }}>
                              <div>Quantity</div>
                              <PpsInput
                                type="number"
                                defaultValue={el?.quantity}
                                name="quantity"
                                onChange={ppsChange(el?.id)}
                              />
                            </div>
                            <div>
                              <div>Sourcing Price</div>
                              <PpsInput
                                type="number"
                                defaultValue={el?.sourcing_price}
                                name="sourcing_price"
                                onChange={ppsChange(el?.id)}
                              />
                            </div>
                          </PpsInputContainer>
                        </PpsTableItem>
                      ))}
                    </div>
                  </div>
                ))}
              </PpsTableContainer>
            </PpsWrapper>
          )}
        </Wrapper>
      </Container>
    </Page>
  );
};

export default ProductDetails;
