import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Controller, useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 2px 4px rgba(9,92,255,0.25)',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  inputWrapper: {
    flex: 1,
    '& input': {
      padding: '0 16px',
      minHeight: 38
    }
  },
  iconButton: {
    padding: 7
  }
}));

const TableSearch = ({ tableRef, value, mw, onSubmit, placeHolder = 'Search Order' }) => {
  const classes = useStyles();
  const methods = useFormContext({
    defaultValues: value || ''
  });
  const { handleSubmit, control } = methods;
  // const onSubmit = ({ text }) => handleSearch(text);
  // const onSubmit = (data) => {
  //   tableRef.current.onQueryChange({
  //     ...tableRef.current.state.query,
  //     filter: {
  //       search: data?.text
  //     }
  //   });
  // };

  return (
    <Paper
      component="form"
      className={classes.root}
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxWidth: mw ?? '' }}
    >
      <Controller
        as={<InputBase />}
        name="text"
        placeholder={placeHolder}
        className={classes.inputWrapper}
        control={control}
        defaultValue={value}
      />
      <IconButton
        onClick={handleSubmit(onSubmit)}
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default TableSearch;
