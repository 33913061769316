import React from 'react';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import { useForm, FormContext, Controller } from 'react-hook-form';

import AsyncSelect from 'components/select/AsyncSelect';
import DmSelect from 'components/select/DmSelect';
import { Wrapper, Form, AvailableOptions, FormAction } from './batchEdit.style';

import useStatusSelect from 'hooks/useStatusSelect';

const BatchEdit = ({ onSubmit, open, onClose }) => {
  const formMethods = useForm();
  const { handleSubmit, control } = formMethods;

  const statusOptionsAsync = useStatusSelect();

  const handleOnChange = ([selected]) => {
    return selected;
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose} variant="persistent">
      <Wrapper>
        <FormContext {...formMethods}>
          <h3 className="title">Batch Edit</h3>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <AvailableOptions>
              {/* <MuiDatePicker label="Pickup Date" name="pickup_date" id="pickup_date" /> */}
              {/* <OrderStatus label="Order Status" name="current_status" menuPlacement="top" /> */}
              <div className="current_status">
                <p>Current Status</p>
                <Controller
                  as={<AsyncSelect isClearable defaultOptions loadOptions={statusOptionsAsync} />}
                  name="current_status"
                  classNamePrefix="sh"
                  menuPlacement="top"
                  control={control}
                  onChange={handleOnChange}
                />
              </div>
              <DmSelect label="Select Delivery Man" />
            </AvailableOptions>
            <FormAction>
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
            </FormAction>
          </Form>
        </FormContext>
      </Wrapper>
    </Drawer>
  );
};

export default BatchEdit;
