import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { clearCart } from '../../../redux/customOrderInfoSlice';

import Container from 'components/Container';
import Paper from '@material-ui/core/Paper';
import { ENV } from 'ENV';
const ProductsTable = ({ toggleOpen }) => {
  const dispatch = useDispatch();

  //dialog state
  const [dialogOpen, setDialogOpen] = useState(false);


  const cartState = useSelector((state) => state.customOrder.cart);



  // console.log(useSelector((state) => state.customOrder.cart));

  // console.log(cartState);

  function createData(product_name, product_image, selling_price, quantity, total_price) {
    return { product_name, product_image, selling_price, quantity, total_price };
  }
  let rows = [];

  const zone = useSelector((state) => state.customOrder.zone.zone);

  cartState.map((product) =>
    rows.push(
      createData(
        product.name,
        product.images?.thumb,
        product.selling_price,
        product.quantity,
        product.selling_price * product.quantity
      )
    )
  );

  // console.log('rows:', rows);
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="Clear Cart"
        aria-describedby="Are you sure you want clear your cart?"
      >
        <DialogTitle id="alert-dialog-title">{'Clear Cart'}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>No</Button>
          <Button
            onClick={() => {
              dispatch(clearCart());
              setDialogOpen(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Container>
        <h2 style={{ marginLeft: 10, marginTop: 10 }}>Your Cart - </h2>
        <Button variant="contained" color="primary" onClick={() => toggleOpen()} disabled={!zone ? true: false }>
          Add Products
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setDialogOpen(true)}
          style={{ marginLeft: 50 }}
          disabled={cartState.length < 1 ? true : false}
        >
          Clear Cart
        </Button>
        <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell align="right">Product Image</TableCell>
                <TableCell align="right">Product Price</TableCell>
                <TableCell align="right">Product Quantity</TableCell>
                <TableCell align="right">Total Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.product_name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.product_name}
                  </TableCell>
                  <TableCell align="right">
                    <img
                      src={`${ENV.REACT_APP_API_URL}${row.product_image}`}
                      alt=""
                      width="48px"
                    />
                  </TableCell>
                  <TableCell align="right">TK {row.selling_price} Each</TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">{row.total_price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default ProductsTable;
