import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'antd';
import { useDispatch } from 'react-redux';
import Products from 'repository/Products';

const { TreeNode } = TreeSelect;

const CategorySelect = ({cats, setCatValue}) => {
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        !categories?.length && (async () => {
            // const res = await Products.getCategories({resolve_levels: 2});
            const res = await Products.getCategories();
            const data = await res.data;
            const cat = JSON.parse(JSON.stringify(data.data));
            setCategories(cat);
        })();
    }, [dispatch, categories]);

    return (
      <TreeSelect
        showSearch
        style={{ width: '100%' }}
        value={cats}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: "9999999" }}
        placeholder="Please select category"
        allowClear
        treeDefaultExpandAll
        size="middle"
        onChange={(value) => setCatValue(value)}
      >
        {categories?.map((item) => (
          <TreeNode key={item?.id} value={item?.id} title={item?.name}>
            {item?.children?.map((child) => (
              <TreeNode key={child?.id} value={child?.id} title={child?.name} />
            ))}
          </TreeNode>
        ))}
      </TreeSelect>
    );
}

export default CategorySelect;