import styled from 'styled-components';

export const ToolbarWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
`;

export const ToolbarWrapperButton = styled.div`
  display: flex;
  padding: 20px;
`;

export const ToolbarOption = styled.div`
  .option_label {
    color: rgba(74, 74, 74, 0.6);
    margin: 0 0 6px 8px;
    font-size: 14px;
    line-height: 12px;
    display: block;
  }
  .dh_select {
    min-width: 180px;
  }
`;

export const ToolbarLeft = styled.form`
  display: flex;
  align-items: flex-end;
  .funnel-icon {
    top: -13px;
    position: relative;
    margin-right: 16px;
  }
  > div {
    margin-right: 16px;
  }
`;
export const ToolbarRight = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .print-button {
    margin-right: 16px;
    svg {
      margin-right: 8px;
    }
  }
  .toggle-column {
    border: 1px solid #dddbe1;
    padding: 0;
    min-width: 40px;
    min-height: 37px;
    margin-left: 16px;
    border-radius: 4px;
  }
`;
