export const zones = [
  { value: 'DH-Z1', label: 'DH-Z1' },
  { value: 'DH-Z2', label: 'DH-Z2' },
  { value: 'DH-Z3', label: 'DH-Z3' },
  { value: 'DH-Z4', label: 'DH-Z4' },
  { value: 'DH-Z5', label: 'DH-Z5' },
  { value: 'DH-Z6', label: 'DH-Z6' },
  { value: 'DH-Z7', label: 'DH-Z7' },
  { value: 'DH-Z8', label: 'DH-Z8' },
  { value: 'DH-Z9', label: 'DH-Z9' },
  { value: 'DH-Z10', label: 'DH-Z10' },
  { value: 'DH-Z11', label: 'DH-Z11' },
  { value: 'DH-Z12', label: 'DH-Z12' },
  { value: 'DH-Z13', label: 'DH-Z13' },
  { value: 'DH-Z14', label: 'DH-Z14' },
  { value: 'DH-Z15', label: 'DH-Z15' },
  { value: 'DH-Z16', label: 'DH-Z16' },
  { value: 'DH-Z17', label: 'DH-Z17' },
  { value: 'DH-Z18', label: 'DH-Z18' },
  { value: 'DH-Z19', label: 'DH-Z19' },
  { value: 'DH-Z20', label: 'DH-Z20' },
  { value: 'DH-Z21', label: 'DH-Z21' },
  { value: 'DH-Z22', label: 'DH-Z22' },
  { value: 'DH-Z23', label: 'DH-Z23' },
  { value: 'DH-Z24', label: 'DH-Z24' },
  { value: 'DH-Z25', label: 'DH-Z25' },
  { value: 'DH-Z26', label: 'DH-Z26' },
  { value: 'DH-Z27', label: 'DH-Z27' },
  { value: 'DH-Z28', label: 'DH-Z28' },
  { value: 'DH-Z29', label: 'DH-Z29' },
  { value: 'DH-Z30', label: 'DH-Z30' },
  { value: 'DH-Z31', label: 'DH-Z31' },
  { value: 'DH-Z32', label: 'DH-Z32' },
  { value: 'DH-Z33', label: 'DH-Z33' },
  { value: 'DH-Z34', label: 'DH-Z34' },
  { value: 'DH-Z35', label: 'DH-Z35' },
  { value: 'DH-Z36', label: 'DH-Z36' },
  { value: 'DH-Z37', label: 'DH-Z37' },
  { value: 'DH-Z38', label: 'DH-Z38' },
  { value: 'DH-Z39', label: 'DH-Z39' },
  { value: 'DH-Z40', label: 'DH-Z40' },
  { value: 'DH-Z41', label: 'DH-Z41' },
  { value: 'DH-Z42', label: 'DH-Z42' },
  { value: 'DH-Z43', label: 'DH-Z43' },
  { value: 'DH-Z44', label: 'DH-Z44' },
  { value: 'DH-Z45', label: 'DH-Z45' },
  { value: 'DH-Z46', label: 'DH-Z46' },
  { value: 'DH-Z47', label: 'DH-Z47' },
  { value: 'DH-Z48', label: 'DH-Z48' },
  { value: 'DH-Z49', label: 'DH-Z49' },
  { value: 'DH-Z50', label: 'DH-Z50' },
  { value: 'not-available', label: 'Not Available Zone' }
];
