import React from 'react';

import { StatItem } from './stat.style';

const StatBar = ({ label, value }) => {
  return (
    <StatItem>
      <span>{label}</span>
      <span>{value}</span>
    </StatItem>
  );
};

export default StatBar;
