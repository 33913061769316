/* eslint-disable no-unused-vars */
import {
  assoc,
  compose,
  filter,
  groupBy,
  isNil,
  map,
  path,
  prop,
  propSatisfies,
  values
} from 'ramda';
import React, { useRef, useState } from 'react';
import { BrandTable, Page } from './brandPage.style';
import Header from 'components/Header';
import Container from 'components/Container';
import MaterialTable from 'components/MaterialTable';
import { useHistory } from 'react-router-dom';
import { MTableEditField, MTableToolbar } from 'material-table';
import Toolbar from './Toolbar/toolbar';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { addBrand, getBrands, updateBrand } from 'redux/brands';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const generateIdMap = compose(map(prop(0)), groupBy(prop('id')));
const relateCategories = (catMap) =>
  compose(
    filter(propSatisfies(isNil, '__relate')),
    map((cat) =>
      assoc(
        'parent',
        cat?.parent ? path([cat.parent.id ?? -1, 'name'], catMap) || cat.parent.id : undefined,
        cat
      )
    ),
    values
  )(catMap);

const BrandPage = () => {
  const [open, setOpen] = useState(false);

  const tableRef = useRef();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const authToken = useSelector((state) => state.auth.token);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddBrand = async (data) => {
    const res = await dispatch(addBrand(data, authToken));
    if (res?.success) {
      handleClose();
      tableRef.current.onQueryChange();
    }
  };

  const getData = async (query) => {
    let body = {
      page: query.page + 1,
      limit: query.pageSize,
      search: query.search,
      resolve_levels: -1
    };
    const paramObj = history?.location?.state;
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }

    try {
      const { payload, meta } = await dispatch(getBrands(body));

      return {
        data: payload ?? [],
        totalCount: meta?.count ?? 0,
        page: meta.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  const handleRowEdit = async (newData, oldData) => {
    dispatch(
      updateBrand(newData?.id, { name: newData?.name, is_active: newData?.is_active }, authToken)
    );
  };

  const columns = [
    {
      title: 'Brand ID',
      field: 'id',
      sorting: true,
      editable: 'never',
      render: ({ id }) => <p>{id}</p>
    },
    {
      title: 'Logo',
      field: 'meta',
      sorting: false,
      editable: 'never',
      render: ({ name, logo }) => {
        // const img = { ...meta?.logo, ...images };
        return (
          <div>
            {logo && logo !== 'string' ? (
              <img src={`${logo}`} alt="" style={{ width: '40px', height: '50px' }} />
            ) : (
              <p>{name}</p>
            )}
          </div>
        );
      }
    },
    {
      title: 'Name',
      field: 'name',
      sorting: true,
      render: ({ name }) => <p>{name}</p>
    },

    {
      title: 'Active',
      field: 'is_active',
      sorting: true,
      type: 'boolean',
      render: ({ is_active }) => <p>{is_active ? 'true' : 'false'}</p>
    }
  ];

  return (
    <Page>
      <Header />
      <Container>
        {/* <CategoryTableToolbar columns={columns} tableRef={tableRef} /> */}
        <BrandTable>
          <MaterialTable
            tableRef={tableRef}
            title={'Brands'}
            onRowClick={(event, rowData) => history.push(`brand/${rowData.id}`)}
            components={{
              EditField: (props) => {
                if (props.columnDef.required && props.value.length === 0) {
                  return <MTableEditField {...props} error label="Required" />;
                }
                return <MTableEditField {...props} />;
              },
              Toolbar: (props) => {
                return (
                  <>
                    <MTableToolbar {...props} columnsButton />
                    <Toolbar
                      open={open}
                      handleClose={handleClose}
                      handleClickOpen={handleClickOpen}
                      handleAddBrand={handleAddBrand}
                      history={history}
                    />
                  </>
                );
              }
            }}
            columns={columns}
            data={getData}
            options={{
              columnsButton: true,
              draggable: false,
              sorting: true,
              pageSize: 1000
              // pageSizeOptions: [120, 150, 180, 200]
            }}
            editable={{
              onRowUpdate: async (newData, oldData) => await handleRowEdit(newData, oldData)
              // onRowDelete: (oldData) => handleRowDelete(oldData)
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange()
              }
            ]}
          />
        </BrandTable>
      </Container>
    </Page>
  );
};

export default BrandPage;
