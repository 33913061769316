import moment from 'moment';

export const timeArr = [
  { start: '10:00 am', end: '11:00 am' },
  { start: '11:00 am', end: '12:00 pm' },
  { start: '12:00 pm', end: '01:00 pm' },
  { start: '01:00 pm', end: '02:00 pm' },
  { start: '02:00 pm', end: '03:00 pm' },
  { start: '03:00 pm', end: '04:00 pm' },
  { start: '04:00 pm', end: '05:00 pm' },
  { start: '05:00 pm', end: '06:00 pm' },
  { start: '06:00 pm', end: '07:00 pm' },
  { start: '07:00 pm', end: '08:00 pm' },
  { start: '08:00 pm', end: '09:00 pm' },
  { start: '09:00 pm', end: '10:00 pm' }
];

export const dateArr = [
  {
    day: 'Today',
    date: moment()
  },
  {
    day: 'Tomorrow',
    date: moment().add(1, 'day')
  },
  { date: moment().add(2, 'day') },
  { date: moment().add(3, 'day') },
  { date: moment().add(4, 'day') },
  { date: moment().add(5, 'day') },
  { date: moment().add(6, 'day') }
];

export const dateFormat = (dates) => {
  if (dates) {
    return `${moment(dates.date).format('DD MMM YY')} ${
      dates?.day ? '(' + dates?.day + ')' : ''
    } ${moment(dates?.date).format('dddd')}`;
  }
};

export const convertToIso = (date, str) =>
  moment(`${moment(date?.date).format('DD MMM YY')} ${str?.start || str?.end}`).toISOString();
