import { createMuiTheme } from '@material-ui/core/styles';

import apercuLight from 'assets/fonts/Apercu-Light.woff';
import apercuLightItalic from 'assets/fonts/Apercu-LightItalic.woff';
import apercu from 'assets/fonts/Apercu.woff';
import apercuItalic from 'assets/fonts/Apercu-Italic.woff';
import apercuMedium from 'assets/fonts/Apercu-Medium.woff';
import apercuBold from 'assets/fonts/Apercu-Bold.woff';
import apercuBoldItalic from 'assets/fonts/Apercu-BoldItalic.woff';

const apercu300 = {
  fontFamily: 'Apercu',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${apercuLight}) format('woff')`
};

const apercu300i = {
  fontFamily: 'Apercu',
  fontStyle: 'Italic',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${apercuLightItalic}) format('woff')`
};

const apercu400 = {
  fontFamily: 'Apercu',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${apercu}) format('woff')`
};

const apercu400i = {
  fontFamily: 'Apercu',
  fontStyle: 'Italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${apercuItalic}) format('woff')`
};

const apercu500 = {
  fontFamily: 'Apercu',
  fontStyle: 'Normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${apercuMedium}) format('woff')`
};

const apercu700 = {
  fontFamily: 'Apercu',
  fontStyle: 'Normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${apercuBold}) format('woff')`
};

const apercu700i = {
  fontFamily: 'Apercu',
  fontStyle: 'Italic',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${apercuBoldItalic}) format('woff')`
};

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#FFE550',
      main: '#FFE33F',
      dark: '#E8CF3A'
    },
    secondary: {
      main: '#e85d75'
    },
    text: {
      primary: '#4A4A4A'
    }
  },
  typography: {
    fontFamily: '"Apercu", sans-serif',
    color: '#979797',
    button: {
      textTransform: 'none'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          apercu300,
          apercu300i,
          apercu400,
          apercu400i,
          apercu500,
          apercu700,
          apercu700i
        ]
      }
    }
  }
});
export default theme;
