import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import * as yup from 'yup';

import Form, { FormControl } from './productForm.style';

const productSchema = yup.object().shape({
  item_name: yup.string().required('Required'),
  quantity: yup.number('Should be number').required('Required'),
  price: yup.number('Should be number').required('Required'),
  source_price: yup.number('Should be number').required('Required'),
  description: yup.string().required('Required'),
  assigned_pickup_source: yup.string().required('Required')
});

const ProductForm = ({ handleAddProduct }) => {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: productSchema,
    defaultValues: {
      item_name: '',
      quantity: '',
      price: '',
      source_price: '',
      description: '',
      assigned_pickup_source: ''
    }
  });
  const onSubmit = (data) => {
    const body = {
      ...data,
      product_id: 1,
      category_id: 15,
      category_name: 'Uncategorized',
      category_slug: 'uncategorized'
    };
    handleAddProduct(body);
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormControl>
          <label htmlFor="item_name">Item Name</label>
          <Controller
            as={TextField}
            fullWidth
            id="item_name"
            name="item_name"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="item_name" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="quantity">Quantity</label>
          <Controller
            as={TextField}
            fullWidth
            type="number"
            id="quantity"
            name="quantity"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="quantity" />
          </span>
        </FormControl>

        <FormControl>
          <label htmlFor="price">Price</label>
          <Controller
            as={TextField}
            id="price"
            type="number"
            variant="outlined"
            name="price"
            control={control}
            fullWidth
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="price" />
          </span>
        </FormControl>

        <FormControl>
          <label htmlFor="source_price">Source Price</label>
          <Controller
            as={TextField}
            fullWidth
            type="number"
            id="source_price"
            name="source_price"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="source_price" />
          </span>
        </FormControl>
      </div>
      <div>
        <FormControl>
          <label htmlFor="description">Description</label>
          <Controller
            as={TextField}
            multiline
            fullWidth
            id="description"
            name="description"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="description" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="assigned_pickup_source">Assigned Pickup Source</label>
          <Controller
            as={TextField}
            fullWidth
            multiline
            id="assigned_pickup_source"
            name="assigned_pickup_source"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="assigned_pickup_source" />
          </span>
        </FormControl>
        <div style={{ textAlign: 'right' }}>
          <Button type="submit" variant="contained" color="primary">
            Add Product
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ProductForm;
