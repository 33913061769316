import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  .logo {
    img {
      width: 40px;
    }
  }
  .MuiAppBar-root {
    background-color: #fff;
  }
  .toolbar {
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 32px;
  }
`;
export const Navbar = styled.div``;
