import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addStoreProduct } from 'redux/stores';
import { FormControll, AddToStoreInput, AddToStoreBtn, Form } from './AddToStore.style';

const storeSchema = yup.object().shape({
  sourcing_price: yup.number().required('Required'),
  quantity: yup.number().required('Required')
});

const AddToStoreForm = ({ prodId, storeId, setShowForm, setOpen }) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm({
    validationSchema: storeSchema
  });

  const onSubmit = (data) => {
    let body = {
      product_id: prodId,
      store_id: storeId?.id,
      area_id: storeId?.area?.id,
      zone: storeId?.area?.zone,
      ...data
    };
    dispatch(addStoreProduct(body)).then((res) => {
      if (res?.success) {
        setOpen(false);
        setShowForm(false);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControll>
        <div>Sourcing Price</div>
        <AddToStoreInput
          ref={register}
          name="sourcing_price"
          placeholder="Sourcing Price"
          type="number"
        />
        <p style={{ color: 'red' }}>{errors.sourcing_price?.message}</p>
      </FormControll>

      <FormControll>
        <div>Quantity</div>
        <AddToStoreInput ref={register} placeholder="Quantity" name="quantity" type="number" />
        <p style={{ color: 'red' }}>{errors.quantity?.message}</p>
      </FormControll>

      <AddToStoreBtn type="submit" value="ADD TO STORE" />
    </Form>
  );
};

export default AddToStoreForm;
