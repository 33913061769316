import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { compare } from 'fast-json-patch';

import { getOrderDetails, getCustomerHistory, addProduct, editProduct } from 'redux/orderDetails';
import MaterialTable from 'components/MaterialTable';
import Container from 'components/Container';
import Header from 'components/Header';
import OrderStats from './stats/OrderStats';
import Toolbar from './Toolbar/toolbar';
import { Page, OrdersTable } from './orderDetails.style';
import Products from 'repository/Products';
import isPositiveInt from 'utils/isPositiveInt';

const VoiceOrderDetails = () => {
  const tableRef = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [stats, setStats] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddProduct = async (data) => {
    const body = {
      checkout_id: stats?.id,
      data
    };
    // console.log(body);
    await dispatch(addProduct(body));
    handleClose();
    tableRef.current.onQueryChange();
  };

  const getHistory = async (phone) => {
    await dispatch(getCustomerHistory(phone));
  }

  const getData = async () => {
    const res = await dispatch(getOrderDetails(Number(id)));
    const copy = JSON.parse(JSON.stringify(res.data));
    setStats(copy);

    // console.log(copy);

    return {
      data: copy?.items ?? [],
      totalCount: copy.count ?? 0,
      page: 0
    };
  };

  const handleRowEdit = async (newData, oldData) => {
    const data = JSON.parse(JSON.stringify(oldData)); // copy oldData object
    delete data.tableData; // tableData was added by material-table
    if (
      !isPositiveInt(newData.price) ||
      !isPositiveInt(newData.quantity) ||
      !isPositiveInt(newData.source_price)
    ) {
      alert('invalid amount');
      return;
    }
    const parsedData = {
      ...newData,
      price: Number(newData.price),
      quantity: Number(newData.quantity),
      source_price: Number(newData.source_price)
    };
    const diff = compare(data, parsedData);
    // console.log(diff);
    if (Boolean(diff.length)) {
      await dispatch(editProduct({ id: newData.id, data: diff }));
    }
  };

  const columns = [
    {
      title: 'Product ID',
      field: 'product_id',
      sorting: false,
      editable: 'never',
      render: ({ product_id }) => <p>{product_id}</p>
    },
    {
      title: 'Item Name',
      field: 'item_name',
      sorting: false,
      editable: 'never',
      render: ({ item_name }) => <p>{item_name}</p>
    },
    {
      title: 'Item Description',
      field: 'item_description',
      sorting: false,
      editable: 'never',
      render: ({ item_description }) => <p>{item_description}</p>
    },
    {
      title: 'Category Name',
      field: 'category_name',
      sorting: false,
      editable: 'never',
      render: ({ category_name }) => <p>{category_name}</p>
    },
    {
      title: 'Image',
      field: 'image',
      sorting: false,
      editable: 'never',
      render: ({ image, item_name }) => (
        <figure>
          <img src={image || 'https://dh-ops.now.sh/logo.png'} width="60" alt={item_name} />
        </figure>
      )
    },
    {
      title: 'Price',
      field: 'price',
      sorting: false,
      type: 'numeric',
      render: ({ price }) => <p>Tk {price}</p>
    },
    {
      title: 'Quantity',
      field: 'quantity',
      sorting: false,
      type: 'numeric',
      render: ({ quantity }) => <p>{quantity}</p>
    },
    {
      title: 'Pickup Zone',
      field: 'pickup_zone',
      sorting: false,
      editable: 'never',
      render: ({ pickup_zone }) => <p>{pickup_zone}</p>
    },
    {
      title: 'Assigned Pickup Source',
      field: 'assigned_pickup_source',
      sorting: false,
      render: ({ assigned_pickup_source }) => <p>{assigned_pickup_source}</p>
    },
    {
      title: 'Status',
      field: 'status',
      sorting: false,
      render: ({ status }) => <p>{status}</p>
    },
    {
      title: 'Hold Reason',
      field: 'hold_reason',
      sorting: false,
      render: ({ hold_reason }) => <p>{hold_reason}</p>
    },
    {
      title: 'Source Price',
      field: 'source_price',
      sorting: false,
      render: ({ source_price }) => <p>{source_price}</p>
    }
  ];

  return (
    <Page>
      <Header />
      <Container>
        <OrderStats data={stats} getHistory={getHistory} />
        <OrdersTable>
          <MaterialTable
            tableRef={tableRef}
            components={{
              Toolbar: (props) => {
                return (
                  <Toolbar
                    open={open}
                    order={stats}
                    handleClose={handleClose}
                    handleClickOpen={handleClickOpen}
                    handleAddProduct={handleAddProduct}
                  />
                );
              }
            }}
            columns={columns}
            data={getData}
            options={{
              columnsButton: true,
              draggable: false,
              sorting: false,
              pageSize: 1000
            }}
            editable={{
              onRowUpdate: (newData, oldData) => handleRowEdit(newData, oldData),
              onRowDelete: (oldData) => Products.deleteProduct(oldData.id)
            }}
          />
        </OrdersTable>
      </Container>
    </Page>
  );
};

export default VoiceOrderDetails;
