import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { ColumnWrapper } from './columns.style';

const ColumnToggle = ({ className, hideColumn, columns }) => {
  const handleChange = (col) => (event) => {
    hideColumn(col, !event.target.checked);
  };

  return (
    <ColumnWrapper>
      {columns.map((col) => (
        <FormControlLabel
          key={col.field}
          label={col.title}
          control={
            <Checkbox
              checked={!col.hidden}
              onChange={handleChange(col)}
              value={!col.hidden}
              color="primary"
            />
          }
        />
      ))}
    </ColumnWrapper>
  );
};
export default ColumnToggle;
