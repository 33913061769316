import React from 'react';

const Layers = props => (
  <svg
    width={props.width ? props.width : 17}
    height={props.height ? props.height : 17}
    viewBox="0 0 17 17"
    fill="none"
    stroke={props.stroke ? props.stroke : '#000'}
    {...props}
  >
    <path
      d="M8.5 1L1 4.75 8.5 8.5 16 4.75 8.5 1zM1 12.25L8.5 16l7.5-3.75M1 8.5l7.5 3.75L16 8.5"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Layers;
