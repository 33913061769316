import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller, ErrorMessage, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import ImageUploader from 'react-images-upload';

import Form, { FormControl } from './brandForm.style';
import SelectBrandProducts from '../SelectBrandProducts/SelectBrandProducts';

const brandSchema = yup.object().shape({
  name: yup.string().required('Required'),
  primary_color: yup
    .string()
    .required('Primary color required')
    .matches(/^#([0-9A-Fa-f]{3}){1,2}$/, 'Invalid color code!'),
  secondary_color: yup
    .string()
    .required('Secondary color required')
    .matches(/^#([0-9A-Fa-f]{3}){1,2}$/, 'Invalid color code!'),
  short_description: yup.string(),
  logo: yup.object(),
  banner: yup.mixed(),
  footerBannerImage: yup.mixed()
});

const BrandForm = ({ handleAddBrand, single, catID }) => {
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState(null);
  const [footerBannerImage, setFooterBannerImage] = useState(null);
  const [productValues, setProductValues] = useState(undefined);

  const { register, control, handleSubmit, setValue, errors } = useForm({
    validationSchema: brandSchema,
    defaultValues: {
      name: '',
      primary_color: '',
      secondary_color: '',
      short_description: '',
      logo: null,
      banner: null,
      footerBannerImage: null
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'meta'
  });

  // useEffect(() => {}, [dispatch, catID, single, setValue, append]);

  const onSubmit = (data) => {
    if (!logo || !banner || !logo?.length || !banner?.length) {
      return;
    } else {
      const body = {
        ...data,
        logo: logo[0],
        banner: banner[0],
        footerBannerImage: footerBannerImage ? footerBannerImage[0] : null,
        products: productValues
      };
      handleAddBrand(body);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ display: 'block' }}>
      <div style={{ textAlign: 'right' }}>
        <Button type="submit" variant="contained" color="primary">
          {/* {single ? 'Update Brand' : 'Add Brand'} */}
          Add Brand
        </Button>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gridGap: '1rem' }}>
        <div>
          <FormControl>
            <label htmlFor="name">Brand Name</label>
            <Controller
              as={TextField}
              fullWidth
              id="name"
              name="name"
              control={control}
              variant="outlined"
            />
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="name" />
            </span>
          </FormControl>
          <FormControl>
            <label htmlFor="primary_color">Primary Color</label>
            <Controller
              as={TextField}
              fullWidth
              id="primary_color"
              name="primary_color"
              control={control}
              variant="outlined"
            />
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="primary_color" />
            </span>
          </FormControl>
          <FormControl>
            <label htmlFor="secondary_color">Secondary Color</label>
            <Controller
              as={TextField}
              fullWidth
              id="secondary_color"
              name="secondary_color"
              control={control}
              variant="outlined"
            />
            <span style={{ color: 'red' }}>
              <ErrorMessage style={{ color: 'red' }} errors={errors} name="secondary_color" />
            </span>
          </FormControl>
          <FormControl>
            <label htmlFor="short_description">Short Description</label>
            <Controller
              as={TextField}
              fullWidth
              id="short_description"
              name="short_description"
              control={control}
              variant="outlined"
            />
          </FormControl>

          <FormControl>
            <label htmlFor="secondary_color">Select products</label>
            <SelectBrandProducts
              productValues={productValues}
              setProductValues={setProductValues}
            />
          </FormControl>
        </div>

        <div>
          <FormControl>
            <label>Logo</label>
            <ImageUploader
              singleImage
              withIcon={true}
              buttonText="Choose a single image"
              onChange={(pictureFiles) => setLogo(pictureFiles)}
              imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
              maxFileSize={500000}
              label="Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted"
              withPreview
            />
          </FormControl>
        </div>

        <div>
          <FormControl>
            <label>Banner</label>
            <ImageUploader
              singleImage
              withIcon={true}
              buttonText="Choose a single image"
              onChange={(pictureFiles) => setBanner(pictureFiles)}
              imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
              maxFileSize={500000}
              label="Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted"
              withPreview
            />
          </FormControl>
        </div>
        <div>
          <FormControl>
            <label>Footer Banner</label>
            <ImageUploader
              singleImage
              withIcon={true}
              buttonText="Choose a single image"
              onChange={(pictureFiles) => setFooterBannerImage(pictureFiles)}
              imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
              maxFileSize={500000}
              label="Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted"
              withPreview
            />
          </FormControl>
        </div>
      </div>
    </Form>
  );
};

export default BrandForm;
