import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFormContext, Controller } from 'react-hook-form';

import AsyncSelect from 'components/select/AsyncSelect';
import Rgba from 'utils/Rgba';
import useStatusSelect from 'hooks/useStatusSelect';

const useStyles = makeStyles({
  reactSelect: ({ minWidth, fullWidth }) => ({
    minWidth: minWidth ?? 200,
    maxWidth: fullWidth ? '100%' : 230
  }),
  selectLabel: {
    color: Rgba('#4A4A4A', 0.6),
    fontSize: 14,
    lineHeight: '12px',
    margin: '0 0 6px 8px'
  },
  selectBox: {
    '& .sh__control': {
      border: '1px solid #DDDBE1'
    }
  }
});

const OrderStatus = ({ label, name, selectProps, ...props }) => {
  const classes = useStyles();
  const { control } = useFormContext();

  const statusOptionsAsync = useStatusSelect();

  return (
    <div className={classes.reactSelect}>
      {label && <p className={classes.selectLabel}>{label}</p>}
      <Controller
        as={<AsyncSelect defaultOptions loadOptions={statusOptionsAsync} {...selectProps} />}
        name={name}
        classNamePrefix="sh"
        control={control}
        {...props}
      />
    </div>
  );
};

export default OrderStatus;
