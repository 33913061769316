import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const LoginPage = styled.div``;
export const LoginWrapper = styled(Paper)`
  max-width: 400px;
  margin: 70px auto 0;
  padding: 20px;
  .formTitle {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .MuiFormControl-root {
    margin-bottom: 24px;
  }
  .MuiInputBase-input {
    padding: 8.5px 14px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 11px) scale(1);
    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
`;
