import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormControlLabel, IconButton, Switch } from '@material-ui/core';

import DMTableWrapper, { Page } from './DMTable.style';

import { getDms } from 'redux/dms';
import Header from 'components/Header';
import Container from 'components/Container';
import MaterialTable from 'components/MaterialTable';
import { Add, Edit } from '@material-ui/icons';

const InactiveToggler = ({ toggleInactive, show_inactive }) => (
  <FormControlLabel
    control={
      <Switch
        checked={show_inactive}
        onChange={(e) => toggleInactive(e.target.checked)}
        name="is_active"
        color="primary"
      />
    }
    label="Show Inactive"
  />
);

const getColumns = ({ onEdit }) => [
  {
    title: 'Edit',
    field: 'edit',
    sorting: false,
    required: true,
    render: ({ id }) => (
      <IconButton onClick={onEdit(id)}>
        <Edit />
      </IconButton>
    )
  },
  {
    title: 'DM ID',
    field: 'id',
    sorting: true,
    required: true,
    render: ({ id }) => <p>{id}</p>
  },
  {
    title: 'Name',
    field: 'name',
    sorting: true,
    required: true,
    searchable: true,
    render: ({ name }) => <p>{name}</p>
  },
  {
    title: 'Phone Number',
    field: 'phone',
    sorting: true,
    required: true,
    searchable: true,
    render: ({ phone }) => <p>{phone}</p>
  },
  {
    title: 'Active?',
    field: 'is_active',
    sorting: true,
    required: true,
    render: ({ is_active }) => <p>{is_active ? 'Yes' : 'No'}</p>
  }
];
function DMHome() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [show_inactive, setShowInactive] = useState(false);
  const tableRef = useRef(null);

  const onEdit = useCallback(
    (id) => (e) => {
      history.push(`/dms/edit/${id}`);
    },
    [history]
  );

  const columns = useMemo(() => getColumns({ onEdit }), [onEdit]);

  const getData = async (query) => {
    let body = {
      page: query.page + 1,
      limit: query.pageSize,
      search: query.search,
      show_inactive
    };
    const paramObj = history?.location?.state;
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.show_inactive) {
      body.show_inactive = paramObj?.show_inactive;
    }
    try {
      const res = await dispatch(getDms(body));
      const copy = JSON.parse(JSON.stringify(res));

      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  return (
    <Page>
      <Header />
      <Container>
        <DMTableWrapper>
          <MaterialTable
            tableRef={tableRef}
            title={'DM List'}
            columns={columns}
            data={getData}
            options={{
              columnsButton: true,
              draggable: false,
              pageSize: 20,
              debounceInterval: 500
            }}
            actions={[
              {
                icon: () => (
                  <InactiveToggler show_inactive={show_inactive} toggleInactive={setShowInactive} />
                ),
                isFreeAction: true,
                onClick: (e) => tableRef.current && tableRef.current.onQueryChange()
              },
              {
                icon: () => <FormControlLabel control={<Add />} label="Add DM" />,
                isFreeAction: true,
                onClick: (_) => history.push('/dms/add')
              }
            ]}
          />
        </DMTableWrapper>
      </Container>
    </Page>
  );
}

export default DMHome;
