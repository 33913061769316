import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useForm, FormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Funnel from 'components/icons/Funnel';
import LayerIcon from 'components/icons/Layers';
import TableSearch from 'components/TableSearch';
import MuiDatePicker from 'components/MuiDatePicker';
// import ReactSelect from 'components/select/ReactSelect';
import SelectController from 'components/select/SelectController';
import OrderStatus from 'components/select/OrderStatus';
import formatDate from 'utils/formatDate';
import Columns from '../Columns';
import { zones } from 'data/zones';
import { deliveryTypes } from 'data/deliveryTypes';
import { ToolbarWrapper, ToolbarLeft, ToolbarRight, ToolbarWrapperButton } from './toolbar.style';

const OrderTableToolbar = ({ columns, hideColumn, tableRef, orderStatus, button, setButton, ...props }) => {
  const history = useHistory();
  const paramObj = history?.location?.state || {};
  // console.log(paramObj);
  const defaultValues = {
    created_at: paramObj?.date || null,
    current_status: paramObj?.status || null,
    delivery_date: paramObj?.delivery_date || null,
    zones: paramObj?.zones || null,
    text: paramObj?.search || '',
    delivery_type: paramObj?.delivery_type || ''
  };
  const methods = useForm({
    defaultValues
  });
  const { formState, handleSubmit, reset } = methods;
  const [openToggle, setOpenToggle] = useState(false);

  const handleColumnToggle = () => {
    setOpenToggle(!openToggle);
  };

  // console.log(getValues());
  const isDirty = formState.dirty || Object.keys(paramObj).find((param) => Boolean(param) === true);

  const onSubmit = (data) => {
    console.log(data);
    const body = {};
    if (data?.created_at) {
      const created_at = formatDate(data?.created_at, 'YYYY-MM-DD');
      body.date = created_at;
    }
    if (data?.delivery_date) {
      const delivery_date = formatDate(data?.delivery_date, 'YYYY-MM-DD');
      body.delivery_date = delivery_date;
    }
    if (data?.current_status) {
      body.status = data?.current_status;
    }
    if (data?.zones?.length) {
      body.zones = data?.zones;
    }
    if (data?.text) {
      body.search = data?.text;
    }
    if (data?.delivery_type) {
      body.delivery_type = data?.delivery_type;
    }

    history.push({
      state: { ...body }
    });

    tableRef.current.onQueryChange();
  };

  const handleClearFilter = () => {
    reset(defaultValues);
    setButton(0);
    history.push({
      state: {}
    });
    tableRef.current.onQueryChange();
  };

  return (
    <>
    <ToolbarWrapper>
      <FormContext {...methods}>
        <ToolbarLeft onSubmit={handleSubmit(onSubmit)}>
          <Funnel className="funnel-icon" />
          <MuiDatePicker
            className="date_picker"
            label="Created at"
            name="created_at"
            id="created_at"
            defaultValue={paramObj?.date}
          />
          <MuiDatePicker
            className="date_picker"
            label="Delivery Date"
            name="delivery_date"
            id="delivery_date"
          />
          <OrderStatus isMulti isClearable label="Current Status" name="current_status" />
          <SelectController
            isClearable
            label="Delivery Zone"
            name="zones"
            options={zones}
            minWidth={150}
            selectProps={{ isMulti: true }}
          />
          <SelectController
            isClearable
            label="Delivery Type"
            name="delivery_type"
            options={deliveryTypes}
            minWidth={120}
          />
          <Button type="submit" variant="contained" color="primary" disabled={!isDirty}>
            Filter
          </Button>
          <Button
            variant="contained"
            type="reset"
            className="reset-toolbar"
            onClick={handleClearFilter}
            disabled={!isDirty}
          >
            Clear
          </Button>
        </ToolbarLeft>
        <ToolbarRight>
          {/* <Button className="print-button" variant="contained" color="primary">
            <Print stroke="#4A4A4A" width={19} height={19} /> Print
          </Button> */}
          <TableSearch value={paramObj?.search || ''} onSubmit={onSubmit} mw={230} />
          <IconButton onClick={handleColumnToggle} className="toggle-column">
            {openToggle ? <CloseIcon /> : <LayerIcon stroke="#4A4A4A" width={15} height={15} />}
          </IconButton>
          {openToggle && <Columns columns={columns} hideColumn={hideColumn} />}
        </ToolbarRight>
      </FormContext>
    </ToolbarWrapper>
    {orderStatus?.orderStatusData && 
    <ToolbarWrapperButton>
      <Button type="button" variant="contained" color={button === 1 ? "primary" : "default"} style={{margin: "0 4px"}}
        onClick={() => { 
          onSubmit({current_status: [{"value":"PENDING_AGENT_ASSIGNMENT","label":"Pending Agent Assignment"}]});
          setButton(1);
        }}
      >
        {"Pending Agent Assignment ("+(orderStatus?.orderStatusData?.PENDING_AGENT_ASSIGNMENT || '0')+")"}
      </Button>
      <Button type="button" variant="contained" color={button === 2 ? "primary" : "default"} style={{margin: "0 4px"}}
        onClick={() => { 
          onSubmit({current_status: [{"value":"PENDING_ORDER_CONFIRMATION","label":"Pending Order Confirmation"}]});
          setButton(2);
        }}
      >
        {"Pending Order Confirmation ("+(orderStatus?.orderStatusData?.PENDING_ORDER_CONFIRMATION || '0')+")"}
      </Button>
      <Button type="button" variant="contained" color={button === 3 ? "primary" : "default"} style={{margin: "0 4px"}}
        onClick={() => { 
          onSubmit({current_status: [{"value":"ONGOING_ORDER_PREPARATION","label":"Ongoing Order Preparation"}]});
          setButton(3);
        }}
      >
        {"Ongoing Order Preparation ("+(orderStatus?.orderStatusData?.ONGOING_ORDER_PREPARATION || '0')+")"}
      </Button>
      <Button type="button" variant="contained" color={button === 4 ? "primary" : "default"} style={{margin: "0 4px"}}
        onClick={() => {
          onSubmit({current_status: [{"value":"IN_DELIVERY","label":"In Delivery"}, {"value":"TRANSFERRING_TO_STATION","label":"Transferring to Station"}]});
          setButton(4);
        }}
      >
        {"In Delivery & Transferring to Station ("+(parseInt(orderStatus?.orderStatusData?.IN_DELIVERY || '0')+parseInt(orderStatus?.orderStatusData?.TRANSFERRING_TO_STATION || '0'))+")"}
      </Button>
      <Button type="button" variant="contained" color={button === 5 ? "primary" : "default"} style={{margin: "0 4px"}}
        onClick={() => {
          onSubmit({current_status: [{"value":"CANCELLED_BY_CUSTOMER","label":"Cancelled by Customer"}]});
          setButton(5);
        }}
      >
        {"Order Cancelled by Customer ("+(orderStatus?.orderStatusData?.CANCELLED_BY_CUSTOMER || '0')+")"}
      </Button>
      <Button type="button" variant="contained" color={button === 6 ? "primary" : "default"} style={{margin: "0 4px"}}
        onClick={() => {
          onSubmit({current_status: [{"value":"DELIVERED","label":"Delivered"}]});
          setButton(6);
        }}
      >
        {"Delivered ("+(orderStatus?.orderStatusData?.DELIVERED || '0')+")"}
      </Button>
    </ToolbarWrapperButton>
    }
    </>
  );
};

export default OrderTableToolbar;
