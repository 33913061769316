import styled from 'styled-components';

export const Page = styled.div``;

const Wrapper = styled.form`
  margin: 0;
  padding: 30px 20px 100px;
  border-radius: 4px;
`;

export const PageHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;

  .product-heading {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    text-transform: capitalize;
    color: #1f1f1f;
  }

  .edit-logo {
    margin-right: 18px;
  }
`;

export const Main = styled.main`
  display: flex;
  justify-content: space-between;
`;

export const MainContent = styled.div``;

export const PriceTag = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;

    del {
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;

export const AddButton = styled.button`
  padding: 3px 20px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 4px;
  color: #282828;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
`;

export const ProductTag = styled.div`
  margin-top: 16px;

  display: flex;
  align-items: center;
`;

export const ProductButton = styled.button`
  margin-right: 12px;
  padding: 3px 8px;
  background-color: #ffe33f;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
`;

export const ExtendedDescriptionForm = styled.div`
  margin-top: 25px;

  .form {
    min-width: 600px;
  }

  .form__group {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .form__label {
    display: block;
    padding-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.5);
  }

  .form__input {
    display: inline-block;
    min-width: 90%;
    padding: 10px 0 10px 17px;
    background-color: #fcfcfc;
    border: 1px solid #dadada;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    &::-webkit-input-placeholder {
      color: #000000;
    }

    &::-moz-placeholder {
      color: #000000;
    }

    &:-ms-input-placeholder {
      color: #000000;
    }

    &:-moz-placeholder {
      color: #000000;
    }
  }

  .form__input--2 {
    min-height: 115px;
  }

  .btn {
    width: 93%;
    padding: 12px 0;
    background: #fcfcfc;
    border: 1px solid #dadada;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: capitalize;
    color: #000000;
  }
`;

export const SalesPrice = styled.div`
  margin-top: 30px;
  hr {
    background-color: rgba(0, 0, 0, 0.1);
    margin: 18px 0 13px;
  }

  .form {
    min-width: 600px;
  }

  .input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .form__group {
    // padding-right: 10px;
    flex-basis: 49%;
  }

  .form__label {
    display: block;
    padding-left: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }

  .form__input {
    display: inline-block;
    /* min-width: 90%; */
    padding: 10px 0 10px 17px;
    background-color: #fcfcfc;
    border: 1px solid #dadada;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    &::-webkit-input-placeholder {
      color: #000000;
    }

    &::-moz-placeholder {
      color: #000000;
    }

    &:-ms-input-placeholder {
      color: #000000;
    }

    &:-moz-placeholder {
      color: #000000;
    }
  }

  .form__label--modify {
    color: rgba(0, 0, 0, 0.5);
  }

  .price-condition {
    display: flex;
    align-items: center;

    .price-condition-item {
      display: inline-block;
    }
    .price-condition-item--1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      background: #ffe33f;
      border: 3px solid #4a4a4a;
      border-radius: 4px;

      margin-right: 12px;
    }

    .price-condition-item--2 {
    }
  }

  .status-container {
    display: flex;
    align-items: center;
  }

  .product-status {
    display: inline-block;
    /* min-width: 90%; */
    padding: 10px 0 10px 17px;
    background-color: #fcfcfc;
    border: 1px solid #dadada;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
`;

export const StoresSourceAndProducts = styled.div`
  margin-top: 45px;
  margin-bottom: 60px;
  padding: 22px 17px 30px;
  background-color: #f2f2f2;
  border-radius: 10px;

  hr {
    margin: 15px 0;
  }

  .stores-area {
    margin-bottom: 14px;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
  }
`;

export const ViewArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #1f1f1f;
  }

  .button-box {
    display: flex;
    align-items: center;
  }

  .map-btn {
    display: flex;
    align-items: center;
    margin-right: 13px;
    padding: 10px 32px 10px 22px;
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
  }

  .add-edit-btn {
    padding: 10px 32px 10px 22px;
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
  }
`;

export const StoreAndSourceCardYellow = styled.div`
  display: flex;
  padding: 15px 20px 15px 25px;
  margin-bottom: 23px;
  justify-content: space-between;
  align-items: center;
  background: #fff2a9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

export const StoreAndSourceCardWhite = styled.div`
  display: flex;
  padding: 15px 20px 15px 25px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

export const CardContentLeft = styled.div`
  display: flex;
  align-items: center;

  .store-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-right: 25px;
  }

  p {
    margin: 0;
  }

  .store-info {
    font-style: normal;
    font-size: 14px;
  }

  .store-heading {
    font-weight: bold;
    color: #000000;
    margin-bottom: 5px;
  }

  .store-number {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const CardContentRight = styled.div`
  .option-box {
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;

    span {
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      margin-right: 10px;
    }
  }

  .stock-remaining {
    margin: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const ProductsCategoriesInput = styled.div`
  .form__label {
    display: block;
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
  }

  .form__input {
    width: 100%;
    padding: 10px 0 10px 11px;
    background-color: #e9e9e9;
    border: 1px solid #7b7b7b;
    border-radius: 4px;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const ImageSlider = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 20px;

  .gallery-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .image-item {
    width: 100%;
  }

  .image-item-g {
    padding: 4px 4px;
  }
`;

export default Wrapper;

export const CategoryWrapper = styled.div`
  margin-top: 10px;
  height: 300px;
  overflow-y: scroll;
`;

export const PpsWrapper = styled.div`
  width: 540px;
  @media(max-width: 480px){
    width: 340px;
    margin-left: -30px;
  }
`

export const PpsHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  background: #f2f2f2;
`;

export const PpsUpdateBtn = styled.div`
  padding: 5px 15px;
  font-weight: bold;
  border: none;
  box-shadow: 0px 0px 3px;
  border-radius: 5px;
  cursor: pointer;
`;

export const PpsTableContainer = styled.div`
  background: #f2f2f2;
  padding: 20px;
  height: 600px;
  overflow-y: scroll;
`;

export const PpsTableItem = styled.div`
  box-shadow: 0px 0px 3px;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  background: #fff;
`;

export const PpsInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
  @media(max-width: 480px){
    align-items: flex-start;
    flex-direction: column;
  }
`

export const PpsInput = styled.input`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  padding: 7px 5px;
  margin-top: 3px;
  &:focus {
    border: 1px solid #ffe33f;
    outline: none;
  }
`;
export const PpsAddBtn = styled.div`
  padding: 5px 15px;
  font-weight: bold;
  border: none;
  box-shadow: 0px 0px 3px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
`;

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;
