import React, { useState } from 'react';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

import Select from 'components/select/Select';
import ProductForm from '../ProductForm/productForm';
import StoreProduct from '../StoreProduct/storeProduct';
import { Wrapper, Header } from './addProduct.style';

const options = [
  {
    label: 'Custom Product',
    value: 'custom'
  },
  {
    label: 'Store Product',
    value: 'store'
  }
];

const AddProduct = ({ open, order, handleClose, handleAddProduct }) => {
  const [productType, setProductType] = useState('custom');

  const handleProductType = (selected) => {
    setProductType(selected.value);
    console.log(selected.value);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Wrapper>
        <Header>
          <Typography component="h3" className="title">
            Add Product
          </Typography>
          <Select options={options} defaultValue={options[0]} onChange={handleProductType} />
        </Header>
        {productType === 'custom' && (
          <ProductForm order={order} handleAddProduct={handleAddProduct} />
        )}
        {productType === 'store' && (
          <StoreProduct order={order} handleAddProduct={handleAddProduct} />
        )}
      </Wrapper>
    </Dialog>
  );
};

export default AddProduct;
