import React from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import Button from "@material-ui/core/Button";
import Container from 'components/Container';
import Header from 'components/Header';
import { updateCategories } from 'redux/categories';
import CategoryForm from '../CategoryForm/categoryForm';


import Wrapper, {
  Page,
} from "./catDetails.style";



const CategoryDetails = () => {
  // const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  const onSubmit = (sid, body) => {
    console.log("update category");
    dispatch(updateCategories({id: sid, data: body}));
  };
  
  return (
    <Page>
      <Header/>
    <Container>
    <Wrapper>
      <CategoryForm catID={id} single={true} handleAddCategory={onSubmit}/>
    </Wrapper>
    </Container>
    </Page>
  );
};

export default CategoryDetails;
