/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useRef, useEffect } from 'react';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { getOrders, editOrder, updateCheckout, bulkUpdate } from 'redux/orders';
import { getOrderStatus, selectors } from 'redux/orderDetails';
import { compare } from 'fast-json-patch';
import moment from 'moment';
import { MTableToolbar, MTableEditField } from 'material-table';
import MaterialTable from 'components/MaterialTable';
// import PageHeader from 'components/PageHeader';
// import Select from 'components/select/Select';
import Container from 'components/Container';
import Header from 'components/Header';
import { orders } from 'data/orders';
import formatDate from 'utils/formatDate';
import OrderDetailPane from './OrderDetailPane';
import PdfDocument from './pdfDocument';
import BatchEdit from './BatchEdit';
import OrderTableToolbar from './OrderTableToolbar';
// import { zones } from 'data/zones';
import { orderHoldReasons } from 'data/orderHoldReasons';
import { Page, OrdersTable } from './home.style';
import Button from '@material-ui/core/Button';
import Print from 'components/icons/Print';
import { OpenInNew } from '@material-ui/icons';
// import { getStats, selectors as statSelectors } from 'redux/stats';

const holdReasonLabels = orderHoldReasons.reduce(
  (reasons, { value, label }) => ({ ...reasons, [value]: label }),
  {}
);

const Home = () => {
  const tableRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [isOpsPdf, setIsOpsPdf] = useState(false);
  const [button, setButton] = useState(0);
  // const tableRef = React.createRef();
  // const [openBatchEdit, setOpenBatchEdit] = useState(false);
  const orderStatus = useSelector(selectors.getStatus);
  const authToken = useSelector((state) => state.auth.token);

  const getData = async (query) => {
    let body = {
      page: query.page + 1,
      limit: query.pageSize
    };
    const paramObj = history?.location?.state;
    if (paramObj?.date) {
      body.date = paramObj?.date;
    }
    if (paramObj?.delivery_date) {
      body.delivery_date = paramObj?.delivery_date;
    }
    if (paramObj?.status) {
      const status = paramObj?.status?.map((st) => st.value);
      body.status = status;
    }
    if (paramObj?.zones) {
      const zones = paramObj?.zones?.map((zone) => zone.value);
      body.zones = zones;
    }
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    if (paramObj?.delivery_type) {
      console.log(paramObj?.delivery_type);
      body.delivery_type = paramObj?.delivery_type.value;
    }
    try {
      const res = await dispatch(getOrders(body, authToken));
      const copy = JSON.parse(JSON.stringify(res));

      return {
        data: copy.data ?? [],
        totalCount: copy.count ?? 0,
        page: copy.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const closeBatchEdit = () => {
    setIsEditOpen(false);
  };

  const handleRowSelect = (rows) => {
    const selectEnabled = !!rows.length;
    const ids = rows.map((r) => r.id);
    setSelectedRows(ids);
    if (selectEnabled !== isEditOpen) {
      setIsEditOpen(selectEnabled);
    }
  };

  const handleBatchEdit = async (values) => {
    const body = {};
    Object.keys(values).forEach((item) => {
      console.log(values);
      if (values[item] !== undefined) {
        body[item] = values[item];
      }
    });

    if (values?.current_status) {
      const status = values?.current_status?.value;
      body.current_status = status;
    }

    if (Object.keys(body).length) {
      body.checkout_ids = selectedRows;
      await dispatch(bulkUpdate(body));
      tableRef.current.onQueryChange();
      closeBatchEdit();
    } else {
      console.log('no changes');
    }
  };

  useEffect(() => {
    dispatch(getOrderStatus(authToken));
  }, [dispatch]);

  const makePdfData = async (data, isOps) => {
    setIsOpsPdf(isOps);
    setData(data);
  };

  const handleRowEdit = async (newData, oldData) => {
    if (!newData.customer_name || !newData.customer_phone || !newData.full_address) {
      throw new Error('Required field');
    }
    const data = JSON.parse(JSON.stringify(oldData)); // copy oldData object
    delete data.tableData; // tableData was added by material-table
    const diff = compare(data, newData);
    // console.log(diff);
    if (Boolean(diff.length)) {
      await dispatch(editOrder(newData.id, diff));
    }
  };

  const handleOrderUpdate = async (id, body) => {
    await dispatch(updateCheckout(id, authToken, body));
    // tableRef?.current?.onQueryChange() || console.log('Table ref not found');
  };

  const columns = [
    {
      title: 'Order ID',
      field: 'id',
      sorting: false,
      editable: 'never',
      // cellStyle: {
      //   width: 'calc(100% - 0px) / 9) !important',
      //   maxWidth: 'calc(100% - 0px) / 9) !important',
      // },
      // headerStyle: {
      //   width: 'calc(100% - 0px) / 9) !important',
      //   maxWidth: 'calc(100% - 0px) / 9) !important',
      // },
      render: ({ id }) => <p className="text__typography">DH-{id}</p>
    },

    // {
    //   title: 'Hold Reason',
    //   field: 'hold_reason',
    //   sorting: false,
    //   editable: 'never',
    //   render: ({ hold_reason }) => {
    //     return <div className="hold_reason">{holdReasonLabels?.[hold_reason]}</div>;
    //   }
    // },
    {
      title: 'Created Time',
      field: 'created_at',
      sorting: false,
      editable: 'never',
      render: ({ created_at, status }) => (
        <>
          <div className="date_time text__typography">
            {moment(created_at).format('hh:mm:ss A')} <br />
            {formatDate(created_at)}
          </div>
          {status?.value === 'PENDING_AGENT_ASSIGNMENT' ||
          status?.value === 'ONGOING_ORDER_PREPARATION' ||
          status?.value === 'IN_DELIVERY' ? (
            <div
              style={{
                background:
                  moment(Date.now())?.diff(created_at, 'm') >= 60
                    ? '#ea4335'
                    : moment(Date.now())?.diff(created_at, 'm') > 30 &&
                      moment(Date.now())?.diff(created_at, 'm') < 60
                    ? '#f5ab00'
                    : '#71d08d',
                borderRadius: 5,
                padding: 2,
                maxWidth: '130px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontWeight: 'semi-bold'
              }}
            >
              <>{moment(created_at).fromNow()}</>
            </div>
          ) : (
            <> </>
          )}
        </>
      )
    },
    {
      title: 'Name & Contact No.',
      field: 'customer_name',
      sorting: false,
      required: true,
      render: ({ customer_name, customer_phone }) => (
        <>
          <span className="customer__name text__typography">{customer_name}</span>
          <span className="customer__name text__typography">{customer_phone}</span>
        </>
      )
    },
    // {
    //   title: 'Customer Phone',
    //   field: 'customer_phone',
    //   required: true,
    //   sorting: false,
    //   render: ({ customer_phone }) => <p>{customer_phone}</p>
    // },
    {
      title: 'Full Address & Area',
      field: 'area_id',
      sorting: false,
      editable: 'never',
      render: ({ area, full_address }) => (
        <>
          <span className="text__typography">{full_address}</span>
          <div className="city_area text__typography">
            <b>
              {area?.area} <br />
            </b>
            {/* <span>Zone</span>: {area.zone} <br /> */}
            {/* <span>Station</span>: {area.station} */}
          </div>
        </>
      )
    },
    // {
    //   title: 'Full Address',
    //   field: 'full_address',
    //   sorting: false,
    //   required: true,
    //   render: ({ full_address }) => <p>{full_address}</p>
    // },
    // {
    //   title: 'Delivery Type',
    //   field: 'delivery_type',
    //   sorting: false,
    //   editable: 'never',
    //   render: ({ delivery_type }) => <p>{delivery_type}</p>
    // },
    // {
    //   title: 'Additional Req',
    //   field: 'additional_req',
    //   sorting: false,
    //   editable: 'never',
    //   editComponent: (props) => <Select options={orderHoldReasons} />,
    //   render: ({ additional_req }) => <p style={{ minWidth: 160 }}>{additional_req}</p>
    // },
    {
      title: (
        <span>
          Total Collectable <br />& Cart Items
        </span>
      ),
      field: 'total_collectable',
      sorting: false,
      type: 'currency',
      editable: 'never',
      cellStyle: {
        textAlign: 'left'
      },
      render: ({ order_price, items_count }) => (
        <>
          <span className="cart__collection text__typography">Tk {order_price}</span>
          <span className="cart__collection text__typography">{items_count} items</span>
        </>
      )
    },
    // {
    //   title: 'Cart Items',
    //   field: 'cart_items',
    //   type: 'numeric',
    //   editable: 'never',
    //   sorting: false,
    //   render: ({ items_count }) => <p>{items_count}</p>
    // },
    {
      title: 'Status & Hold Reason',
      field: 'order_status',
      sorting: false,
      editable: 'never',
      render: ({ status, hold_reason, admin_comment, delivered_at, created_at }) => (
        <>
          {status?.value === 'DELIVERED' ? (
            <span
              className="text__typography"
              style={{
                background: '#71d08d',
                borderRadius: 5,
                padding: 2,
                maxWidth: '150px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontWeight: 'bold'
              }}
            >{`${status?.label} (${moment(delivered_at)?.diff(
              moment(created_at),
              'm'
            )} min)`}</span>
          ) : (
            <span className="text__typography">{status?.label}</span>
          )}
          <div className="hold_reason text__typography">{holdReasonLabels?.[hold_reason]}</div>
          <div className="text__typography">{admin_comment}</div>
        </>
      )
    },
    {
      title: 'Delivery Time',
      field: 'delivery_date',
      editable: 'never',
      sorting: false,
      render: ({ delivery_date }) => <p className="text__typography">{formatDate(delivery_date)}</p>
    },
    {
      title: 'Assigned Agent',
      field: 'assigned_agent',
      sorting: false,
      editable: 'never',
      render: ({ delivery_man }) => (
        <div className="assigned_agent text__typography">
          {delivery_man?.name}
          <br />
          {delivery_man?.phone}
        </div>
      )
    }

    // {
    //   title: 'Cart Price',
    //   field: 'cart_price',
    //   type: 'numeric',
    //   editable: 'never',
    //   sorting: false,
    //   render: ({ cart_price }) => <p>Tk {cart_price}</p>
    // }
  ];

  const name = data?.map((ck) => `DH-${ck.id}-${ck?.area?.area?.slice(8)}`)?.join('+') ?? 'pdf';

  return (
    <Page>
      <Header />
      <Container>
        <OrdersTable className={isEditOpen && 'isOpen'}>
          {/* <PageHeader title="DeliveryHobe Orders Management Dashboard" data={stats.data} /> */}
          <div style={{ textAlign: 'end' }}>
            <span onClick={() => setData(undefined)}>
              {data && (
                <PDFDownloadLink
                  document={<PdfDocument data={data} isOpsPdf={isOpsPdf} />}
                  fileName={`${name}.pdf`}
                  style={{
                    textDecoration: 'none',
                    color: '#4a4a4a',
                    backgroundColor: '#f2f2f2'
                  }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      ''
                    ) : (
                      <Button className="print-button" variant="contained" color="primary">
                        <Print stroke="#4A4A4A" width={19} height={19} /> Print {name}
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
            </span>
          </div>
          <MaterialTable
            liveSearch
            tableRef={tableRef}
            title={orders.length > 1 ? 'Orders Table' : 'Order Table'}
            columns={columns}
            data={getData}
            onSelectionChange={handleRowSelect}
            onRowClick={(event, rowData) => history.push(`order/${rowData.id}`)}
            onSearchChange={(v) => console.log(v)}
            components={{
              EditField: (props) => {
                if (props.columnDef.required && props.value.length === 0) {
                  return <MTableEditField {...props} error label="Required" />;
                }
                return <MTableEditField {...props} />;
              },
              Toolbar: (props) => {
                // const { actions } = props;
                return (
                  <>
                    <MTableToolbar {...props} search={false} columnsButton={false} />
                    <OrderTableToolbar
                      columns={columns}
                      tableRef={tableRef}
                      hideColumn={props.onColumnsChanged}
                      orderStatus={orderStatus}
                      button={button}
                      setButton={setButton}
                      {...props}
                    />
                  </>
                );
              }
            }}
            options={{
              columnsButton: true,
              draggable: false,
              selection: true,
              pageSize: 20,
              pageSizeOptions: [20, 120, 150, 180, 200]
              // actionsColumnIndex: -1
              // sorting: false
            }}
            editable={{
              onRowUpdate: (newData, oldData) => handleRowEdit(newData, oldData)
            }}
            actions={[
              {
                tooltip: 'click for pdf',
                icon: (props) => <PictureAsPdfIcon />,
                onClick: (evt, data) => {
                  // console.log(evt);
                  makePdfData(data, false);
                }
                // isFreeAction: true
              },
              {
                tooltip: 'click for ops pdf',
                icon: (props) => <PictureAsPdfIcon />,
                onClick: (evt, data) => {
                  // console.log(evt);
                  makePdfData(data, true);
                }
                // isFreeAction: true
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange()
              },
              {
                icon: () => <OpenInNew />,
                tooltip: 'Open in New Tab',
                position: 'row',
                onClick(evt, rowData) {
                  window.open(`order/${rowData.id}`, '_blank');
                }
              }
            ]}
            detailPanel={(rowData) => {
              return <OrderDetailPane order={rowData} onOrderUpdate={handleOrderUpdate} />;
            }}
          />
        </OrdersTable>
        <BatchEdit onSubmit={handleBatchEdit} open={isEditOpen} onClose={closeBatchEdit} />
      </Container>
    </Page>
  );
};

export default Home;
