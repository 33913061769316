import React from 'react';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/auth';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { selectors } from 'redux/auth';

import { HeaderWrapper, Navbar } from './header.style';

import logo from 'assets/img/logo.png';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectors.getUser);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = () => {
    dispatch(logout());
    setAnchorEl(null);
  };

  // console.log(user, 'user');

  return (
    <HeaderWrapper>
      <AppBar position="static">
        <Toolbar className="toolbar">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="logo"
            className="logo"
            onClick={() => history.push('/')}
          >
            <img src={logo} alt="DeliveryHobe Logo" />
          </IconButton>
          <Navbar>
            <Button color="inherit" onClick={() => history.push('/dms')}>
              DM Management
            </Button>
            <Button color="inherit" onClick={() => history.push('/products')}>
              Products
            </Button>
            <Button color="inherit" onClick={() => history.push('/categories')}>
              Categories
            </Button>
            <Button color="inherit" onClick={() => history.push('/brands')}>
              Brands
            </Button>
            <Button color="inherit" onClick={() => history.push('/stores')}>
              Stores
            </Button>
            <Button color="inherit" onClick={() => history.push('/statistics')}>
              Statistics
            </Button>
            {user?.role === 'admin' ||
            user?.role === 'super_admin' ||
            user?.role === 'super_ops' ? (
              <Button color="inherit" onClick={() => history.push('/voice-orders')}>
                Voice Orders
              </Button>
            ) : null}
            {user?.role === 'admin' ||
            user?.role === 'super_admin' ||
            user?.role === 'super_ops' ? (
              <Button color="inherit" onClick={() => history.push('/custom-order')}>
                Custom Order
              </Button>
            ) : null}
            <Button
              variant="contained"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              {user?.name || ''}
            </Button>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={logoutUser}>Logout</MenuItem>
            </Menu>
          </Navbar>
        </Toolbar>
      </AppBar>
    </HeaderWrapper>
  );
};

export default Header;
