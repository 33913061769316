import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const Wrapper = styled(Paper)`
  max-width: 1840px;
  padding: 16px;
  background-color: #fafafa;
  text-align: left;

  display: flex;

  @media (max-width: 1020px) {
    flex-wrap: wrap;
  }
`;

export const ListHover = styled.div`
  width: 50%;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: lightblue;
    transition: ease-in 0.2s;
  }
`;

export const Group = styled.div`
  width: calc(33% - 20px);
  padding: 16px;
  text-align: left;

  &:not(:last-child) {
    margin-right: 20px;
  }

  .groupTitle {
    margin-top: 0;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 12px;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    span {
      display: block;
      color: #707070;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dhSelect {
    min-width: 240px;
  }
  .MuiOutlinedInput-input {
    padding: 0 14px;
    min-height: 40px;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`;

export const ProductsTotalCost = styled.div`
  margin-top: 2.5rem;

  .pencil-wrapper {
    height: 2.25rem;
    width: 2.25rem;
    margin-right: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
