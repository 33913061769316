import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm, Controller, ErrorMessage, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import ImageUploader from 'react-images-upload';
import CategorySelect from './CatSelect';
import Tags from './tags';
import { getDetails, editCatThumb } from 'redux/categories';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Form, { FormControl } from './categoryForm.style';
import { ENV } from 'ENV';

const categorySchema = yup.object().shape({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
  active: yup.bool(),
  tags: yup.array().of(yup.string()),
  meta: yup.array().of(yup.object()),
  category_layer: yup.number()
});

const CategoryForm = ({ handleAddCategory, single, catID }) => {
  const dispatch = useDispatch();
  const [CatData, setCatData] = useState([]);
  const [thumb, setThumb] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [catValue, setCatValue] = useState(undefined);
  const [active, setActive] = React.useState(false);

  const { register, control, handleSubmit, setValue, errors } = useForm({
    validationSchema: categorySchema,
    defaultValues: {
      name: '',
      description: '',
      active: false,
      parent_id: null,
      tags: [],
      meta: single ? [] : [{ key: 'color', value: '#000000' }],
      category_layer: null
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'meta'
  });

  useEffect(() => {
    single &&
      (async () => {
        console.log('running...');
        const res = await dispatch(getDetails(catID));
        const data = JSON.parse(JSON.stringify(res));
        setTagsList(res?.tags?.map((item) => item));
        setCatValue(res?.parent?.id);
        setActive(res?.active);
        const category_layer = res?.category_layer;
        const metas =
          data?.meta &&
          Object.keys(data?.meta)?.map((key) => {
            append({ key: key, value: data?.meta[key] });
            return { key: key, value: data?.meta[key] };
          });

        // console.log(metas);
        setCatData(data);
        setValue('name', res?.name || '');
        setValue('description', res?.description || '');
        setValue('active', res?.active);
        setValue('parent_id', res?.parent_id || '');
        setValue('tags', res?.tags?.map((item) => item) || []);
        setValue('meta', !!metas?.length ? [...metas] : []);
        setValue('category_layer', res?.category_layer || '');
      })();
  }, [dispatch, catID, single, setValue, append]);

  const onSubmit = (data) => {
    const body = {
      ...data,
      tags: tagsList || null,
      parent_id: Number(catValue),
      meta: !!data?.meta?.length
        ? data?.meta?.reduce((a, item) => {
            const { key } = item;
            const camel = key.replace(/ ./g, (chars) => chars[1].toUpperCase());
            a[camel] = item.value;
            return a;
          }, {})
        : null
    };
    // single ? handleAddCategory(catID, body) : handleAddCategory(body, thumb);
    single && !!thumb.length && dispatch(editCatThumb(catID, thumb));
    if (single) {
      handleAddCategory(catID, body);
    } else {
      handleAddCategory(body, thumb);
      setValue('name', '');
      setValue('description', '');
      setValue('active', false);
      setValue('parent_id', '');
      setValue('tags', []);
      setValue('meta', []);
      setValue('category_layer', '');
      setTagsList([]);
      setCatValue(undefined);
    }
  };

  const handleActiveChange = (event) => {
    setActive(event.target.checked);
  };

  if (single && !CatData?.id) return <div>Loading data...</div>;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormControl>
          <label htmlFor="name">Category Name yes</label>
          <Controller
            as={TextField}
            fullWidth
            id="name"
            name="name"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="name" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="description">Description</label>
          <Controller
            as={TextField}
            fullWidth
            id="description"
            name="description"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="description" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="active">Active?</label>
          <Controller
            as={
              <Switch
                checked={active}
                inputRef={register}
                onChange={handleActiveChange}
                name="active"
              />
            }
            id="active"
            name="active"
            label="active"
            control={control}
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="active" />
          </span>
        </FormControl>
        <br />
        <label>Parent Category</label>
        <br />
        <CategorySelect cats={catValue} setCatValue={setCatValue} />
        <br />
        <br />
        <FormControl>
          <label htmlFor="order_channel">Category Layer</label>
          <Controller
            as={
              <Select
                labelId="category_layer"
                id="category_layer"
                label="category_layer"
                variant="outlined"
                fullWidth
                // disabled={deliMethod !== 'ONLINE' ? true : false}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            }
            fullWidth
            id="category_layer"
            name="category_layer"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="category_layer" />
          </span>
        </FormControl>
      </div>
      <div>
        <label>Tags</label>
        <Tags Tags={tagsList} setTags={setTagsList} />
        <br />
        <label>Meta</label>
        <ul>
          {fields.map((item, index) => (
            <li key={item.id}>
              <input
                name={`meta[${index}].key`}
                ref={register()}
                defaultValue={item.key} // make sure to set up defaultValue
              />
              <span />
              <Controller
                as={<input />}
                name={`meta[${index}].value`}
                control={control}
                defaultValue={item.value} // make sure to set up defaultValue
              />
              <button type="button" onClick={() => remove(index)}>
                Delete
              </button>
            </li>
          ))}
        </ul>
        <button type="button" onClick={() => append({ key: 'Key', value: 'Value' })}>
          append
        </button>
        <br />
      </div>
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button type="submit" variant="contained" color="primary">
            {single ? 'Update Category' : 'Add Category'}
          </Button>
        </div>
        <FormControl>
          <label>Category Image</label>
          <ImageUploader
            singleImage
            withIcon={true}
            buttonText="Choose a single image"
            onChange={(pictureFiles) => setThumb(pictureFiles)}
            imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
            maxFileSize={500000}
            label="Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted"
            defaultImages={[ENV.REACT_APP_API_URL + CatData?.meta?.images?.thumb ?? '']}
            withPreview
          />
        </FormControl>
      </div>
    </Form>
  );
};

export default CategoryForm;
