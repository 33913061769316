import { baseUrl } from './config';
import axios from 'axios';

const instance = axios.create({
  baseURL: baseUrl
  // headers: {
  //   'X-Requested-With': 'XMLHttpRequest'
  // }
});

export default async (url, optionsProps = {}) => {
  const options = {
    method: 'GET',
    mode: 'cors',
    url: url,
    ...optionsProps
  };

  try {
    const response = await instance(options);

    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return error.response;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      return error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
    return error;
  }
};
