import styled from 'styled-components';

export const ToolbarRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const ToolbarWrapperTop = styled.form`
  display: flex;
  align-items: flex-end;
  > div {
    margin-right: 16px;
  }
  > form {
    margin-right: 16px;
  }
`;

export const ToolbarWrapperButton = styled.div`
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  align-items: flex-end;
  > div {
    margin-right: 16px;
  }
  > form {
    margin-right: 16px;
  }
`;


export const Left = styled.div``;

export const Right = styled.div``;
