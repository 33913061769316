import React, { useState } from 'react';
import Container from 'components/Container';
import Header from 'components/Header';

import AddDMForm, { Page } from './DMAddForm.style';

import { Button, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addDm } from 'redux/dms';
import CustomAreaSelect from './CustomAreaSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1.5, 0),
      width: '100%'
    }
  }
}));

const DMAddForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [zone, selectedZone] = useState('');

  const [state, setState] = useState({
    is_active: true,
    name: '',
    phone: '',
  });

  const handleChange = (name) => (event) => {
    event.persist();
    if (name === 'is_active') setState((prev) => ({ ...prev, is_active: event.target.checked }));
    else setState((prev) => ({ ...prev, [name]: event.target.value, zone:  zone}));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if(state?.name !== '' && state?.phone !== ''){
      await dispatch(addDm({...state, area_id: zone?.id ? zone?.id : 834}));
      history.push('/dms');
    }
  };

  return (
    <Page>
      <Header />
      <Container>
        <AddDMForm>
          <Typography align="center" variant="h5" component="h1">
            Add DM 🏍️
          </Typography>
          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleUpdate}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.is_active}
                  onChange={handleChange('is_active')}
                  name="is_active"
                  color="primary"
                />
              }
              label="Active"
            />
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              value={state.name}
              onChange={handleChange('name')}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="phone"
              label="Phone Number"
              type="tel"
              value={state.phone}
              onChange={handleChange('phone')}
            />
            <CustomAreaSelect
              value={zone}
              onChange={(value) => {
              selectedZone(value);
            }}
          />
            <Button variant="contained" color="primary" size="large" type="submit" disabled={state?.name === '' || state?.phone === ''}>
              Save
            </Button>
          </form>
        </AddDMForm>
      </Container>
    </Page>
  );
};

export default DMAddForm;
