import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  addToTempUnlistedProds,
  clearTempUnlistedProds,
  addToTempUnlistedProdNames,
  addToNewlyAddedUnlistedProdName
} from 'redux/voiceOrders';

import { SearchWrapper, FormControl } from './storeProduct.style';
import useProductsSearch from './useProducts';
import Products from 'repository/Products';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders, editOrder, updateCheckout, bulkUpdate } from 'redux/voiceOrders';
import { useDebouncedCallback } from 'use-debounce';
import {
  getOrderDetails,
  editProduct,
  addProduct,
  pushVpcItems,
  pushVpcItemsIds,
  pushNewVpcItemsIds
} from 'redux/voiceOrderDetails';
import { ENV } from 'ENV';

const StoreProduct = ({ handleAddProduct, order, refreshData }) => {
  const dispatch = useDispatch();
  const { inputText, setInputText, search } = useProductsSearch();
  const [product, setProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProductQty, setSelecetedProdQrt] = useState(1);
  const [productList, setProductList] = useState([]);
  const ordZone = useSelector((state) => state?.voiceOrderDetails?.zone);
  const voiceOrderId = useSelector((state) => state?.voiceOrderDetails?.data?.id);
  const voiceOrderTempList = useSelector((state) => state?.voiceOrderDetails?.vpcOrderList);
  const authToken = useSelector((state) => state.auth.token);
  const debouncedSearch = useDebouncedCallback(
    async (val) => {
      const response = await Products.get({ zone: ordZone, search: val });
      if (response.status === 200) {
        setProductList([...response?.data?.data]);
      }
    },
    // delay in ms
    500
  );
  const handleSearch = async (e) => {
    e.persist();
    // setProduct(null);
    setInputText(e.target.value);
    setSearchQuery(e.target.value);
    const val = e.target.value;
    // const response = await Products.get({ zone: ordZone, search: val });
    // if (response.status === 200) {
    //   setProductList([...response?.data?.data]);
    // }
    debouncedSearch(val);
  };

  const handleProductSelect = (item) => {
    // console.log(item);
    // console.log(item.images.thumb);
    const {
      id,
      name,
      selling_price,
      discounted_price,
      productPerStores,
      long_description,
      discount_percentage,
      images,
      categories
    } = item;
    const address = `${productPerStores[0]?.store?.name}-${productPerStores[0]?.store?.address}-${productPerStores[0]?.store?.primary_contact_phone}`;

    const obj = {
        name: name,
        price: discounted_price ? discounted_price : selling_price,
        selling_price: selling_price,
        mrp: selling_price,
        discounted_price: discounted_price,
        source_price: productPerStores[0].sourcing_price,
        pps_id: productPerStores[0]?.id,
        discount_percentage: discount_percentage,
        description: long_description,
        assigned_pickup_source: address,
        product_id: id,
        image: `${ENV.REACT_APP_API_URL}${item?.images?.thumb}`,
        images: images,
        category_id: categories[0].id,
        category_name: categories[0].name,
        category_slug: categories[0].slug
    };
    setProduct({ ...obj });

    // console.log(obj)
  };

  const handleOrderUpdate = async (id, body) => {
    await dispatch(updateCheckout(id, body));
    // tableRef?.current?.onQueryChange() || console.log('Table ref not found');
  };

  const handleQuantityChange = (e) => {
    console.log(product);
    if (product?.product) {
      setProduct({product: {
        ...product?.product,
        quantity: parseInt(e.target.value)
      }});
    }
    if (!product?.product) {
      setProduct({product: {
        ...product,
        quantity: parseInt(e.target.value)
      }});
    }
  };

  const addProduct = () => {
    if (product && product?.quantity) {
      handleAddProduct(product);
    }
  };
  // console.log(productList && productList.length === 0 && !search.loading);

  return (
    <SearchWrapper>
      {/* {product && productList.length !== 0 && (
        <>
          <p style={{ fontWeight: 'bold', fontSize: 18 }}>Selected Product: {product?.name}</p>
          <p style={{ fontWeight: 'bold', fontSize: 18 }}>Price: {product?.selling_price}</p>
          <FormControl>
            <label htmlFor="quantity">Quantity</label>
            <TextField
              fullWidth
              type="number"
              id="quantity"
              name="quantity"
              variant="outlined"
              onChange={handleQuantityChange}
            />
          </FormControl>
          <Button
            disabled={!product || !product?.quantity}
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(pushVpcItems(product));
              dispatch(pushVpcItemsIds(product?.product?.id));
              dispatch(pushNewVpcItemsIds(product?.product?.id));
              // dispatch(updateCheckout(voiceOrderId, {vpcOrderItems: voiceOrderTempList}));
            }}
            style={{ marginBottom: '10px' }}
          >
            Add to cart
          </Button>
        </>
      )} */}
      <FormControl>
        <label htmlFor="store_product">Search Products From Store</label>
        <div style={{ display: 'flex', flexDirection: 'row', width: '130%' }}>
          <TextField
            fullWidth
            id="store_product"
            name="store_product"
            variant="outlined"
            value={inputText}
            style={{ maxWidth: '40%' }}
            onChange={handleSearch}
          />
          <div style={{ display: searchQuery?.length > 0 ? 'block' : 'none', marginLeft: '5px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(
                  addToTempUnlistedProds({
                    product_name: searchQuery,
                    checkout_id: null,
                    isManaged: false,
                    created_at: Date.now(),
                    updated_at: Date.now()
                  })
                );
                dispatch(addToTempUnlistedProdNames(searchQuery));
                dispatch(addToNewlyAddedUnlistedProdName(searchQuery));
                dispatch(
                  updateCheckout(
                    voiceOrderId,
                    {
                      requested_products: [searchQuery]
                    },
                    authToken
                  )
                );
                // refreshData();
              }}
              style={{ marginBottom: '10px', minWidth: '100%' }}
            >
              Add - "{searchQuery}" to Unlisted Products
            </Button>
          </div>
        </div>
      </FormControl>
      {search?.loading && (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
        </div>
      )}
      {/* {productList.length === 0 && searchQuery !== '' && !search.loading && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(
              addToTempUnlistedProds({
                id: 6969,
                product_name: searchQuery,
                checkout_id: null,
                isManaged: false,
                created_at: Date.now(),
                updated_at: Date.now()
              })
            );
            dispatch(addToTempUnlistedProdNames(searchQuery));
            dispatch(addToNewlyAddedUnlistedProdName(searchQuery));
          }}
          style={{ marginBottom: '10px' }}
        >
          Add - "{searchQuery}" to Unlisted Products
        </Button>
      )} */}
      {productList && searchQuery?.length > 0 && (
        <List>
          {productList?.map((item) => (
            <div key={item.id} style={{ display: 'flex', flexDirection: 'row', width: '130%', minWidth: '120%' }}>
              <div style={{ minWidth: '60%' }}>
                <ListItem alignItems="flex-start" button onClick={() => handleProductSelect(item)}>
                  <ListItemAvatar>
                    <Avatar
                      alt="Remy Sharp"
                      src={`${ENV.REACT_APP_API_URL}${item?.images?.thumb}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    secondary={`Price: ${
                      item?.discounted_price ? item?.discounted_price : item?.selling_price
                    }`}
                  />
                </ListItem>
              </div>
              {product && item.id === product?.product_id || item.id === product?.product?.product_id ? (
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '5px',width: '300px' }}>
                  <FormControl>
                    <TextField
                      style={{width: '100px'}}
                      type="number"
                      id="quantity"
                      name="quantity"
                      label="Quantity"
                      variant="outlined"
                      onChange={handleQuantityChange}
                    />
                  </FormControl>
                  <Button
                    disabled={!product || !product?.product?.quantity}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '5px', height: '40px', width: '120px' }}
                    onClick={() => {
                      dispatch(pushVpcItems(product));
                      dispatch(pushVpcItemsIds(product?.product?.product_id));
                      dispatch(pushNewVpcItemsIds(product?.product?.product_id));
                      dispatch(
                        updateCheckout(
                          voiceOrderId,
                          {
                            vpc_order_items: [product?.product]
                          },
                          authToken
                        )
                      );
                      // dispatch(getOrderDetails(Number(voiceOrderId), authToken));
                      setTimeout(() => {
                        refreshData();
                        setSearchQuery('');
                      }, 700);
                      // refreshData();
                      // dispatch(updateCheckout(voiceOrderId, {vpcOrderItems: voiceOrderTempList}));
                    }}
                  >
                    Add Product
                  </Button>
                </div>
              ) : null}
              <Divider variant="inset" component="li" />
            </div>
          ))}
        </List>
      )}
    </SearchWrapper>
  );
};

export default StoreProduct;
