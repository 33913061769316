import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

class Stats {
  get(query, token) {
    return SuperFetch(`checkout/stats?${querystring.stringify(query)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }
}

export default new Stats();
