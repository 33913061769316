import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SuperFetch from '../api/SuperFetch';

const initialState = {
  coupon: {},
  status: null,
};

export const getCoupons = createAsyncThunk('coupon', async query => {
  return await SuperFetch(`/discounts/coupons/${query}`).then(res => res);
});

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    refreshCoupon: state => {
      state.coupon = {};
      state.status = null;
    },
  },
  extraReducers: {
    [getCoupons.fulfilled]: (state, action) => {
      console.log("I'm here")
      state.coupon = action.payload?.data;
      state.status = action.payload?.status;
    },
    [getCoupons.rejected]: state => {
      state.coupon = {};
    },
  },
});
export const { refreshCoupon } = couponSlice.actions;

export default couponSlice.reducer;
