export const orders = [
  {
    id: 1,
    created_at: '2020-01-05T12:20:37Z',
    order_id: '356-24-8300',
    customer_name: 'Carissa Ruane',
    customer_phone: '1918524779',
    city_area: 'Xhafzotaj',
    full_address: '43419 Randy Terrace',
    additional_req: 'in felis eu sapien cursus vestibulum proin eu',
    cart_items: 3,
    cart_price: '4467.51',
    del_fee: '60.00',
    total_collectable: '1153.08',
    order_status: 'at turpis a',
    hold_reason: 'vivamus in felis eu',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Idanha-a-Nova',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 2,
    created_at: '2019-04-13T16:51:21Z',
    order_id: '673-16-5050',
    customer_name: 'Salvador Yerrall',
    customer_phone: '6555969955',
    city_area: 'Boac',
    full_address: '68650 Waubesa Junction',
    additional_req: 'a suscipit nulla elit ac nulla sed vel enim',
    cart_items: 10,
    cart_price: '95.81',
    del_fee: '60.00',
    total_collectable: '2725.29',
    order_status: 'dictumst etiam faucibus',
    hold_reason: 'lectus pellentesque eget',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Radojevo',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 3,
    created_at: '2019-09-19T13:57:14Z',
    order_id: '507-07-9148',
    customer_name: 'Herculie Boyda',
    customer_phone: '8838451769',
    city_area: 'Bayt Liqyā',
    full_address: '96706 Morrow Center',
    additional_req: 'aliquam lacus morbi quis tortor',
    cart_items: 3,
    cart_price: '2004.26',
    del_fee: '60.00',
    total_collectable: '5317.94',
    order_status: 'convallis eget',
    hold_reason: 'nascetur ridiculus',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Waru',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 4,
    created_at: '2020-01-21T10:53:51Z',
    order_id: '708-14-9055',
    customer_name: 'Isabeau Washbrook',
    customer_phone: '4301065188',
    city_area: 'Zall-Dardhë',
    full_address: '3899 Bonner Way',
    additional_req: 'in imperdiet et commodo vulputate',
    cart_items: 9,
    cart_price: '1538.74',
    del_fee: '60.00',
    total_collectable: '4831.12',
    order_status: 'sit amet sapien dignissim',
    hold_reason: 'at nulla suspendisse',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Guantang',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 5,
    created_at: '2019-11-01T17:26:08Z',
    order_id: '683-43-3669',
    customer_name: 'Putnem Parcell',
    customer_phone: '8696128626',
    city_area: 'Jiaodai',
    full_address: '8 Hintze Park',
    additional_req: 'id sapien in sapien iaculis congue vivamus metus arcu adipiscing',
    cart_items: 14,
    cart_price: '4244.80',
    del_fee: '60.00',
    total_collectable: '5687.84',
    order_status: 'ante ipsum primis in',
    hold_reason: 'lectus suspendisse potenti in',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Krajan Puru',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 6,
    created_at: '2020-01-18T14:15:15Z',
    order_id: '789-39-9996',
    customer_name: 'Heywood Lunbech',
    customer_phone: '7868256281',
    city_area: 'Baraguá',
    full_address: '4484 Knutson Parkway',
    additional_req: 'consequat in consequat ut nulla sed',
    cart_items: 15,
    cart_price: '1965.80',
    del_fee: '60.00',
    total_collectable: '3685.10',
    order_status: 'nisl duis bibendum felis',
    hold_reason: 'ipsum aliquam non',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Atlantis',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 7,
    created_at: '2019-05-27T18:42:39Z',
    order_id: '814-74-4308',
    customer_name: 'Basilio Hardwick',
    customer_phone: '1648768087',
    city_area: 'Gazanjyk',
    full_address: '5595 Jana Place',
    additional_req: 'proin leo odio porttitor id consequat',
    cart_items: 11,
    cart_price: '4406.79',
    del_fee: '60.00',
    total_collectable: '2361.80',
    order_status: 'interdum venenatis',
    hold_reason: 'euismod scelerisque quam turpis',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Rochester',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 8,
    created_at: '2019-07-01T01:20:09Z',
    order_id: '231-53-8649',
    customer_name: 'Thor Rentcome',
    customer_phone: '9696416231',
    city_area: 'Kesheng',
    full_address: '28701 Pleasure Alley',
    additional_req: 'semper est quam pharetra magna ac consequat',
    cart_items: 4,
    cart_price: '2541.34',
    del_fee: '60.00',
    total_collectable: '730.57',
    order_status: 'faucibus orci luctus et',
    hold_reason: 'accumsan tortor quis turpis',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Karlshamn',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 9,
    created_at: '2019-12-21T22:37:36Z',
    order_id: '206-62-3155',
    customer_name: 'Brittani Bumpas',
    customer_phone: '6519568192',
    city_area: 'Minneapolis',
    full_address: '7096 Badeau Junction',
    additional_req: 'vitae ipsum aliquam non mauris morbi',
    cart_items: 2,
    cart_price: '3426.94',
    del_fee: '60.00',
    total_collectable: '3483.44',
    order_status: 'rutrum rutrum',
    hold_reason: 'felis eu sapien',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Noginsk-9',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 10,
    created_at: '2020-02-27T07:06:59Z',
    order_id: '593-42-4411',
    customer_name: 'Donnie Jowett',
    customer_phone: '4328062215',
    city_area: 'Pincher Creek',
    full_address: '1513 Morrow Terrace',
    additional_req: 'consequat morbi a ipsum integer a nibh',
    cart_items: 14,
    cart_price: '4357.31',
    del_fee: '60.00',
    total_collectable: '2966.18',
    order_status: 'id massa',
    hold_reason: 'dis parturient montes nascetur',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Chicago',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 11,
    created_at: '2019-12-24T02:22:47Z',
    order_id: '498-22-8884',
    customer_name: 'Nichol Dobrowolski',
    customer_phone: '3044092206',
    city_area: 'Xekar',
    full_address: '5 Laurel Drive',
    additional_req:
      'consectetuer adipiscing elit proin risus praesent lectus vestibulum quam sapien',
    cart_items: 16,
    cart_price: '1351.51',
    del_fee: '60.00',
    total_collectable: '3980.26',
    order_status: 'non mauris',
    hold_reason: 'eu interdum',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Ciomas',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 12,
    created_at: '2020-02-23T21:16:00Z',
    order_id: '398-18-4421',
    customer_name: "Adrienne D'Enrico",
    customer_phone: '8641986960',
    city_area: 'Mueang Nonthaburi',
    full_address: '54 Rutledge Avenue',
    additional_req: 'nullam sit amet turpis elementum ligula vehicula consequat morbi a',
    cart_items: 16,
    cart_price: '4499.97',
    del_fee: '60.00',
    total_collectable: '796.51',
    order_status: 'ante vestibulum ante',
    hold_reason: 'luctus cum sociis',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Ayia Napa',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 13,
    created_at: '2019-12-30T11:21:43Z',
    order_id: '152-01-2060',
    customer_name: 'Gaynor Philippon',
    customer_phone: '2347915383',
    city_area: 'Mineralnye Vody',
    full_address: '86 Red Cloud Center',
    additional_req: 'accumsan tellus nisi eu orci mauris lacinia sapien',
    cart_items: 17,
    cart_price: '3677.02',
    del_fee: '60.00',
    total_collectable: '310.94',
    order_status: 'volutpat eleifend donec',
    hold_reason: 'tempor turpis',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Oktyabr’skiy',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 14,
    created_at: '2019-10-14T23:33:23Z',
    order_id: '231-91-5558',
    customer_name: 'Dosi Lambirth',
    customer_phone: '8549672611',
    city_area: 'Marki',
    full_address: '2 Monica Center',
    additional_req: 'luctus et ultrices posuere cubilia curae',
    cart_items: 9,
    cart_price: '4995.53',
    del_fee: '60.00',
    total_collectable: '3543.18',
    order_status: 'justo etiam',
    hold_reason: 'vitae quam suspendisse',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Lanús',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 15,
    created_at: '2019-10-12T02:34:53Z',
    order_id: '664-18-1180',
    customer_name: 'Moe Cheves',
    customer_phone: '8554911379',
    city_area: 'Quvasoy',
    full_address: '82 La Follette Alley',
    additional_req: 'justo morbi ut odio cras mi pede',
    cart_items: 18,
    cart_price: '3307.58',
    del_fee: '60.00',
    total_collectable: '3547.39',
    order_status: 'tortor duis',
    hold_reason: 'accumsan odio curabitur convallis',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Kambaxoi',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 16,
    created_at: '2019-05-25T05:03:18Z',
    order_id: '251-41-8277',
    customer_name: 'Mechelle Waterdrinker',
    customer_phone: '1185565734',
    city_area: 'Dolní Cerekev',
    full_address: '5880 Division Center',
    additional_req: 'habitasse platea dictumst aliquam augue quam sollicitudin',
    cart_items: 13,
    cart_price: '1758.01',
    del_fee: '60.00',
    total_collectable: '3845.97',
    order_status: 'nec nisi',
    hold_reason: 'ipsum primis',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Libu',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 17,
    created_at: '2019-06-05T02:20:29Z',
    order_id: '313-54-9780',
    customer_name: 'Nester MacMaykin',
    customer_phone: '2156773290',
    city_area: 'Kamigyō-ku',
    full_address: '5477 Scofield Center',
    additional_req: 'in consequat ut nulla sed accumsan felis',
    cart_items: 4,
    cart_price: '4797.47',
    del_fee: '60.00',
    total_collectable: '1995.71',
    order_status: 'molestie hendrerit at',
    hold_reason: 'vehicula condimentum curabitur in',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Maracha',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 18,
    created_at: '2020-03-26T02:53:03Z',
    order_id: '171-01-3743',
    customer_name: 'Harley Edmans',
    customer_phone: '8193172306',
    city_area: 'Novyy Karachay',
    full_address: '46 Nobel Place',
    additional_req: 'nibh quisque id justo sit',
    cart_items: 11,
    cart_price: '204.08',
    del_fee: '60.00',
    total_collectable: '3526.49',
    order_status: 'mauris non ligula pellentesque',
    hold_reason: 'congue eget semper rutrum',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Byczyna',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 19,
    created_at: '2019-07-21T01:59:16Z',
    order_id: '210-99-8432',
    customer_name: 'Maurice Clotworthy',
    customer_phone: '5872652193',
    city_area: 'Tonggoh',
    full_address: '6 Petterle Lane',
    additional_req: 'mi pede malesuada in imperdiet',
    cart_items: 7,
    cart_price: '3547.27',
    del_fee: '60.00',
    total_collectable: '3118.32',
    order_status: 'in faucibus orci luctus',
    hold_reason: 'odio cras',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Gizałki',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 20,
    created_at: '2019-08-04T04:10:17Z',
    order_id: '114-86-7669',
    customer_name: 'Nicoli Spragge',
    customer_phone: '1596081089',
    city_area: 'Mizhou',
    full_address: '0 Eagle Crest Street',
    additional_req: 'vel lectus in quam fringilla',
    cart_items: 2,
    cart_price: '802.49',
    del_fee: '60.00',
    total_collectable: '3570.00',
    order_status: 'ipsum integer',
    hold_reason: 'consequat varius',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Suchań',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 21,
    created_at: '2019-06-27T16:41:26Z',
    order_id: '254-38-8156',
    customer_name: 'Jillene Gudde',
    customer_phone: '8521815000',
    city_area: 'Lousado',
    full_address: '227 Tony Place',
    additional_req: 'lacus purus aliquet at feugiat non pretium quis lectus',
    cart_items: 17,
    cart_price: '2376.38',
    del_fee: '60.00',
    total_collectable: '3464.79',
    order_status: 'vulputate luctus cum',
    hold_reason: 'mauris sit amet',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Arinos',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 22,
    created_at: '2019-08-25T12:51:15Z',
    order_id: '356-77-2359',
    customer_name: 'Kaylyn Pettinger',
    customer_phone: '5749205788',
    city_area: 'Kamloops',
    full_address: '42 Old Gate Road',
    additional_req: 'diam in magna bibendum imperdiet nullam orci pede',
    cart_items: 12,
    cart_price: '890.00',
    del_fee: '60.00',
    total_collectable: '3185.07',
    order_status: 'posuere felis',
    hold_reason: 'turpis donec posuere',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Jayyūs',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 23,
    created_at: '2019-10-23T14:35:11Z',
    order_id: '593-88-6283',
    customer_name: 'Saxon Gass',
    customer_phone: '7449114359',
    city_area: 'Baoji',
    full_address: '54510 Reinke Circle',
    additional_req: 'in tempus sit amet sem fusce consequat nulla nisl',
    cart_items: 14,
    cart_price: '1318.64',
    del_fee: '60.00',
    total_collectable: '2502.63',
    order_status: 'eget congue eget',
    hold_reason: 'facilisi cras',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: '‘Izbat al Burj',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 24,
    created_at: '2019-12-02T01:27:12Z',
    order_id: '606-59-4871',
    customer_name: 'Alvinia Sellner',
    customer_phone: '2306886844',
    city_area: 'Thị Trấn Việt Lâm',
    full_address: '65377 Comanche Parkway',
    additional_req: 'rutrum nulla tellus in sagittis dui vel nisl',
    cart_items: 1,
    cart_price: '1185.98',
    del_fee: '60.00',
    total_collectable: '571.21',
    order_status: 'ut erat curabitur gravida',
    hold_reason: 'elementum ligula vehicula',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Dimitrovgrad',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 25,
    created_at: '2019-12-30T07:09:53Z',
    order_id: '584-88-2152',
    customer_name: 'Silvanus McLese',
    customer_phone: '3206360356',
    city_area: 'Pijao',
    full_address: '6 Maryland Street',
    additional_req: 'nullam sit amet turpis elementum ligula vehicula consequat',
    cart_items: 1,
    cart_price: '801.63',
    del_fee: '60.00',
    total_collectable: '2930.86',
    order_status: 'nibh ligula nec sem',
    hold_reason: 'diam neque vestibulum eget',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Nerokoúros',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 26,
    created_at: '2019-11-01T04:50:04Z',
    order_id: '887-01-2790',
    customer_name: 'Nananne Seabourne',
    customer_phone: '4765652490',
    city_area: 'Nusajaya',
    full_address: '5 Graceland Terrace',
    additional_req: 'odio justo sollicitudin ut suscipit a feugiat et eros',
    cart_items: 16,
    cart_price: '4543.37',
    del_fee: '60.00',
    total_collectable: '3541.39',
    order_status: 'lectus in est',
    hold_reason: 'pellentesque volutpat dui',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Grazhdanskoye',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 27,
    created_at: '2019-06-05T17:50:01Z',
    order_id: '641-47-4783',
    customer_name: 'Kelby Crichmer',
    customer_phone: '9748223940',
    city_area: 'Kertanegla',
    full_address: '54 Service Court',
    additional_req: 'elementum eu interdum eu tincidunt in leo maecenas',
    cart_items: 13,
    cart_price: '3788.20',
    del_fee: '60.00',
    total_collectable: '2032.96',
    order_status: 'luctus rutrum nulla tellus',
    hold_reason: 'at ipsum ac',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Bulbul',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 28,
    created_at: '2020-04-02T06:03:26Z',
    order_id: '301-82-8695',
    customer_name: 'Bone Aspray',
    customer_phone: '9499353203',
    city_area: 'San Juan',
    full_address: '157 Ridgeview Crossing',
    additional_req: 'lobortis ligula sit amet eleifend',
    cart_items: 9,
    cart_price: '2560.35',
    del_fee: '60.00',
    total_collectable: '4798.28',
    order_status: 'nulla eget',
    hold_reason: 'mi integer ac',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Xijiao',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 29,
    created_at: '2019-12-27T08:55:49Z',
    order_id: '217-10-6848',
    customer_name: 'Loralie Badder',
    customer_phone: '7754989054',
    city_area: 'Custóias',
    full_address: '3 Pankratz Pass',
    additional_req: 'ultrices posuere cubilia curae nulla dapibus dolor vel',
    cart_items: 18,
    cart_price: '4199.24',
    del_fee: '60.00',
    total_collectable: '761.94',
    order_status: 'lacus at turpis donec',
    hold_reason: 'ultrices libero non',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Sāh',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 30,
    created_at: '2019-07-11T02:22:21Z',
    order_id: '846-74-7070',
    customer_name: 'Dunstan Heiton',
    customer_phone: '8793181034',
    city_area: 'Lipin Bor',
    full_address: '976 Union Junction',
    additional_req: 'ut tellus nulla ut erat id mauris',
    cart_items: 7,
    cart_price: '3202.56',
    del_fee: '60.00',
    total_collectable: '4862.01',
    order_status: 'arcu libero rutrum',
    hold_reason: 'pede justo eu massa',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Tama',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 31,
    created_at: '2019-11-02T14:57:09Z',
    order_id: '847-07-5742',
    customer_name: 'Sissy Wombwell',
    customer_phone: '3098690094',
    city_area: 'Carepa',
    full_address: '19832 Del Mar Junction',
    additional_req: 'arcu adipiscing molestie hendrerit at vulputate vitae',
    cart_items: 13,
    cart_price: '1291.06',
    del_fee: '60.00',
    total_collectable: '4898.63',
    order_status: 'justo lacinia eget',
    hold_reason: 'lorem ipsum dolor',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Padaimut',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 32,
    created_at: '2020-01-10T22:49:49Z',
    order_id: '610-74-8427',
    customer_name: 'Grange Haffenden',
    customer_phone: '8577772862',
    city_area: 'Wangjing',
    full_address: '15927 Armistice Park',
    additional_req: 'in leo maecenas pulvinar lobortis est phasellus sit amet erat',
    cart_items: 16,
    cart_price: '2507.50',
    del_fee: '60.00',
    total_collectable: '4384.60',
    order_status: 'ante ipsum',
    hold_reason: 'sollicitudin mi',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Shahre Jadide Andisheh',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 33,
    created_at: '2019-05-14T16:51:26Z',
    order_id: '548-28-3094',
    customer_name: 'Cobb Dougher',
    customer_phone: '2828939025',
    city_area: 'Khlong Sam Wa',
    full_address: '93 Jackson Place',
    additional_req: 'arcu libero rutrum ac lobortis vel',
    cart_items: 16,
    cart_price: '4595.97',
    del_fee: '60.00',
    total_collectable: '5362.35',
    order_status: 'et tempus semper est',
    hold_reason: 'rutrum at',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Tabon',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 34,
    created_at: '2019-11-07T05:17:37Z',
    order_id: '392-88-3885',
    customer_name: "Hedi O'Lagene",
    customer_phone: '4296391346',
    city_area: 'Wonocolo',
    full_address: '495 Hermina Road',
    additional_req: 'a ipsum integer a nibh in quis justo maecenas rhoncus',
    cart_items: 19,
    cart_price: '3003.92',
    del_fee: '60.00',
    total_collectable: '4855.80',
    order_status: 'justo sollicitudin ut',
    hold_reason: 'sit amet',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Nkpor',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 35,
    created_at: '2019-05-22T13:03:53Z',
    order_id: '627-83-8059',
    customer_name: 'Xavier Karlik',
    customer_phone: '6353063803',
    city_area: 'Hallsberg',
    full_address: '93320 Lukken Plaza',
    additional_req: 'nulla integer pede justo lacinia eget tincidunt eget',
    cart_items: 11,
    cart_price: '4693.39',
    del_fee: '60.00',
    total_collectable: '119.38',
    order_status: 'nullam varius',
    hold_reason: 'nisl ut volutpat sapien',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Lembung Tengah',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 36,
    created_at: '2019-07-30T13:08:19Z',
    order_id: '227-19-6287',
    customer_name: 'Moritz Hemshall',
    customer_phone: '9431565068',
    city_area: 'Borzęcin',
    full_address: '4 Schurz Alley',
    additional_req: 'condimentum id luctus nec molestie sed',
    cart_items: 20,
    cart_price: '3149.49',
    del_fee: '60.00',
    total_collectable: '5979.99',
    order_status: 'vestibulum eget vulputate',
    hold_reason: 'in eleifend quam',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Sholokhove',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 37,
    created_at: '2019-05-20T20:59:53Z',
    order_id: '602-84-8519',
    customer_name: 'Afton Blunkett',
    customer_phone: '8144344989',
    city_area: 'Rambouillet',
    full_address: '31 Bartelt Way',
    additional_req: 'sed accumsan felis ut at',
    cart_items: 16,
    cart_price: '627.95',
    del_fee: '60.00',
    total_collectable: '205.45',
    order_status: 'nullam varius nulla facilisi',
    hold_reason: 'dui nec nisi',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Huangzhai',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 38,
    created_at: '2019-11-23T12:10:29Z',
    order_id: '565-30-7157',
    customer_name: 'Camala Sempill',
    customer_phone: '3225183569',
    city_area: 'Wangcungang',
    full_address: '47791 Eliot Road',
    additional_req: 'pede ac diam cras pellentesque volutpat',
    cart_items: 4,
    cart_price: '4981.62',
    del_fee: '60.00',
    total_collectable: '5538.70',
    order_status: 'lectus pellentesque eget nunc',
    hold_reason: 'vivamus tortor',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Fengren',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 39,
    created_at: '2020-02-24T13:05:31Z',
    order_id: '868-69-4581',
    customer_name: 'Galina Sivills',
    customer_phone: '4786926415',
    city_area: 'Agía Varvára',
    full_address: '4 Kensington Avenue',
    additional_req: 'pellentesque ultrices phasellus id sapien in sapien iaculis congue vivamus',
    cart_items: 11,
    cart_price: '156.44',
    del_fee: '60.00',
    total_collectable: '1672.31',
    order_status: 'phasellus id',
    hold_reason: 'nec sem',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Udënisht',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 40,
    created_at: '2020-03-19T09:56:17Z',
    order_id: '618-49-4238',
    customer_name: 'Alard Haeslier',
    customer_phone: '4596389352',
    city_area: 'Cabuyaro',
    full_address: '92095 Thierer Point',
    additional_req: 'nec condimentum neque sapien placerat ante nulla justo aliquam quis',
    cart_items: 10,
    cart_price: '401.79',
    del_fee: '60.00',
    total_collectable: '918.37',
    order_status: 'purus eu magna',
    hold_reason: 'nibh in hac habitasse',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Vinica',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 41,
    created_at: '2019-05-16T10:39:35Z',
    order_id: '189-12-5797',
    customer_name: 'Terencio Seeger',
    customer_phone: '1096985758',
    city_area: 'Łopuszno',
    full_address: '8 Fallview Pass',
    additional_req: 'nam congue risus semper porta volutpat quam pede lobortis',
    cart_items: 6,
    cart_price: '1429.43',
    del_fee: '60.00',
    total_collectable: '5862.92',
    order_status: 'cras mi pede malesuada',
    hold_reason: 'dolor sit amet',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Pindi Gheb',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 42,
    created_at: '2019-09-07T14:25:21Z',
    order_id: '823-48-8975',
    customer_name: 'Martynne Rubinlicht',
    customer_phone: '8633151115',
    city_area: 'Paizhou',
    full_address: '61105 Redwing Park',
    additional_req: 'justo morbi ut odio cras mi pede',
    cart_items: 6,
    cart_price: '614.62',
    del_fee: '60.00',
    total_collectable: '1841.31',
    order_status: 'varius integer',
    hold_reason: 'ac nulla sed',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Disūq',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 43,
    created_at: '2019-05-17T14:54:56Z',
    order_id: '602-89-1245',
    customer_name: 'Sonny Groome',
    customer_phone: '6037979190',
    city_area: 'Matabao',
    full_address: '3580 Hovde Street',
    additional_req: 'vestibulum velit id pretium iaculis diam erat',
    cart_items: 8,
    cart_price: '2615.80',
    del_fee: '60.00',
    total_collectable: '2965.90',
    order_status: 'ultrices posuere cubilia',
    hold_reason: 'lacus purus aliquet',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Espinheira',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 44,
    created_at: '2019-05-02T23:34:35Z',
    order_id: '838-68-5056',
    customer_name: 'Carri Ranken',
    customer_phone: '9822296391',
    city_area: 'Sulang Tengah',
    full_address: '3618 Farwell Trail',
    additional_req: 'ut mauris eget massa tempor convallis nulla neque',
    cart_items: 9,
    cart_price: '1712.08',
    del_fee: '60.00',
    total_collectable: '2774.08',
    order_status: 'augue vestibulum rutrum rutrum',
    hold_reason: 'in felis donec semper',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Moville',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 45,
    created_at: '2019-07-05T16:51:11Z',
    order_id: '103-26-1199',
    customer_name: 'Hiram Droghan',
    customer_phone: '8436765793',
    city_area: 'Yunga',
    full_address: '8111 Blackbird Terrace',
    additional_req: 'iaculis congue vivamus metus arcu adipiscing',
    cart_items: 5,
    cart_price: '3611.84',
    del_fee: '60.00',
    total_collectable: '4540.52',
    order_status: 'at ipsum ac',
    hold_reason: 'lacus curabitur at ipsum',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Santiago dos Velhos',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 46,
    created_at: '2019-05-17T18:31:44Z',
    order_id: '735-26-6466',
    customer_name: 'Bayard Havenhand',
    customer_phone: '3509727069',
    city_area: 'Dongjiahe',
    full_address: '14295 Briar Crest Park',
    additional_req: 'eleifend donec ut dolor morbi vel lectus in quam',
    cart_items: 5,
    cart_price: '552.16',
    del_fee: '60.00',
    total_collectable: '1476.59',
    order_status: 'etiam justo',
    hold_reason: 'leo pellentesque',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Oenpeotnai',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 47,
    created_at: '2020-01-11T12:54:29Z',
    order_id: '771-86-8151',
    customer_name: 'Waneta Cumbridge',
    customer_phone: '8012824782',
    city_area: 'Carbajales',
    full_address: '4264 Dakota Crossing',
    additional_req: 'interdum mauris non ligula pellentesque',
    cart_items: 11,
    cart_price: '339.25',
    del_fee: '60.00',
    total_collectable: '3015.62',
    order_status: 'amet turpis',
    hold_reason: 'amet eros suspendisse',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Lanci Satu',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 48,
    created_at: '2019-07-30T07:56:58Z',
    order_id: '709-61-1142',
    customer_name: 'Dehlia Jeenes',
    customer_phone: '5641309610',
    city_area: 'Lushikeng',
    full_address: '29 Butterfield Plaza',
    additional_req: 'lorem quisque ut erat curabitur',
    cart_items: 7,
    cart_price: '4652.89',
    del_fee: '60.00',
    total_collectable: '222.08',
    order_status: 'metus sapien ut nunc',
    hold_reason: 'id luctus',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Mashi',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 49,
    created_at: '2019-09-28T22:03:29Z',
    order_id: '294-66-1843',
    customer_name: 'Christina Timmermann',
    customer_phone: '7707261947',
    city_area: 'Kastrat',
    full_address: '5748 Gina Street',
    additional_req: 'tempor turpis nec euismod scelerisque quam turpis adipiscing',
    cart_items: 9,
    cart_price: '4333.61',
    del_fee: '60.00',
    total_collectable: '5868.06',
    order_status: 'sit amet',
    hold_reason: 'odio justo sollicitudin',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Baihe',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 50,
    created_at: '2019-06-28T20:04:46Z',
    order_id: '654-93-4134',
    customer_name: 'Irving Tarver',
    customer_phone: '9655377672',
    city_area: 'Pedro Juan Caballero',
    full_address: '3 Merry Court',
    additional_req: 'sit amet consectetuer adipiscing elit proin interdum',
    cart_items: 18,
    cart_price: '37.99',
    del_fee: '60.00',
    total_collectable: '1425.58',
    order_status: 'massa quis augue',
    hold_reason: 'nulla tempus',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Shimorskoye',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 51,
    created_at: '2019-08-22T09:13:56Z',
    order_id: '212-46-4671',
    customer_name: 'Phineas Drewet',
    customer_phone: '1413622731',
    city_area: 'Chambly',
    full_address: '44196 Kropf Place',
    additional_req: 'risus auctor sed tristique in',
    cart_items: 14,
    cart_price: '1965.49',
    del_fee: '60.00',
    total_collectable: '4155.76',
    order_status: 'pellentesque volutpat',
    hold_reason: 'vestibulum eget',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'New Iloilo',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 52,
    created_at: '2020-02-16T07:54:54Z',
    order_id: '718-44-6332',
    customer_name: 'Cordi Fentem',
    customer_phone: '5932963243',
    city_area: 'Tayturka',
    full_address: '00092 Cardinal Junction',
    additional_req: 'bibendum felis sed interdum venenatis turpis enim',
    cart_items: 2,
    cart_price: '12.42',
    del_fee: '60.00',
    total_collectable: '3402.81',
    order_status: 'ipsum primis',
    hold_reason: 'in felis',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Qo’ng’irot Shahri',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 53,
    created_at: '2019-06-30T10:19:33Z',
    order_id: '877-72-1691',
    customer_name: 'Nisse Fulger',
    customer_phone: '2688319528',
    city_area: 'Orléans',
    full_address: '2 Golf Trail',
    additional_req: 'donec semper sapien a libero nam dui proin',
    cart_items: 8,
    cart_price: '1281.70',
    del_fee: '60.00',
    total_collectable: '3356.73',
    order_status: 'maecenas tristique',
    hold_reason: 'ut erat id mauris',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Bayrachky',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 54,
    created_at: '2019-07-03T06:03:20Z',
    order_id: '370-29-7785',
    customer_name: 'Ermanno Qualtrough',
    customer_phone: '3437228471',
    city_area: 'Luhyny',
    full_address: '39088 Mallory Avenue',
    additional_req: 'fermentum donec ut mauris eget massa tempor convallis',
    cart_items: 7,
    cart_price: '3767.05',
    del_fee: '60.00',
    total_collectable: '1426.46',
    order_status: 'pulvinar lobortis est phasellus',
    hold_reason: 'mi integer ac neque',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'San Francisco',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 55,
    created_at: '2019-09-15T11:51:08Z',
    order_id: '882-16-1269',
    customer_name: "Drusie O'Dooghaine",
    customer_phone: '1662044612',
    city_area: 'Allealle',
    full_address: '542 Dixon Parkway',
    additional_req: 'mi pede malesuada in imperdiet et commodo',
    cart_items: 2,
    cart_price: '4784.85',
    del_fee: '60.00',
    total_collectable: '2379.47',
    order_status: 'scelerisque quam',
    hold_reason: 'varius integer ac',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Yantai',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 56,
    created_at: '2019-12-15T00:48:21Z',
    order_id: '747-04-5316',
    customer_name: 'Gregoor Januszewski',
    customer_phone: '3892205722',
    city_area: 'São José do Rio Pardo',
    full_address: '24957 Miller Crossing',
    additional_req: 'dolor vel est donec odio justo sollicitudin ut',
    cart_items: 12,
    cart_price: '3286.50',
    del_fee: '60.00',
    total_collectable: '407.50',
    order_status: 'faucibus orci luctus',
    hold_reason: 'placerat ante nulla justo',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Zlynka',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 57,
    created_at: '2019-08-05T15:12:01Z',
    order_id: '257-31-1489',
    customer_name: 'Hadley Timothy',
    customer_phone: '5304373493',
    city_area: 'Tegalgunung',
    full_address: '94309 Schlimgen Parkway',
    additional_req: 'eget tempus vel pede morbi porttitor',
    cart_items: 2,
    cart_price: '3334.22',
    del_fee: '60.00',
    total_collectable: '4785.02',
    order_status: 'venenatis non sodales',
    hold_reason: 'nibh fusce lacus',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Aliuroba',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 58,
    created_at: '2019-05-25T21:26:51Z',
    order_id: '669-80-3842',
    customer_name: 'Calley Ipsley',
    customer_phone: '7722783078',
    city_area: 'Kolkhozobod',
    full_address: '13076 Gateway Alley',
    additional_req: 'felis donec semper sapien a libero nam dui proin leo',
    cart_items: 2,
    cart_price: '2225.70',
    del_fee: '60.00',
    total_collectable: '274.82',
    order_status: 'nulla ultrices',
    hold_reason: 'luctus rutrum nulla',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Saransk',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 59,
    created_at: '2019-07-22T08:53:06Z',
    order_id: '684-63-3998',
    customer_name: 'Reina Dublin',
    customer_phone: '2053623336',
    city_area: 'Mauloo',
    full_address: '75161 Maple Wood Junction',
    additional_req: 'odio curabitur convallis duis consequat dui nec',
    cart_items: 18,
    cart_price: '2651.25',
    del_fee: '60.00',
    total_collectable: '4844.28',
    order_status: 'quam sapien',
    hold_reason: 'amet consectetuer adipiscing elit',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Iwamizawa',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 60,
    created_at: '2020-02-24T05:55:55Z',
    order_id: '875-35-1894',
    customer_name: 'Gypsy Gibbins',
    customer_phone: '5804527129',
    city_area: 'Cikadu',
    full_address: '449 Canary Road',
    additional_req: 'montes nascetur ridiculus mus vivamus vestibulum sagittis sapien cum',
    cart_items: 5,
    cart_price: '4485.02',
    del_fee: '60.00',
    total_collectable: '1537.24',
    order_status: 'et tempus semper est',
    hold_reason: 'nunc purus',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Julita',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 61,
    created_at: '2019-08-23T17:12:03Z',
    order_id: '125-60-5622',
    customer_name: 'Baudoin Humpage',
    customer_phone: '1242458308',
    city_area: 'Svislach',
    full_address: '749 Hayes Center',
    additional_req: 'non pretium quis lectus suspendisse potenti in eleifend quam a',
    cart_items: 20,
    cart_price: '4958.55',
    del_fee: '60.00',
    total_collectable: '2220.49',
    order_status: 'sed accumsan felis',
    hold_reason: 'quis turpis eget elit',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Kamyanyuki',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 62,
    created_at: '2019-10-25T19:46:01Z',
    order_id: '352-13-9295',
    customer_name: 'Turner Arkill',
    customer_phone: '4509412953',
    city_area: 'Pemba',
    full_address: '6727 Dakota Circle',
    additional_req: 'vivamus in felis eu sapien',
    cart_items: 8,
    cart_price: '4379.22',
    del_fee: '60.00',
    total_collectable: '4183.54',
    order_status: 'eu mi nulla',
    hold_reason: 'nulla integer pede',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Chongxian',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 63,
    created_at: '2019-05-11T09:59:33Z',
    order_id: '439-45-8994',
    customer_name: 'Allayne Ianniello',
    customer_phone: '6263451750',
    city_area: 'Strazhitsa',
    full_address: '1457 Melby Junction',
    additional_req: 'vel accumsan tellus nisi eu',
    cart_items: 5,
    cart_price: '1721.00',
    del_fee: '60.00',
    total_collectable: '5009.96',
    order_status: 'curabitur at',
    hold_reason: 'aliquet maecenas',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Miyakonojō',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 64,
    created_at: '2019-10-14T00:22:48Z',
    order_id: '110-38-2556',
    customer_name: 'Allyson New',
    customer_phone: '2718267879',
    city_area: 'Coro',
    full_address: '3586 Moland Crossing',
    additional_req: 'pede venenatis non sodales sed tincidunt eu',
    cart_items: 4,
    cart_price: '1383.17',
    del_fee: '60.00',
    total_collectable: '5643.29',
    order_status: 'at nulla',
    hold_reason: 'sed accumsan felis ut',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: '‘Izrā',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 65,
    created_at: '2019-12-02T06:31:53Z',
    order_id: '875-16-9166',
    customer_name: 'Delcina Pavlasek',
    customer_phone: '6246342592',
    city_area: 'Asia',
    full_address: '2695 Marquette Avenue',
    additional_req: 'ac tellus semper interdum mauris ullamcorper purus sit amet nulla',
    cart_items: 4,
    cart_price: '122.49',
    del_fee: '60.00',
    total_collectable: '2632.43',
    order_status: 'diam id ornare imperdiet',
    hold_reason: 'pede venenatis non',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Libog',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 66,
    created_at: '2020-03-29T06:13:03Z',
    order_id: '716-38-3147',
    customer_name: 'Lona Canet',
    customer_phone: '8415505580',
    city_area: 'Tuohua',
    full_address: '73945 Thackeray Pass',
    additional_req: 'consectetuer adipiscing elit proin risus',
    cart_items: 2,
    cart_price: '2995.75',
    del_fee: '60.00',
    total_collectable: '2472.40',
    order_status: 'et eros',
    hold_reason: 'a feugiat et',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Omaha',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 67,
    created_at: '2019-06-06T20:14:49Z',
    order_id: '652-56-9015',
    customer_name: 'Audi Mathwen',
    customer_phone: '5998413862',
    city_area: 'Ko Si Chang',
    full_address: '9592 5th Way',
    additional_req: 'diam neque vestibulum eget vulputate',
    cart_items: 18,
    cart_price: '4424.75',
    del_fee: '60.00',
    total_collectable: '4010.63',
    order_status: 'ipsum primis in faucibus',
    hold_reason: 'orci luctus et',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Pindangan Centro',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 68,
    created_at: '2019-07-10T04:39:45Z',
    order_id: '790-64-1697',
    customer_name: 'Artair Lowbridge',
    customer_phone: '5479560827',
    city_area: 'Isak',
    full_address: '0313 Moose Alley',
    additional_req: 'in faucibus orci luctus et ultrices posuere',
    cart_items: 9,
    cart_price: '2308.05',
    del_fee: '60.00',
    total_collectable: '4421.94',
    order_status: 'tristique tortor',
    hold_reason: 'vulputate justo',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Mirskoy',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 69,
    created_at: '2019-04-20T09:21:35Z',
    order_id: '640-70-7236',
    customer_name: 'Ase Paull',
    customer_phone: '5178217869',
    city_area: 'Fengshan',
    full_address: '78 Acker Place',
    additional_req: 'eu felis fusce posuere felis',
    cart_items: 13,
    cart_price: '1114.03',
    del_fee: '60.00',
    total_collectable: '1799.85',
    order_status: 'condimentum neque',
    hold_reason: 'metus sapien ut',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Tielong',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 70,
    created_at: '2019-10-04T20:36:52Z',
    order_id: '314-91-8788',
    customer_name: 'Nerti Ebbrell',
    customer_phone: '1788773209',
    city_area: 'Pizhma',
    full_address: '413 2nd Road',
    additional_req: 'magnis dis parturient montes nascetur',
    cart_items: 11,
    cart_price: '4418.74',
    del_fee: '60.00',
    total_collectable: '2914.08',
    order_status: 'dui vel sem sed',
    hold_reason: 'morbi ut odio cras',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Bassar',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 71,
    created_at: '2019-11-14T03:02:52Z',
    order_id: '441-26-6366',
    customer_name: 'Ilsa Orniz',
    customer_phone: '7446454125',
    city_area: 'Arasji',
    full_address: '65128 Merchant Pass',
    additional_req: 'non quam nec dui luctus rutrum nulla tellus',
    cart_items: 13,
    cart_price: '2065.81',
    del_fee: '60.00',
    total_collectable: '4921.64',
    order_status: 'sollicitudin vitae consectetuer eget',
    hold_reason: 'et ultrices posuere',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Rancho Nuevo',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 72,
    created_at: '2020-03-30T13:09:19Z',
    order_id: '259-31-2659',
    customer_name: 'Roseann Cummins',
    customer_phone: '6324500032',
    city_area: 'Basīrpur',
    full_address: '74507 John Wall Street',
    additional_req: 'lacus morbi quis tortor id nulla ultrices aliquet maecenas leo',
    cart_items: 4,
    cart_price: '4315.78',
    del_fee: '60.00',
    total_collectable: '87.64',
    order_status: 'non quam nec',
    hold_reason: 'justo aliquam quis turpis',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Taibai',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 73,
    created_at: '2020-02-19T21:25:44Z',
    order_id: '423-01-2299',
    customer_name: 'Rurik Issatt',
    customer_phone: '1672577717',
    city_area: 'Haiyan',
    full_address: '821 Ohio Street',
    additional_req: 'nulla nunc purus phasellus in felis donec semper sapien a',
    cart_items: 10,
    cart_price: '345.69',
    del_fee: '60.00',
    total_collectable: '122.64',
    order_status: 'et magnis dis',
    hold_reason: 'aliquet maecenas leo odio',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Sanxi',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 74,
    created_at: '2019-11-17T15:50:03Z',
    order_id: '124-41-9951',
    customer_name: 'Lyndel Lavin',
    customer_phone: '2849190175',
    city_area: 'Feicheng',
    full_address: '72 Swallow Junction',
    additional_req: 'sapien sapien non mi integer ac neque duis bibendum morbi',
    cart_items: 3,
    cart_price: '4092.29',
    del_fee: '60.00',
    total_collectable: '5077.05',
    order_status: 'turpis nec euismod scelerisque',
    hold_reason: 'nec nisi vulputate nonummy',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Limoncito',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 75,
    created_at: '2019-05-27T05:13:08Z',
    order_id: '596-82-3649',
    customer_name: 'Bianka Gee',
    customer_phone: '4078188806',
    city_area: 'Orlando',
    full_address: '5127 Kennedy Point',
    additional_req: 'nulla suspendisse potenti cras in purus eu magna vulputate luctus',
    cart_items: 10,
    cart_price: '2284.29',
    del_fee: '60.00',
    total_collectable: '5107.23',
    order_status: 'vel enim',
    hold_reason: 'nulla pede',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Tacurong',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 76,
    created_at: '2020-03-27T02:03:00Z',
    order_id: '198-34-1117',
    customer_name: 'Kamila Errigo',
    customer_phone: '7873399492',
    city_area: 'Tanggulangin',
    full_address: '45 American Circle',
    additional_req: 'arcu adipiscing molestie hendrerit at vulputate',
    cart_items: 16,
    cart_price: '1571.95',
    del_fee: '60.00',
    total_collectable: '719.34',
    order_status: 'eu est congue elementum',
    hold_reason: 'metus arcu adipiscing molestie',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Kamifukuoka',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 77,
    created_at: '2019-04-10T18:46:20Z',
    order_id: '561-23-6480',
    customer_name: 'Orazio Jacques',
    customer_phone: '8943246982',
    city_area: 'Belomorsk',
    full_address: '75986 Florence Junction',
    additional_req: 'sagittis sapien cum sociis natoque penatibus',
    cart_items: 15,
    cart_price: '3490.45',
    del_fee: '60.00',
    total_collectable: '953.21',
    order_status: 'scelerisque mauris sit amet',
    hold_reason: 'ac enim in tempor',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Oncativo',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 78,
    created_at: '2019-11-21T07:58:45Z',
    order_id: '641-27-0725',
    customer_name: 'Tildy Bartolic',
    customer_phone: '9072289968',
    city_area: 'Zabierzów',
    full_address: '46 Mandrake Center',
    additional_req: 'nisl duis bibendum felis sed interdum',
    cart_items: 7,
    cart_price: '3449.46',
    del_fee: '60.00',
    total_collectable: '4995.92',
    order_status: 'et ultrices posuere',
    hold_reason: 'nulla nunc',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Ganpu',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 79,
    created_at: '2019-11-30T19:47:47Z',
    order_id: '375-57-1398',
    customer_name: 'Tomi Whitnall',
    customer_phone: '1657671868',
    city_area: 'Bitam',
    full_address: '53 Quincy Crossing',
    additional_req: 'amet consectetuer adipiscing elit proin risus',
    cart_items: 8,
    cart_price: '3205.02',
    del_fee: '60.00',
    total_collectable: '2892.55',
    order_status: 'ultrices posuere',
    hold_reason: 'morbi vestibulum',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Guanaja',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 80,
    created_at: '2019-11-22T06:46:00Z',
    order_id: '512-01-7017',
    customer_name: 'Karlee Kiss',
    customer_phone: '6978234673',
    city_area: 'Rzewnie',
    full_address: '1 Weeping Birch Drive',
    additional_req: 'ligula pellentesque ultrices phasellus id',
    cart_items: 14,
    cart_price: '1675.24',
    del_fee: '60.00',
    total_collectable: '2051.38',
    order_status: 'sem sed sagittis nam',
    hold_reason: 'integer ac leo pellentesque',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Osapsio',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 81,
    created_at: '2020-01-04T16:56:19Z',
    order_id: '504-97-5330',
    customer_name: 'Bram Blinco',
    customer_phone: '3389258231',
    city_area: 'Hongkou',
    full_address: '38910 2nd Park',
    additional_req: 'duis bibendum felis sed interdum venenatis turpis enim blandit mi',
    cart_items: 9,
    cart_price: '2199.35',
    del_fee: '60.00',
    total_collectable: '908.51',
    order_status: 'luctus et ultrices',
    hold_reason: 'libero rutrum',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Teresa',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 82,
    created_at: '2019-09-19T01:48:51Z',
    order_id: '370-77-6681',
    customer_name: 'Rolfe Wallsam',
    customer_phone: '7901876224',
    city_area: 'Singida',
    full_address: '4 Longview Plaza',
    additional_req: 'erat fermentum justo nec condimentum neque sapien placerat ante nulla',
    cart_items: 6,
    cart_price: '2813.90',
    del_fee: '60.00',
    total_collectable: '1364.08',
    order_status: 'pede justo lacinia',
    hold_reason: 'arcu sed augue',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Longbu',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 83,
    created_at: '2019-11-24T10:07:52Z',
    order_id: '443-28-3700',
    customer_name: 'Earle Keel',
    customer_phone: '3973071033',
    city_area: 'Luozhou',
    full_address: '5 Cascade Pass',
    additional_req: 'nascetur ridiculus mus etiam vel',
    cart_items: 6,
    cart_price: '4507.69',
    del_fee: '60.00',
    total_collectable: '1730.78',
    order_status: 'vel enim sit',
    hold_reason: 'morbi non quam',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Cannes',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 84,
    created_at: '2019-06-04T23:52:04Z',
    order_id: '211-64-6757',
    customer_name: 'Vassily Robertsen',
    customer_phone: '7081992542',
    city_area: 'Krivodanovka',
    full_address: '64764 Morrow Avenue',
    additional_req: 'vulputate ut ultrices vel augue vestibulum ante',
    cart_items: 4,
    cart_price: '3693.21',
    del_fee: '60.00',
    total_collectable: '3245.75',
    order_status: 'consequat nulla nisl',
    hold_reason: 'tempor convallis nulla',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Glamang',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 85,
    created_at: '2019-08-13T21:39:05Z',
    order_id: '369-77-1034',
    customer_name: 'Tess Texton',
    customer_phone: '7744663283',
    city_area: 'Shang Boingor',
    full_address: '74 Clyde Gallagher Drive',
    additional_req: 'commodo vulputate justo in blandit ultrices enim lorem ipsum dolor',
    cart_items: 11,
    cart_price: '3736.31',
    del_fee: '60.00',
    total_collectable: '1948.87',
    order_status: 'nulla justo',
    hold_reason: 'vitae ipsum aliquam non',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Matanog',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 86,
    created_at: '2019-07-11T01:24:29Z',
    order_id: '580-69-9376',
    customer_name: 'Royall Kremer',
    customer_phone: '8101159178',
    city_area: 'Sinjār',
    full_address: '4597 Commercial Parkway',
    additional_req: 'amet consectetuer adipiscing elit proin',
    cart_items: 6,
    cart_price: '4315.77',
    del_fee: '60.00',
    total_collectable: '2602.79',
    order_status: 'praesent id massa',
    hold_reason: 'erat eros',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Tierra Blanca',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 87,
    created_at: '2020-03-23T20:06:32Z',
    order_id: '380-17-3177',
    customer_name: 'Jennie Ivanyutin',
    customer_phone: '2504489031',
    city_area: 'Tartu',
    full_address: '11 Carberry Lane',
    additional_req: 'consectetuer adipiscing elit proin interdum mauris non',
    cart_items: 6,
    cart_price: '3600.61',
    del_fee: '60.00',
    total_collectable: '4470.57',
    order_status: 'lectus in quam fringilla',
    hold_reason: 'quam suspendisse potenti',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Ręczno',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 88,
    created_at: '2019-04-23T11:40:13Z',
    order_id: '227-52-0576',
    customer_name: 'Templeton Basile',
    customer_phone: '4193273587',
    city_area: 'Zhaotong',
    full_address: '8 Parkside Alley',
    additional_req: 'non sodales sed tincidunt eu felis fusce posuere felis sed',
    cart_items: 4,
    cart_price: '3507.80',
    del_fee: '60.00',
    total_collectable: '5815.96',
    order_status: 'ligula pellentesque ultrices phasellus',
    hold_reason: 'ante vestibulum ante ipsum',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Saint-Gaudens',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 89,
    created_at: '2020-03-30T23:57:03Z',
    order_id: '427-44-7031',
    customer_name: 'Avictor Ledford',
    customer_phone: '1119826451',
    city_area: 'Thompson',
    full_address: '876 Hovde Pass',
    additional_req: 'consectetuer adipiscing elit proin risus praesent',
    cart_items: 10,
    cart_price: '4067.83',
    del_fee: '60.00',
    total_collectable: '952.84',
    order_status: 'quam sapien',
    hold_reason: 'velit nec nisi',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Luxi',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 90,
    created_at: '2019-08-15T15:47:41Z',
    order_id: '895-16-3009',
    customer_name: 'Glen Mearing',
    customer_phone: '4668349439',
    city_area: 'Malandag',
    full_address: '380 Larry Parkway',
    additional_req: 'lacinia erat vestibulum sed magna at nunc commodo placerat',
    cart_items: 15,
    cart_price: '3375.35',
    del_fee: '60.00',
    total_collectable: '2113.91',
    order_status: 'justo morbi ut odio',
    hold_reason: 'ipsum praesent blandit lacinia',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Mazyr',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 91,
    created_at: '2019-09-10T00:19:43Z',
    order_id: '878-62-8336',
    customer_name: 'Mattie Ciraldo',
    customer_phone: '4336614355',
    city_area: 'Sinjil',
    full_address: '425 Banding Center',
    additional_req: 'vitae ipsum aliquam non mauris morbi non',
    cart_items: 8,
    cart_price: '3887.48',
    del_fee: '60.00',
    total_collectable: '2379.57',
    order_status: 'porta volutpat quam pede',
    hold_reason: 'mattis pulvinar nulla pede',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Jiacun',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 92,
    created_at: '2019-10-06T05:11:06Z',
    order_id: '287-03-1316',
    customer_name: 'Leonid Mityushkin',
    customer_phone: '1083716722',
    city_area: 'Santa Ana',
    full_address: '3 Commercial Road',
    additional_req: 'eget semper rutrum nulla nunc purus',
    cart_items: 16,
    cart_price: '533.62',
    del_fee: '60.00',
    total_collectable: '4914.28',
    order_status: 'molestie hendrerit',
    hold_reason: 'purus sit',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Arai',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 93,
    created_at: '2020-02-10T13:49:39Z',
    order_id: '196-31-3894',
    customer_name: 'Clayborne Pirozzi',
    customer_phone: '1008442015',
    city_area: 'Karangkedawung',
    full_address: '307 Valley Edge Place',
    additional_req: 'amet lobortis sapien sapien non mi integer ac neque duis',
    cart_items: 13,
    cart_price: '2706.06',
    del_fee: '60.00',
    total_collectable: '3377.26',
    order_status: 'enim blandit',
    hold_reason: 'lacus at',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Nazrēt',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 94,
    created_at: '2019-05-10T22:31:20Z',
    order_id: '764-56-6207',
    customer_name: 'Silvia Thompsett',
    customer_phone: '8969778779',
    city_area: 'Fengjiang',
    full_address: '11 Morning Circle',
    additional_req: 'erat eros viverra eget congue',
    cart_items: 9,
    cart_price: '2077.29',
    del_fee: '60.00',
    total_collectable: '3042.04',
    order_status: 'nec dui',
    hold_reason: 'amet eleifend pede',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Peña',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 95,
    created_at: '2020-02-17T19:00:07Z',
    order_id: '583-54-5154',
    customer_name: 'Gill Karleman',
    customer_phone: '1034234200',
    city_area: 'Yufa',
    full_address: '90292 Rowland Lane',
    additional_req: 'lobortis est phasellus sit amet erat nulla',
    cart_items: 6,
    cart_price: '1839.88',
    del_fee: '60.00',
    total_collectable: '3296.83',
    order_status: 'dui luctus rutrum nulla',
    hold_reason: 'risus auctor',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Oslomej',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 96,
    created_at: '2019-08-04T23:55:00Z',
    order_id: '233-72-9666',
    customer_name: 'Kari Tipler',
    customer_phone: '3845959351',
    city_area: 'Yili',
    full_address: '463 Holy Cross Way',
    additional_req: 'eleifend quam a odio in hac habitasse platea',
    cart_items: 8,
    cart_price: '293.37',
    del_fee: '60.00',
    total_collectable: '706.68',
    order_status: 'sit amet',
    hold_reason: 'potenti nullam porttitor',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Yanggu’ao',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 97,
    created_at: '2020-03-18T12:13:16Z',
    order_id: '806-40-9520',
    customer_name: 'Far Pendry',
    customer_phone: '6622922166',
    city_area: 'Bronnitsy',
    full_address: '23665 Westridge Terrace',
    additional_req: 'justo lacinia eget tincidunt eget tempus',
    cart_items: 7,
    cart_price: '1950.61',
    del_fee: '60.00',
    total_collectable: '333.60',
    order_status: 'pellentesque viverra',
    hold_reason: 'tempus vel',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Shiyan',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 98,
    created_at: '2019-11-22T17:48:03Z',
    order_id: '163-77-7973',
    customer_name: 'Vale Cheevers',
    customer_phone: '3901835017',
    city_area: 'Kota Bharu',
    full_address: '9 Lerdahl Parkway',
    additional_req: 'felis sed lacus morbi sem mauris laoreet ut rhoncus',
    cart_items: 6,
    cart_price: '311.20',
    del_fee: '60.00',
    total_collectable: '2550.34',
    order_status: 'eget tempus vel pede',
    hold_reason: 'erat id mauris vulputate',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Harbin',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 99,
    created_at: '2020-03-13T01:46:01Z',
    order_id: '233-79-2496',
    customer_name: 'Donielle Caskie',
    customer_phone: '8742418503',
    city_area: 'Orlovskiy',
    full_address: '0276 Eliot Point',
    additional_req: 'odio porttitor id consequat in',
    cart_items: 10,
    cart_price: '3881.02',
    del_fee: '60.00',
    total_collectable: '4193.33',
    order_status: 'sapien cum sociis natoque',
    hold_reason: 'justo sit amet',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Nové Město pod Smrkem',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  },
  {
    id: 100,
    created_at: '2019-08-13T04:20:12Z',
    order_id: '629-97-8906',
    customer_name: 'Deny Kringe',
    customer_phone: '8134963271',
    city_area: 'Kousa',
    full_address: '51 Starling Way',
    additional_req: 'in faucibus orci luctus et',
    cart_items: 4,
    cart_price: '3853.60',
    del_fee: '60.00',
    total_collectable: '2507.67',
    order_status: 'lacinia nisi venenatis',
    hold_reason: 'placerat ante nulla justo',
    delivery_zone: 'DH-Z1',
    assigned_pickup_source: null,
    assigned_delivery_station: 'Oeiras',
    delivery_type: 'Station Pickup',
    assigned_agent: null,
    total_collected: null
  }
];
