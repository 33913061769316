import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  IconButton 
} from '@material-ui/core';
import { transform, isEqual, isObject } from 'lodash';
import isPositiveInt from 'utils/isPositiveInt';
import { MTableToolbar, MTableEditField } from 'material-table';

import ProductsTableWrapper from './ProductTable.style';

import { editProducts } from 'redux/stores';
import Stores from 'repository/Stores';
import Container from 'components/Container';
import MaterialTable from 'components/MaterialTable';
import { Add, Delete } from '@material-ui/icons';
import { getMappings } from 'redux/productperstore';

//axios
import axios from 'axios';
import { ENV } from 'ENV';

const ProductsTable = ({ storeID, toggleOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  //delete dialog state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [IsDelButtonDisabled, setIsDelButtonDisabled] = useState(true);

  const tableRef = useRef(null);

  const getData = async (query) => {
    const body = {
      page: query.page + 1,
      limit: query.pageSize,
      search: query.search
    };
    const paramObj = history?.location?.state;
    if (paramObj?.search) {
      body.search = paramObj?.search;
    }
    try {
      const res = await dispatch(getMappings(storeID, body));
      const copy = JSON.parse(JSON.stringify(res));
      return {
        data: copy?.data ?? [],
        totalCount: copy?.count ?? 0,
        page: copy?.page - 1
      };
    } catch (error) {
      return {
        data: [],
        totalCount: 0,
        page: 0
      };
    }
  };

  //this function compares objects so that product updating process can be executed
  const diffObject = (object, base) => {
    function changes(object, base) {
      return transform(object, function (result, value, key) {
        if (!isEqual(value, base[key])) {
          result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
        }
      });
    }
    return changes(object, base);
  };

  //this function handles product data updation
  const handleRowEdit = async (newData, oldData) => {
    const data = JSON.parse(JSON.stringify(oldData)); // copy oldData object
    delete data.tableData; // tableData was added by material-table
    if (!isPositiveInt(newData.quantity) || !isPositiveInt(newData.sourcing_price) | !isPositiveInt(newData.product?.selling_price)) {
      alert('invalid amount');
      return;
    }

    //console.log(newData.product?.selling_price)

    const parsedData = {
      ...newData,
      quantity: Number(newData.quantity), 
      sourcing_price: Number(newData.sourcing_price),
      //"product.meta.selling_price": Number(newData.product?.selling_price),
    };
    console.log(parsedData)
    const diff = diffObject(parsedData, data);
    // console.log(diff);
    if (Boolean(Object.keys(diff).length)) {
      await dispatch(editProducts({ id: newData.id, data: diff }));
    }
  };

  //performing table refresh function
  const reloadTableData = () => {
    tableRef.current.onQueryChange();
  };

  const handleDeleteAllProducts = async () => {
    console.log('Clicked delete all products');

    //console.log(storeID)
    const baseApiUrl = ENV.REACT_APP_API_URL;
    const storedIdToDel = storeID;

    //delete req
    try {
      console.log('🟡 Initiating API Call To Delete');
      axios
        .delete(`${baseApiUrl}/store/del/product/${storedIdToDel}`)
        .then(function (response) {
          console.log('🟠 All products were deleted');
          console.log(response);
          reloadTableData();
        })
        .catch(function (error) {
          console.log(error);
          window.alert('🔴 Error Occurred While Deleting !');
          reloadTableData();
        });
    } catch (err) {
      console.log('🔴 Error occurred while deleting');
      window.alert('🔴 Error Occurred While Deleting !');
      reloadTableData();
    }


    //reseting to defaults
    setIsDelButtonDisabled(true);
    setDialogOpen(false);
  };

  //for verifying that the user is sure about deleting, have to type exactly "DELETE"
  const handleDelTextChange = (e) => {
    let text = e.target.value;
    if (text === 'DELETE') {
      setIsDelButtonDisabled(false);
    }
    else{
      setIsDelButtonDisabled(true);
    }
  };

  const columns = [
    {
      title: 'Product ID',
      field: 'productId',
      sorting: true,
      required: true,
      editable: 'never',
      render: ({ productId }) => <p>{productId}</p>
    },
    {
      title: 'Product Name',
      field: 'name',
      sorting: true,
      required: true,
      searchable: true,
      editable: 'never',
      render: ({ product }) => <p>{product?.name}</p>
    },
    {
      title: 'Zone',
      field: 'zone',
      sorting: true,
      // required: true,
      searchable: true,
      // editable: 'never',
      render: ({ zone }) => <p>{zone}</p>
    },
    {
      title: 'Selling Price',
      field: 'selling_price',
      sorting: false,
      type: 'numeric',
      editable: 'never',
      render: ({ product }) => <p>Tk { product?.selling_price}</p>
    },
    {
      title: 'Quantity',
      field: 'quantity',
      sorting: false,
      type: 'numeric',
      // editable: 'never',
      render: ({ quantity }) => <p>{quantity}</p>
    },
    {
      title: 'Sourcing Price',
      field: 'sourcing_price',
      sorting: false,
      type: 'numeric',
      // editable: 'never',
      render: ({ sourcing_price }) => <p>Tk {sourcing_price}</p>
    }
  ];
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="Delete all products"
        aria-describedby="Are you sure you want delete all products ?"
      >
        <DialogTitle id="alert-dialog-title">{'Delete All Products Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Type "DELETE" to confirm
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="Type"
            variant="outlined"
            onChange={handleDelTextChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDelButtonDisabled(true);
              setDialogOpen(false);
            }}
            autoFocus
          >
            No
          </Button>
          <Button disabled={IsDelButtonDisabled} onClick={handleDeleteAllProducts}> 
            Yes Delete All Products
          </Button>
        </DialogActions>
      </Dialog>
      <Container>
        <ProductsTableWrapper>
          <MaterialTable
            tableRef={tableRef}
            title="Products"
            components={{
              EditField: (props) =>
                props.columnDef.required && props.value.length === 0 ? (
                  <MTableEditField {...props} error label="Required" />
                ) : (
                  <MTableEditField {...props} />
                ),
              Toolbar: (props) => <MTableToolbar {...props} search columnsButton={false} />
            }}
            columns={columns}
            data={getData}
            options={{
              columnsButton: true,
              draggable: false,
              pageSize: 20,
              pageSizeOptions: [20, 60, 120, 200]
            }}
            editable={{
              onRowUpdate: (newData, oldData) => handleRowEdit(newData, oldData),
              onRowDelete: (oldData) => Stores.deleteProduct(oldData.id)
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current?.onQueryChange()
              },
              {
                icon: () => <FormControlLabel control={<Add />} label="Add Product" />,
                isFreeAction: true,
                onClick: (_) => toggleOpen()
              },
              {
                icon: () => <FormControlLabel control={<Delete />} label="Delete All Products" />,
                isFreeAction: true,
                onClick: (_) => setDialogOpen(true)
              }
            ]}
          />
        </ProductsTableWrapper>
      </Container>
    </>
  );
};

export default ProductsTable;
