/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'antd';
import { useDispatch } from 'react-redux';
import Products from 'repository/Products';
import { useDebouncedCallback } from 'use-debounce';

const { TreeNode } = TreeSelect;

const SelectBrandProducts = ({ productValues, setProductValues }) => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    !products?.length && getProducts();
  }, [dispatch, products]);

  const getProducts = async (search = '') => {
    try {
      const res = await Products.get({ search, product_status: 'ACTIVE' });
      const data = await res.data;
      const cat = JSON.parse(JSON.stringify(data.data));
      setProducts(cat);
      return cat;
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const debounced = useDebouncedCallback((value) => {
    getProducts(value);
  }, 500);

  return (
    <>
      {products?.length > 0 ? (
        <TreeSelect
          showSearch
          style={{ width: '100%' }}
          value={productValues}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '9999999' }}
          placeholder="Select products"
          allowClear
          multiple
          treeDefaultExpandAll
          size="middle"
          onKeyUp={(e) => {
            setIsLoading(true);
            debounced(e?.target?.value);
          }}
          onBlur={() => getProducts('')}
          onChange={(value) => setProductValues(value)}
          loading={isLoading}
          filterTreeNode={(search, item) => {
            return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
          }}
        >
          {products?.map(
            (
              item //parent_name
            ) => (
              <TreeNode key={item?.id} value={item?.id} title={item?.name}>
                {item?.second_layer_cats?.map(
                  (
                    child //second_layer_cats
                  ) => (
                    <TreeNode key={child?.id} value={child?.id} title={child?.name}>
                      <>
                        {child?.children?.map(
                          (
                            child //second_layer_cats.children
                          ) => (
                            <TreeNode key={child?.id} value={child?.id} title={child?.name} />
                          )
                        )}
                      </>
                    </TreeNode>
                  )
                )}
              </TreeNode>
            )
          )}
        </TreeSelect>
      ) : (
        <p style={{ marginTop: 2 }}> ⌛ Loading Please Wait ....</p>
      )}
    </>
  );
};

export default SelectBrandProducts;
