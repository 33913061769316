import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

export default {
  add: (data) => {
    return SuperFetch(`store`, {
      method: 'POST',
      data
    });
  },

  edit: (id, data) => {
    return SuperFetch(`store/${id}`, {
      method: 'PUT',
      data
    });
  },

  getDetails(id) {
    return SuperFetch(`store/${id}`);
  },

  getProducts(id, query) {
    return SuperFetch(`store/${id}/pps?${querystring.stringify(query, { arrayFormat: 'comma' })}`);
  },

  delete: (id) => {
    return SuperFetch(`store/${id}`, {
      method: 'DELETE'
    });
  },

  getAll: async (params) => {
    return SuperFetch(`store?${querystring.stringify(params, { arrayFormat: 'comma' })}`);
  },

  bulkAddProducts: (file) => {
    var bodyFormData = new FormData();

    // Display the values
    bodyFormData.append('file', file);

    return SuperFetch(`product/productStore/bulk`, {
      method: 'POST',
      data: bodyFormData,
      headers: {
        'Content-Type':
          'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
        // 'X-Requested-With': 'XMLHttpRequest'
      }
    });
  },

  bulkAddStores: (file) => {
    var bodyFormData = new FormData();

    // Display the values
    bodyFormData.append('file', file);

    return SuperFetch(`store/bulk`, {
      method: 'POST',
      data: bodyFormData,
      headers: {
        'Content-Type':
          'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
        // 'X-Requested-With': 'XMLHttpRequest'
      }
    });
  },

  addProduct: (data) => {
    return SuperFetch(`product/productStore`, {
      method: 'POST',
      data
    });
  },

  editProduct: (id, data) => {
    return SuperFetch(`product/productStore/${id}`, {
      method: 'PUT',
      data
    });
  },

  deleteProduct: (id) => {
    return SuperFetch(`product/productStore/${id}`, {
      method: 'DELETE'
    });
  }
};
