import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller, ErrorMessage, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import ImageUploader from 'react-images-upload';
import CategorySelect from './CatSelect';
import StatusSelect from './StatusSelect';
import Tags from './tags';

//rich text editor
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'

import Form, { FormControl } from './productForm.style';

const productSchema = yup.object().shape({
  name: yup.string().required('Required'),
  short_description: yup.string().required('Required'),
  long_description: yup.string(),
  selling_price: yup.number('Should be number').required('Required'),
  discounted_price: yup.number('Should be number').required('Required'),
  mrp: yup.number('Should be number').required('Required'),
  weight: yup.number('Should be number'),
  tags: yup.array().of(yup.string()),
  meta: yup.array().of(yup.object()),
  categories: yup.array().of(yup.number()),
  product_status: yup.string(),
  discount_percentage: yup.number()
});

const ProductForm = ({ product, handleAddProduct }) => {
  const [images, setImages] = useState([]);
  const [thumb, setThumb] = useState([]);
  const [catValue, setCatValue] = useState(undefined);
  const [statusValue, setStatusValue] = useState(undefined);
  const [statusError, setStatusError] = useState('');
  const [tagsList, setTagsList] = useState([]);
  const [lastProduct, setLastProduct] = useState(null);
  const [sellingPrice, setSellingPrice] = useState(null);
  const [discountParcentage, setDiscountParcentage] = useState(null);
  const [mrPrice, setMrPrice] = useState(0);

  //quill editor state
  const [editorValue, setEditorValue] = useState('')

  useEffect(() => {
    if (product?.name?.length > 1) setLastProduct(product);
    else setLastProduct(null);
    return () => {
      setLastProduct(null);
    };
  }, [product]);

  const { register, control, handleSubmit, setValue, errors } = useForm({
    validationSchema: productSchema,
    defaultValues: {
      name: '',
      short_description: '',
      long_description: '',
      selling_price: '',
      discounted_price: '',
      mrp: '',
      weight: '1',
      tags: [],
      meta: [{ key: 'Key', value: 'Value' }],
      categories: [],
      product_status: ''
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'meta'
  });

  useEffect(() => {
    if (lastProduct) {
      setValue('name', lastProduct?.name || '');
      setValue('short_description', lastProduct?.short_description || '');
      setEditorValue(lastProduct?.long_description || ''); //quill editor value
      setValue('long_description', lastProduct?.long_description || '');
      setValue('selling_price', lastProduct?.selling_price || '');
      // setValue('discounted_price', lastProduct?.discounted_price || '');
      setValue('mrp', lastProduct?.mrp || '');
      setValue('weight', lastProduct?.weight || '');
      setValue('tags', lastProduct?.tags || []);
      setTagsList(lastProduct?.tags);
      console.log(lastProduct?.tags);
    } else {
      setValue('name', '');
      setValue('short_description', '');
      setValue('long_description', '');
      setEditorValue(''); //quill editor value
      setValue('selling_price', '');
      // setValue('discounted_price', '');
      setValue('mrp', '');
      setValue('weight', '');
      setValue('tags', []);
    }
    return () => {
      setTagsList(lastProduct?.tags);
    };
  }, [lastProduct, setValue]);

  const onSubmit = (data) => {
    const body = {
      ...data,
      tags: tagsList || null,
      categories: catValue || null,
      product_status: statusValue || null,
      meta: !!data?.meta?.length
        ? data?.meta?.reduce((a, item) => {
            const { key } = item;
            const camel = key.replace(/ ./g, (chars) => chars[1].toUpperCase());
            a[camel] = item.value;
            return a;
          }, {})
        : null
    };
    if (statusValue) {
      handleAddProduct(body, thumb, images);
      setStatusError('');
    } else {
      setStatusError('Required');
    }
  };

  const parcentageCalc = (sell, par, mr) => {
    const p = (sell * par) / 100;
    const discountedPrice = sell > mr ? Math.floor(sell - p) : Math.ceil(sell - p);
    return discountedPrice;
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'selling_price') {
      setSellingPrice(value);
      setValue('selling_price', value);
      setValue('discounted_price', parcentageCalc(value, discountParcentage, mrPrice));
    }
    if (name === 'discount_percentage') {
      if (parseInt(value) <= 0 || !value) {
        setValue('discounted_price', 0);
      } else {
        setValue('discounted_price', parcentageCalc(sellingPrice, value, mrPrice));
        setDiscountParcentage(value);
      }
    }
    if (name === 'mrp') {
      setValue('discounted_price', parcentageCalc(sellingPrice, discountParcentage, value));
      setValue('mrp', value);
      setMrPrice(value);
    }
  };

  console.log(images, tagsList, fields, thumb);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
      <div>
        <FormControl>
          <label htmlFor="name">Product Name</label>
          <Controller
            as={TextField}
            fullWidth
            id="name"
            name="name"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="name" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="short_description">Short Description</label>
          <Controller
            as={TextField}
            fullWidth
            id="short_description"
            name="short_description"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage style={{ color: 'red' }} errors={errors} name="short_description" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="long_description">Long Description</label>
          <Controller
            as={<ReactQuill theme="snow" value={editorValue} onChange={setEditorValue} id="long_description" control={control} name="long_description"/>}
            multiline
            fullWidth
            id="long_description"
            name="long_description"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="long_description" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="selling_price">Selling Price</label>
          <Controller
            as={TextField}
            fullWidth
            type="number"
            id="selling_price"
            name="selling_price"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="selling_price" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="discounted_price">Discount Percentage</label>
          <Controller
            as={TextField}
            id="discount_percentage"
            type="number"
            variant="outlined"
            name="discount_percentage"
            control={control}
            fullWidth
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="discount_percentage" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="discounted_price">Discounted Price</label>
          <Controller
            as={TextField}
            id="discounted_price"
            type="number"
            variant="outlined"
            name="discounted_price"
            control={control}
            fullWidth
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="discounted_price" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="mrp">MRP</label>
          <Controller
            as={TextField}
            fullWidth
            type="number"
            id="mrp"
            name="mrp"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="mrp" />
          </span>
        </FormControl>
        <FormControl>
          <label htmlFor="weight">Weight (gm)</label>
          <Controller
            as={TextField}
            fullWidth
            type="number"
            id="weight"
            name="weight"
            control={control}
            variant="outlined"
          />
          <span style={{ color: 'red' }}>
            <ErrorMessage errors={errors} name="weight" />
          </span>
        </FormControl>
      </div>
      <div>
        <label>Tags</label>
        <Tags Tags={tagsList} setTags={setTagsList} />
        <br />
        <label>Select Category</label>
        <br />
        <CategorySelect cats={catValue} setCatValue={setCatValue} />
        <br />
        <br />
        <label>
          Select Status <span style={{ color: 'red' }}>*</span>{' '}
        </label>
        <StatusSelect status={statusValue} setStatusValue={setStatusValue} />
        {statusError !== '' && <span style={{ color: 'red' }}>{statusError}</span>}
        <br />
        <br />
        <label>Meta</label>
        <ul style={{ listStyle: 'none', padding: '0' }}>
          {fields.map((item, index) => (
            <li style={{ padding: '5px 0' }} key={item.id}>
              <input
                name={`meta[${index}].key`}
                ref={register()}
                defaultValue={item.key} // make sure to set up defaultValue
              />
              <span />
              <Controller
                style={{ marginLeft: '5px' }}
                as={<input />}
                name={`meta[${index}].value`}
                control={control}
                defaultValue={item.value} // make sure to set up defaultValue
              />
              <button style={{ marginLeft: '5px' }} type="button" onClick={() => remove(index)}>
                Delete
              </button>
            </li>
          ))}
        </ul>
        <button type="button" onClick={() => append({ key: 'Key', value: 'Value' })}>
          append
        </button>
        <br />
        <FormControl>
          <label style={{ marginTop: '50px' }}>Product Gallery</label>
          <ImageUploader
            // singleImage
            withIcon={true}
            buttonText="Choose images upto five"
            onChange={(pictureFiles) => {
              setThumb(pictureFiles);
              setImages(pictureFiles);
            }}
            imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
            maxFileSize={500000}
            label="Max file size: 500kb and .jpg, .gif, .png, .jpeg, accepted"
            withPreview
            style={{ boxShadow: '0px 0px 3px #d9d9d9', borderRadius: '5px' }}
          />
        </FormControl>
      </div>
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button type="submit" variant="contained" color="primary">
            Add Product
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ProductForm;
