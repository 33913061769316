import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import Auth from 'repository/Auth';

export const loginRequest = createAsyncThunk('auth/login', async (body, thunkApi) => {
  const response = await Auth.login(body);
  if (response.status === 200) {
    return response.data;
  } 
  else if (response.status === 401) {
    window.alert("Incorrect Credentials")
    thunkApi.rejectWithValue('Login Failed');
  }
  else {
    thunkApi.rejectWithValue('Login Failed');
  }
});

const auth = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    authenticated: false,
    token: null,
    user: null,
    error: null
  },
  reducers: {
     logout: (state) => {
      state.authenticated = false;
      state.token = null;
      state.user = null;
    },
  },
  extraReducers: {
    [loginRequest.pending]: (state, action) => {
      state.loading = true;
    },
    [loginRequest.fulfilled]: (state, action) => {
      state.authenticated = true;
      state.loading = false;
      state.token = action.payload?.jwtToken?.token;
      state.user = action.payload?.user;
    },
    [loginRequest.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const selectors = {
  getAuth: createSelector(
    (state) => state.auth.authenticated,
    (data) => data
  ),
  getUser: createSelector(
    (state) => state.auth.user,
    (data) => data
  )
};


export function logout() {
    return async (dispatch) => {
      try {
        dispatch(actions.logout());
        return { success: true };
      } catch (error) {
        console.log(error);
        return { error: true };
      }
    };
  }

export const { actions } = auth;

export default auth.reducer;
