import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { compare } from 'fast-json-patch';

import { getOrderDetails, getCustomerHistory, addProduct, editProduct } from 'redux/orderDetails';
import { updateCheckout } from 'redux/orders';
import MaterialTable from 'components/MaterialTable';
import Container from 'components/Container';
import Header from 'components/Header';
import OrderStats from './stats/OrderStats';
import Toolbar from './Toolbar/toolbar';
import { Page, OrdersTable } from './orderDetails.style';
import Products from 'repository/Products';
import isPositiveInt from 'utils/isPositiveInt';
import { baseUrl } from 'api/config';
import defaultImg from 'assets/img/default.jpg';
import { getCoupons, refreshCoupon } from 'redux/couponSlice';

const OrderDetails = () => {
  const tableRef = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [stats, setStats] = useState([]);
  const [open, setOpen] = useState(false);
  const couponData = useSelector((state) => state.coupon);
  const authToken = useSelector((state) => state.auth.token);
  // console.log(couponData);

  useEffect(() => {
    dispatch(refreshCoupon());
    setTimeout(() => window.scroll({ top: 0, behavior: 'smooth' }), 500);
    if (stats?.coupon_code) {
      dispatch(getCoupons(stats?.coupon_code));
    }
  }, [stats?.coupon_code]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddProduct = async (data) => {
    let delFee = stats?.del_fee;
    let oldCartPrice = stats?.cart_price;
    let newCartPrice = oldCartPrice + data?.price * data?.quantity;
    if (newCartPrice <= 150) {
      delFee = 0;
    } else {
      delFee = 20;
    }

    // console.log(stats?.cart_price);
    // console.log('new cart price', newCartPrice);
    // console.log('new del fee');
    const body = {
      checkout_id: stats?.id,
      data
    };
    await dispatch(
      updateCheckout(stats?.id, authToken, {
        del_fee: couponData?.coupon?.extra_config?.delivery_discount === 1 ? 0 : delFee
      })
    );
    if (couponData?.coupon?.extra_config?.sc_discount === 1) {
      await dispatch(
        updateCheckout(stats?.id, authToken, {
          service_charge: 0
        })
      );
    }
    // console.log(body);
    await dispatch(addProduct(body));
    handleClose();
    tableRef.current.onQueryChange();
  };

  const getHistory = async (phone) => {
    await dispatch(getCustomerHistory(phone, authToken));
  };

  const getData = async () => {
    const res = await dispatch(getOrderDetails(Number(id), authToken));
    const copy = JSON.parse(JSON.stringify(res.data));
    setStats(copy);

    // console.log(copy);

    return {
      data: copy?.items ?? [],
      totalCount: copy.count ?? 0,
      page: 0
    };
  };

  const handleRowEdit = async (newData, oldData) => {
    let delFee = stats?.del_fee;
    let oldCartPrice = stats?.cart_price;
    let newCartPrice;
    if (newData?.price * newData?.quantity > oldData?.price * oldData?.quantity) {
      // console.log('im here q increased')
      newCartPrice = oldCartPrice + newData?.price * (newData?.quantity - oldData?.quantity);
    }
    if (newData?.price * newData?.quantity < oldData?.price * oldData?.quantity) {
      // console.log('im here q lessen')
      newCartPrice = oldCartPrice - newData?.price * newData?.quantity;
    }
    // console.log(newCartPrice);
    if (newCartPrice <= 150) {
      delFee = 0;
    } else {
      delFee = 20;
    }
    if (newCartPrice) {
      await dispatch(
        updateCheckout(stats?.id, authToken,{
          del_fee: couponData?.coupon?.extra_config?.delivery_discount === 1 ? 0 : delFee
        })
      );
    }
    const data = JSON.parse(JSON.stringify(oldData)); // copy oldData object
    delete data.tableData; // tableData was added by material-table
    if (
      !isPositiveInt(newData.price) ||
      !isPositiveInt(newData.quantity) ||
      !isPositiveInt(newData.source_price)
    ) {
      alert('invalid amount');
      return;
    }
    const parsedData = {
      ...newData,
      price: Number(newData.price),
      quantity: Number(newData.quantity),
      source_price: Number(newData.source_price)
    };
    const diff = compare(data, parsedData);
    // console.log(diff);
    if (Boolean(diff.length)) {
      await dispatch(editProduct({ id: newData.id, data: diff }));
    }
    //service charge update doesn't work
    if (couponData?.coupon?.extra_config?.sc_discount === 1) {
      await dispatch(
        updateCheckout(stats?.id, authToken, {
          service_charge: 0
        })
      );
    }
    tableRef.current.onQueryChange();
  };

  const handleProdDel = async (id, body) => {
    let delFee = stats?.del_fee;
    let oldCartPrice = stats?.cart_price;
    let newCartPrice = oldCartPrice - body?.price * body?.quantity;
    // console.log(newCartPrice);
    if (newCartPrice <= 150) {
      delFee = 0;
    } else {
      delFee = 20;
    }
    await dispatch(
      updateCheckout(stats?.id, authToken,{
        del_fee: couponData?.coupon?.extra_config?.delivery_discount === 1 ? 0 : delFee
      })
    );
    if (couponData?.coupon?.extra_config?.sc_discount === 1) {
      await dispatch(
        updateCheckout(stats?.id, authToken,{
          service_charge: 0
        })
      );
    }
    // console.log(body);
    Products.deleteProduct(id);
    tableRef.current.onQueryChange();
  };

  const columns = [
    {
      title: 'Product ID',
      field: 'product_id',
      sorting: false,
      editable: 'never',
      render: ({ product_id }) => <p>{product_id}</p>
    },
    {
      title: 'Item Name',
      field: 'item_name',
      sorting: false,
      editable: 'never',
      render: ({ item_name }) => <p>{item_name}</p>
    },
    {
      title: 'Item Description',
      field: 'item_description',
      sorting: false,
      editable: 'never',
      render: ({ item_description }) => <p>{item_description}</p>
    },
    {
      title: 'Category Name',
      field: 'category_name',
      sorting: false,
      editable: 'never',
      render: ({ category_name }) => <p>{category_name}</p>
    },
    {
      title: 'Image',
      field: 'image',
      sorting: false,
      editable: 'never',
      render: ({ image, item_name }) => (
        <figure>
          <img
            src={
              image
                ? image?.includes('deliveryhobe') || image?.includes('localhost')
                  ? image
                  : `${baseUrl}${image}`
                : defaultImg
            }
            width="60"
            alt={item_name}
          />
        </figure>
      )
    },
    {
      title: 'Price',
      field: 'price',
      sorting: false,
      type: 'numeric',
      render: ({ price }) => <p>Tk {price}</p>
    },
    {
      title: 'Quantity',
      field: 'quantity',
      sorting: false,
      type: 'numeric',
      render: ({ quantity }) => <p>{quantity}</p>
    },
    {
      title: 'Pickup Zone',
      field: 'pickup_zone',
      sorting: false,
      editable: 'never',
      render: ({ pickup_zone }) => <p>{pickup_zone}</p>
    },
    {
      title: 'Assigned Pickup Source',
      field: 'assigned_pickup_source',
      sorting: false,
      render: ({ assigned_pickup_source }) => <p>{assigned_pickup_source}</p>
    },
    {
      title: 'Status',
      field: 'status',
      sorting: false,
      render: ({ status }) => <p>{status}</p>
    },
    {
      title: 'Hold Reason',
      field: 'hold_reason',
      sorting: false,
      render: ({ hold_reason }) => <p>{hold_reason}</p>
    },
    {
      title: 'Source Price',
      field: 'source_price',
      sorting: false,
      render: ({ source_price }) => <p>{source_price}</p>
    }
  ];

  return (
    <Page>
      <Header />
      <Container>
        <OrderStats data={stats} getHistory={getHistory} refreshData={getData} />
        <OrdersTable>
          <MaterialTable
            tableRef={tableRef}
            components={{
              Toolbar: (props) => {
                return (
                  <Toolbar
                    open={open}
                    order={stats}
                    handleClose={handleClose}
                    handleClickOpen={handleClickOpen}
                    handleAddProduct={handleAddProduct}
                  />
                );
              }
            }}
            columns={columns}
            data={getData}
            options={{
              columnsButton: true,
              draggable: false,
              sorting: false,
              pageSize: 1000
            }}
            editable={{
              onRowUpdate: (newData, oldData) => handleRowEdit(newData, oldData),
              onRowDelete: (oldData) => handleProdDel(oldData?.id, oldData)
            }}
          />
        </OrdersTable>
      </Container>
    </Page>
  );
};

export default OrderDetails;
