import React from 'react';
// import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CategoryIcon from '@material-ui/icons/Category';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useForm, FormContext } from 'react-hook-form';
import { zones } from 'data/zones';

import AddStore from '../AddStore/AddStore';
import { ToolbarWrapper, Left, Right } from './toolbar.style';
import TableSearch from 'components/TableSearch';
import { ToolbarWrapperButton, ToolbarWrapperTop } from 'pages/Products/Toolbar/toolbar.style';
import SelectController from 'components/select/SelectController';

const Toolbar = ({ open, handleClickOpen, handleClose, handleAddStore, history, tableRef }) => {
  // const history = useHistory();

  const paramObj = history?.location?.state || {};
  const defaultValues = {
    zones: null,
    text: ''
  };

  const methods = useForm({
    defaultValues
  });

  const { formState, handleSubmit, reset } = methods;

  const isDirty = formState.dirty || Object.keys(paramObj).find((param) => Boolean(param) === true);

  const onSubmit = (data) => {
    const body = {};

    if (data?.zones) {
      body.zone = data?.zones?.value;
    }
    if (data?.text) {
      body.search = data?.text;
    }

    history.push({
      state: { ...body }
    });

    tableRef.current.onQueryChange();
  };

  const handleClearFilter = () => {
    reset(defaultValues);
    history.push({
      state: {}
    });
    tableRef.current.onQueryChange();
  };

  return (
    <>
      <ToolbarWrapper>
        <Left>
          {/* <ToolbarTitle>
          <Typography className="title" component="h2">
            Products
          </Typography>
        </ToolbarTitle> */}
          <Button
            variant="contained"
            startIcon={<ShoppingCartIcon />}
            onClick={() => history.push(`products`)}
            style={{ margin: '0 4px' }}
          >
            Products
          </Button>
          <span />
          <Button
            variant="contained"
            startIcon={<CategoryIcon />}
            onClick={() => history.push(`categories`)}
            style={{ margin: '0 4px' }}
          >
            Categories
          </Button>
        </Left>
        <Right>
          {/* <Button variant="contained" color="secondary" onClick={() => history.push(`store/bulkadd`)} style={{margin: "0 4px"}}>
          Bulk Upload
        </Button> */}
          <span />
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            style={{ margin: '0 4px' }}
          >
            Add a Store
          </Button>
        </Right>

        <AddStore open={open} handleClose={handleClose} handleAddStore={handleAddStore} />
      </ToolbarWrapper>
      <FormContext {...methods}>
        <ToolbarWrapperButton>
          <TableSearch value={paramObj?.search || ''} onSubmit={onSubmit} mw={230} />
          <ToolbarWrapperTop onSubmit={handleSubmit(onSubmit)}>
            <SelectController
              isClearable
              label="Delivery Zone"
              name="zones"
              options={zones}
              minWidth={150}
            />

            <Button type="submit" variant="contained" color="primary" disabled={!isDirty}>
              Filter
            </Button>
            <Button
              variant="contained"
              type="reset"
              className="reset-toolbar"
              onClick={handleClearFilter}
              disabled={!isDirty}
              style={{ marginLeft: '5px' }}
            >
              Clear
            </Button>
          </ToolbarWrapperTop>
        </ToolbarWrapperButton>
      </FormContext>
    </>
  );
};

export default Toolbar;
