import { createSlice } from '@reduxjs/toolkit';
import { getProductStatus } from 'repository/ProductStatus';

const initialState = {
  status: []
};

const productStatus = createSlice({
  name: 'productStatus',
  initialState: initialState,
  reducers: {
    get: (state, action) => {
      state.status = action.payload;
    }
  }
});

export const getProductStatusAct = () => {
  return async (dispatch) => {
    try {
      const response = await getProductStatus();
      if (response.status === 200) {
        dispatch(get(response?.data?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const { get } = productStatus.actions;

export default productStatus.reducer;
