import React from 'react';
import Button from '@material-ui/core/Button';
import { ToolbarWrapper, Left, Right } from './toolbar.style';
import AddBrand from '../AddBrand/AddBrand';

const Toolbar = ({ open, handleClickOpen, handleClose, handleAddBrand, history }) => {
  // const history = useHistory();
  return (
    <ToolbarWrapper>
      <Left></Left>
      <Right>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add Brands
        </Button>
      </Right>

      <AddBrand open={open} handleClose={handleClose} handleAddBrand={handleAddBrand} />
    </ToolbarWrapper>
  );
};

export default Toolbar;
