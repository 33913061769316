import styled from 'styled-components';

export const SearchWrapper = styled.div`
  min-width: 350px;
  max-width: 400px;
`;

export const FormControl = styled.div`
  margin-bottom: 16px;
  label {
    display: block;
  }
  .MuiInputBase-input {
    padding: 8.5px 14px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 11px) scale(1);
    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
`;
