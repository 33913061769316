import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@material-ui/icons/Save';

const TextInput = ({ name, defaultValue, onSave }) => {
  const [value, setValue] = useState(defaultValue || '');
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (value) {
      onSave({ [name]: value });
    }
  };
  return (
    <TextField
      placeholder="Admin Comment"
      variant="outlined"
      value={value}
      multiline
      rowsMax={3}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleSave}>
              <SaveIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default TextInput;
