import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

import Rgba from 'utils/Rgba';
import Select from 'components/select/Select';

const useStyles = makeStyles({
  reactSelect: ({ minWidth, fullWidth }) => ({
    minWidth: minWidth ?? 200,
    maxWidth: fullWidth ? '100%' : 230
  }),
  selectLabel: {
    color: Rgba('#4A4A4A', 0.6),
    fontSize: 14,
    lineHeight: '12px',
    margin: '0 0 6px 8px'
  },
  selectBox: {
    '& .sh__control': {
      border: '1px solid #DDDBE1'
    }
  }
});

const SelectController = ({
  name,
  label,
  selectProps,
  placeholder,
  isMulti,
  fullWidth,
  minWidth,
  className,
  options,
  ...props
}) => {
  const classes = useStyles({ minWidth, fullWidth });
  const { control } = useFormContext();

  return (
    <div className={clsx(classes.reactSelect, className)}>
      {label && <p className={classes.selectLabel}>{label}</p>}
      <Controller
        as={<Select options={options} {...selectProps} />}
        name={name}
        classNamePrefix="sh"
        control={control}
        {...props}
      />
    </div>
  );
};
export default SelectController;
