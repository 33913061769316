import React, { useEffect, useState } from 'react';
import Container from 'components/Container';
import Header from 'components/Header';

import EditFormWrapper, { Page } from './DMEditForm.style';

import { Button, Chip, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getDmById, getDms, newUpdateDm, selectors, updateDm } from 'redux/dms';
import { useHistory, useParams } from 'react-router-dom';
import CustomAreaSelect from './CustomAreaSelect';
import CustomeZoneSelect from './CustomZoneSelect';
import SuperFetch from 'api/SuperFetch';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1.5, 0),
      width: 'inherit'
    }
  }
}));

const DMEditForm = () => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const dmsData = useSelector(selectors.getDmsMap);
  const authToken = useSelector((state) => state.auth.token);
  const id = params.id;
  const [zone, setZone] = useState('');

  const [state, setState] = useState({
    is_active: true,
    name: '',
    phone: ''
  });

  const handleChange = (name) => (event) => {
    event.persist();
    if (name === 'is_active') setState((prev) => ({ ...prev, is_active: event.target.checked }));
    else setState((prev) => ({ ...prev, [name]: event.target.value }));
  };

  // useEffect(() => {
  //   if (dmsData?.[id]) {
  //     const { is_active, name, phone, area } = dmsData[id];
  //     console.log('area', area.zone);
  //     setState({ is_active, name, phone });
  //     setZone(area);
  //   } else {
  //     dispatch(getDms({ page: 1, limit: 99999 }));
  //     // console.log('im here')
  //   }
  // }, [dmsData, setState, id, dispatch]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        await getDmById(id);
      };

      fetchData();
    }
  }, [setState, id]);

  const getDmById = async (id) => {
    try {
      const response = await SuperFetch(`dms/id/${id}`);
      if (response.status === 200) {
        const { is_active, name, phone, current_zone } = response.data;
        setState({ is_active, name, phone, current_zone });
      } else {
        throw new Error('Cannot get dm');
      }
    } catch (error) {
      return {
        error: true
      };
    }
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   await dispatch(updateDm(id, { ...state, area_id: zone?.id }));
  //   history.push('/dms');
  // };

  const handleUpdate = async (e) => {
    e.preventDefault();
    await dispatch(newUpdateDm(id, authToken, { ...state, current_zone: zone?.zone }));
    history.push('/dms');
  };

  return (
    <Page>
      <Header />
      <Container>
        <EditFormWrapper>
          <Typography align="center" variant="h5" component="h1">
            Edit DM 🏍️
          </Typography>

          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleUpdate}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.is_active}
                  onChange={handleChange('is_active')}
                  name="is_active"
                  color="primary"
                />
              }
              label="Active"
            />
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              value={state.name}
              onChange={handleChange('name')}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="phone"
              label="Phone Number"
              type="tel"
              value={state.phone}
              onChange={handleChange('phone')}
            />
            {/* <CustomAreaSelect
              value={zone}
              onChange={(value) => {
                console.log(value);
                setZone(value);
              }}
            /> */}

            <CustomeZoneSelect
              value={state?.current_zone}
              onChange={(value) => {
                setZone(value);
              }}
            />

            {zone?.areas?.length && (
              <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                {zone.areas.map((area, index) => (
                  <Chip label={area} key={index} />
                ))}
              </div>
            )}

            <Button variant="contained" color="primary" size="large" type="submit">
              Save
            </Button>
          </form>
        </EditFormWrapper>
      </Container>
    </Page>
  );
};

export default DMEditForm;
