import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

class Orders {
  get(params, token) {
    return SuperFetch(`checkout?${querystring.stringify(params, { arrayFormat: 'comma' })}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  getDetails(id, token) {
    return SuperFetch(`checkout/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  getCustomerHistory(number, token) {
    return SuperFetch(`checkout/history/${number}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  getStatusInfo(token) {
    return SuperFetch('checkout/status-info', {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  async edit(id, data = {}) {
    return SuperFetch(`checkout/${id}`, {
      method: 'PATCH',
      data
    });
  }

  async updateCheckout(id, token,data = {}) {

    let key = Object.keys(data)[0];
    if ((key === 'discount')) {
      let payload = {
        op: 'replace',
        path: `/discount`,
        value: data.discount
      };
      return SuperFetch(`checkout/${id}`, {
        method: 'PATCH',
        data : [{...payload}],
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        }
      });
    } else {
      return SuperFetch(`checkout/update/${id}`, {
        method: 'PUT',
        data,
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        }
      });
    }
  }

  async bulkUpdate(data) {
    return SuperFetch(`checkout/bulk`, {
      method: 'PUT',
      data
    });
  }
}

export default new Orders();
