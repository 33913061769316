import React, { useState, useEffect } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Select from 'components/select/Select';
import { updateCheckout } from 'redux/orders';
import AsyncSelect from 'components/select/AsyncSelect';
import { StatsWrapper, StatGroup, Span } from './orderStats.style';
import NumberInput from './NumberInput';

import { selectors } from 'redux/orderDetails';
import moment from 'moment';

import DmRepo from 'repository/Dm';
import formatDate from 'utils/formatDate';
import { orderHoldReasons } from 'data/orderHoldReasons';
import useStatusSelect from 'hooks/useStatusSelect';
import TextInput from 'pages/Home/OrderDetailPane/TextInput';
import DateTime from './DateTime';

const OrderStats = ({ data, getHistory, refreshData = () => {console.log('refresh data called')} }) => {
  const dispatch = useDispatch();
  const [closeState, setCloseState] = useState(true);
  const [selectStates, setSelectStates] = useState({});
  const authToken = useSelector((state) => state.auth.token);

  const customer = useSelector(selectors.getHistory);

  useEffect(() => {
    setSelectStates({
      discount: data?.discount,
      del_fee: data?.del_fee,
      service_charge: data?.service_charge,
      current_status: data?.status,
      total_collected: data?.total_collected,
      hub_collection_status: data?.hub_collection,
      hold_reason: { value: data?.hold_reason, label: data?.hold_reason },
      delivery_man_id: {
        value: data?.delivery_man?.id,
        label: data?.delivery_man?.name
      },
      admin_comment: data?.admin_comment
    });
    if (data?.customer_phone) {
      getHistory(data?.customer_phone);
    }
  }, [data, getHistory]);

  // console.log(selectStates);

  const handleOrderUpdate = async (id, body) => {
    // console.log(body);
    await dispatch(updateCheckout(id,authToken,body));
    refreshData();
  };

  const updateOrder = (body) => {
    handleOrderUpdate(data.id, body);
  };

  const handleCloseState = () => {
    setCloseState(!closeState);
  };

  const handleStatusSelect = (newValue) => {
    setSelectStates({
      ...selectStates,
      current_status: newValue
    });
    updateOrder({ current_status: newValue?.value });
  };

  const handleHoldSelect = (newValue) => {
    setSelectStates({
      ...selectStates,
      hold_reason: newValue
    });
    updateOrder({ hold_reason: newValue?.value ?? null });
  };

  const handleHmCollection = (newValue) => {
    setSelectStates({
      ...selectStates,
      hub_collection_status: newValue
    });
    updateOrder({ hub_collection_status: newValue?.value });
  };

  const statusOptionsAsync = useStatusSelect();

  const handleDeliveryDate = (date) => {
    updateOrder({ delivery_date: formatDate(date, 'YYYY-MM-DD') });
  };
  const promiseOptions = async (input) => {
    const res = await DmRepo.getAll({ search: input });
    const data = res?.data?.data?.map((item) => ({ value: item.id, label: item.name }));
    return data;
  };
  const handleDmSelect = (newValue) => {
    setSelectStates({
      ...selectStates,
      delivery_man_id: newValue
    });
    updateOrder({ delivery_man_id: newValue?.value ?? null });
  };

  // console.log('datadata: ', data);
  // console.log(selectStates?.total_collected);

  return (
    <StatsWrapper>
      <FormControlLabel
        label="Show"
        control={<Switch checked={closeState} onChange={handleCloseState} />}
        className="closeState"
      />
      <Typography component="h2" className="title">
        Order Stats
      </Typography>
      {closeState && (
        <StatGroup>
          <ul>
            <li>
              <span>Order ID</span>
              <span>{data?.id}</span>
            </li>
            <li>
              <span>Assigned Delivery Station</span>
              <span>{data?.area?.station}</span>
            </li>
            <li>
              <span>Delivery Zone</span>
              <span>{data?.area?.zone}</span>
            </li>
            <li>
              <span>Current Status</span>
              <span>{data?.status?.label}</span>
            </li>
            <li>
              <span>Customer Name</span>
              <span>{data?.customer_name}</span>
            </li>
            <li>
              <span>Customer Area</span>
              <span>{data?.area?.area}</span>
            </li>
            <li>
              <span>Customer Phone</span>
              <span>{data?.customer_phone}</span>
            </li>
            <li>
              <span>Full Address</span>
              <span>{data?.full_address}</span>
            </li>
            <li>
              <span>Delivery Type</span>
              {<span>{data?.delivery_type}</span>}
            </li>
          </ul>
          <ul>
            <li>
              <span>Delivery Fee</span>
              {/* {<span>{data?.del_fee}</span>} */}
              <NumberInput
                  name="del_fee"
                  defaultValue={selectStates?.del_fee || 0}
                  onSave={updateOrder}
                />
            </li>
            <li>
              <span>Discount</span>
              {/* <span>{data?.discount}</span> */}
              <Span>
                <NumberInput
                  name="discount"
                  defaultValue={selectStates?.discount || 0}
                  onSave={updateOrder}
                />
              </Span>
            </li>
            <li>
              <span>Processing Fee</span>
              <span>{data?.processing_fee}</span>
            </li>
            <li>
              <span>Service Charge</span>
              <span>{data?.service_charge}</span>
              {/* <NumberInput
                  name="service_charge"
                  defaultValue={selectStates?.service_charge || 0}
                  onSave={updateOrder}
                /> */}
            </li>
            <li>
              <span>Vat</span>
              <span>{data?.vat}</span>
            </li>
            <li>
              <span>Total Cart Price</span>
              <span>{data?.cart_price}</span>
            </li>
            <li>
              <span>Total Payable</span>
              <span>{data?.order_price}</span>
            </li>
            <li>
              <span>Coupon Code</span>
              <span>{data?.coupon_code}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span>Order Status</span>
              <Span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={statusOptionsAsync}
                  value={selectStates.current_status}
                  onChange={handleStatusSelect}
                  className="dhSelect"
                  classNamePrefix="dh"
                  style={{ minWidth: 240 }}
                />
              </Span>
            </li>
            <li>
              <span>Hold Reason</span>
              <Span>
                <Select
                  className="dhSelect"
                  options={orderHoldReasons}
                  classNamePrefix="dh"
                  value={selectStates.hold_reason}
                  style={{ minWidth: 240 }}
                  onChange={handleHoldSelect}
                  isClearable
                />
              </Span>
            </li>
            <li>
              <span>Admin Comment</span>
              <Span>
                <TextInput
                  name="admin_comment"
                  defaultValue={selectStates.admin_comment}
                  key={selectStates.admin_comment}
                  onSave={updateOrder}
                />
              </Span>
            </li>
            <li>
              <span>Total Collected </span>
              <Span>
                <NumberInput
                  name="total_collected"
                  defaultValue={selectStates?.total_collected || 0}
                  onSave={updateOrder}
                />
              </Span>
            </li>
            <li>
              <span>Collection Status </span>
              <Span>
                <Select
                  className="dhSelect"
                  classNamePrefix="dh"
                  style={{ minWidth: 240 }}
                  onChange={handleHmCollection}
                  value={selectStates.hub_collection_status}
                  options={[
                    { value: 'PENDING', label: 'Pending' },
                    { value: 'HM_COLLECTED', label: 'Collected By HM' }
                  ]}
                />
              </Span>
            </li>
            <li>
              <span>Assigned Agent</span>
              <Span>
                <AsyncSelect
                  loadOptions={promiseOptions}
                  cacheOptions
                  defaultOptions
                  value={selectStates.delivery_man_id}
                  onChange={handleDmSelect}
                  className="dhSelect"
                  classNamePrefix="dh"
                  style={{ minWidth: 240 }}
                  isClearable
                />
              </Span>
            </li>
            {/* <li>
              <span>Delivery Date</span>
              <Span>
                <DatePicker
                  autoOk
                  clearable
                  value={data?.delivery_date}
                  id="delivery_date"
                  format="YYYY-MM-DD"
                  name="delivery_date"
                  className="input-field"
                  animateYearScrolling
                  inputVariant="outlined"
                  onChange={handleDeliveryDate}
                />
              </Span>
            </li> */}
            <li>
              <span>Delivery time slot</span>
              <Span>
                  <DateTime id={data?.id} slot={data?.delivery_slot} />
              </Span>
            </li>
            {/* <li>
              <span>Assigned DM Date&Time</span>
              <Span>
                {moment(data?.delivery_man?.created_at).format('hh:mm:ss A')} <br />
                {formatDate(data?.delivery_man?.created_at)}
              </Span>
            </li> */}
            <li>
              <span>Additional Request</span>
              <Span>{data?.additional_req}</Span>
            </li>
          </ul>
        </StatGroup>
      )}
      {closeState && customer?.historyData && (
        <>
          <Typography component="h2" className="title">
            Customer History
          </Typography>
          <StatGroup>
            <ul>
              <li>
                <span>Total Orders</span>
                <span>{customer?.historyData?.total_orders}</span>
              </li>
              <li>
                <span>Current Week</span>
                <span>{customer?.historyData?.current_week}</span>
              </li>
              <li>
                <span>Last Week</span>
                <span>{customer?.historyData?.last_week}</span>
              </li>
              <li>
                <span>Current Month</span>
                <span>{customer?.historyData?.current_month}</span>
              </li>
              <li>
                <span>Last Month</span>
                <span>{customer?.historyData?.last_month}</span>
              </li>
              <li>
                <span>Last Order At</span>
                <span>
                  {moment(customer?.historyData?.last_order_date).format(
                    'dddd, MMMM Do YYYY, h:mm:ss a'
                  )}
                </span>
              </li>
            </ul>
          </StatGroup>
        </>
      )}
    </StatsWrapper>
  );
};

export default OrderStats;
