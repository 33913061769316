import { createSelector, createSlice } from '@reduxjs/toolkit';

import Category from 'repository/Category';
import { enqueueSnackbar } from './notifications';

const categories = createSlice({
  name: 'categories',
  initialState: {
    loading: false,
    data: {
      data: [],
      count: 0,
      page: 1,
      limit: 100
    },
    error: null
  },
  reducers: {
    get: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    done: (state) => {
      state.loading = false;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    }
  }
});

export const selectors = {
  getCategories: createSelector(
    (state) => state.categories,
    (data) => data.data
  )
};

export const { actions } = categories;

export function getCategories(params) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Category.getCategories(params);
      if (response.status === 200) {
        dispatch(actions.success(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Categories list');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function getDetails(id) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Category.getDetails(id);
      if (response.status === 200) {
        dispatch(actions.done(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Category');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function updateCategories(body) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Category.edit(body.id, body.data);
      // console.log('response', response);
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Category updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(actions.done());
        return { success: true };
      } else {
        throw new Error('Cannot edit Category');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Category update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}

export function addCategory(body, thumb) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Category.add(body);
      console.log(response);
      // console.log('response', response);
      if (response.status === 201) {
        if (thumb?.length) {
          // console.log(thumb,"thumb");
          const thumbRes = await Category.addThumb(response?.data?.id, thumb);
          console.log(thumbRes);

          if (thumbRes.status === 200) {
            dispatch(
              enqueueSnackbar({
                message: 'Category added successfully',
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: 'success'
                }
              })
            );
            dispatch(actions.done());
            return { success: true };
          } else {
            throw new Error('Cannot add image');
          }
        }
      } else {
        throw new Error('Cannot add Category');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Category addition failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return { error: true };
    }
  };
}

export function editCatThumb(id, thumb) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      let thumbs = [];
      thumbs.push(thumb[0]?.file || thumb[0]);
      const response = await Category.addThumb(id, thumbs);
      console.log('response', thumb);
      if (response.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'Category thumbline updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(actions.done());
        return { success: true };
      } else {
        throw new Error('Cannot edit category thumb');
      }
    } catch (error) {
      dispatch(actions.failed(error));
      dispatch(
        enqueueSnackbar({
          message: 'Category thumbline updated failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return {
        error: true
      };
    }
  };
}

export function deleteCategory(id) {
  return async (dispatch) => {
    try {
      const response = await Category.delete(id);
      dispatch(
        enqueueSnackbar({
          message: 'Category deleted successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      );
      console.log(response);
      return { success: true };
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'Category remove failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      return { error: true };
    }
  };
}


export default categories.reducer;
