export const orderHoldReasons = [
  // New Hold Reasons
  { value: 'REJECTED_PARTIAL_OUT_OF_STOCK', label: 'Partial / Out of Stock Rejected' },
  { value: 'CUSTOMER_RESCHEDULED', label: 'Customer - Rescheduled' },
  { value: 'CUSTOMER_CNR', label: 'Customer - Call not Received' },
  { value: 'UNFORSEEN_EVENT', label: 'Natural/Unforeseen Events (Rain, Blockage, etc.)' },
  { value: 'HIGH_DEMAND', label: 'High Demand Workload' },
  { value: 'CANCEL_PRODUCT_DAMAGED', label: 'Cancel / P.Cancel Accepted - Damaged Products' },
  { value: 'CANCEL_PRODUCT_WRONG', label: 'Cancel / P.Cancel Accepted - Wrong Products' },
  { value: 'CANCEL_PRODUCT_EXPIRED', label: 'Cancel / P.Cancel Accepted - Expired Products' },
  { value: 'CANCEL_PRODUCT_FAULTY', label: 'Cancel / P.Cancel Accepted - Faulty Products' },
  { value: 'CANCEL_DELIVERY_LATE', label: 'Cancel / P.Cancel Accepted - Late Delivery' },
  { value: 'Customer doesn’t want products', label: 'Customer doesn’t want products' },
  { value: 'DM not Available', label: 'DM not Available' },
  // Old Hold Reasons
  // { isDisabled: true, value: 'Call not Received', label: 'Call not Received' },
  // { isDisabled: true, value: 'Ongoing Product Sourcing', label: 'Ongoing Product Sourcing' },
  // {
  //   isDisabled: true,
  //   value: 'Customer does not want product',
  //   label: 'Customer does not want product'
  // },
  // {
  //   isDisabled: true,
  //   value: 'Late Delivery - Cancellation',
  //   label: 'Late Delivery - Cancellation'
  // },
  // {
  //   isDisabled: true,
  //   value: 'Product Damaged - Cancellation',
  //   label: 'Product Damaged - Cancellation'
  // },
  // {
  //   isDisabled: true,
  //   value: 'Poor Product Quality - Cancellation',
  //   label: 'Poor Product Quality - Cancellation'
  // },
  // {
  //   isDisabled: true,
  //   value: 'Partial Cancel Accepted - Could not find products',
  //   label: 'Partial Cancel Accepted - Could not find products'
  // },
  // {
  //   isDisabled: true,
  //   value: 'Partial Cancel Accepted - Damaged Products',
  //   label: 'Partial Cancel Accepted - Damaged Products'
  // },
  // {
  //   isDisabled: true,
  //   value: 'Partial Cancel Accepted - Wrong Products',
  //   label: 'Partial Cancel Accepted - Wrong Products'
  // },
  // {
  //   isDisabled: true,
  //   value: 'Partial Cancel Accepted - Expired Products',
  //   label: 'Partial Cancel Accepted - Expired Products'
  // },
  // { isDisabled: true, value: 'Wants in different time', label: 'Wants in different time' },
  // { isDisabled: true, value: 'Could not source products', label: 'Could not source products' },
  { value: 'Out of coverage area', label: 'Out of coverage area' }
  // {
  //   isDisabled: true,
  //   value: 'Partial Stock / Out of Stock',
  //   label: 'Partial Stock / Out of Stock'
  // },
  // {
  //   isDisabled: true,
  //   value: 'Stock confirmed for tomorrow',
  //   label: 'Stock confirmed for tomorrow'
  // },
  // {
  //   isDisabled: true,
  //   value: 'Stock needs to confirmed for tomorro',
  //   label: 'Stock needs to confirmed for tomorrow'
  // }
];
