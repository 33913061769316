import React from 'react';

const Funnel = props => (
  <svg
    width={props.width ? props.width : 17}
    height={props.height ? props.height : 15}
    viewBox="0 0 17 15"
    fill="none"
    {...props}
  >
    <path
      d="M16 1H1l6 6.832v4.724L10 14V7.832L16 1z"
      fill="#fff"
      stroke={props.stroke ? props.stroke : '#979797'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Funnel;
