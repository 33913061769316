import { createSelector, createSlice } from '@reduxjs/toolkit';

import Dm from 'repository/Dm';
import { enqueueSnackbar } from './notifications';

const dms = createSlice({
  name: 'dms',
  initialState: {
    loading: false,
    data: {
      data: [],
      count: 0,
      page: 1,
      limit: 100
    },
    map: {},
    error: null
  },
  reducers: {
    get: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.map = []
        .concat(action.payload.data || [])
        .reduce((acc, row) => ({ ...acc, [row.id]: row }), {});
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    }
  }
});

export const selectors = {
  getDms: createSelector(
    (state) => state.dms,
    (data) => data
  ),
  getDmsMap: createSelector([(state) => state.dms.map], (data) => data)
};

export function getDms(params) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Dm.getAll(params);
      if (response.status === 200) {
        dispatch(actions.success(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get dms list');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function updateDm(id, body) {
  return async (dispatch) => {
    try {
      const response = await Dm.updateDm(id, body);
      dispatch(
        enqueueSnackbar({
          message: 'DM updated successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      );
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'DM update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export function newUpdateDm(id, token, body) {
  return async (dispatch) => {
    try {
      const response = await Dm.newUpdateDm(id, token, body);
      dispatch(
        enqueueSnackbar({
          message: 'DM updated successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      );
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'DM update failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export function addDm(body) {
  return async (dispatch) => {
    try {
      const response = await Dm.addDm(body);
      dispatch(
        enqueueSnackbar({
          message: 'DM added successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      );
      console.log(response);
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueSnackbar({
          message: 'DM addition failed',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
    }
  };
}

export const { actions } = dms;

export default dms.reducer;
