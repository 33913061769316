import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Select from 'components/select/AsyncSelect';
import Areas from 'repository/CustomAreas';
import Rgba from 'utils/Rgba';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  reactSelect: ({ minWidth, fullWidth }) => ({
    minWidth: minWidth ?? 200,
    maxWidth: fullWidth ? '100%' : 230
  }),
  selectLabel: {
    color: Rgba('#4A4A4A', 0.6),
    fontSize: 14,
    lineHeight: '12px',
    margin: '0 0 6px 8px'
  },
  selectBox: {
    '& .sh__control': {
      border: '1px solid #DDDBE1'
    }
  }
});

const AreaSelect = ({ label, onChange, value }) => {
  const promiseOptions = async (inputValue) => {
    const res = await Areas.getAll(Object.assign(inputValue ? { search: inputValue } : {}));
    const resObj = res.data.data.map((item) => ({
      id: item.id,
      label: item.area,
      zone: item.zone,
      station: item.station,
      is_active: item.is_active,
      is_available: item.is_available
    }));
    // console.log(resObj);

    return resObj;
  };

  const classes = useStyles();

  return (
    <div className={classes.reactSelect}>
      {label && <p className={classes.selectLabel}>{label}</p>}
      <Select
        onChange={onChange}
        defaultValue={value}
        loadOptions={promiseOptions}
        className={classes.selectBox}
        placeholder={value ? value?.area : "Search area here"}
      />
    </div>
  );
};

export default AreaSelect;
