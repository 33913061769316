import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { useFormContext, Controller } from 'react-hook-form';

import { PickerWrapper, PickerLabel } from './datePicker.style';

const MuiDatePicker = ({ id, label, isISO, name, ...props }) => {
  const { control } = useFormContext();
  return (
    <PickerWrapper>
      {label && <PickerLabel htmlFor={id}>{label}</PickerLabel>}
      <Controller
        as={<DatePicker />}
        autoOk
        id={id}
        clearable
        name={name}
        control={control}
        format="YYYY-MM-DD"
        animateYearScrolling
        inputVariant="outlined"
        placeholder="Select.."
        {...props}
      />
    </PickerWrapper>
  );
};

export default MuiDatePicker;
