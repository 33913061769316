import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { ToolbarTitle } from 'components/MaterialTable/materialTable.style';
import AddProduct from '../AddProduct/AddProduct';
import { ToolbarWrapper, Left, Right } from './toolbar.style';

const Toolbar = ({ open, order, handleClickOpen, handleClose, handleAddProduct }) => {
  const history = useHistory();
  return (
    <ToolbarWrapper>
      <Left>
        <ToolbarTitle>
          <IconButton onClick={() => history.goBack()}>
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography className="title" component="h2">
            Order Details 📦
          </Typography>
        </ToolbarTitle>
      </Left>
      <Right>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add Product
        </Button>
      </Right>

      <AddProduct
        open={open}
        order={order}
        handleClose={handleClose}
        handleAddProduct={handleAddProduct}
      />
    </ToolbarWrapper>
  );
};

export default Toolbar;
