import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';

import Orders from '../repository/VoiceOrders';
import Products from 'repository/VoiceProducts';

export const addProduct = createAsyncThunk('voiceOrderDetails/addProduct', async (body) => {
  const response = await Products.addProduct(body);

  return response.data;
});

export const editProduct = createAsyncThunk(
  'voiceOrderDetails/editProduct',
  async ({ id, data }) => {
    const response = await Products.editProduct(id, data);

    return response.data;
  }
);

const voiceOrderDetails = createSlice({
  name: 'voiceOrderDetails',
  initialState: {
    zone: 'DH-Z1',
    loading: false,
    data: {},
    error: null,
    loadingHistory: false,
    loadingStatus: false,
    historyData: {},
    vpcOrderList: [],
    vpcOrderListIds: [],
    newVpcOrderListIds: [],
    orderStatusData: {}
  },
  reducers: {
    get: (state, action) => {
      state.loading = true;
    },
    changeZone: (state, action) => {
      state.loading = false;
      state.zone = action.payload;
    },
    pushVpcItems: (state, action) => {
      state.loading = false;
      state.vpcOrderList.push(action.payload);
    },
    removeVpcItemsByIndex: (state, action) => {
      state.loading = false;
      state.vpcOrderList.splice(action.payload, 1);
      state.vpcOrderListIds.splice(action.payload, 1);
      state.newVpcOrderListIds.splice(state.newVpcOrderListIds.indexOf(state.vpcOrderListIds[action.payload]), 1);
    },
    pushVpcItemsIds: (state, action) => {
      state.loading = false;
      state.vpcOrderListIds.push(action.payload);
    },
    pushNewVpcItemsIds: (state, action) => {
      state.loading = false;
      state.newVpcOrderListIds.push(action.payload);
    },
    clearVpcItems: (state) => {
      state.vpcOrderList = [];
      state.vpcOrderListIds = [];
      state.newVpcOrderListIds = [];
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    getHistory: (state, action) => {
      state.loadingHistory = true;
    },
    successHistory: (state, action) => {
      state.loadingHistory = false;
      state.historyData = action.payload;
    },
    failedHistory: (state, action) => {
      state.loadingHistory = false;
      state.error = action.payload.error;
    },
    getStatus: (state, action) => {
      state.loadingStatus = true;
    },
    successStatus: (state, action) => {
      state.loadingStatus = false;
      state.orderStatusData = action.payload;
    },
    failedStatus: (state, action) => {
      state.loadingStatus = false;
      state.error = action.payload.error;
    }
  }
});

export const selectors = {
  getOrderDetails: createSelector(
    (state) => state.orderDetails,
    (data) => data
  ),
  getHistory: createSelector(
    (state) => state.orderDetails,
    (historyData) => historyData
  ),
  getStatus: createSelector(
    (state) => state.orderDetails,
    (orderStatusData) => orderStatusData
  )
};

export function getOrderDetails(id, token) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Orders.getDetails(id, token);
      if (response.status === 200) {
        dispatch(actions.clearVpcItems());
        dispatch(actions.success(response.data));
        for (let i = 0; i < response.data?.vpcOrderItems?.length; i++) {
          dispatch(actions.pushVpcItems(response.data?.vpcOrderItems[i]));
          dispatch(actions.pushVpcItemsIds(response.data?.vpcOrderItems[i]?.id));
        }
        if (response.data?.address) {
          dispatch(actions.changeZone(response.data?.address?.zone));
        }
        if (response.data?.address === null || response.data?.address?.zone === 'DH-Z42069') {
          dispatch(actions.changeZone('DH-Z1'));
        }
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Details');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function getCustomerHistory(number) {
  return async (dispatch) => {
    dispatch(actions.getHistory());
    try {
      const response = await Orders.getCustomerHistory(number);
      if (response.status === 200) {
        dispatch(actions.successHistory(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failedHistory(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get History');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failedHistory(error));
      return {
        error: true
      };
    }
  };
}

export function getOrderStatus() {
  return async (dispatch) => {
    dispatch(actions.getStatus());
    try {
      const response = await Orders.getStatusInfo();
      if (response.status === 200) {
        dispatch(actions.successStatus(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failedStatus(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Status');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failedStatus(error));
      return {
        error: true
      };
    }
  };
}

export const { actions } = voiceOrderDetails;

export default voiceOrderDetails.reducer;

export const {
  changeZone,
  pushVpcItems,
  clearVpcItems,
  pushVpcItemsIds,
  pushNewVpcItemsIds,
  removeVpcItemsByIndex
} = voiceOrderDetails.actions;
