import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

class Areas {
  getAll(query) {
    return SuperFetch(`areas?${querystring.stringify(query)}`);
  }
}

export default new Areas();
