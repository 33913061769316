import { wooApi } from 'api/config';
import SuperFetch from 'api/SuperFetch';
import querystring from 'query-string';

export default {
  fetchProducts: async (query) => {
    try {
      const res = await wooApi.get('products', {
        per_page: 20,
        ...query
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  fetchCategory: async (query) => {
    try {
      const res = await wooApi.get('products/categories', {
        exclude: 15,
        ...query
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  addProduct: (id, data) => {
    return SuperFetch(`voice-pre-checkout/${id}`, {
      method: 'PUT',
      data
    });
  },

  editProduct: (id, data) => {
    return SuperFetch(`items/${id}`, {
      method: 'PATCH',
      data
    });
  },

  deleteProduct: (id) => {
    return SuperFetch(`items/${id}`, {
      method: 'DELETE'
    });
  },


  get(params) {
    // console.log(params)
    return SuperFetch(`product?${querystring.stringify(params, { arrayFormat: 'comma' })}`);
  },

  getDetails(id) {
    return SuperFetch(`product/${id}`);
  },

  add: (data) => {
    return SuperFetch(`product`, {
      method: 'POST',
      data
    });
  },

  bulkAdd: (file) => {
    var bodyFormData = new FormData();

    // Display the values
    bodyFormData.append('file', file);

    return SuperFetch(`product/bulk`, {
      method: 'POST',
      data: bodyFormData,
      headers: {
        'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        // 'X-Requested-With': 'XMLHttpRequest'
      }
    });
  },

  edit: (id, data) => {
    return SuperFetch(`product/${id}`, {
      method: 'PUT',
      data
    });
  },

  delete: (id) => {
    return SuperFetch(`product/${id}`, {
      method: 'DELETE'
    });
  },

  getCategories: async (params) => {
    // return SuperFetch(`category?${querystring.stringify(params, { arrayFormat: 'comma' })}`);
    return SuperFetch(`category?limit=120&page=1&${querystring.stringify(params, { arrayFormat: 'comma' })}`); //return SuperFetch(`category?limit=120&page=1&cat_tree=true`);
  },

  addThumb: (id, thumb) => {

    var bodyFormData = new FormData();

    // console.log(thumb, "api");

    // Display the values
    bodyFormData.append('image', thumb[0]);

    return SuperFetch(`product/image/${id}`, {
      method: 'PUT',
      data: bodyFormData,
      headers: {
        'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        // 'X-Requested-With': 'XMLHttpRequest'
      }
    });
  },

  addGallery: (id, images) => {

    var bodyFormData = new FormData();

    // console.log(images, "api");

    // Display the values
    for(let i = 0; i < images?.length; i++) {
      bodyFormData.append(images[i]?.name, images[i]);
    }

    return SuperFetch(`product/gallery/${id}`, {
      method: 'PUT',
      data: bodyFormData,
      headers: {
        'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        // 'X-Requested-With': 'XMLHttpRequest'
      }
    });
  },

};
