import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from './notifications';

import Orders from '../repository/Orders';
import Products from 'repository/Products';

export const addProduct = createAsyncThunk('orderDetails/addProduct', async (body, dispatch) => {
  const response = await Products.addProduct(body);
  if (response.status === 201) {
    console.log('im here');
    dispatch(
      enqueueSnackbar({
        message: 'Product added successfully',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success'
        }
      })
    );
  }

  return response.data;
});

export const editProduct = createAsyncThunk('orderDetails/editProduct', async ({ id, data }) => {
  const response = await Products.editProduct(id, data);

  return response.data;
});

const orderDetails = createSlice({
  name: 'orderDetails',
  initialState: {
    loading: false,
    data: {},
    error: null,
    loadingHistory: false,
    loadingStatus: false,
    historyData: {},
    orderStatusData: {},
  },
  reducers: {
    get: (state, action) => {
      state.loading = true;
    },
    success: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    getHistory: (state, action) => {
      state.loadingHistory = true;
    },
    successHistory: (state, action) => {
      state.loadingHistory = false;
      state.historyData = action.payload;
    },
    failedHistory: (state, action) => {
      state.loadingHistory = false;
      state.error = action.payload.error;
    },
    getStatus: (state, action) => {
      state.loadingStatus = true;
    },
    successStatus: (state, action) => {
      state.loadingStatus = false;
      state.orderStatusData = action.payload;
    },
    failedStatus: (state, action) => {
      state.loadingStatus = false;
      state.error = action.payload.error;
    },
  }
});

export const selectors = {
  getOrderDetails: createSelector(
    (state) => state.orderDetails,
    (data) => data
  ),
  getHistory: createSelector(
    (state) => state.orderDetails,
    (historyData) => historyData
  ),
  getStatus: createSelector(
    (state) => state.orderDetails,
    (orderStatusData) => orderStatusData
  )
};

export function getOrderDetails(id, token) {
  return async (dispatch) => {
    dispatch(actions.get());
    try {
      const response = await Orders.getDetails(id, token);
      if (response.status === 200) {
        dispatch(actions.success(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failed(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Details');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failed(error));
      return {
        error: true
      };
    }
  };
}

export function getCustomerHistory(number, token) {
  return async (dispatch) => {
    dispatch(actions.getHistory());
    try {
      const response = await Orders.getCustomerHistory(number, token);
      if (response.status === 200) {
        dispatch(actions.successHistory(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failedHistory(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get History');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failedHistory(error));
      return {
        error: true
      };
    }
  };
}

export function getOrderStatus(token) {
  return async (dispatch) => {
    dispatch(actions.getStatus());
    try {
      const response = await Orders.getStatusInfo(token);
      if (response.status === 200) {
        dispatch(actions.successStatus(response.data));
        return response.data;
      } else if (response.status === 204) {
        dispatch(actions.failedStatus(response));
        return {
          error: true
        };
      } else {
        throw new Error('Cannot get Status');
      }
    } catch (error) {
      console.log(error);
      dispatch(actions.failedStatus(error));
      return {
        error: true
      };
    }
  };
}

export const { actions } = orderDetails;

export default orderDetails.reducer;
