import React from 'react';
import moment from 'moment';
import { Font, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import {
  Table,
  TableHeader,
  TableCell,
  DataTableCell,
  TableBody
} from '@david.kucsai/react-pdf-table';

import roboto from 'assets/fonts/Roboto-Regular.ttf';
import robotobold from 'assets/fonts/Roboto-Bold.ttf';
import notoFont from 'assets/fonts/noto-sans.ttf';

Font.register(
  {
    family: 'Noto',
    fonts: [{ src: notoFont }, { src: notoFont, fontWeight: 'bold' }]
  },
  {
    family: 'Roboto',
    fonts: [{ src: roboto }, { src: robotobold, fontWeight: 'bold' }]
  }
);

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: '1cm'
  },
  header: {
    padding: '5pt 0',
    paddingBottom: 10,
    borderBottom: '2pt solid gold'
  },
  header__title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    margin: '5pt 0'
  },
  small_text: {
    fontSize: 12,
    fontFamily: 'Noto',
    margin: '5pt 0'
  },
  table_header: {
    backgroundColor: 'black',
    color: 'white',
    padding: '4pt 1pt'
  },
  table_cell: { fontSize: 10, padding: '5pt' },
  row: { display: 'flex', flexDirection: 'row', flexShrink: 0 },
  cell: {
    padding: 7,
    fontSize: 8,
    border: '1pt solid gray',
    borderRadius: 0,
    flexShrink: 0
  },
  cell_label: {
    flexBasis: '70%',
    backgroundColor: 'whitesmoke'
  },
  cell_value: {
    flexBasis: '30%',
    textAlign: 'right'
  },
  footer_cell: {
    backgroundColor: 'black',
    color: 'yellow',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    border: '1pt solid black'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 15
  }
});

const DH_DATE = "Do MMM, 'YY - LT";

export default function PdfDocument({ data, isOpsPdf }) {
  return (
    <Document>
      {data.map((ck) => (
        <Page size="A4" orientation="portrait" style={styles.page} key={ck.id}>
          <View style={styles.header}>
            <Text style={styles.header__title}>
              DeliveryHobe - Assignment Sheet {isOpsPdf && '(Ops)'}
            </Text>
            <Text style={[styles.small_text, { fontWeight: 'bold' }]}>
              Date: {moment().format(DH_DATE)}
            </Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.small_text, { fontWeight: 'bold' }]}>Agent: </Text>
              <Text style={styles.small_text}>
                {!ck.delivery_man ? 'N/A' : `${ck.delivery_man?.name} - ${ck.delivery_man?.phone}`}
              </Text>
            </View>
          </View>
          <View style={[styles.header, { borderBottom: '1pt solid lightgray' }]}>
            <Text style={[styles.small_text, { fontSize: 14, fontWeight: 'bold' }]}>
              DH - {ck.id}
            </Text>
            <View
              style={[
                styles.row,
                {
                  justifyContent: 'space-between'
                }
              ]}
            >
              <Text style={styles.small_text}>
                Order Date: {moment(ck.created_at).format(DH_DATE)}
              </Text>
              <Text style={styles.small_text}>
                Last Updated: {moment(ck.updated_at).format(DH_DATE)}
              </Text>
            </View>
          </View>
          <View style={[styles.header, { borderBottom: 0 }]}>
            <Text style={[styles.small_text, { fontWeight: 'bold' }]}>Deliver To:</Text>
            {isOpsPdf ? (
              <>
                <Text style={[styles.small_text, { fontWeight: 'bold' }]}>{ck.customer_name}</Text>
                <Text style={[styles.small_text, { margin: 0 }]}>{ck.customer_phone}</Text>
                <Text style={[styles.small_text, { margin: 0 }]}>{ck.full_address}</Text>
                <Text style={[styles.small_text, { margin: 0, color: 'gray' }]}>
                  Additional Request: {ck.additional_req}
                </Text>
              </>
            ) : (
              <></>
            )}

            <Text style={[styles.small_text, { fontWeight: 'bold', margin: 0 }]}>
              {ck?.area?.area} - {ck?.area?.zone}
            </Text>
          </View>
          <View style={{ marginHorizontal: -5 }} wrap>
            <Table data={ck.items.map((dt, i) => ({ ...dt, serial: i + 1 }))}>
              <TableHeader fontSize={10}>
                <TableCell style={styles.table_header} weighting={0.05}>
                  SL
                </TableCell>
                <TableCell style={styles.table_header} weighting={0.35}>
                  Product Name + Attributes
                </TableCell>
                <TableCell style={styles.table_header} weighting={0.4}>
                  Pickup Address
                </TableCell>
                <TableCell style={styles.table_header} weighting={0.2}>
                  Sourcing Price
                </TableCell>
                <TableCell style={styles.table_header} weighting={0.05}>
                  Qty
                </TableCell>
                {isOpsPdf && (
                  <TableCell style={styles.table_header} weighting={0.15}>
                    Total Price
                  </TableCell>
                )}
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={styles.table_cell}
                  weighting={0.025}
                  getContent={(r) => r.serial}
                />
                <DataTableCell
                  style={styles.table_cell}
                  weighting={0.34}
                  getContent={(r) => (
                    <>
                      <Text
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'Roboto'
                        }}
                      >
                        {r.item_name}
                      </Text>
                      <Text>
                        {/* {r.description} Price: Tk {r.price} */}
                        Price: Tk {r.price}
                      </Text>
                    </>
                  )}
                />
                <DataTableCell
                  style={styles.table_cell}
                  weighting={0.4}
                  getContent={(r) => r.assigned_pickup_source}
                />
                <DataTableCell
                  style={styles.table_cell}
                  weighting={0.2}
                  getContent={(r) => r.source_price}
                />
                <DataTableCell
                  style={styles.table_cell}
                  weighting={0.025}
                  getContent={(r) => r.quantity}
                />
                {isOpsPdf && (
                  <DataTableCell
                    style={[
                      styles.table_cell,
                      {
                        textAlign: 'right',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto'
                      }
                    ]}
                    getContent={(r) => `Tk ${r.price * r.quantity}`}
                    weighting={0.15}
                  />
                )}
              </TableBody>
            </Table>
          </View>
          <View
            style={[
              styles.row,
              {
                flexDirection: 'row-reverse',
                marginHorizontal: -5
              }
            ]}
            wrap={false}
          >
            <View
              style={{
                flexBasis: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch'
              }}
            >
              <View style={[styles.row]}>
                <Text style={[styles.cell, styles.cell_label]}>Subtotal</Text>
                <Text style={[styles.cell, styles.cell_value]}>
                  Tk{' '}
                  {isOpsPdf
                    ? ck.cart_price
                    : ck.items.reduce((ac, curr) => ac + curr?.source_price * curr.quantity, 0)}
                </Text>
              </View>
              {isOpsPdf && (
                <>
                  <View style={[styles.row]}>
                    <Text style={[styles.cell, styles.cell_label]}>Service charge (upto 9.5%)</Text>
                    <Text style={[styles.cell, styles.cell_value]}>+ Tk {ck.service_charge}</Text>
                  </View>
                  <View style={[styles.row]}>
                    <Text style={[styles.cell, styles.cell_label]}>Order Processing Fee</Text>
                    <Text style={[styles.cell, styles.cell_value]}>+ Tk {ck.processing_fee}</Text>
                  </View>
                  <View style={[styles.row]}>
                    <Text style={[styles.cell, styles.cell_label]}>VAT &amp; Taxes</Text>
                    <Text style={[styles.cell, styles.cell_value]}>+ Tk {ck.vat}</Text>
                  </View>
                  <View style={[styles.row]}>
                    <Text style={[styles.cell, styles.cell_label]}>Delivery Fee</Text>
                    <Text style={[styles.cell, styles.cell_value]}>+ Tk {ck.del_fee}</Text>
                  </View>
                  <View style={[styles.row]}>
                    <Text style={[styles.cell, styles.cell_label]}>Discount</Text>
                    <Text style={[styles.cell, styles.cell_value]}>- Tk {ck.discount}</Text>
                  </View>
                </>
              )}

              <View style={[styles.row]}>
                <Text style={[styles.cell, styles.cell_label, styles.footer_cell]}>
                  Final Collectable Price
                </Text>
                <Text style={[styles.cell, styles.cell_value, styles.footer_cell]}>
                  Tk{' '}
                  {isOpsPdf
                    ? ck.order_price
                    : ck.items.reduce((ac, curr) => ac + curr?.source_price * curr.quantity, 0)}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.footer} fixed>
            <Text style={styles.small_text}>
              * if any of the products are missing in the store please contact Station Manager and
              Customer immidiately.
            </Text>
          </View>
        </Page>
      ))}
    </Document>
  );
}
