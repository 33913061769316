import React from 'react';

const Print = props => (
  <svg {...props} fill="none">
    <path
      d="M4.4 6.95V1h10.2v5.95M4.4 14.6H2.7A1.7 1.7 0 011 12.9V8.65a1.7 1.7 0 011.7-1.7h13.6a1.7 1.7 0 011.7 1.7v4.25a1.7 1.7 0 01-1.7 1.7h-1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.6 11.2H4.4V18h10.2v-6.8z" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Print;
