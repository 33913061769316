import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFormContext } from 'react-hook-form';

import Select from 'components/select/AsyncSelect';
import Dm from 'repository/Dm';
import Rgba from 'utils/Rgba';

const useStyles = makeStyles({
  reactSelect: ({ minWidth, fullWidth }) => ({
    minWidth: minWidth ?? 200,
    maxWidth: fullWidth ? '100%' : 230
  }),
  selectLabel: {
    color: Rgba('#4A4A4A', 0.6),
    fontSize: 14,
    lineHeight: '12px',
    margin: '0 0 6px 8px'
  },
  selectBox: {
    '& .sh__control': {
      border: '1px solid #DDDBE1'
    }
  }
});

const promiseOptions = async (inputValue) => {
  const res = await Dm.getAll(inputValue ? { search: inputValue } : {});
  const dm = res.data.data.map((item) => ({ value: item.id, label: item.name }));

  return dm;
};

const DeliveryMan = ({ label }) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(null);
  const { register, setValue } = useFormContext();
  useEffect(() => {
    register({ name: 'delivery_man_id' }); // custom register react-select
  }, [register]);

  const handleSelect = (selectedOption) => {
    if (selectedOption) {
      setValue('delivery_man_id', selectedOption.value);
      setSelectedValue(selectedOption);
    } else {
      setValue('delivery_man_id', null);
      setSelectedValue(null);
    }
  };

  return (
    <div className={classes.reactSelect}>
      {label && <p className={classes.selectLabel}>{label}</p>}
      <Select
        isClearable
        defaultOptions
        className={classes.selectBox}
        value={selectedValue}
        onChange={handleSelect}
        placeholder="Delivery Man"
        cacheOptions
        loadOptions={promiseOptions}
        menuPlacement="top"
      />
    </div>
  );
};

export default DeliveryMan;
