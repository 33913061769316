import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from 'pages/Home';
import Statistics from 'pages/Statistics';
import Login from 'pages/Login/login';
import OrderDetails from 'pages/OrderDetails';
import ProductsPage from 'pages/Products';
import ProductDetails from 'pages/Products/ProductDetails';
import ProductBulkUpload from 'pages/Products/bulk';
import ProductsStoreBulkUpdate from 'pages/Stores/bulk';
import StoreBulkUpdate from 'pages/Stores/bulk/stores';
import PrivateRoute from 'components/PrivateRoute';
import DMHome from 'pages/DM';
import DMAddForm from 'pages/DM/DMAddForm';
import DMEditForm from 'pages/DM/DMEditForm';
import CategoriesPage from 'pages/Categories';
import StoresPage from 'pages/Stores';
import StoreDetails from 'pages/Stores/StoreDetails';
import CategoryDetails from 'pages/Categories/Details';
import CustomOrder from 'pages/CustomOrder';
import VoiceOrders from 'pages/VoiceOrders/index';
import VoiceOrderDetails from 'pages/VoiceOrderDetails';
import { useSelector } from 'react-redux';
import { selectors } from 'redux/auth';
import BrandPage from 'pages/Brand';
import BrandDetails from 'pages/Brand/BrandDetails';

function App() {
  const user = useSelector(selectors.getUser);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/dms/edit/:id">
            <PrivateRoute component={DMEditForm} />
          </Route>
          <Route path="/dms/add">
            <PrivateRoute component={DMAddForm} />
          </Route>
          <Route path="/dms">
            <PrivateRoute component={DMHome} />
          </Route>
          <Route path="/order/:id">
            <PrivateRoute component={OrderDetails} />
          </Route>
          <Route path="/voice-order/:id">
            <PrivateRoute component={VoiceOrderDetails} />
          </Route>
          <Route path="/products">
            <PrivateRoute component={ProductsPage} />
          </Route>
          <Route path="/product/bulkadd">
            <PrivateRoute component={ProductBulkUpload} />
          </Route>
          <Route path="/product/:id">
            <PrivateRoute component={ProductDetails} />
          </Route>
          <Route path="/categories">
            <PrivateRoute component={CategoriesPage} />
          </Route>
          <Route path="/brands">
            <PrivateRoute component={BrandPage} />
          </Route>
          <Route path="/brand/:id">
            <PrivateRoute component={BrandDetails} />
          </Route>
          <Route path="/stores">
            <PrivateRoute component={StoresPage} />
          </Route>
          <Route path="/store/:id">
            <PrivateRoute component={StoreDetails} />
          </Route>
          <Route path="/category/:id">
            <PrivateRoute component={CategoryDetails} />
          </Route>
          <Route path="/productstoreupdate">
            <PrivateRoute component={ProductsStoreBulkUpdate} />
          </Route>
          <Route path="/store/bulkadd">
            <PrivateRoute component={StoreBulkUpdate} />
          </Route>
          <Route path="/statistics">
            <PrivateRoute component={Statistics} />
          </Route>
          <Route path="/custom-order">
            {user?.role === 'admin' ||
            user?.role === 'super_admin' ||
            user?.role === 'super_ops' ? (
              <PrivateRoute component={CustomOrder} />
            ) : (
              <PrivateRoute component={Home} />
            )}
          </Route>
          <Route path="/voice-orders">
            {user?.role === 'admin' ||
            user?.role === 'super_admin' ||
            user?.role === 'super_ops' ? (
              <PrivateRoute component={VoiceOrders} />
            ) : (
              <PrivateRoute component={Home} />
            )}
          </Route>
          <Route path="/">
            <PrivateRoute component={Home} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
