import { useCallback } from 'react';
import debounce from 'lodash.debounce';

import Status from 'repository/Status';

const searchAPI = (text) => Status.getAll(text ? { search: text } : {});

export default function useStatusSelect() {
  const promiseOptions = (inputValue, callback) => {
    searchAPI(inputValue).then((res) => {
      const mappedData = res.data.data.map((item) => ({ value: item.value, label: item.label }));
      const result = mappedData.filter((st) =>
        st.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(result);
    });
    return;
  };

  const debouncedFunc = useCallback(debounce(promiseOptions, 300), []);
  return debouncedFunc;
}
