/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useRef, useEffect } from 'react';

// import { DatePicker } from '@material-ui/pickers';

// import AreaSelect from 'pages/Home/AreaSelect';
// import Select from 'components/select/Select';
// import AsyncSelect from 'components/select/AsyncSelect';
// import { orderHoldReasons } from 'data/orderHoldReasons';
// import MaterialTable from 'components/MaterialTable';

// import NumberInput from './NumberInput';
// import TextInput from './TextInput';

// import DmRepo from 'repository/Dm';
// import formatDate from 'utils/formatDate';
// import useStatusSelect from 'hooks/useStatusSelect';
// import AudioPlayer from 'material-ui-audio-player';
// import { useParams } from 'react-router-dom';
// import isPositiveInt from 'utils/isPositiveInt';
// import { compare } from 'fast-json-patch';
// import Toolbar from './Toolbar/toolbar';
// import Products from 'repository/Products';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import { useDispatch } from 'react-redux';
import {
  getOrderDetails,
  editProduct,
  addProduct,
  clearVpcItems,
  removeVpcItemsByIndex
} from 'redux/voiceOrderDetails';
import { useSelector } from 'react-redux';
import {
  updateCheckout,
  removeUnlistedProdsByIndex,
  deleteCheckOutItem,
  updateSingleVpcItemQty,
  deleteReqItem,
  updateConfirmOrder,
  updateConfirmLabeling
} from 'redux/voiceOrders';
import { baseUrl } from 'api/config';
import StoreProduct from './StoreProduct/storeProduct';
import {
  addToTempUnlistedProds,
  clearTempUnlistedProds,
  addToTempUnlistedProdNames
} from 'redux/voiceOrders';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReactAudioPlayer from 'react-audio-player';
import uploadIcon from 'assets/img/upload-voice.png';
import pencilIcon from 'assets/img/pencil.png';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  Button,
  Typography,
  makeStyles,
  Divider
} from '@material-ui/core';

import { Wrapper, Group, Row, ListHover, ProductsTotalCost } from './detailPane.style';
import CustomAreaSelect from '../CustomAreaSelect';
import { enqueueSnackbar } from '../../../redux/notifications';
import SuperFetch from '../../../api/SuperFetch';
import ProductForm from '../productForm';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headingContainer: {
    paddingBottom: '0.6rem',
    marginBottom: '1.5rem',
    borderBottom: '1px solid #000000',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttonRed: {
    backgroundColor: '#E73948',
    fontSize: '0.9rem',
    fontWeight: '700',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#E73948'
    }
  },
  buttonBlack: {
    backgroundColor: '#1E1E1E',
    fontSize: '0.9rem',
    fontWeight: '700',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1E1E1E'
    }
  },
  buttonYellow: {
    backgroundColor: '#FFE33F',
    fontSize: '0.9rem',
    fontWeight: '700',
    color: '#000',
    '&:hover': {
      backgroundColor: '#FFE33F'
    }
  },
  buttonAdd: {
    backgroundColor: 'rgba(255, 227, 63, 0.53)',
    fontSize: '0.9rem',
    fontWeight: '700',
    color: '#000',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(255, 227, 63, 0.53)'
    }
  },
  buttonBack: {
    backgroundColor: '#ffffff',
    fontSize: '0.9rem',
    fontWeight: '700',
    color: '#000',
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  }
});

const OrderDetailPane = ({ order, onOrderUpdate, order_id, refreshTable }) => {
  const classes = useStyles();
  // const [selectStates, setSelectStates] = useState({
  //   current_status: order?.status,
  //   hold_reason: { value: order?.hold_reason, label: order?.hold_reason },
  //   area_id: { value: order?.area?.id, label: order?.area?.area },
  //   hub_collection_status: order?.hub_collection,
  //   acc_collection_status: order?.acc_collection,
  //   delivery_man_id: {
  //     value: order?.delivery_man?.id,
  //     label: order?.delivery_man?.name
  //   },
  //   collection_method_value: order?.collection_method,
  //   payment_status_value: order?.payment_status
  // });
  const [voiceMsg, setVoiceMsg] = useState(
    'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3'
  );
  const authToken = useSelector((state) => state.auth.token);
  const [tempReqArr, setTempReqArr] = useState([]);
  const tableRef = useRef();
  // const { id } = useParams();
  const [stats, setStats] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  // const updateOrder = (body) => {
  //   onOrderUpdate(order.id, body);
  // };
  const voiceOrderTempList = useSelector((state) => state?.voiceOrderDetails?.vpcOrderList);
  const ordZone = useSelector((state) => state?.voiceOrderDetails?.zone);
  // const voiceOrderTempListIds = useSelector((state) => state?.voiceOrderDetails?.vpcOrderListIds);
  const voiceOrderId = useSelector((state) => state?.voiceOrderDetails?.data?.id);
  const voiceOrderDetails = useSelector((state) => state?.voiceOrderDetails?.data);
  const userInfo = useSelector((state) => state?.voiceOrderDetails?.data);
  const newVoiceOrderTempListIds = useSelector(
    (state) => state?.voiceOrderDetails?.newVpcOrderListIds
  );

  // const handleStatusSelect = (newValue) => {
  //   setSelectStates({
  //     ...selectStates,
  //     current_status: newValue
  //   });
  //   updateOrder({ current_status: newValue?.value });
  // };
  // const handleHoldSelect = (newValue) => {
  //   setSelectStates({
  //     ...selectStates,
  //     hold_reason: newValue
  //   });
  //   updateOrder({ hold_reason: newValue?.value ?? null });
  // };
  // const handleCityArea = (newValue) => {
  //   setSelectStates({
  //     ...selectStates,
  //     area_id: newValue
  //   });
  //   updateOrder({ area_id: newValue?.value });
  // };
  // const handleHmCollection = (newValue) => {
  //   setSelectStates({
  //     ...selectStates,
  //     hub_collection_status: newValue
  //   });
  //   updateOrder({ hub_collection_status: newValue?.value });
  // };
  // const handleAccCollection = (newValue) => {
  //   setSelectStates({
  //     ...selectStates,
  //     acc_collection_status: newValue
  //   });
  //   updateOrder({ acc_collection_status: newValue?.value });
  // };
  // const handleDmSelect = (newValue) => {
  //   setSelectStates({
  //     ...selectStates,
  //     delivery_man_id: newValue
  //   });
  //   updateOrder({ delivery_man_id: newValue?.value ?? null });
  // };
  // const handleDeliveryDate = (date) => {
  //   updateOrder({ delivery_date: formatDate(date, 'YYYY-MM-DD') });
  // };
  // const handlePaymentMethod = (newValue) => {
  //   setSelectStates({
  //     ...selectStates,
  //     collection_method_value: newValue
  //   });
  //   updateOrder({ collection_method_value: newValue?.value });
  // };
  // const handlePaymentStatus = (newValue) => {
  //   setSelectStates({
  //     ...selectStates,
  //     payment_status_value: newValue
  //   });
  //   updateOrder({ payment_status_value: newValue?.value });
  // };
  // const statusOptionsAsync = useStatusSelect();
  // const promiseOptions = async (input) => {
  //   const res = await DmRepo.getAll({ search: input });
  //   const data = res?.data?.data?.map((item) => ({ value: item.id, label: item.name }));
  //   return data;
  // };
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  const getData = async () => {
    dispatch(clearTempUnlistedProds());
    const res = await dispatch(getOrderDetails(Number(order_id), authToken));
    const copy = JSON.parse(JSON.stringify(res));
    setStats(copy);
    setVoiceMsg(copy?.voice);
    setTempReqArr(copy?.requestedProducts);
    for (let i = 0; i < copy?.requestedProducts?.length; i++) {
      // console.log(copy?.requestedProducts[i]?.product_name);
      dispatch(addToTempUnlistedProds(copy?.requestedProducts[i]));
      dispatch(addToTempUnlistedProdNames(copy?.requestedProducts[i]?.product_name));
    }
    // copy?.requestedProducts.map((reqProd) => dispatch(addToTempUnlistedProds(reqProd)));

    // console.log(copy);

    // console.log(copy?.vpcOrderItems);

    // console.log(voiceOrderTempList);

    return {
      // data: copy?.vpcOrderItems ?? [],
      data: copy?.vpcOrderItems ?? [],
      totalCount: copy?.count ?? 0,
      page: 0
    };
  };

  useEffect(() => {
    getData();
  }, []);

  // const handleRowEdit = async (newData, oldData) => {
  //   const data = JSON.parse(JSON.stringify(oldData)); // copy oldData object
  //   delete data.tableData; // tableData was added by material-table
  //   if (
  //     !isPositiveInt(newData.price) ||
  //     !isPositiveInt(newData.quantity) ||
  //     !isPositiveInt(newData.source_price)
  //   ) {
  //     alert('invalid amount');
  //     return;
  //   }
  //   const parsedData = {
  //     ...newData,
  //     price: Number(newData.price),
  //     quantity: Number(newData.quantity),
  //     source_price: Number(newData.source_price)
  //   };
  //   const diff = compare(data, parsedData);
  //   // console.log(diff);
  //   if (Boolean(diff.length)) {
  //     await dispatch(editProduct({ id: newData.id, data: diff }));
  //   }
  // };

  const unlistedProdsArr = useSelector((state) => state.voiceOrders.tempUnlistedProds);
  // const unlistedProdsArrNames = useSelector((state) => state.voiceOrders.tempUnlistedProdsNames);
  const newUnlistedProdsArrNames = useSelector(
    (state) => state.voiceOrders.newlyAddedUnlistedProdName
  );

  // const columns = [
  //   {
  //     title: 'Product ID',
  //     field: 'product_id',
  //     sorting: false,
  //     editable: 'never',
  //     render: ({ product }) => <p>{product?.id}</p>
  //   },
  //   {
  //     title: 'Item Name',
  //     field: 'item_name',
  //     sorting: false,
  //     editable: 'never',
  //     render: ({ product }) => <p>{product?.name}</p>
  //   },
  //   {
  //     title: 'Image',
  //     field: 'image',
  //     sorting: false,
  //     editable: 'never',
  //     render: ({ image, item_name }) => (
  //       <figure>
  //         <img src={image || 'https://dh-ops.now.sh/logo.png'} width="60" alt={item_name} />
  //       </figure>
  //     )
  //   },
  //   {
  //     title: 'Price',
  //     field: 'price',
  //     sorting: false,
  //     type: 'numeric',
  //     render: ({ product }) => <p>Tk {product?.selling_price}</p>
  //   },
  //   {
  //     title: 'Quantity',
  //     field: 'quantity',
  //     sorting: false,
  //     type: 'numeric',
  //     render: ({ product }) => <p>{product?.quantity}</p>
  //   }
  // ];

  const handleAddProduct = async (data, id) => {
    // const body = {
    //   id: stats?.id,
    //   data
    // };
    // console.log(body);
    await dispatch(addProduct(id, data));
    handleClose();
    // tableRef.current.onQueryChange();
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);
  const [confirmLabelDialog, setConfirmLabelDialog] = useState(false);
  const [conifrmOrderDialog, setConfirmOrderDialog] = useState(false);
  const [recModalOpen, setRecModalOpen] = useState(false);
  const [couponModalOpen, setCouponModalOpen] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [customProdModalOpen, setCustomProdModalOpen] = useState(false);
  const [isCouponBtnDisabled, setIsCouponBtnDisabled] = useState(true);

  const [editedAddressValue, setEditedAddressValue] = useState('');
  const [editedZoneValue, setEditedZoneValue] = useState('');
  const [coupon, setCoupon] = useState('');
  const [couponData, setCouponData] = useState({});

  const handleCouponFiledChange = (e) => {
    let text = e.target.value;
    if (text.length >= 2) {
      setIsCouponBtnDisabled(false);
      setCoupon(text);
    } else {
      setIsCouponBtnDisabled(true);
    }
  };

  const handleCouponSubmit = async () => {
    // console.log(coupon);

    let res = await SuperFetch(`/discounts/coupons/${coupon}`).then((res) => res);

    // console.log(res);
    if (res?.status === 404 || res?.data.message === 'Invalid promo code!') {
      // window.alert('Invalid Promo Code');
      dispatch(
        enqueueSnackbar({
          message: 'Invalid Promo Code',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }
        })
      );
      setCouponModalOpen(true);
    } else {
      // console.log('Valid Promo code');
      dispatch(
        updateCheckout(
          voiceOrderId,
          {
            coupon: coupon
          },
          authToken
        )
      );
      dispatch(
        enqueueSnackbar({
          message: 'Promo code applied successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      );
      setCouponData(res?.data);
      setIsCouponBtnDisabled(true);
      setCouponModalOpen(false);
      setTimeout(() => {
        dispatch(getOrderDetails(Number(order_id), authToken));
      }, 700);
    }
  };

  console.log(editedZoneValue);

  return (
    <Wrapper>
      <Dialog
        open={customProdModalOpen}
        onClose={() => {
          setCustomProdModalOpen(false);
          getData();
        }}
        maxWidth="md"
      >
        <Wrapper>
          <DialogTitle>
            <Typography component="h3" className="title">
              Add Product
            </Typography>
          </DialogTitle>
          <DialogContent>
            <ProductForm
              order={order}
              handleAddProduct={handleAddProduct}
              handleClose={() => {
                setCustomProdModalOpen(false);
                getData();
              }}
            />
          </DialogContent>
        </Wrapper>
      </Dialog>
      <Dialog
        open={couponModalOpen}
        onClose={() => setCouponModalOpen(false)}
        aria-labelledby="apply coupon"
        aria-describedby="apply coupon"
      >
        <DialogTitle id="alert-dialog-title">{'Add Coupon'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter your coupon code
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="e.g. KOLAMAN20"
            variant="outlined"
            onChange={handleCouponFiledChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCouponSubmit} disabled={isCouponBtnDisabled}>
            Apply Coupon
          </Button>
          <Button
            onClick={() => {
              setIsCouponBtnDisabled(true);
              setCouponModalOpen(false);
            }}
            autoFocus
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="Clear Cart Items ?"
        aria-describedby="Are you sure you clear all cart items ?"
      >
        <DialogTitle id="alert-dialog-title">{'Delete All Products Confirmation'}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={() => {
              dispatch(clearTempUnlistedProds());
              dispatch(clearVpcItems());
              setDialogOpen(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={cancelOrder}
        onClose={() => setCancelOrder(false)}
        aria-labelledby="Cancel Order"
        aria-describedby="Are you sure , you want to cancel this order ?"
      >
        <DialogTitle id="alert-dialog-title">{'Cancel Order'}</DialogTitle>
        <DialogContent>
          <Typography>⚠️ Are you sure , you want to cancel this order ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCancelOrder(false);
            }}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={() => {
              dispatch(
                updateCheckout(
                  voiceOrderId,
                  {
                    current_status: 'CANCELLED'
                  },
                  authToken
                )
              );
              setTimeout(() => {
                refreshTable();
              }, 700);
              setCancelOrder(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmLabelDialog}
        onClose={() => setConfirmLabelDialog(false)}
        aria-labelledby="Confirm Labeling ?"
        aria-describedby="⚠️ You can't undo this action"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Labeling ?'}</DialogTitle>
        <DialogContent>
          <Typography>⚠️ You can't undo this action</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmLabelDialog(false);
            }}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={() => {
              dispatch(updateConfirmLabeling(userInfo?.id, {}, authToken));
              setTimeout(() => {
                dispatch(getOrderDetails(Number(order_id), authToken));
                refreshTable();
              }, 700);
              setConfirmLabelDialog(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={conifrmOrderDialog}
        onClose={() => setConfirmOrderDialog(false)}
        aria-labelledby="Confirm Ordering ?"
        aria-describedby="⚠️ You can't undo this action"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Order ?'}</DialogTitle>
        <DialogContent>
          <Typography>⚠️ You can't edit this order after this</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmOrderDialog(false);
            }}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={() => {
              dispatch(updateConfirmOrder(userInfo?.id, {}, authToken));
              setTimeout(() => {
                dispatch(getOrderDetails(Number(order_id), authToken));
                refreshTable();
              }, 700);
              setConfirmOrderDialog(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addressModalOpen}
        onClose={() => {
          setAddressModalOpen(false);
          setEditedAddressValue('');
          setEditedZoneValue('');
        }}
        aria-labelledby="Edit Customer Address"
        aria-describedby="Edit Customer Address"
      >
        <DialogTitle id="alert-dialog-title">{'Edit Customer Address'}</DialogTitle>
        <DialogContent style={{ width: '400px', minHeight: '300px' }}>
          {/* <CustomAreaSelect onChange={(value) => {
                setAreaID(value);
                dispatch(changeZone(value));
              }}
              value={areaID}/> */}
          <CustomAreaSelect
            value={userInfo?.address?.area}
            onChange={(value) => {
              setEditedZoneValue(value);
            }}
          />
          <TextField
            className={classes.inputField}
            id="standard-basic"
            label="Full Address"
            multiline={true}
            variant="outlined"
            fullWidth={true}
            size="medium"
            defaultValue={
              editedAddressValue.length !== 0
                ? editedAddressValue
                : userInfo?.address !== null
                ? userInfo?.address?.full_address
                : ''
            }
            style={{ marginTop: '10px' }}
            onChange={(e) => {
              setEditedAddressValue(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddressModalOpen(false);
              setEditedAddressValue('');
              setEditedZoneValue('');
            }}
            autoFocus
          >
            Close
          </Button>
          <Button
            onClick={() => {
              // setAddressModalOpen(false);
              // console.log('clicked confirm edit address');
              console.log(editedAddressValue);
              console.log(editedZoneValue);
              if (editedZoneValue?.length === 0 && editedAddressValue?.length !== 0) {
                dispatch(
                  updateCheckout(
                    voiceOrderId,
                    {
                      address: {
                        id: userInfo?.address?.id,
                        area_id: userInfo?.address?.area_id,
                        area: userInfo?.address?.area,
                        zone: userInfo?.address?.zone,
                        full_address: editedAddressValue
                      }
                    },
                    authToken
                  )
                );
                dispatch(getOrderDetails(Number(order_id), authToken));
              }
              if (editedZoneValue?.value && editedAddressValue?.length === 0) {
                dispatch(
                  updateCheckout(
                    voiceOrderId,
                    {
                      address: {
                        id: userInfo?.address?.id,
                        area_id: editedZoneValue?.value,
                        area: editedZoneValue?.label,
                        zone: editedZoneValue?.zone,
                        full_address: userInfo?.address?.full_address
                      }
                    },
                    authToken
                  )
                );
                dispatch(getOrderDetails(Number(order_id), authToken));
              }
              if (editedZoneValue?.value && editedAddressValue?.length !== 0) {
                dispatch(
                  updateCheckout(
                    voiceOrderId,
                    {
                      address: {
                        id: userInfo?.address?.id,
                        area_id: editedZoneValue?.value,
                        area: editedZoneValue?.label,
                        zone: editedZoneValue?.zone,
                        full_address: editedAddressValue
                      }
                    },
                    authToken
                  )
                );
                dispatch(getOrderDetails(Number(order_id), authToken));
              }
              dispatch(getOrderDetails(Number(order_id), authToken));
              setAddressModalOpen(false);
            }}
            autoFocus
            variant="contained"
            color="primary"
            disabled={editedAddressValue?.length === 0 && !editedZoneValue?.zone}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        style={{ padding: '0', margin: '0' }}
        open={recModalOpen}
        onClose={() => setRecModalOpen(false)}
        aria-labelledby="Select Recommended Products"
        aria-describedby="Select Recommended Products"
      >
        {/* <DialogTitle id="alert-dialog-title">{'Iphone charger within Tk 800'}</DialogTitle> */}
        <DialogContent style={{ width: '600px' }}>
          {unlistedProdsArr?.map((prod) => (
            <>
              <Box className={classes.headingContainer}>
                <Typography variant="h6">{prod?.product_name}</Typography>
                <Button className={classes.buttonRed} variant="contained">
                  {'Can’t find'}
                </Button>
              </Box>
              <Box style={{ marginBottom: '1rem' }} className={classes.root}>
                <TextField id="standard-basic" label="Product Name" variant="standard" />
                <TextField
                  className={classes.inputField}
                  id="standard-basic"
                  label="Sourcing Price"
                  variant="standard"
                />
                <TextField
                  className={classes.inputField}
                  id="standard-basic"
                  label="Pickup Location"
                  variant="standard"
                />
                <Button variant="contained" style={{ backgroundColor: '#fff' }}>
                  <img src={uploadIcon} alt=" " />
                </Button>
                <Button className={classes.buttonAdd} variant="contained">
                  + Add to Cart
                </Button>
              </Box>
              <Box className={classes.root}>
                <TextField id="standard-basic" label="Product Name" variant="standard" />
                <TextField
                  className={classes.inputField}
                  id="standard-basic"
                  label="Sourcing Price"
                  variant="standard"
                />
                <TextField
                  className={classes.inputField}
                  id="standard-basic"
                  label="Pickup Location"
                  variant="standard"
                />
                <Button variant="contained" style={{ backgroundColor: '#fff' }}>
                  <img src={uploadIcon} alt=" " />
                </Button>
                <Button className={classes.buttonAdd} variant="contained">
                  + Add to Cart
                </Button>
              </Box>
            </>
          ))}
          {/* <ul>
            {unlistedProdsArr?.map((prod) => (
              <ListHover onClick={() => setRecModalOpen(true)}>
                <li style={{ marginLeft: '3px', fontWeight: 'bold', fontSize: '18px' }}>
                  {prod?.product_name}
                </li>
                <hr></hr>
              </ListHover>
            ))}
          </ul> */}
          <Box
            className={classes.root}
            style={{
              backgroundColor: 'rgba(30, 30, 30, 0.2)',
              padding: '1.5rem 1rem',
              marginTop: '2rem'
            }}
          >
            <Button
              className={classes.buttonBack}
              onClick={() => {
                setRecModalOpen(false);
              }}
              autoFocus
            >
              Back
            </Button>
            <Box style={{ display: 'flex' }}>
              <Button
                style={{ marginRight: '0.5rem' }}
                className={classes.buttonBlack}
                variant="contained"
              >
                + Add Another Request
              </Button>
              <Button className={classes.buttonYellow} variant="contained">
                Confirm Recommendations
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={() => {
              setRecModalOpen(false);
            }}
            autoFocus
          >
            Back
          </Button> */}
          {/* <Button
            onClick={() => {
              dispatch(clearTempUnlistedProds());
              dispatch(clearVpcItems());
              setDialogOpen(false);
            }}
          >
            Yes
          </Button> */}
        </DialogActions>
      </Dialog>

      {/* Created at <span>{formatDate(order.created_at)}</span> */}
      <Group>
        <h3 className="groupTitle">Audio Message</h3>
        <IconButton
          style={{ float: 'right' }}
          aria-label="refresh cart"
          onClick={() => {
            getData();
          }}
        >
          <RefreshIcon />
        </IconButton>
        <Row>
          <div style={{ marginBottom: '50px', marginTop: '10px' }}>
            <ReactAudioPlayer src={voiceMsg} controls style={{ width: '320px' }} />
            {/* <AudioPlayer src={voiceMsg} width="400px" /> */}
          </div>
        </Row>
        <div style={{ display: userInfo?.current_status === 'CONFIRMED' || userInfo?.current_status === 'CANCELLED' ? 'none' : 'block' }}>
          <StoreProduct order={order} handleAddProduct={handleAddProduct} refreshData={getData} />
        </div>
      </Group>
      <Group>
        <h3 className="groupTitle">Cart</h3>
        <div>
          {/* <MaterialTable
            style={{ maxHeight: '500px', overflow: 'scroll', width: '110%' }}
            tableRef={tableRef}
            components={{
              Toolbar: (props) => {
                return (
                  <Toolbar
                    open={open}
                    order={stats}
                    handleClose={handleClose}
                    handleClickOpen={handleClickOpen}
                    handleAddProduct={handleAddProduct}
                  />
                );
              }
            }}
            columns={columns}
            data={getData}
            options={{
              columnsButton: true,
              draggable: false,
              sorting: false,
              pageSize: 1000
            }}
            editable={{
              onRowUpdate: (newData, oldData) => handleRowEdit(newData, oldData),
              onRowDelete: (oldData) => Products.deleteProduct(oldData.id)
            }}
          /> */}
          <ol>
            {voiceOrderTempList?.map((prod, idx) => (
              <li style={{ marginBottom: '10px', fontSize: '16px' }}>
                <p style={{ fontSize: '16px' }}>
                  <img
                    src={
                      prod?.product?.images?.thumb
                        ? `${baseUrl}${prod?.product?.images?.thumb}`
                        : `https://wabisabiproject.com/wp-content/uploads/woocommerce-placeholder.png`
                    }
                    width="70"
                    alt={prod?.product?.name}
                    style={{ marginBottom: '10px' }}
                  />{' '}
                  <div style={{ marginLeft: '80px', marginTop: '-70px' }}>
                    <b>{prod?.product?.name}</b>
                    <span style={{ fontWeight: 'bold' }}>
                      Price: {prod?.product?.selling_price}Tk | Quantity:{' '}
                      {userInfo?.current_status === 'CONFIRMED' || userInfo?.current_status === 'CANCELLED' ? (
                        prod?.product?.quantity ? (
                          prod?.product?.quantity
                        ) : (
                          prod?.quantity
                        )
                      ) : (
                        <TextField
                          defaultValue={
                            prod?.product?.quantity ? prod?.product?.quantity : prod?.quantity
                          }
                          InputProps={{
                            inputProps: { min: 1 }
                          }}
                          type="number"
                          style={{
                            width: '40px',
                            fontWeight: 'bolder',
                            marginTop: '-4px',
                            textAlign: 'center'
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateSingleVpcItemQty(
                                prod?.id,
                                { quantity: parseInt(e.target.value) },
                                authToken
                              )
                            );
                            setTimeout(() => {
                              dispatch(getOrderDetails(Number(order_id), authToken));
                            }, 700);
                          }}
                        />
                      )}{' '}
                      | Product Id:
                      {prod?.product?.id ? prod?.product?.id : prod?.product?.product_id}
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      dispatch(removeVpcItemsByIndex(idx));
                      if (prod?.id) {
                        dispatch(deleteCheckOutItem(prod?.id, {}, authToken));
                      }
                      setTimeout(() => {
                        dispatch(getOrderDetails(Number(order_id), authToken));
                      }, 700);
                    }}
                  >
                    {userInfo?.current_status === 'CONFIRMED' || userInfo?.current_status === 'CANCELLED' ? null : (
                      <DeleteIcon
                        style={{ float: 'right', marginTop: '-20px', cursor: 'pointer' }}
                      />
                    )}
                  </div>
                </p>
                <hr></hr>
              </li>
            ))}
          </ol>
        </div>
        <ProductsTotalCost>
          <Box
            className={classes.root}
            style={{
              paddingBottom: '0.75rem',
              borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
              display: userInfo?.current_status === 'CONFIRMED' || userInfo?.current_status === 'CANCELLED' ? 'none' : ''
            }}
          >
            <Button
              className={classes.buttonBlack}
              variant="contained"
              style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '0.6rem' }}
              onClick={() => setCouponModalOpen(true)}
              disabled={voiceOrderTempList?.length === 0 ? true : false}
            >
              Add Coupon 🏷
            </Button>
            <Button
              className={classes.buttonAdd}
              variant="contained"
              style={{
                backgroundColor: '#FFFBD2',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.22)',
                borderRadius: '0.6rem'
              }}
              disabled={userInfo?.current_status === 'PENDING_LABELLING'}
              onClick={() => setCustomProdModalOpen(true)}
            >
              + Add Custom Product
            </Button>
          </Box>
          <Box className={classes.root} style={{ borderBottom: '1px solid #000000' }}>
            <Box className={classes.root} style={{ marginTop: '0.5rem', flexBasis: '60%' }}>
              <Typography>
                Address :{' '}
                {editedAddressValue.length !== 0
                  ? editedAddressValue
                  : userInfo?.address !== null
                  ? userInfo?.address?.full_address
                  : ''}
                (
                {editedZoneValue?.value && editedZoneValue?.length !== 0
                  ? editedZoneValue?.label
                  : userInfo?.address !== null
                  ? userInfo?.address?.area
                  : ''}
                )
              </Typography>
              <div
                className="pencil-wrapper"
                onClick={() => setAddressModalOpen(true)}
                style={{
                  minWidth: '40px',
                  display: userInfo?.current_status === 'CONFIRMED' || userInfo?.current_status === 'CANCELLED' ? 'none' : ''
                }}
              >
                <img src={pencilIcon} alt=" " />
              </div>
            </Box>
            <Divider
              orientation="vertical"
              flexItem="true"
              style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
            />
            <Box style={{ marginTop: '0.5rem', marginBottom: '0.5rem', flexBasis: '39%' }}>
              <Typography style={{ textAlign: 'right' }}>
                Total Cart Price : Tk {voiceOrderDetails?.cart_price}
              </Typography>
              <Typography style={{ textAlign: 'right' }}>
                Service Charge : Tk {voiceOrderDetails?.service_charge}
              </Typography>
              <Typography style={{ textAlign: 'right' }}>
                Delivery Charge : Tk {voiceOrderDetails?.del_fee}
              </Typography>
              {voiceOrderDetails?.coupon ? (
                <>
                  <Typography style={{ color: '#E73948', textAlign: 'right' }}>
                    Coupon Applied : {voiceOrderDetails?.coupon}
                  </Typography>
                  {/* <Typography style={{ color: '#E73948', textAlign: 'right' }}>
                    Discount : - Tk {voiceOrderDetails?.discount}({voiceOrderDetails?.coupon})
                  </Typography> */}
                </>
              ) : null}
              {/* <Typography style={{ color: '#E73948', textAlign: 'right' }}>
                Discount : - Tk {voiceOrderDetails?.discount}({voiceOrderDetails?.coupon})
              </Typography> */}
            </Box>
          </Box>
          <Box className={classes.root} style={{ marginTop: '0.3rem' }}>
            <Typography>Order No : {voiceOrderDetails?.id}</Typography>
            <Typography variant="h6" style={{ textAlign: 'right', fontSize: '0.80rem' }}>
              Total Payable : Tk {voiceOrderDetails?.order_price}
            </Typography>
          </Box>
        </ProductsTotalCost>
      </Group>
      <Group>
        {unlistedProdsArr?.length !== 0 || voiceOrderTempList?.length !== 0 ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            // disabled={
            //   newVoiceOrderTempListIds?.length === 0 && newUnlistedProdsArrNames?.length === 0
            // }
            disabled={userInfo?.current_status !== 'PENDING_LABELLING'}
            onClick={() => {
              setConfirmLabelDialog(true);
            }}
            style={{ marginBottom: '20px' }}
          >
            Confirm Labeling
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            // disabled={
            //   newVoiceOrderTempListIds?.length === 0 && newUnlistedProdsArrNames?.length === 0
            // }
            disabled={true}
            style={{ marginBottom: '20px' }}
          >
            Confirm Labeling
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          size="large"
          disabled={
            userInfo?.current_status !== 'PENDING_RECOMMENDATION' &&
            userInfo?.current_status !== 'PENDING_RECOMMENDATION_AND_PENDING_CONFIRMATION' &&
            userInfo?.current_status !== 'PENDING_CONFIRMATION'
          }
          onClick={() => setConfirmOrderDialog(true)}
          style={{ marginBottom: '20px', float: 'right' }}
        >
          Confirm Order
        </Button>
        <h3 className="groupTitle">Requested Products</h3>
        <Row>
          <p>Requested Products List</p>
        </Row>
        <ul>
          {unlistedProdsArr?.map((prod, idx) => (
            <div
              style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}
            >
              <ListHover onClick={() => setRecModalOpen(true)}>
                <li style={{ marginLeft: '3px' }}>{prod?.product_name}</li>
              </ListHover>
              <button
                onClick={() => {
                  dispatch(removeUnlistedProdsByIndex(idx));
                  if (prod?.id) {
                    dispatch(deleteReqItem(prod?.id, {}, authToken));
                  }
                  setTimeout(() => {
                    dispatch(getOrderDetails(Number(order_id), authToken));
                  }, 850);
                }}
                style={{
                  marginLeft: '5px',
                  cursor: 'pointer',
                  display: userInfo?.current_status === 'CONFIRMED' || userInfo?.current_status === 'CANCELLED' ? 'none' : 'block'
                }}
              >
                Delete Item
              </button>
            </div>
          ))}
        </ul>
        {/* <Button
          onClick={() => setRecModalOpen(true)}
          variant="contained"
          color="primary"
          disabled={
            unlistedProdsArr?.length === 0 || userInfo?.current_status === 'CONFIRMED'
              ? true
              : false
          }
        >
          Add Recommended Products
        </Button> */}
        <Button
          onClick={() => setCancelOrder(true)}
          variant="contained"
          color="secondary"
          disabled={
            userInfo?.current_status !== 'PENDING_LABELLING' &&
            userInfo?.current_status !== 'PENDING_RECOMMENDATION_AND_PENDING_CONFIRMATION' &&
            userInfo?.current_status !== 'PENDING_RECOMMENDATION' &&
            userInfo?.current_status !== 'PENDING_CONFIRMATION'
          }
        >
          Cancel Order
        </Button>
      </Group>
      {/* <Group>
        <h3 className="groupTitle">Accounts Collection</h3>
        <Row>
          <p>Payment Status</p>
          <Select
            className="dhSelect"
            classNamePrefix="dh"
            style={{ minWidth: 240 }}
            onChange={handlePaymentStatus}
            value={selectStates.payment_status_value}
            options={[
              {
                value: 'PAID',
                label: 'Paid'
              },
              {
                value: 'PENDING_PAYMENT',
                label: 'Pending Payment'
              }
            ]}
          />
        </Row>
        <Row>
          <p>Delivery Hobe Sourcing Price</p>
          <NumberInput
            name="dh_sourcing_price"
            defaultValue={order?.dh_sourcing_price}
            onSave={updateOrder}
          />
        </Row>
        <Row>
          <p>Outsourcing Price</p>
          <NumberInput
            name="outsourcing_price"
            defaultValue={order?.outsourcing_price}
            onSave={updateOrder}
          />
        </Row>
        <Row>
          <p>Freelance DM Fee</p>
          <NumberInput
            name="freelance_dm_fee"
            defaultValue={order?.freelance_dm_fee}
            onSave={updateOrder}
          />
        </Row>
      </Group> */}
    </Wrapper>
  );
};

export default OrderDetailPane;
