import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 300px;
  min-width: 400px;
  padding: 20px;
  .title {
    font-size: 24px;
    font-weight: 500;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
`;

export const ProductTopWrapper = styled.div`
  display: flex;
`;

export const ProductPriceWrapper = styled.div`
  display: flex;
`;

export const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
`;
