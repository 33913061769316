import React from 'react';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Wrapper, Header } from './addBrand.style';
import 'antd/dist/antd.css';
import BrandForm from '../BrandForm/brandForm';

const AddBrand = ({ open, handleClose, handleAddBrand }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullScreen fullWidth>
      <Wrapper>
        <Header>
          <Typography component="h3" className="title">
            Add a Brand
          </Typography>
          <div>
            <Button variant="contained" onClick={handleClose} style={{ margin: '0 4px' }}>
              Close
            </Button>
          </div>
        </Header>
        <BrandForm handleAddBrand={handleAddBrand} />
      </Wrapper>
    </Dialog>
  );
};

export default AddBrand;
