import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Select from 'components/select/AsyncSelect';
import Products from 'repository/Products';
import Rgba from 'utils/Rgba';

const useStyles = makeStyles({
  reactSelect: ({ minWidth, fullWidth }) => ({
    minWidth: minWidth ?? 200,
    maxWidth: fullWidth ? '100%' : 230
  }),
  selectLabel: {
    color: Rgba('#4A4A4A', 0.6),
    fontSize: 14,
    lineHeight: '12px',
    margin: '0 0 6px 8px'
  },
  selectBox: {
    '& .sh__control': {
      border: '1px solid #DDDBE1'
    }
  }
});

const promiseOptions = async (inputValue) => {
  const res = await Products.get(inputValue ? { search: inputValue } : {});
  const resObj = res?.data?.data?.map((item) => ({ value: item.id, label: item.name }));

  return resObj;
};

const ProductSelect = ({ label, onChange, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.reactSelect}>
      {label && <p className={classes.selectLabel}>{label}</p>}
      <Select
        cacheOptions
        defaultOptions
        onChange={onChange}
        defaultValue={value}
        loadOptions={promiseOptions}
        className={classes.selectBox}
        placeholder="Select Product"
      />
    </div>
  );
};

export default ProductSelect;
